import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';

const ProgresImage = () => {
  const svgFile = useSelector((state) => state.progressImage);

  return (
    <>
      <Box className="progress-bg-img">
        <img src={svgFile} alt="SVG" />
      </Box>
    </>
  );
};

export default ProgresImage;
