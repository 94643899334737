import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import DummyImg from 'src/assets/images/logo-black.png';
import DogCard from '../DogCard';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';
import { setApiMessage } from 'src/helpers/commonFunctions';
import moment from 'moment';
import '../thrownBones.scss';

const MatchesTab = ({ activeTab, localesData, selecteTab }) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [loader, setLoader] = useState(false);
  const [matchesData, setMatchesData] = useState([]);

  const handleBoxClick = (boxId) => {
    setSelectedBox((prevSelectedBox) =>
      prevSelectedBox === boxId ? null : boxId
    );
  };

  //GET MATCHES DATA
  const getMatchesdata = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.MATCHES);
      if (status === 200) {
        setApiMessage('success', data?.message);
        const allThrownData = data?.users?.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.id === thing.id)
        );
        setMatchesData(allThrownData);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getMatchesdata();
  }, [selecteTab]);

  return (
    <>
      {loader && <PreLoader />}
      {matchesData?.length === 0 ? (
        <Box className='kennel-page-head-sec'>
          <Box className='dummy-img t-center'>
            <img
              alt='not found'
              className='no-data-dummy-img mb-4 opacity'
              src={DummyImg}
            />
          </Box>
          <Typography
            variant='h3'
            className='t-center'
            dangerouslySetInnerHTML={{
              __html: localesData?.kennel_tab_no_data_found?.MATCHES_TAB,
            }}
          />
        </Box>
      ) : (
        <Box className='pb-18'>
          {matchesData &&
            matchesData?.length > 0 &&
            matchesData?.map((matches) => {
              //AGE
              let age = '';
              if (matches?.dob !== '' && matches?.dob !== null) {
                const d = moment(matches?.dob).format('MMM DD, YYYY');
                const msDiff = new Date().getTime() - new Date(d).getTime();
                const daysTill30June2035 = Math.floor(
                  msDiff / (1000 * 60 * 60 * 24)
                );
                age = Math.floor(daysTill30June2035 / 365);
              }
              return (
                <>
                  <Box key={matches}>
                    {/* MATCHES DATA */}
                    <DogCard
                      thrownid={matches}
                      activeTab={activeTab}
                      matches={matches}
                      dogs={matches?.dogs}
                      getMatchesdata={getMatchesdata}
                      setMatchesData={setMatchesData}
                      selectedBox={selectedBox}
                      handleBoxClick={handleBoxClick}
                      firstImg={matches?.images?.[0]?.url}
                      secondImg={matches?.dogs?.images?.[0]?.url}
                      star={matches?.star}
                      firstDogName={matches?.firstname}
                      secondDogName={matches?.lastname}
                      dogsName={matches?.dogs?.name}
                      deceaseDog={matches?.true}
                      firstDogBirthDate={age}
                      secondDogBirthDate={matches?.dogs?.age}
                      firstDogHeight={matches?.height}
                      firstDogLocationName={matches?.location}
                      secondDogGenderIcon={matches?.secondDogGenderIcon}
                      secondDogGender={matches?.dogs?.gender}
                      secondDogType={matches?.dogs?.breed}
                    />
                  </Box>
                </>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default MatchesTab;
