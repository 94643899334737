import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../../../App';
import IOSSwitch from '../../UI/IOSSwitch';
import UpgradePlan from '../ManageProfile/UpgradePlan';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import PreLoader from 'src/components/Loader';
import './Notification.scss';

const ProfileOpenNotification = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [loader, setLoader] = useState(false);

  const [notifications, setNotifications] = useState([
    {
      index: 0,
      subject: 'New matches',
      details:
        'When you log in, if you have pending matches, a notification will display to let you know. You can now click through to view these matches.',
      is_active: false,
    },
    {
      index: 1,
      subject: 'User accepts bone',
      details:
        'If someone has accepted your bone, the bone will be thrown into a kennel. You can now start chatting with your match. If the match is rejected, the dog will bury the bone.',
      is_active: false,
    },
    {
      index: 2,
      subject: 'User receives a match',
      details:
        'If someone throws you a bone, you can choose to accept it or reject it.',
      is_active: false,
    },
  ]);

  const getNotifications = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.NOTIFICATION);
      if (status === 200) {
        if (data === null) {
          createNotification();
        } else {
          const updatedNotifications = notifications?.map((item, index) => {
            let is_active = 'false';
            if (index === 0) {
              is_active = data?.newMatch ? true : false;
            } else if (index === 1) {
              is_active = data?.newMessage ? true : false;
            } else if (index === 2) {
              is_active = data?.unreadMessage ? true : false;
            }
            return {
              ...item,
              is_active,
            };
          });
          setLoader(false);
          setNotifications(updatedNotifications);
        }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const createNotification = async () => {
    try {
      setLoader(true);
      const param_data = {
        newMatch: 'true',
        newMessage: 'true',
        unreadMessage: 'true',
      };
      const { status } = await axiosInstance.post(
        URLS.NOTIFICATION,
        param_data
      );
      if (status === 200) {
        setLoader(false);
        getNotifications();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getNotifications();
    //eslint-disable-next-line
  }, []);

  const setItem = (e, index) => {
    const { checked } = e.target;
    const updatedNotifications = notifications.map((notification, idx) => {
      if (index === idx) {
        return {
          ...notification,
          is_active: checked,
        };
      }
      return notification;
    });
    setNotification(updatedNotifications);
    setNotifications(updatedNotifications);
  };

  const setNotification = async (currentNotifications) => {
    try {
      let newMatch = 'false';
      let newMessage = 'false';
      let unreadMessage = 'false';
      currentNotifications?.map((item, index) => {
        if (index === 0) {
          newMatch = item?.is_active === true ? 'true' : 'false';
        } else if (index === 1) {
          newMessage = item?.is_active === true ? 'true' : 'false';
        } else if (index === 2) {
          unreadMessage = item?.is_active === true ? 'true' : 'false';
        }
        return true;
      });
      const param_data = {
        newMatch,
        newMessage,
        unreadMessage,
      };
      const { status } = await axiosInstance.put(URLS.NOTIFICATION, param_data);
      if (status === 200) {
        setLoader(false);
      }
    } catch (error) {
      setLoader(true);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      {loader ? (
        <PreLoader />
      ) : (
        <Box className='notification-head-sec'>
          <Box className='account-type-sec mb-3'>
            <UpgradePlan
              title={localesData?.profile_notification?.NOTIFICATION}
            />
          </Box>

          <Grid
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={2.8}
            className='switch-sec-grid '
          >
            {notifications &&
              notifications?.length > 0 &&
              notifications?.map((content, id) => {
                return (
                  <>
                    <Grid
                      item
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className='left-content-grid pb-2'
                    >
                      <Typography className='switch-title'>
                        {content.subject}
                      </Typography>
                      <Typography className='switch-desvc'>
                        {content.details}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className='right-btn-grid'
                    >
                      <FormGroup className='switch-btn'>
                        <FormControlLabel
                          control={<IOSSwitch />}
                          name='is_active'
                          checked={
                            content?.is_active === 'true' ||
                            content?.is_active === true
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setItem(e, id);
                          }}
                        />
                      </FormGroup>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProfileOpenNotification;
