import { createSlice } from '@reduxjs/toolkit';

export const questionAnswerSlice = createSlice({
  name: 'questionAnswer',
  initialState: {},
  reducers: {
    addQuestionAnswer: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    clearQuestionAnswer: (state) => {
      return {};
    },
  },
});

export const { addQuestionAnswer, clearQuestionAnswer } =
  questionAnswerSlice.actions;

export default questionAnswerSlice.reducer;
