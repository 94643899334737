import React, { useContext, useState } from 'react';
import './premiumPopup.scss';
import {
  Box,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
} from '@mui/material';
import Petbarn from '../../../../assets/images/premium-dog.svg';
import { IntlContext } from '../../../../App';
import CustomButton from '../Button/CustomButton';
import SubDialogbox from '../Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from '../Subscriptionpopup/SubscriptionDialog';

const PremiumPopup = ({ handleClose, navigate }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [toogleDialog, setToogleDialog] = useState(false);

  return (
    <Box className='premium-popup-sec'>
      <Box className='premium-card'>
        <Box className='premium-dog-img pb-1'>
          <CardMedia
            component='img'
            image={Petbarn}
            alt='green iguana'
            className='dog-img'
          />
        </Box>
        <CardContent>
          {/* <Typography
            dangerouslySetInnerHTML={{
              __html: localesData?.premium_popup?.YOU_HAVE_NO_MORE_BONES,
            }}
            className='premium-head-text pb-3'
          /> */}
          <Typography
            dangerouslySetInnerHTML={{
              __html: localesData?.premium_popup?.PREMIUM_DESC,
            }}
            className='premium-head-text pb-1'
          />
        </CardContent>

        <CardActions className='card-action-btn'>
          <CustomButton
            variant='contained'
            title={localesData?.premium_popup?.UPGRADE_BTN}
            // background='#F54D71'
            fontWeight='600'
            type='submit'
            classname='upgrade-premium-btn card-btns'
            onClick={() => {
              setToogleDialog(!toogleDialog);
            }}
          />
        </CardActions>
        <CardActions className='card-action-btn'>
          <CustomButton
            variant='contained'
            title={localesData?.premium_popup?.CANCEL_BTN}
            // background='#F54D71'
            fontWeight='600'
            type='submit'
            classname='premium-cancel-btn card-btns'
            onClick={handleClose}
          />
        </CardActions>
      </Box>
      <SubDialogbox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default PremiumPopup;
