import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

import { ReactComponent as SelectedIcon } from '../../../../assets/images/icons/selected-icon.svg';
// import DogPhoto from "../../../../assets/images/dogPhoto.png";
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import './profileCompletion.scss';

// const dogImage = [
// 	{
// 		id: 1,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 2,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 3,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 4,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 5,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 6,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 7,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 8,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 9,
// 		image: DogPhoto,
// 	},
// 	{
// 		id: 10,
// 		image: DogPhoto,
// 	},
// ];
const SocialMediaImages = ({ getSelectedMedia, mediaArray, handleClose }) => {
  // const [source, setSource] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  // const videoRef = useRef(null);

  // useEffect(() => {
  //   videoRef.current && (videoRef.current.autoplay = true);
  // }, [source]);

  // useEffect(() => {
  // 	getSelectedMedia(selectedImages);
  // }, [selectedImages]);

  return (
    <Grid container className='imagre-box'>
      {selectedImages?.length > 0 && (
        <Box className='dialog-save'>
          <CustomButton
            variant='contained'
            title='Save'
            background='#59D09D'
            fontWeight='600'
            classname='save-btn inherit'
            onClick={() => {
              getSelectedMedia(selectedImages);
              handleClose();
            }}
          />
        </Box>
      )}
      {mediaArray &&
        mediaArray?.map((item) => (
          <Grid
            item
            key={item.id}
            xs={3}
            className='pointer'
            onClick={() => {
              if (selectedImages.find((image) => image.id === item.id)) {
                setSelectedImages((prevSelectedImages) =>
                  prevSelectedImages.filter((image) => image.id !== item.id)
                );
              } else {
                if (selectedImages.length < 9) {
                  setSelectedImages((prevSelectedImages) => [
                    ...prevSelectedImages,
                    item,
                  ]);
                } else {
                  // Display an error message or handle it as per your requirements
                }
              }
            }}
          >
            <Box
              className={`image-wrapper ${
                selectedImages.find((image) => image.id === item.id)
                  ? 'selected'
                  : ''
              }`}
            >
              <img src={item?.media_url} alt={`Dog ${item.id}`} />
              {selectedImages.find((image) => image.id === item.id) && (
                <span className='selected-icon'>
                  <SelectedIcon />
                </span>
              )}
            </Box>
          </Grid>
        ))}
      {mediaArray?.length === 0 && (
        <Typography variant='h5' className='w-100 t-center'>
          No media
        </Typography>
      )}
    </Grid>
  );
};

export default SocialMediaImages;
