import React from 'react';
import Page from '../../../components/Page';
import Favourites from 'src/views/component/application/Favourites';

const FavouritesView = () => {
  return (
    <Page title='Favourites'>
      <Favourites />
    </Page>
  );
};

export default FavouritesView;
