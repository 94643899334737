import React, { useContext, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IntlContext } from '../../../../App';

import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import SetFirstName from './SetFirstName';
import SetLastName from './SetLastName';
import SetEmail from './SetEmail';

import PreLoader from '../../../../components/Loader';

const ProfileSetup = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const profileData = useSelector((state) => state?.profileData);

  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!profileData?.phone_number) {
      navigate('/sign-in');
    }
  }, [navigate, profileData]);

  useEffect(() => {
    if (reduxStep) {
      setActiveStep(reduxStep);
    } else {
      setActiveStep(0);
    }
  }, [reduxStep]);

  const handleNexts = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(handleNext(activeStep + 1));
  };

  const renderComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <SetFirstName localesData={localesData} handleNext={handleNexts} />
        );
      case 1:
        return (
          <SetLastName localesData={localesData} handleNext={handleNexts} />
        );
      case 2:
        return <SetEmail localesData={localesData} setLoader={setLoader} />;
      default:
        return (
          <SetFirstName localesData={localesData} handleNext={handleNexts} />
        );
    }
  };

  return (
    <Box className='page-content-wrap'>
      {loader && <PreLoader />}
      {renderComponent()}
    </Box>
  );
};

export default ProfileSetup;
