import React, { Component } from 'react';
import './chat-style.scss';
// import ChatHeader from './components/ChatHeader';
// import WelcomeContent from './components/WelcomeContent';
import MessageContainer from './Messages/container/MessageContainer';
// import MessageContainer from './Messages/container/GroupMessageContainer';

class WelcomePage extends Component {
  render() {
    return (
      <>
        {/* <ChatHeader
          image={require('../../../../assets/images/logo-black.png')}
          title={'Champy'}
          showMenuButton={false}
        /> */}
        <div className='chat-content'>
          {/* <WelcomeContent /> */}
          {/* <MessageContainer /> */}
          <MessageContainer />
        </div>
      </>
    );
  }
}

export default WelcomePage;
