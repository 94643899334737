import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  // FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { ReactComponent as Checked } from 'src/assets/images/profile-checked.svg';
// import { addProfileData } from 'src/helpers/store/actions/profileSetup';
import { ReactComponent as UnChecked } from 'src/assets/images/unchecked.svg';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';

// import {
//   clearSvgData,
//   setSVGFile,
// } from '../../../../helpers/store/actions/progressImage';
// import svgData from '../../../../assets/images/dob.svg';
// import ProgressBar from '../../UI/ProgressBar';
import ProgressBar from 'src/views/component/UI/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { IntlContext } from 'src/App';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import PreLoader from 'src/components/Loader';

const StartTalk = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  // const reduxProfileData = useSelector((state) => state?.profileData);
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [answars, setAnswars] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [checkLooking, setCheckLooking] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [progressWeightage, setProgressWeightage] = useState(0);
  // const reduxProgress = useSelector((state) => state?.progressBarData);
  const navigate = useNavigate();

  const getAllAnswers = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setAnswars(data?.questions);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllAnswers();
  }, []);

  useEffect(() => {
    // Get the selected chips from the saved data and set them in selectedChips state
    const savedSelectedChips = answars
      ?.find((o) => o?.id === 8)
      ?.Option?.filter((item) => item?.selected === 1);

    setSelectedChips(savedSelectedChips || []);

    // Set the available options for the checkboxes in checkLooking state
    answars?.map((item) => {
      if (item?.id === 8) {
        setCheckLooking(item?.Option?.filter((option) => option?.id !== 13));
      }
      return true;
    });
  }, [answars]);

  const getAllQuestionsBlank = async () => {
    var black_data = 0;
    var job = true;
    var education = true;
    var height_weight = true;
    var vices = true;
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      if (status === 200) {
        data?.questions?.map((item) => {
          // if (item.id === 8) {
          //   black_data++; //looking for
          // }
          if (
            item.id === 9 ||
            item.id === 10 ||
            item.id === 11 ||
            item.id === 12
          ) {
            if (job) {
              black_data++; //job
              job = false;
            }
          }
          if (item.id === 13 || item.id === 14 || item.id === 15) {
            if (education) {
              black_data++; //education
              education = false;
            }
          }
          if (item.id === 16) {
            black_data++; //hobbies
          }
          if (item.id === 17) {
            black_data++; //religion
          }
          if (item.id === 18) {
            black_data++; //politics
          }
          if (item.id === 19) {
            black_data++; //children
          }
          if (item.id === 20) {
            black_data++; //how many kids
          }
          if (item.id === 23 || item.id === 24) {
            if (height_weight) {
              black_data++; //height weight
              height_weight = false;
            }
          }
          if (item.id === 25) {
            black_data++; //ethnicity
          }
          if (item.id === 26) {
            black_data++; //family plan
          }
          if (
            item.id === 28 ||
            item.id === 29 ||
            item.id === 30 ||
            item.id === 31
          ) {
            if (vices) {
              black_data++; //vices
              vices = false;
            }
          }
          if (item.id === 33) {
            black_data++; //location
          }
          if (item.id === 2) {
            black_data++; //about gender
          }
          if (item.id === 54) {
            black_data++; //about age
          }
          if (item.id === 55) {
            black_data++; //about dog breed
          }
          if (item.id === 56) {
            black_data++; //about job
          }
          if (item.id === 57) {
            black_data++; //about education
          }
          if (item.id === 58) {
            black_data++; //about hobbies
          }
          if (item.id === 59) {
            black_data++; //about religion
          }
          if (item.id === 60) {
            black_data++; //about politics
          }
          if (item.id === 61) {
            black_data++; //about height weight
          }
          if (item.id === 62) {
            black_data++; //about ethnicity
          }
          if (item.id === 63) {
            black_data++; //about vices
          }
          if (item.id === 64) {
            black_data++; //about location
          }
          return true;
        });

        setProgressWeightage((25 - black_data) * 4);
        // dispatch(addProgressBar((25 - black_data) * 4));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllQuestionsBlank();
  }, []);

  // const checkForBlankData = async () => {
  //   try {
  //     const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
  //     var breakIt = false;

  //     if (status === 200) {
  //       data?.questions.map((item) => {
  //         if (breakIt === false) {
  //           console.log('hello', item);
  //           if (
  //             item.id === 9 ||
  //             item.id === 10 ||
  //             item.id === 11 ||
  //             item.id === 12
  //           ) {
  //             breakIt = true; //job
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(1));
  //           } else if (item.id === 13 || item.id === 14 || item.id === 15) {
  //             breakIt = true; //education
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(2));
  //           } else if (item.id === 16) {
  //             breakIt = true; //hobbies
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(3));
  //           } else if (item.id === 17) {
  //             breakIt = true; //religion
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(4));
  //           } else if (item.id === 18) {
  //             breakIt = true; //politics
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(5));
  //           } else if (item.id === 19) {
  //             breakIt = true; ///children
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(6));
  //           } else if (item.id === 20) {
  //             breakIt = true; //how many kids
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext('otherKids'));
  //           } else if (item.id === 23 || item.id === 24) {
  //             breakIt = true; //height weight
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(7));
  //           } else if (item.id === 25) {
  //             breakIt = true; //ethnicity
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(8));
  //           } else if (item.id === 26) {
  //             breakIt = true; //family plan
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(9));
  //           } else if (
  //             item.id === 28 ||
  //             item.id === 29 ||
  //             item.id === 30 ||
  //             item.id === 31
  //           ) {
  //             breakIt = true; //vices
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(10));
  //           } else if (item.id === 33) {
  //             breakIt = true; //location
  //             navigate('/profile-completion-advance', { state: 'isEdit' });
  //             dispatch(handleNext(11));
  //           } else {
  //             breakIt = true;
  //             navigate('/edit-profile', {
  //               state: 'profile-edit',
  //             });
  //           }
  //         }
  //         return true;
  //       });
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  return (
    <Formik
      initialValues={{
        looking_for: selectedChips?.map((chip) => chip.id.toString()),
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        looking_for: Yup.array().min(
          1,
          localesData?.validation?.SELECT_AT_LIST_ONE
        ),
      })}
      onSubmit={async (requestData) => {
        var opt = [];

        opt.push({
          optionId: checkLooking[0]?.id,
          answer: requestData?.looking_for?.includes(
            checkLooking[0]?.id?.toString()
          )
            ? '1'
            : '0',
          selected: requestData?.looking_for?.includes(
            checkLooking[0]?.id?.toString()
          ),
          private: false,
        });
        opt.push({
          optionId: checkLooking[1]?.id,
          answer: requestData?.looking_for?.includes(
            checkLooking[1]?.id?.toString()
          )
            ? '1'
            : '0',
          selected: requestData?.looking_for?.includes(
            checkLooking[1]?.id?.toString()
          ),
          private: false,
        });

        let sendData = {
          private: false,
          optionIds: opt,
        };
        try {
          setLoader(true);
          const { status, data } = await axiosInstance.post(
            URLS.SET_ANSWERS,
            sendData
          );
          if (status === 200) {
            setApiMessage('success', data?.message);
            navigate('/profile-completion-advance', { state: 'isEdit' });
            dispatch(handleNext(1));
            // checkForBlankData();
            // navigate('/profile-completion-advance', {
            //   state: 'isEdit',
            // });
            // handleNext();
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
          setApiMessage('error', error?.response?.data?.message);
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className='form-content pb-6'>
          {loader && <PreLoader />}
          <Box className='t-center mb-5'>
            <Typography
              className='mb-2 font-r-12'
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.profile_completion_user?.PROFILE_COMPLETION,
              }}
            />
            <Box className='mb-3'>
              <Typography variant='h4' className='pb-1'>
                {/* {reduxProgress}% */}
                {/* {profileData?.weightage}% */}
                {progressWeightage}%
              </Typography>
              <ProgressBar processData={progressWeightage} />
            </Box>
            <Typography variant='h3' className='mb-4 s-bold'>
              {localesData?.profile?.ALL_ABOUT_YOU_TEXT}{' '}
            </Typography>
            <Typography variant='h3' className='mb-4 s-bold'></Typography>
            <Typography
              variant='h3'
              className='mb-4'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.LETS,
              }}
            />
            <Typography variant='h4' className='s-bold'>
              {localesData?.profile?.SELECT_AS_YOU_LIKE}
            </Typography>
          </Box>

          <Box className='field-content pb-20'>
            {checkLooking?.map((option, i) => (
              <Box className='checkbox-container mb-4' key={i}>
                <Typography variant='h5' className='custom-checkbox-text'>
                  {option?.title}
                </Typography>
                <Checkbox
                  className='custom-checkbox'
                  icon={<UnChecked />}
                  checkedIcon={<Checked />}
                  checked={values?.looking_for?.includes(
                    option?.id?.toString()
                  )}
                  disableRipple
                  value={option?.id}
                  name='looking_for'
                  onChange={handleChange}
                />
              </Box>
            ))}
            {errors.looking_for && (
              <FormHelperText className='color-error'>
                {errors.looking_for}
              </FormHelperText>
            )}
          </Box>
          <Box>
            <CustomButton
              variant='contained'
              title={'DONE'}
              background='#59D09D'
              fontWeight='600'
              type='submit'
              onClick={() => handleSubmit()}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default StartTalk;
