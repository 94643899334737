import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@mui/material';
import CustomButton from '../../../../UI/Button/CustomButton';
import { ReactComponent as LockIcon } from '../../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../../assets/images/icons/unlock.svg';
import { setApiMessage } from '../../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../../helpers/store/actions/profileSetup';
import IOSSwitch from '../../../../UI/IOSSwitch';
import { CustomTextField } from '../../../../UI/textfield';
import { useNavigate } from 'react-router-dom';
import UpgradePlan from '../../UpgradePlan';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const YourChildren = ({
  localesData,
  handleNext,
  questions,
  setLoader,
  dispatch,
  answars,
}) => {
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [youHaveKidsData, setYouHaveKidsData] = useState('');
  const [haveKidsIsPrivate, setHaveKidsIsPrivate] = useState(false);
  const [oldChildData, setOldChildData] = useState('');
  const [childGender, setChildGender] = useState('');
  const [otherData, setOtherData] = useState({
    totalKids: [],
    how_many_kids: '',
    show_how_many_kids_error: false,
  });
  const [toogleDialog, setToogleDialog] = useState(false);
  const genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    answars?.map((item) => {
      if (item.id === 20) {
        setYouHaveKidsData({
          title: item.title,
          id: item.id,
          options: item.Option,
        });
        setHaveKidsIsPrivate(
          item?.Option?.[0]?.private === 1 || item?.Option?.[0]?.private === '1'
            ? true
            : false
        );
      }
      if (item.id === 21) {
        setOldChildData({
          title: item.title,
          id: item.id,
          options: item.Option,
        });
        setOtherData({
          ...otherData,
          // totalKids: JSON?.parse(item?.Option?.[0]?.answer),
          totalKids: item?.Option?.[0]?.answer
            ? JSON.parse(item.Option[0].answer)
            : null,
        });
      }
      if (item.id === 22) {
        setChildGender({
          title: item.title,
          id: item.id,
          options: item.Option,
        });
      }
      return true;
    });
    // eslint-disable-next-line
  }, [answars]);
  const handleChange = (index, e) => {
    const { name, value, checked } = e?.target;
    if (name === 'youHaveKidsData') {
      setYouHaveKidsData({
        ...youHaveKidsData,
        options: [
          {
            ...youHaveKidsData.options[0],
            answer: value,
          },
          ...youHaveKidsData.options.slice(1),
        ],
      });
      setOtherData({
        ...otherData,
        totalKids: [],
        how_many_kids: value,
        show_how_many_kids_error: false,
      });
      setErrorMessage('');
    }

    if (name === 'kidsHaveSwitch') {
      setYouHaveKidsData({ ...youHaveKidsData, private: checked });
      setHaveKidsIsPrivate(checked);
    }
    if (name === 'oldChildData-' + index) {
      setOldChildData({ ...oldChildData, answer: value });
      let addAge = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, age_state: value };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addAge });
    }
    if (name === 'oldChildDataSwitch-' + index) {
      setOldChildData({ ...oldChildData, private: checked });
      let addAgePrivate = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, age_private: checked };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addAgePrivate });
    }
    if (name === 'childGender-' + index) {
      setChildGender({ ...childGender, answer: value });
      let addGender = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, gender_state: value };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addGender });
    }
    if (name === 'childGenderSwitch-' + index) {
      setChildGender({ ...childGender, private: checked });
      let addGenderPrivate = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, gender_private: checked };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addGenderPrivate });
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    opt.push({
      optionId: youHaveKidsData?.options[0]?.id,
      answer:
        youHaveKidsData?.options[0]?.answer === ''
          ? null
          : youHaveKidsData?.options[0]?.answer,

      selected: false,
      private: youHaveKidsData?.private ? youHaveKidsData?.private : false,
    });

    opt.push({
      optionId: oldChildData?.options[0]?.id,
      answer:
        otherData?.totalKids === ''
          ? null
          : JSON.stringify(otherData?.totalKids),

      selected: false,

      private: oldChildData?.private ? oldChildData?.private : false,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };

    try {
      if (!youHaveKidsData?.options[0]?.answer) {
        setErrorMessage('Please enter, how many kids do you have?.');
        return;
      }
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        handleNext();
        setLoader(false);
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const onChangeText = (text) => {
    if (Number(text) === 0 || text.trim().length === 0) {
      setOtherData({
        ...otherData,
        totalKids: [],
        how_many_kids: text,
        show_how_many_kids_error: false,
      });
    } else {
      for (let i = 1; i <= Number(text); i++) {
        otherData.totalKids.push({
          index: i,
          age_state: '',
          gender_state: '',
          age_private: false,
          gender_private: false,
        });
      }
      setOtherData({
        ...otherData,
        how_many_kids: text,
        show_how_many_kids_error: false,
      });
    }
  };
  return (
    <>
      <UpgradePlan title={localesData?.profile?.YOUR_CHILDREN} />
      <Box
        className='t-center your-childern-box'
        // style={{ overflowY: 'scroll', height: '100%', maxHeight: '400px' }}
      >
        <Box>
          <Typography variant='h4'>
            {localesData?.edit_profile?.you_have_some_kids}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'left' }} className='pb-6'>
          <Box className='field-content'>
            <CustomTextField
              fullWidth
              variant='standard'
              className='custom-textfield pb-2'
              name='youHaveKidsData'
              value={youHaveKidsData?.options?.[0]?.answer}
              onChange={(e) => handleChange('', e)}
              onBlur={(e) => onChangeText(e.target.value)}
              onKeyPress={(e) => {
                if (/[^0-9]/g.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </Box>
          <Box>
            <Typography variant='h5' className='s-bold pb-2'>
              {youHaveKidsData?.title}
            </Typography>
            <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  name='kidsHaveSwitch'
                  checked={haveKidsIsPrivate}
                  onChange={(e) => {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange('', e);
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {youHaveKidsData?.private ? <LockIcon /> : <UnLockIcon />}
            </Box>
          </Box>
          {otherData?.totalKids &&
            otherData?.totalKids?.map((kids) => {
              return (
                <>
                  <Box className='field-content'>
                    <CustomTextField
                      fullWidth
                      variant='standard'
                      className='custom-textfield pb-2'
                      name={`oldChildData-` + kids?.index}
                      value={kids?.age_state}
                      onKeyPress={(e) => {
                        if (/[^0-9]/g.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleChange(kids?.index, e)}
                    />
                  </Box>
                  <Box>
                    <Typography variant='h5' className='s-bold pb-2'>
                      {/* {oldChildData?.title} */}
                      {localesData?.user_kids?.HOW_OLD_IS_CHILD} {kids?.index}?
                    </Typography>
                    <Box
                      sx={{ justifyContent: 'space-around' }}
                      className='d-flex pb-4'
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={<IOSSwitch />}
                          name={`oldChildDataSwitch-` + kids?.index}
                          checked={kids?.age_private}
                          onChange={(e) => {
                            profileData?.isPaid === false
                              ? setToogleDialog(true)
                              : handleChange(kids?.index, e);
                          }}
                        />
                      </FormGroup>
                      <Typography variant='h5'>
                        {localesData?.userwork?.thats_private_dont_show_this}
                      </Typography>
                      {kids?.age_private ? <LockIcon /> : <UnLockIcon />}
                    </Box>
                  </Box>
                  <Box className='field-content'>
                    <FormControl fullWidth variant='standard'>
                      <Select
                        value={kids?.gender_state}
                        onChange={(e) => handleChange(kids?.index, e)}
                        name={`childGender-` + kids?.index}
                      >
                        {genderList?.map((item, i) => (
                          <MenuItem
                            key={i}
                            value={item?.value}
                            className='select-options'
                          >
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {/* Add your helper text for the gender field here */}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography variant='h5' className='s-bold pb-2'>
                      {/* {childGender?.title} */}
                      {localesData?.user_kids?.WHAT_IS_CHILD} {kids?.index}'s{' '}
                      {localesData?.user_kids?.GENDER}?
                    </Typography>
                    <Box
                      sx={{ justifyContent: 'space-around' }}
                      className='d-flex pb-4'
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={<IOSSwitch />}
                          checked={kids?.gender_private}
                          onChange={(e) => {
                            profileData?.isPaid === false
                              ? setToogleDialog(true)
                              : handleChange(kids?.index, e);
                          }}
                          name={`childGenderSwitch-` + kids?.index}
                        />
                      </FormGroup>
                      <Typography variant='h5'>
                        {localesData?.userwork?.thats_private_dont_show_this}
                      </Typography>
                      {kids?.gender_private ? <LockIcon /> : <UnLockIcon />}
                    </Box>
                  </Box>
                </>
              );
            })}
        </Box>
        <Box>
          <Box className='done-btn-sec'>
            <CustomButton
              variant='contained'
              title={localesData?.DONE}
              background={'#59D09D'}
              fontWeight='600'
              classname='done-btn s-bold'
              // onClick={handleNext}
              onClick={() => handleOnSubmit()}
            />
          </Box>
        </Box>

        <DialogBox
          open={toogleDialog}
          handleClose={() => setToogleDialog(!toogleDialog)}
          title=''
          content={
            <PremiumPopup
              handleClose={() => setToogleDialog(!toogleDialog)}
              navigate={navigate}
            />
          }
        />
      </Box>
    </>
  );
};

export default YourChildren;
