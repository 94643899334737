import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from 'src/App';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import './termAndCondtions.scss';
// import RNFetchBlob from 'rn-fetch-blob';
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';

// import { downloadZip } from 'https://cdn.jsdelivr.net/npm/client-zip/index.js';

const DownloadMyData = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const downloadUserData = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.USEREXPORT, {
        responseType: 'blob',
      });
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user_data.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // GET COUNTRY LIST
  const getCountryList = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.COUNTRY_LIST);
      if (status === 200) {
        setApiMessage('success', data?.message);
        // setCountryOptions(data);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getCountryList();

    // eslint-disable-next-line
  }, []);

  return (
    <Box className='download-data-sec'>
      {loader && <PreLoader />}
      <Typography
        className='header-txt pb-4    '
        dangerouslySetInnerHTML={{
          __html: localesData?.download_my_data?.DOWNLOAD_MY_DATA,
        }}
      />
      <Box className='t-center'>
        <Typography
          className='sub-header pb-10'
          dangerouslySetInnerHTML={{
            __html: localesData?.download_my_data?.DOWNLOAD_DATA_HEAD,
          }}
        />
      </Box>

      <Box>
        <CustomButton
          variant='contained'
          title={
            loading
              ? ' Downloading...'
              : localesData?.download_my_data?.DOWNLOAD_DATA_BTN
          }
          background='#F54D71'
          fontWeight='600'
          type='submit'
          classname='download-data-btn'
          disabled={loading ? true : false}
          onClick={() => downloadUserData()}
        />
      </Box>
      {/* )} */}
    </Box>
  );
};

export default DownloadMyData;
