import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFromStorage, saveToStorage } from '../../helpers/context';
import { identifiers } from '../../helpers/constants/identifier';
import { handleNext } from '../../helpers/store/actions/navigateSlice';
import ProgresImage from '../../views/component/UI/ProgressImage';
import LogoUI from '../../views/component/UI/LogoComponent/LogoUI';
import ScrollToTop from '../../components/ScrollTop';
import axiosInstance from '../../helpers/axios/axiosInstance';
import { URLS } from '../../helpers/constants/urls';
import { setApiMessage } from '../../helpers/commonFunctions';
import PreLoader from '../../components/Loader';
import TopBar from './TopBar';
import { clearSvgData } from '../../helpers/store/actions/progressImage';
import '../layout.scss';

const MaiLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const reduxAuth = useSelector((state) => state?.auth_data?.auth);
  const progressImg = useSelector((state) => state.progressImage);
  const localAuth = fetchFromStorage(identifiers?.AUTH_DATA);
  const localUserData = fetchFromStorage(identifiers?.USER_DATA);
  const [loader, setLoader] = useState(false);

  const auth = reduxAuth ? reduxAuth : localAuth;

  const getAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        var all_ans = data?.questions;

        var selectedCount = 0;
        all_ans?.map((itm) => {
          if (itm?.id === 74) {
            itm?.Option?.map((item) => {
              if (
                item?.answer === true ||
                item?.answer === '1' ||
                item?.answer === 1
              ) {
                selectedCount++;
              }
              return true;
            });
          }
          return true;
        });
        if (selectedCount === 0) {
          getProfile(true);
        } else {
          getProfile(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getProfile = async (userType) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveToStorage(identifiers?.USER_DATA, data);
        // if (userType) {
        //   navigate('/profile-setup');
        //   dispatch(handleNext(0));
        // } else
        if (!data?.images || data?.images.length === 0) {
          navigate('/profile-setup');
          dispatch(handleNext(1));
        } else if (
          data?.dob === '' ||
          data?.dob === null ||
          data?.dob === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(2));
        } else if (
          data?.gender === '' ||
          data?.gender === null ||
          data?.gender === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(3));
        } else if (
          data?.looking_for === '' ||
          data?.looking_for === null ||
          data?.looking_for === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(4));
        } else {
          // navigate('/');
          if (location?.pathname === '/profile-setup') {
            // if (reduxStep === 4) {
            //   navigate('/profile-setup');
            //   dispatch(handleNext(4));
            // }
            if (reduxStep === 5) {
              navigate('/profile-setup');
              dispatch(handleNext(5));
            } else {
              navigate('/');
            }
          }
          dispatch(handleNext(0));
          if (location?.pathname === '/profile-completion') {
            if (reduxStep !== 0) {
              dispatch(clearSvgData());
            }
          } else {
            dispatch(clearSvgData());
          }
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const pageTopRef = useRef(null);

  // const scrollToTop = () => {
  //   if (pageTopRef.current) {
  //     pageTopRef.current.scrollIntoView({
  //       top: 0,
  //       behavior: 'smooth',
  //     });
  //   }
  // };
  // const scrollToTop = () => {
  //   const offset = -50; // Adjust the offset as per your requirement
  //   const scrollOptions = {
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth',
  //   };

  //   window.scrollTo(scrollOptions);

  //   setTimeout(() => {
  //     scrollOptions.top = offset;
  //     window.scrollTo(scrollOptions);
  //   }, 300); // Adjust the delay (in milliseconds) as needed
  // };
  // useEffect(() => {
  //   const scrollToPosition = () => {
  //     window.scrollTo(0, 0);
  //   };
  //   scrollToPosition();
  //   scrollToTop();
  // }, []);

  // useEffect(() => {
  //   scrollToTop();
  //   // window.scrollTo(0, 0); // Scrolls to the top of the page
  // }, []);

  useEffect(() => {
    getAllAnswars();
    // getProfile();
    // eslint-disable-next-line
  }, []);

  const hideLogo = location?.state === 'isEdit';

  return (
    <Box className='body-image'>
      <Box className='main-layout-wrapper'>
        {loader && <PreLoader />}
        <ToastContainer style={{ padding: '5px' }} limit={1} />
        <Box>
          <ScrollToTop />
          <TopBar
            auth={auth}
            navigate={navigate}
            localUserData={localUserData}
          />
          <Box className='layout-wrapper overflow' ref={pageTopRef}>
            {!hideLogo && (
              <Box className='pb-8'>
                <LogoUI />
              </Box>
            )}
            <Outlet />
          </Box>
        </Box>
        {progressImg && <ProgresImage />}
      </Box>
    </Box>
  );
};

export default MaiLayout;
