import { Box, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import CustomButton from '../Button/CustomButton';
import { IntlContext } from 'src/App';
// import SubDialogbox from '../Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from '../Subscriptionpopup/SubscriptionDialog';
import { useNavigate } from 'react-router-dom';
// import DialogBox from '../PremiumPopup/PremiumDialogBox';
// import PremiumPopup from '../PremiumPopup';
import SubDialogbox from 'src/views/component/UI/Subscriptionpopup/SubDialogbox';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import './footer.scss';

const BottomFooter = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);

  return (
    <>
      <Box className='profile-bg mb-5 bottom-text-wrap'>
        <Typography variant='h4'>
          {profileData?.isPaid ? (
            <span
              variant='h4'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile_No?.PAID_PROFILE,
              }}
            />
          ) : (
            <span
              variant='h4'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile_No?.PROFILE,
              }}
            />
          )}
          <span className='s-bold'> 5% </span>
          <span
            variant='h4'
            dangerouslySetInnerHTML={{
              __html: localesData?.profile_No?.ANIMAL,
            }}
          />
        </Typography>
        <Box className='done-footer-btn'>
          <CustomButton
            variant='contained'
            title={localesData?.profile_No?.SEE_PLANS}
            background='#59D09D'
            fontWeight='600'
            classname='continue-btn '
            onClick={() => setToogleDialog(!toogleDialog)}
          />
        </Box>
      </Box>
      <SubDialogbox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </>
  );
};

export default BottomFooter;
