import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  //  Chip, FormHelperText
} from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { useSelector } from 'react-redux';
// import { differenceInYears } from 'date-fns';
import CustomButton from '../../UI/Button/CustomButton';
import { fetchFromStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { URLS } from '../../../../helpers/constants/urls';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
// import { CustomTextField } from '../../UI/textfield';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';

const SetExpressIdentitfy = ({
  localesData,
  handleNext,
  setLoader,
  dispatch,
  questions,
  getProfile,
  getUpdatedAllAnswars,
  answers,
}) => {
  // const reduxProfileData = useSelector((state) => state?.profileData);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  // const [selectedChips, setSelectedChips] = useState([]);
  // const [errors, setErrors] = useState('');
  // const [genderName, setGenderName] = useState('');
  // const [genderMaxLength, setGenderMaxLength] = useState(75);
  // const [subgender, setsubgender] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [subgenderAns, setsubgenderAns] = useState([]);
  const [subgenderList, setsubgenderList] = useState([]);
  const [genderInputValue, setGenderInputValue] = useState();
  // const genderData = questions?.[0]?.Question?.find((o) => o?.q_order === 2);
  const handleKeyDown = (event) => {
    if (event.key === ',') {
      // Simulate Tab key behavior
      event.preventDefault();
      // selectRef.current.select.selectOption({
      //   label: event.target.value,
      //   value: event.target.value,
      // });
      if (genderInputValue !== '') {
        // const sublist = subgenderList;
        // sublist.push(newOption);

        const checkedinList = subgenderList.find(
          (f) =>
            f?.label?.toLowerCase().includes(genderInputValue?.toLowerCase()) &&
            !subgenderAns.includes(f?.values)
        );
        // sublist.push(newOption);
        if (!checkedinList) {
          let newOption = {
            label: genderInputValue,
            value: genderInputValue,
          };
          const slists = subgenderAns;
          const filterlists = slists.filter((l) => l !== undefined);
          setsubgenderAns([...filterlists, genderInputValue]);
          setsubgenderList([...subgenderList, newOption]);
          setGenderInputValue('');
        } else {
          const slists = subgenderAns;
          const filterlists = slists.filter((l) => l !== undefined);
          setsubgenderAns([...filterlists, checkedinList?.id]);
          setGenderInputValue('');
        }
      }
      //
    }
    //
  };
  useEffect(() => {
    const getGenderList = answers?.filter((item) => item?.id === 2)?.[0]
      ?.Option;
    const filteredData = getGenderList
      ?.map((filteredItem, index) => {
        return {
          ...filteredItem,
          label: filteredItem?.title,
          name: filteredItem?.title,
          value: filteredItem?.id,
          // id: index,
        };
      })
      ?.filter((item) => {
        return (
          item?.title !== 'Male' &&
          item?.title !== 'Female' &&
          item?.title !== 'Nonbinary'
        );
      });

    setsubgenderList(filteredData);
    // const getGenName = getGenderList?.filter(
    //   (item) => item?.id === profileData?.gender
    // )?.[0]?.title;
    // setGenderName(getGenName);
    // if (getGenName && getGenName !== undefined) {
    //   setGenderMaxLength(75 - (getGenName ? Number(getGenName.length) : 0));
    // }
  }, [answers, profileData?.gender]);

  const userUpdate = async (req) => {
    let sendData = { gender: 2, subGender: req };
    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, sendData);
      if (status === 200) {
        getProfile();
        // handleNext();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // const handleDelete = (chipToDelete) => () => {
  //   setSelectedChips((chips) =>
  //     chips?.filter((chip) => chip?.id !== chipToDelete.id)
  //   );
  // };

  // const handleChipClick = (chip) => {
  //   if (!selectedChips?.includes(chip)) {
  //     // setSelectedChips([chip, ...selectedChips]); //for multiple
  //     setSelectedChips([chip]);
  //   }
  //   setErrors('');
  // };

  // const handleOnSubmit = async () => {
  //   let sendData = {
  //     private: false,
  //     optionIds: {
  //       optionId: selectedChips?.map((obj) => obj?.id)?.join(','),
  //       selected: true,
  //     },
  //   };
  //   if (selectedChips?.length !== 0) {
  //     try {
  //       setLoader(true);
  //       const { status, data } = await axiosInstance.post(
  //         URLS.SET_ANSWERS,
  //         sendData
  //       );
  //       if (status === 200) {
  //         setApiMessage('success', data?.message);
  //         updateUserDetails(selectedChips?.map((obj) => obj?.id)?.join(','));
  //         dispatch(
  //           addProfileData({
  //             key: 'gender',
  //             value: selectedChips?.map((obj) => obj?.id)?.join(','),
  //           })
  //         );
  //         setLoader(false);
  //       }
  //     } catch (error) {
  //       setLoader(false);
  //       setApiMessage('error', error?.response?.data?.message);
  //     }
  //   } else {
  //     setErrors(localesData?.validation?.SELECT_AT_LIST_ONE);
  //   }
  // };
  const handleCloseOptions = () => {
    setIsOpen(false);
  };

  const handleToggleOptions = () => {
    setIsOpen(!isOpen);
  };
  const handlesubGenderChange = async () => {
    // const slist = subgenderAns?.map((S) => S);
    const filterlist = subgenderAns?.filter(
      (l) => l !== undefined && typeof l === 'number'
    );
    const slistlabel = subgenderList?.filter((S) => S.id === undefined);
    const filterlabel = slistlabel.map((S) => {
      return {
        optionId: null,
        answer: S?.value,
        selected: true,
      };
    });

    const subgenderfilter = subgenderList.filter((s) => s.id);
    const subgender = subgenderfilter.map((g) => {
      if (filterlist.includes(g?.value)) {
        return { optionId: g?.value, selected: true };
      } else {
        return { optionId: g?.value, selected: false };
      }
    });
    const param_data = subgender.concat(filterlabel);
    const filterSelected = param_data.filter((p) => p?.selected === true);
    const sendData = {
      private: false,
      optionIds: filterSelected,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        const slists = data?.optionsCreated?.map((S) => S.optionId);
        const filterlists = slists.filter((l) => l !== undefined);
        setsubgenderAns(filterlists);
        dispatch(
          addProfileData({
            key: 'gender',
            value: filterlists, // data?.optionsCreated?.id,
          })
        );
        getUpdatedAllAnswars();
        userUpdate(filterlists.toString());
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      <Box className='form-content pb-6'>
        <Box className='t-center mb-7'>
          {/* <Typography variant='h3' className='mb-6'>
            {localesData?.OKAY}{' '}
            <span className='s-bold'>
              {profileData?.firstname} {profileData?.lastname}
            </span>{' '}
            {localesData?.gender?.WHO_IS}{' '}
            <span className='s-bold'>
              {differenceInYears(new Date(), new Date(profileData?.dob))}
            </span>{' '}
            {localesData?.gender?.YEAR}
          </Typography> */}

          <Typography variant='h3' className='s-bold mb-2'>
            {localesData?.help_center?.IDENTIFY}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            sub_gender: '', //genderName ? genderName : '',
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            // sub_gender: Yup.string()
            //   .required(localesData?.validation?.REQUIRED)
            //   .max(75, '')
            //   .test(
            //     'valid-gender',
            //     'Male and Female genders are not allowed.',
            //     function (value) {
            //       if (
            //         value === 'Male' ||
            //         value === 'Female' ||
            //         value === 'male' ||
            //         value === 'female'
            //       ) {
            //         return false; // Return false to indicate validation failure
            //       }
            //       return true; // Return true to indicate validation success
            //     }
            //   ),
          })}
          onSubmit={async (requestData) => {
            handlesubGenderChange();
            //   let sendData = {
            //     private: false,
            //     optionIds: [
            //       {
            //         optionId: null,
            //         answer: requestData?.sub_gender,
            //         selected: true,
            //       },
            //     ],
            //   };
            //   try {
            //     setLoader(true);
            //     const { status, data } = await axiosInstance.post(
            //       URLS.SET_ANSWERS,
            //       sendData
            //     );
            //     if (status === 200) {
            //       setApiMessage('success', data?.message);
            //       // updateUserDetails(data?.optionsCreated?.id);
            //       dispatch(
            //         addProfileData({
            //           key: 'gender',
            //           value: subgenderAns, // data?.optionsCreated?.id,
            //         })
            //       );
            //       getUpdatedAllAnswars();
            //       setLoader(false);
            //     }
            //   } catch (error) {
            //     setLoader(false);
            //     setApiMessage('error', error?.response?.data?.message);
            //   }
            // }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} className='form-content'>
              <Box className='field-content mb-8'>
                {/* <CustomTextField
                  fullWidth
                  error={Boolean(touched.sub_gender && errors.sub_gender)}
                  helperText={touched.sub_gender && errors.sub_gender}
                  variant='standard'
                  className='custom-textfield'
                  name='sub_gender'
                  placeholder='e.g. Non-binary'
                  value={values.sub_gender || ''}
                  onChange={(e) => {
                    if (e.target.value.length <= 75) {
                      handleChange(e);
                      setGenderMaxLength(75 - e.target.value?.length);
                    }
                  }}
                  onBlur={handleBlur}
                  maxLength='75'
                /> */}
                <CreatableSelect
                  className='custom-select react-select '
                  classNamePrefix='select'
                  options={_.chain(subgenderList)
                    ?.filter((option) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(genderInputValue?.toLowerCase())
                    )
                    .uniqBy('value')
                    .value()}
                  isMulti
                  onChange={(e, newValue) => {
                    if (genderInputValue !== '') {
                      let newOption = {
                        label: genderInputValue,
                        value: genderInputValue,
                      };
                      // const sublist = subgenderList;
                      // sublist.push(newOption);
                      setsubgenderList([...subgenderList, newOption]);
                      setGenderInputValue('');
                    }
                    const slists = e?.map((S) => S.value);
                    const filterlists = slists.filter((l) => l !== undefined);
                    setsubgenderAns(filterlists);
                    // handlesubGenderChange(e);
                  }}
                  menuIsOpen={isOpen}
                  onFocus={handleToggleOptions}
                  onBlur={handleCloseOptions}
                  onKeyDown={handleKeyDown}
                  value={
                    subgenderAns &&
                    subgenderAns.length !== 0 &&
                    subgenderList?.filter((opt) => {
                      return subgenderAns.includes(opt?.value);
                    })
                  }
                  inputValue={genderInputValue}
                  onInputChange={(value) => {
                    setGenderInputValue(value);
                  }}
                />
                <Typography
                  variant='h5'
                  className='font-r-13'
                  // textAlign='right'
                >
                  Use commas to separate values
                </Typography>
              </Box>
              <CustomButton
                classname='uppercase'
                variant='contained'
                title={localesData?.NEXT}
                background='#000000'
                fontWeight='600'
                type={subgenderAns && subgenderAns.length !== 0 ? 'submit' : ''}
                // onClick={handleOnSubmit}
              />
            </form>
          )}
        </Formik>

        {/* <Box pb='4px' className='chip-block b-border mb-3'>
          {selectedChips?.map((chip, i) => (
            <Chip
              deleteIcon={<CloseIcon />}
              key={i}
              label={chip?.title}
              onDelete={handleDelete(chip)}
              className='chip-active font-r-11'
            />
          ))}
        </Box>
        <Box className='pb-20 horizontal-scroll'>
          <Box className='chip-block mb-2'>
            {genderData?.Option?.filter(
              (obj) => obj?.id !== 2 && obj?.id !== 3
            )?.map((item, i) => (
              <Chip
                className={`chip-inner font-r-11 ${
                  selectedChips?.[0] === item ? 'chip-active' : ''
                } `}
                key={i}
                label={item?.title}
                onClick={() => handleChipClick(item)}
              />
            ))}
          </Box>
          {errors && (
            <FormHelperText sx={{ margin: '0' }} className='color-error'>
              {errors}
            </FormHelperText>
          )}
        </Box> */}
        {/* <CustomButton
          classname='uppercase'
          variant='contained'
          title={localesData?.NEXT}
          background='#000000'
          fontWeight='600'
          onClick={handleOnSubmit}
        /> */}
      </Box>
    </>
  );
};

export default SetExpressIdentitfy;
