import React, { useState, useEffect } from 'react';
import {
  Box,
  MenuItem,
  Typography,
  Select,
  FormHelperText,
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { CustomTextField } from '../../UI/textfield';
import { CustomSelect } from '../../UI/select';
import CustomButton from '../../UI/Button/CustomButton';

import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/down-icon.svg';

import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';

const ForgotWithNumber = ({
  localesData,
  handleNext,
  profileData,
  changeComponent,
  setLoader,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);

  // GET COUNTRY LIST
  const getCountryList = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.COUNTRY_LIST);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setCountryOptions(data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getCountryList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          phone_country: profileData?.phone_country
            ? profileData?.phone_country
            : '61',
          phone_number: profileData?.phone_number
            ? profileData?.phone_number
            : '',
        }}
        // enableReinitialize={true}
        validationSchema={Yup.object().shape({
          phone_country: Yup.string().required(
            localesData?.validation?.REQUIRED
          ),
          phone_number: Yup.string()
            .required(localesData?.validation?.REQUIRED)
            .matches(/[^a-zA-Z]/g, localesData?.validation?.ALLOW_ONLY_NUMBER),
        })}
        onSubmit={async (requestData) => {
          // let sendData = {
          //   country: requestData.phone_country,
          //   phone_number: requestData.phone_number,
          // };
          try {
            setLoader(true);
            const { status, data } = await axiosInstance.get(
              URLS.FORGOT_PASSWORD + `/${requestData.phone_number}`
            );
            if (status === 200) {
              setApiMessage('success', data?.message);
              handleNext();
              setLoader(false);
            }
          } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className='form-content pb-2'>
            <Box className='t-center mb-10'>
              <Typography variant='h3' className='s-bold mb-2'>
                {localesData?.HI} {profileData?.first_name}{' '}
                {/* {profileData?.last_name}, */}
              </Typography>
              <Typography
                variant='h4'
                dangerouslySetInnerHTML={{
                  __html: localesData?.forgot?.FORGOT_PONE_DESC,
                }}
              />
            </Box>

            <Box className='login-field-content mb-8'>
              <Box className='field-content'>
                <Select
                  fullWidth
                  variant='standard'
                  error={Boolean(touched.phone_country && errors.phone_country)}
                  className='custom-select'
                  name='phone_country'
                  input={<CustomSelect />}
                  IconComponent={DropdownIcon}
                  value={values.phone_country?.replace('+', '') || '61'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  displayEmpty
                >
                  {countryOptions?.map((item, i) => (
                    <MenuItem
                      key={i}
                      value={item?.countryCode?.replace('+', '')}
                      className='select-options'
                    >
                      <img
                        src={item?.flagUrl}
                        alt=''
                        className='dropdown-flag-icon'
                      />
                      <span className='dropdown-flag-text'>
                        {/* {item?.countryName} ({item?.countryCode}) */}
                        {item?.countryCode}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
                {errors.phone_country && (
                  <FormHelperText error={Boolean(errors.phone_country)}>
                    {touched.phone_country && errors.phone_country}
                  </FormHelperText>
                )}
              </Box>
              <Box className='field-content'>
                <CustomTextField
                  fullWidth
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                  variant='standard'
                  className='custom-textfield'
                  name='phone_number'
                  value={values.phone_number || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    if (/[^0-9]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Box>
            </Box>
            <CustomButton
              variant='contained'
              title={localesData?.SEND_NOW}
              background='#F54D71'
              fontWeight='600'
              type='submit'
            />
          </form>
        )}
      </Formik>
      {/* <Typography
        variant='h6'
        className='t-center pointer'
        onClick={() => changeComponent('email')}
      >
        {localesData?.forgot?.SEND_EMAIL}
      </Typography> */}
    </>
  );
};

export default ForgotWithNumber;
