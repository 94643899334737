import React, { Component } from 'react';
import moment from 'moment';
import { Typography, Link, Box, Avatar } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReactPlayer from 'react-player';
import { ReactComponent as AudioPlayerWhiteIcon } from 'src/assets/images/white-audio-player.svg';
import { ReactComponent as AudioPlayerPinkIcon } from 'src/assets/images/pink-audio-player.svg';
import { ReactComponent as VoiceWhiteIcon } from 'src/assets/images/white-voice-icon.svg';
import { ReactComponent as VoicePinkIcon } from 'src/assets/images/pink-voice-icon.svg';

class ViewMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSwitchForDocumentIcon(param) {
    const splitdata = param.split('.');
    const docurl = splitdata[splitdata.length - 1];
    switch (docurl) {
      case 'pdf':
        return (
          <>
            <PictureAsPdfIcon fontSize='inherit' />
            <p>{docurl}</p>
          </>
        );
      case 'txt':
      default:
        return (
          <>
            <InsertDriveFileIcon fontSize='inherit' />
            <p>{docurl}</p>
          </>
        );
    }
  }

  renderForwardMessage(message) {
    switch (message?.reply?.messageType) {
      case 'image':
        return (
          <>
            <span className='sender-message'>
              <img src={message?.reply?.messageContent} alt='' />
            </span>
          </>
        );
      case 'string':
        return (
          <>
            <span className='sender-message'>
              {message?.reply?.messageContent}
            </span>
          </>
        );
      default:
        return '';
    }
  }

  render() {
    const {
      type,
      message,
      messageFromMe,
      champyChat,
      openImage,
      openAudioMsg,
    } = this.props;

    if (message?.type === 'string') {
      const text = message?.text;
      const spliturl = text.split(' ');
      var sendText =
        spliturl &&
        spliturl.length > 0 &&
        spliturl.map((suburl, index) => {
          // const pattern = /^https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*$/;
          // const pattern = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
          const pattern = /(?:(?:https?|ftp):\/\/|www\.)[^\s/$.?#].[^\s]*/gi;

          var isLink = pattern.test(suburl);
          if (isLink) {
            return (
              <>
                {index !== 0 && ' '}
                <Link target='_blank' href={message?.text} className='link'>
                  {suburl}
                </Link>
              </>
            );
          } else {
            return (
              <>
                {index !== 0 && ' '}
                <Typography
                  variant='span'
                  className={messageFromMe ? 'message-text' : ''}
                  dangerouslySetInnerHTML={{
                    __html: suburl,
                  }}
                />
              </>
            );
            // return suburl;
          }
        });
    }
    if (type === 'systemtime') {
      return (
        <>
          <li className={'Messages-message type-' + message?.type}>
            {moment(moment.unix(message?.createdAt)).calendar(null, {
              sameDay: 'MMM DD, YYYY',
              // sameDay: '[Today]',
              nextDay: 'MMM DD, YYYY',
              // nextDay: '[Tomorrow]',
              // nextWeek: 'DD/MM/YYYY',
              nextWeek: 'MMM DD, YYYY',
              lastDay: 'MMM DD, YYYY',
              // lastDay: '[Yesterday]',
              // lastWeek: 'DD/MM/YYYY',
              lastWeek: 'MMM DD, YYYY',
              sameElse: function (now) {
                var diff = moment(now).diff(
                  moment.unix(message?.createdAt),
                  'days'
                );
                // return diff <= 8 ? 'dddd' : 'DD/MM/YYYY';
                return diff <= 8 ? 'MMM DD, YYYY' : 'MMM DD, YYYY';
              },
            })}
          </li>
        </>
      );
    } else {
      switch (message?.type) {
        case 'systemdate':
          return <div>{message?.createdAt}</div>;
        case 'missed_call':
          return <div>{message?.text}</div>;
        case 'string':
          return (
            <>
              {champyChat === true ? (
                <>
                  {/* {renderHTML(
                    anchorme({
                      input: message.text?.replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    })
                  )} */}
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: message?.text,
                    }}
                  />
                </>
              ) : (
                <>
                  {/* renderHTML(
                    anchorme({
                      input: message.text?.replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    })
                  ) */}
                  {/* <Typography
                    dangerouslySetInnerHTML={{
                      __html: message?.text,
                    }}
                  /> */}
                  {sendText}
                </>
              )}
            </>
          );
        case 'image':
          // let imageurlArray = [];
          // this.props?.chatHistory?.map((msg) => {
          //   var imageArr = {
          //     src: msg?.image,
          //     downloadUrl: msg?.image,
          //   };
          //   msg?.type === 'image' &&
          //     msg?.image !== '' &&
          //     imageurlArray.push(imageArr);
          //   return msg;
          // });

          return (
            <div className='imageViewer-main pointer'>
              {/* <LightBox
                imageurl={imageurlArray}
                image={message.image}
                otherUserId={otherUserId}
              /> */}
              <img
                src={message?.image}
                width='100%'
                height='100%'
                alt=''
                onClick={() => {
                  openImage(message?.image);
                }}
              />
            </div>
          );
        case 'audio':
          return (
            <Box
              onClick={() => openAudioMsg(message?.audiourl)}
              className='audio-player-wrapper'
            >
              {/* <ReactPlayer
                className='react-player-audio'
                url={message?.audiourl}
                playing={false}
                // width='100%'
                // height='100%'
                controls={true}
                playIcon={
                  <button onClick={() => openAudioMsg(message?.audiourl)}>
                    Play
                  </button>
                }
                light={true}
                // onClick={() => openAudioMsg(message?.audiourl)}
                // onPlay={() => openAudioMsg(message?.audiourl)}
                config={{
                  file: {
                    attributes: { controlsList: 'nodownload' },
                  },
                }}
              /> */}
              {messageFromMe ? (
                <>
                  <Avatar src={message?.user?.avatar} />
                  <Box>
                    <VoiceWhiteIcon
                      className={`voice-icon ${messageFromMe && 'send'}`}
                    />
                  </Box>

                  <Box className={`audio-icon ${messageFromMe && 'send'}`}>
                    <AudioPlayerWhiteIcon />
                  </Box>

                  {/* <ReactPlayer
                className='react-player-audio'
                url={message?.audiourl}
                playing={false}
                // width='100%'
                // height='100%'
                controls={true}
                config={{
                  file: {
                    attributes: { controlsList: 'nodownload' },
                  },
                }}
              /> */}
                </>
              ) : (
                <>
                  <Box className={`audio-icon ${!messageFromMe && 'received'}`}>
                    <AudioPlayerPinkIcon />
                  </Box>

                  <VoicePinkIcon
                    className={`voice-icon ${!messageFromMe && 'received'}`}
                  />

                  <Avatar src={message?.user?.avatar} />
                </>
              )}
            </Box>
          );
        case 'video':
          return (
            <ReactPlayer
              className='react-player'
              url={message?.videourl}
              playing={false}
              width='100%'
              height='100%'
              controls={true}
            />
          );
        case 'emoji':
          return <img src={message?.emoji} width='100%' height='100%' alt='' />;
        default:
          return '';
      }
    }
  }
}
export default ViewMessage;
