import React from 'react';
import Page from '../../../components/Page';
// import DownloadMyData from '../../component/application/DownloadData';
import DownloadMyData from 'src/views/component/application/ManageProfile/AccountDetails/DownloadData';

const DownloadDataView = () => {
  return (
    <Page title='Download My Data'>
      <DownloadMyData />
    </Page>
  );
};

export default DownloadDataView;
