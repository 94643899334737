import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Reorderable from './Reorderable';
import dogImage from 'src/assets/images/dogprofile.png';
import { useDispatch } from 'react-redux';
import { addProfileData } from 'src/helpers/store/actions/profileSetup';

const EditDogProfile = ({ dogDetails, isPaid, localesData }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      addProfileData({
        key: 'dog_images',
        value: undefined,
      })
    );
  }, [dispatch]);

  return (
    <>
      {dogDetails && dogDetails?.length === 0 ? (
        <>
          <Box
            className={`d-flex justify-center pb-10  ${
              !isPaid ? 'mt-4' : 'mt-14'
            }`}
          >
            <img alt='not found' src={dogImage} />
          </Box>
          <Box
            className={`t-center m-auto ${!isPaid ? 'pb-4' : 'pb-16'}`}
            width='230px'
          >
            <Typography
              variant='h3'
              dangerouslySetInnerHTML={{
                __html: localesData?.dog_profile?.DONT_HAVE_FUR,
              }}
            />
          </Box>
        </>
      ) : (
        <Box className='edit-dog-profile-sec'>
          <Box className='edit-dog-accordian-box-sec'>
            <Reorderable dogDetails={dogDetails} />
          </Box>
          <Box className='t-center pb-23'>
            <Typography
              variant='h6'
              dangerouslySetInnerHTML={{
                __html: localesData?.edit_dog_profile?.DRAG_RECORDER,
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default EditDogProfile;
