import React, { useEffect, useState } from 'react';
import '../thrownBones.scss';
import { Box, Typography } from '@mui/material';
import { ReactComponent as CameraGreyImage } from 'src/assets/images/icons/CameraGreyImage.svg';

import { ReactComponent as PlayGreyImage } from 'src/assets/images/icons/PlayGreyImage.svg';

import { ReactComponent as AudioIcon } from 'src/assets/images/icons/audioIcon.svg';
import DummyImg from 'src/assets/images/logo-black.png';
import { useNavigate } from 'react-router-dom';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';
import { socket } from 'src/helpers/context/socket';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const MessageTab = ({ localesData, location }) => {
  const navigate = useNavigate();
  const localUserData = fetchFromStorage(identifiers?.USER_DATA);
  const [loader, setLoader] = useState(false);
  const [msgData, setMsgData] = useState([]);
  // const [state, setState] = useState({
  //   messages_data: [],
  //   massageCount: 0,
  //   message_id_record: [],
  //   selected_button: 0,
  //   clicked_chat_id: '',
  // });
  // const is_mounted = true;
  const [massageCount, setMassageCount] = useState(0);
  const [messageIdRecord, setMessageIdRecord] = useState([]);

  //GET MESSAGE LIST DATA
  const getMessegedata = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.CHAT_LIST);
      if (status === 200) {
        setApiMessage('success', data?.message);
        const sortedMsgData = data?.users.sort(
          (a, b) =>
            new Date(b.lastMessase?.MessageCreated).getTime() -
            new Date(a.lastMessase?.MessageCreated).getTime()
        );
        for (let i = 0; i < sortedMsgData.length; i++) {
          sortedMsgData[i].unique_id_1 =
            localUserData?.id + '_' + sortedMsgData[i].id;
          sortedMsgData[i].unique_id_2 =
            sortedMsgData[i].id + '_' + localUserData?.id;
        }

        setMsgData(sortedMsgData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getMessegedata();
    listenSocket();
  }, []);

  const checkAvailable = (message_id_record, both_id) => {
    return message_id_record.some((item) => item === both_id);
  };

  // listen socket
  const listenSocket = () => {
    if (socket) {
      // DELETE MESSAGE
      // const user_id = localUserData?.id;
      // socket.on('DeleteMessageSocket', (data) => {
      //   if (data) {
      //     const compare_id = data.ReceiverID + '_' + user_id;
      //     const messages_data = [...msgData];
      //     console.log('messages_data', messages_data);
      //     for (let i = 0; i < messages_data.length; i++) {
      //       const old_last_message_data = messages_data[i]?.lastMessase;
      //       if (
      //         (messages_data[i]?.unique_id_1 === compare_id ||
      //           messages_data[i]?.unique_id_2 === compare_id) &&
      //         old_last_message_data?.MessageID === data._id
      //       ) {
      //         old_last_message_data.MessageDeleted = 1;
      //         old_last_message_data.userId = data?.UserID;
      //         setMsgData(messages_data);
      //       }
      //     }
      //   }
      // });
      // socket.on('listGroup', (data) => {
      //   // alert('list');
      //   const updatedMsgData =
      //     msgData &&
      //     msgData.length > 0 &&
      //     msgData.map((obj) => {
      //       if (data?.user?._id === obj?.id) {
      //         return {
      //           ...obj,
      //           lastMessase: {
      //             ...obj?.lastMessase,
      //             MessageJSON: {
      //               ...obj?.lastMessase?.MessageJSON,
      //               message: {
      //                 ...obj?.lastMessase?.MessageJSON?.message,
      //                 content: data?.text,
      //                 type: data?.type,
      //               },
      //             },
      //           },
      //         };
      //       } else {
      //         return obj;
      //       }
      //     });
      //   updatedMsgData &&
      //     updatedMsgData?.length > 0 &&
      //     setMsgData(updatedMsgData);
      //   console.log('setLatestData', updatedMsgData);
      //   // setLatestData(data);
      //   const {
      //     massageCount,
      //     message_id_record,
      //     messages_data,
      //     selected_button,
      //     clicked_chat_id,
      //   } = state;
      //   let total_count = massageCount;
      //   let total_records = [...message_id_record];
      //   console.log('first', total_records);
      //   if (data && data.user?._id && is_mounted) {
      //     const sender_receiver_id = data.ReceiverID + '_' + data.UserID;
      //     console.log('sender_receiver_id', sender_receiver_id);
      //     if (message_id_record?.length === 0) {
      //       console.log('massageCount', massageCount);
      //       total_count = massageCount + 1;
      //       total_records.push(sender_receiver_id);
      //     } else {
      //       const status = checkAvailable(
      //         message_id_record,
      //         sender_receiver_id
      //       );
      //       if (!status) {
      //         total_count = massageCount + 1;
      //         total_records.push(sender_receiver_id);
      //       }
      //     }
      //     setState({
      //       ...state,
      //       message_id_record: total_records,
      //       massageCount: total_count,
      //     });
      //     console.log('total_records', total_records);
      //     if (
      //       messages_data !== null &&
      //       messages_data.length !== 0 &&
      //       selected_button === 3
      //     ) {
      //       const compare_id = data.ReceiverID + '_' + user_id;
      //       for (let i = 0; i < messages_data.length; i++) {
      //         if (
      //           messages_data[i]?.unique_id_1 === compare_id ||
      //           messages_data[i]?.unique_id_2 === compare_id
      //         ) {
      //           let old_last_message_data = messages_data[i]?.lastMessase;
      //           const whole_message =
      //             old_last_message_data?.MessageJSON?.message;
      //           if (whole_message) {
      //             const updatedWholeMessage = {
      //               ...whole_message,
      //               type: data?.type,
      //               content: data?.text,
      //             };
      //             old_last_message_data.MessageJSON.message =
      //               updatedWholeMessage;
      //           }
      //           old_last_message_data.MessageCreated = data?.createdAt;
      //           old_last_message_data.MessageDeleted = 0;
      //           old_last_message_data.MessageID = data?._id;
      //           old_last_message_data.userId = data?.user?._id;
      //           if (
      //             !old_last_message_data.SeenByDataStatus &&
      //             data?.user?._id !== user_id &&
      //             clicked_chat_id !== data?.ReceiverID
      //           ) {
      //             old_last_message_data.SeenByDataStatus = true;
      //           }
      //           setState({ ...state, messages_data });
      //         }
      //       }
      //     }
      //   }
      // });
    }
  };

  const checkMessageDeleted = (last_message) => {
    if (
      last_message.MessageDeleted !== null &&
      last_message.MessageDeleted === 1 &&
      last_message?.userId
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getMessage = (last_message) => {
    if (last_message != null) {
      if (checkMessageDeleted(last_message)) {
        return 'Message deleted';
      }
      if (
        last_message.MessageJSON.message.type === 'string' ||
        last_message.MessageJSON.message.type === 'missed_call'
      ) {
        return last_message.MessageJSON.message.content;
      } else if (last_message.MessageJSON.message.type === 'image') {
        return 'Image';
      } else if (last_message.MessageJSON.message.type === 'video') {
        return 'video';
      } else if (last_message.MessageJSON.message.type === 'audio') {
        return 'Audio';
      } else if (last_message.MessageJSON.message.type === 'emoji') {
        return 'Emoji';
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  // useEffect(() => {
  //   // const storedMsgData = localStorage.getItem('msgData');
  //   if (msgData && !location?.state?.message) {
  //     setMsgData(msgData);
  //   } else {
  //     getMessegedata();
  //   }
  //   listenSocket();
  //   return () => {
  //     // Cleanup code
  //   };
  // }, []);
  useEffect(() => {
    const user_id = localUserData?.id;
    socket.on('listGroup', (data) => {
      let total_count = massageCount;
      let total_records = [...messageIdRecord];

      if (data && data?.user?._id) {
        let sender_receiver_id = data?.ReceiverID + '_' + data?.UserID;

        if (messageIdRecord.length === 0) {
          total_count = massageCount + 1;
          total_records.push(sender_receiver_id);
        } else {
          let status = checkAvailable(messageIdRecord, sender_receiver_id);
          if (!status) {
            total_count = massageCount + 1;
            total_records.push(sender_receiver_id);
          }
        }

        setMessageIdRecord(total_records);
        setMassageCount(total_count);

        if (msgData !== null && msgData.length !== 0) {
          let compare_id = data.ReceiverID + '_' + data.UserID;

          const updatedMessagesData = msgData?.map((message) => {
            if (
              message.unique_id_1 === compare_id ||
              message.unique_id_2 === compare_id
            ) {
              let old_last_mesage_data = message.lastMessase;
              let whole_message = old_last_mesage_data.MessageJSON.message;
              whole_message.type = data.type;
              whole_message.content = data.text;

              old_last_mesage_data.MessageCreated = data.createdAt;
              old_last_mesage_data.MessageDeleted = 0;
              old_last_mesage_data.MessageID = data._id;
              old_last_mesage_data.userId = data.user._id;
              if (
                !old_last_mesage_data.SeenByDataStatus &&
                data.user._id !== user_id
              ) {
                old_last_mesage_data.SeenByDataStatus = true;
              }
            }
            return message;
          });
          const sortedMsgData = updatedMessagesData?.sort(
            (a, b) =>
              new Date(b.lastMessase?.MessageCreated).getTime() -
              new Date(a.lastMessase?.MessageCreated).getTime()
          );

          setMsgData(sortedMsgData);
        }
      }
    });
  }, [msgData]);

  // useEffect(() => {
  //   localStorage.setItem('msgData', JSON.stringify(msgData));
  // }, [msgData]);

  return (
    <>
      {loader && <PreLoader />}
      {msgData?.length === 0 ? (
        <Box className='kennel-page-head-sec'>
          <Box className='dummy-img t-center'>
            <img
              alt='not found'
              className='no-data-dummy-img mb-4 opacity'
              src={DummyImg}
            />
          </Box>
          <Typography
            variant='h3'
            className='t-center'
            dangerouslySetInnerHTML={{
              __html: localesData?.kennel_tab_no_data_found?.MESSAGES_TAB,
            }}
          />
        </Box>
      ) : (
        <Box className='msg-head-sec pb-18'>
          {msgData &&
            msgData?.length > 0 &&
            msgData?.map((content, id) => {
              return (
                <Box
                  className='msg-content-sec'
                  onClick={() => navigate(`/chat/${content?.id}`)}
                >
                  <Box className='msg-img-sec'>
                    <img
                      src={content?.images?.[0]?.url}
                      alt='not-found'
                      className='msg-img'
                    />
                  </Box>
                  <Box className='msg-sec'>
                    <Box className='msg-content'>
                      <Box className='d-flex space-between'>
                        <Typography
                          variant='h3'
                          className={`font-r-12 user-name ${
                            content.lastMessase &&
                            !content.lastMessase.SeenByDataStatus
                              ? ''
                              : 'top-padding color-pink'
                          }`}
                        >
                          {content?.firstname + ' ' + content?.lastname}
                          {content.lastMessase &&
                            !content.lastMessase.SeenByDataStatus && (
                              <span className='unread-indicator'></span>
                            )}
                        </Typography>
                      </Box>
                      <Typography
                        variant='h3'
                        className='font-r-12 user-msg pb-2'
                      >
                        {/* {content?.msg} */}

                        {getMessage(content?.lastMessase) !== null &&
                        getMessage(content?.lastMessase).length !== 0 ? (
                          <Box className='d-flex align-center msg-box'>
                            {!checkMessageDeleted(content?.lastMessase) &&
                            content?.lastMessase.MessageJSON.message.type ===
                              'image' ? (
                              <>
                                <Box height='22px' className='icon-wrap-msg'>
                                  <CameraGreyImage />{' '}
                                </Box>
                              </>
                            ) : null}
                            {!checkMessageDeleted(content?.lastMessase) &&
                            content?.lastMessase.MessageJSON.message.type ===
                              'video' ? (
                              <Box height='22px' className='icon-wrap-msg'>
                                <PlayGreyImage />
                              </Box>
                            ) : null}

                            {!checkMessageDeleted(content?.lastMessase) &&
                            content?.lastMessase.MessageJSON.message.type ===
                              'audio' ? (
                              <Box height='22px' className='icon-wrap-msg'>
                                <AudioIcon />
                              </Box>
                            ) : null}
                            {!checkMessageDeleted(content?.lastMessase) &&
                            content?.lastMessase.MessageJSON.message.type ===
                              'emoji' ? (
                              <Box height='22px' className='icon-wrap-msg'>
                                <CameraGreyImage />
                              </Box>
                            ) : null}
                            <Box>{getMessage(content?.lastMessase)}</Box>
                          </Box>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default MessageTab;
