import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addAuth: (state) => {
      return state.value;
    },
  },
});
export const { addAuth } = authSlice.actions;

export default authSlice.reducer;
