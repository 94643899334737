import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntlContext } from 'src/App';
import './favourites.scss';
import { Box, Typography, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Places from './Places';
import Matches from './Matches';
import Benefits from './Benefits';
import { useLocation } from 'react-router-dom';

const Favourites = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [value] = useState('1');
  const location = useLocation();
  const [selecteTab, setSelectTab] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const pageTopRef = useRef(null);

  const scrollToTop = () => {
    if (pageTopRef.current) {
      setSelectedTab(0);
    }
  };

  const tabsData = [
    {
      value: '1',
      label: localesData?.favourites?.PLACES,
      content: <Places localesData={localesData} selectedTab={selecteTab} />,
    },
    {
      value: '2',
      label: localesData?.favourites?.MATCHES,
      content: <Matches localesData={localesData} selectedTab={selecteTab} />,
    },
    {
      value: '3',
      label: localesData?.favourites?.BENEFITS,
      content: <Benefits localesData={localesData} selectedTab={selecteTab} />,
    },
  ];
  useEffect(() => {
    scrollToTop();
  }, [location]);

  useEffect(() => {
    if (location?.state?.isBenefit) {
      setSelectedTab(2);
    } else if (location?.state?.isMatches) {
      setSelectedTab(1);
    }
  }, [location?.state]);

  return (
    <Box className='favourites-sec' ref={pageTopRef}>
      <Box className='chip-container-box'>
        <Typography
          className='t-center header-text pb-3'
          variant='h4'
          dangerouslySetInnerHTML={{
            __html: localesData?.favourites?.HEADER_TEXT,
          }}
        />
        <Box className='favourites-tab-sec'>
          <TabContext value={value} className='tab-context-sec'>
            <Box className='tab-box'>
              <TabList
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                  setSelectTab(!selecteTab);
                }}
                className='tab-list-sec'
              >
                {tabsData.map((tab, index) => {
                  return (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      className={
                        selectedTab === index
                          ? 'favourites-tab selected-tab'
                          : 'favourites-tab'
                      }
                    />
                  );
                })}
              </TabList>
            </Box>
          </TabContext>
        </Box>
      </Box>
      <Box className='favourites-tab-sec'>
        <TabContext value={value} className='tab-context-sec'>
          {tabsData?.map((tab, index) => {
            return (
              <Box
                className='favourites-tab-box'
                // style={{ height: 'calc(100vh - 60vh)', overflow: 'scroll' }}
                key={tab.id}
                role='tabpanel'
                hidden={selectedTab !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
              >
                {selectedTab === index && <Box>{tab.content}</Box>}
              </Box>
            );
          })}
        </TabContext>
      </Box>
    </Box>
  );
};

export default Favourites;
