import React from 'react';
import Page from '../../../components/Page';
import SelectedKennlMatches from 'src/views/component/application/KennelThrownBones/Matches/SelectedBones';

const SelectedKennelMatchesView = () => {
  return (
    <Page title='Subscription Popup'>
      <SelectedKennlMatches />
    </Page>
  );
};

export default SelectedKennelMatchesView;
