import React, { useState, useRef } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Cropper from 'react-cropper';
import DialogBox from '../PremiumPopup/PremiumDialogBox';
import 'cropperjs/dist/cropper.css';
import './cropimage.scss';
import CustomButton from '../Button/CustomButton';

const CropImage = ({
  file,
  localesData,
  getCropData,
  handleCropclose,
  MultipleFile,
  currentFile,
  currentIndex,
  setCurrentIndex,
  setCurrentFile,
}) => {
  const [cropper, setCropper] = useState();
  const [crop, setCrop] = useState(false);
  // const [zoom, setZoom] = useState(0);
  const cropperRef = useRef(null);
  const [disModal, setDisModal] = useState(false);
  // const zoomIn = () => {
  //   setZoom((parseFloat(zoom) + 0.1).toFixed(1));
  //   if (cropperRef.current) {
  //     cropperRef.current.cropper.zoom(0.1);
  //   }
  // };

  // const zoomOut = () => {
  //   setZoom((parseFloat(zoom) - 0.1).toFixed(1));
  //   if (cropperRef.current) {
  //     cropperRef.current.cropper.zoom(-0.1);
  //   }
  // };

  // const customZoom = (zoomValue) => {
  //   setZoom(zoomValue);
  //   if (cropperRef.current) {
  //     cropperRef.current.cropper.zoom((zoomValue - zoom).toFixed(1));
  //   }
  // };
  const discardModalDetails = () => {
    return (
      <Box className='block-top-wrap'>
        <Typography variant='h5' className='mb-17'>
          {localesData?.discard_changes_text}
        </Typography>
        <Divider className='divider' />
        <Box className='all-block-wrap' textAlign='end'>
          <Button className='btn-gray' onClick={() => handleDiscardModal()}>
            {localesData?.profile?.CANCEL_BTN}
          </Button>
          <Button
            className='btn-blue ml-18'
            onClick={() => {
              handleDiscardModal();
              handleCropclose();
            }}
          >
            {localesData?.OKAY}
          </Button>
        </Box>
      </Box>
    );
  };
  const handleDiscardModal = () => {
    setDisModal(!disModal);
  };
  const handleMultiCrop = async () => {
    var baseText = cropper.getCroppedCanvas().toDataURL();
    const blob = await (await fetch(baseText)).blob();
    const files = new File([blob], currentFile?.preview, {
      type: 'image',
      lastModified: new Date(),
    });
    MultipleFile[currentIndex] = files;
    if (currentIndex < MultipleFile.length - 1) {
      // handleCropclose()
      setCurrentFile(MultipleFile[currentIndex + 1]);
      setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 200);
    } else {
      getCropData(MultipleFile);
    }
  };
  // const cropImage = () => {
  //   // setZoom(0)
  //   if (cropperRef.current) {
  //     const croppedData = cropperRef.current.cropper
  //       .getCroppedCanvas()
  //       .toDataURL();
  //     setCropData(croppedData);
  //   }
  // };

  // const undoCrop = () => {
  //   setZoom(0);
  //   setCropData(null);
  //   if (cropperRef.current) {
  //     cropperRef.current.cropper.reset();
  //   }
  // };
  return (
    <Box className='crop-image'>
      <Box className='crop-details'>
        <Typography variant='h6' className='crop-title-text'>
          {localesData?.POSITION_IMAGE}
        </Typography>
        <Typography variant='h5' className='crop-title-desc'>
          {localesData?.POSITION_IMAGE_DESC}
        </Typography>
      </Box>
      <Box className={crop ? 'cropped-image' : 'without-crop'} id='imageId'>
        <Cropper
          ref={cropperRef}
          style={{ height: 454.26, maxWidth: '100%' }}
          // zoomable={false}
          aspectRatio={3 / 2}
          initialAspectRatio={3 / 2}
          src={currentFile?.preview}
          viewMode={2}
          background={false}
          responsive={true}
          autoCropArea={1}
          className={'profile-cropper'}
          checkOrientation={true}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={false}
          zoomOnWheel={false}
          dragMode='move'
          cropBoxMovable={false}
          cropBoxResizable={false}
        />
      </Box>
      {/* <Box className='crop-image-top'>
        <Box className='zoom-plus-minus'>
          <Box className='icon-btn'>
            <IconButton
              disabled={zoom > 0 ? false : true}
              className={zoom > 0 ? '' : 'disabled-btn'}
              onClick={() => zoom > 0 && zoomOut()}
            >
              <Remove />
            </IconButton>
          </Box>
          <Box className='zoom-bar'>
            <input
              type='range'
              min={0}
              max={1}
              step={0.1}
              value={zoom}
              // min="0"
              // max="1"
              // step="0.1"
              defaultValue={0}
              onChange={(e) => {
                customZoom(e.target.value);
              }}
            />
          </Box>
          <Box className='icon-btn'>
            <IconButton
              disabled={zoom < 1 ? false : true}
              className={zoom < 1 ? '' : 'disabled-btn'}
              onClick={() => zoom < 1 && zoomIn()}
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
      </Box> */}
      {/* <Divider className='divider' /> */}
      <Box className='crop-save-btn'>
        {/* <Button
          className='btn-gray'
          onClick={() => {
            if (!crop) {
              cropImage();
              setCrop(!crop);
            } else {
              undoCrop();
              setCrop(!crop);
            }
          }}
        >
          {!crop ? localesData?.crop_photo : localesData?.undo_crop}
        </Button> */}
        <Box className='all-block-wrap next-btton-sec' textAlign='end'>
          {/* <Button
            className='b-btn-blue btn'
            onClick={() => handleDiscardModal()}
          >
            {localesData?.cancel}
          </Button> */}
          {/* <Button
            className='btn-blue btn ml-18'
            onClick={() => getCropData(cropper, item, type)}
          >
            {localesData?.OKAY}
          </Button> */}
          <CustomButton
            variant='contained'
            title={localesData?.NEXT}
            background='#F54D71'
            fontWeight='600'
            onClick={() => handleMultiCrop()}
          />
        </Box>
      </Box>
      <DialogBox
        open={disModal}
        handleClose={() => handleDiscardModal()}
        title={localesData?.discard_changes}
        content={discardModalDetails()}
      />
    </Box>
  );
};

export default CropImage;
