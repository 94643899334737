import React from "react";
import Page from "../../../components/Page";
import ProfileCompletion from "../../component/application/ProfileCompletion";

const ProfileCompletionView = () => {
  return (
    <Page title="Profile Completion">
      <ProfileCompletion />
    </Page>
  );
};

export default ProfileCompletionView;
