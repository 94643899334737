import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import CustomButton from '../../../UI/Button/CustomButton';
import ProgressBar from '../../../UI/ProgressBar';
// import { useNavigate } from 'react-router-dom';
import '../UserWork/userWork.scss';

const UserInformation = ({
  localesData,
  handleNext,
  reduxProgress,
  redirectHomePage,
  progressWeightage,
}) => {
  // const navigate = useNavigate();
  const redirectrulespage = () => {
    handleNext();
  };
  return (
    <Box className='t-center pb-6'>
      <Typography
        className='mb-2 font-r-12'
        dangerouslySetInnerHTML={{
          __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
        }}
      />
      <Box className='mb-3'>
        <Typography variant='h4' className='pb-1'>
          {/* {reduxProgress}% */}
          {progressWeightage}%
        </Typography>
        <ProgressBar processData={progressWeightage} />
      </Box>

      <Typography variant='h3' className='s-bold pb-2'>
        {localesData?.user_information?.thats_AWESOME}
      </Typography>
      <Typography
        variant='h4'
        className='pb-4'
        dangerouslySetInnerHTML={{
          __html:
            localesData?.user_information
              ?.Well_done_on_making_the_choice_to_take_advantage_of_our_matching_algorithm,
        }}
      />
      <Typography
        variant='h4'
        className='pb-4'
        dangerouslySetInnerHTML={{
          __html:
            localesData?.user_information
              ?.your_likes_and_dislikes_and_your_personality,
        }}
      />
      <Typography
        variant='h4'
        className='pb-4'
        dangerouslySetInnerHTML={{
          __html:
            localesData?.user_information
              ?.you_can_also_return_to_this_at_any_time,
        }}
      />
      <Typography
        variant='h4'
        className='pb-4'
        dangerouslySetInnerHTML={{
          __html:
            localesData?.user_information
              ?.you_arent_required_to_answer_any_of_the_questions,
        }}
      />

      <CustomButton
        variant='contained'
        title={localesData?.user_information?.GET_STARTED}
        background='#6A42ED'
        fontWeight='400'
        onClick={() => redirectrulespage()}
      />

      <DogIcon width='81.32px' height='61.36px' className='mb-2' />

      <Typography
        variant='h5'
        className='pointer pb-6'
        // onClick={() => navigate('/')}
        onClick={() => redirectHomePage()}
      >
        {localesData?.userwork?.FINISH}
      </Typography>
    </Box>
  );
};

export default UserInformation;
