import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { useSelector } from 'react-redux';
import CustomButton from '../../UI/Button/CustomButton';
import { ReactComponent as Checked } from '../../../../assets/images/profile-checked.svg';
// import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { ReactComponent as UnChecked } from '../../../../assets/images/unchecked.svg';
// import { fetchFromStorage } from '../../../../helpers/context';
// import { identifiers } from '../../../../helpers/constants/identifier';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { URLS } from '../../../../helpers/constants/urls';

// import {
//   clearSvgData,
//   setSVGFile,
// } from '../../../../helpers/store/actions/progressImage';
// import svgData from '../../../../assets/images/dob.svg';
import ProgressBar from '../../UI/ProgressBar';
// import { useNavigate } from 'react-router-dom';

const StartTalk = ({
  localesData,
  setLoader,
  handleNext,
  dispatch,
  progressWeightage,
  answars,
}) => {
  // const reduxProfileData = useSelector((state) => state?.profileData);
  const [checkLooking, setCheckLooking] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);
  // const [questions, setQuestions] = useState([]);
  // const reduxProgress = useSelector((state) => state?.progressBarData);
  // const navigate = useNavigate();

  // const handleDone = () => {
  //   // navigate('/profile-completion-advance');
  //   // dispatch(handleNext(0));
  //
  // };

  // const getAllQuestions = async () => {
  //   try {
  //     //   setLoader(true);
  //     const { status, data } = await axiosInstance.get(URLS.QUESTIONS);
  //     if (status === 200) {
  //       setApiMessage('success', data?.message);
  //       setQuestions(data?.types);
  //       // setLoade r(false);
  //     }
  //   } catch (error) {
  //     //   setLoader(false);
  //     setApiMessage('error', error?.response?.data?.message);
  //   }
  // };

  useEffect(() => {
    const savedSelectedChips = answars
      ?.find((o) => o?.id === 8)
      ?.Option?.filter((item) => item?.selected === 1);

    const savedSelectedOther = answars
      ?.find((o) => o?.id === 74)
      ?.Option?.filter((item) => item?.selected === 1);
    savedSelectedOther?.filter((item) =>
      item?.id === 275
        ? (item.id = 11)
        : item?.id === 276
        ? (item.id = 12)
        : item?.id === 277
        ? (item.id = 13)
        : item
    );
    const combinedArray = [
      ...(savedSelectedChips || []),
      ...(savedSelectedOther || []),
    ];
    setSelectedChips(combinedArray || []);

    answars?.map((item) => {
      if (item?.id === 8) {
        setCheckLooking(item?.Option?.filter((option) => option?.id !== 13));
      }
      return true;
    });
  }, [answars]);
  // const lookingForOpt = questions?.[0]?.Question?.find((o) => o?.q_order === 4)
  //   ?.Option;

  // const updateUserDetails = async (req) => {
  //   let sendData = { looking_for: req?.looking_for?.join(',') };
  //   try {
  //     //   setLoader(true);
  //     const { status } = await axiosInstance.put(URLS.USER, sendData);
  //     if (status === 200) {
  //       getProfile();
  //       handleNext();
  //       // dispatch(clearSvgData());
  //       // setLoader(false);
  //     }
  //   } catch (error) {
  //     //   setLoader(false);
  //     setApiMessage('error', error?.response?.data?.message);
  //   }
  // };
  // useEffect(() => {
  //   getAllQuestions();
  // }, []);
  // useEffect(() => {
  //   dispatch(setSVGFile(svgData));
  //   getAllQuestions();
  // }, [dispatch]);

  return (
    <Formik
      initialValues={{
        looking_for: selectedChips?.map((chip) => chip.id.toString()),
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        looking_for: Yup.array().min(
          1,
          localesData?.validation?.SELECT_AT_LIST_ONE
        ),
      })}
      onSubmit={async (requestData) => {
        var opt = [];
        opt.push({
          optionId: checkLooking[0]?.id,
          answer: requestData?.looking_for?.includes(
            checkLooking[0]?.id?.toString()
          )
            ? '1'
            : '0',
          selected: requestData?.looking_for?.includes(
            checkLooking[0]?.id?.toString()
          ),
          private: false,
        });
        opt.push({
          optionId: checkLooking[1]?.id,
          answer: requestData?.looking_for?.includes(
            checkLooking[1]?.id?.toString()
          )
            ? '1'
            : '0',
          selected: requestData?.looking_for?.includes(
            checkLooking[1]?.id?.toString()
          ),
          private: false,
        });
        let sendData = {
          private: false,
          optionIds: opt,
        };
        try {
          setLoader(true);
          const { status, data } = await axiosInstance.post(
            URLS.SET_ANSWERS,
            sendData
          );
          if (status === 200) {
            setApiMessage('success', data?.message);
            // updateUserDetails(requestData);
            // getProfile();
            handleNext();
            // dispatch(
            //   addProfileData({
            //     key: 'looking_for',
            //     value: requestData?.looking_for,
            //   })
            // );
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
          setApiMessage('error', error?.response?.data?.message);
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className='form-content pb-6'>
          <Box className='t-center mb-5'>
            <Typography
              className='mb-2 font-r-12'
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.profile_completion_user?.PROFILE_COMPLETION,
              }}
            />
            <Box className='mb-3'>
              <Typography variant='h4' className='pb-1'>
                {/* {reduxProgress}% */}
                {progressWeightage}%
              </Typography>
              <ProgressBar processData={progressWeightage} />
            </Box>
            <Typography variant='h3' className='mb-4 s-bold'>
              {localesData?.profile?.ALL_ABOUT_YOU_TEXT}{' '}
            </Typography>
            <Typography
              variant='h3'
              className='mb-4'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.LETS,
              }}
            />
            <Typography variant='h4' className='s-bold'>
              {localesData?.profile?.SELECT_AS_YOU_LIKE}
            </Typography>
          </Box>

          <Box className='field-content pb-20'>
            {checkLooking?.map((option, i) => (
              <Box className='checkbox-container mb-4' key={i}>
                {/* <Typography variant='h5' >
               
                </Typography> */}

                <FormControlLabel
                  value='start'
                  className='custom-checkbox-text custom-label'
                  control={
                    <Checkbox
                      className='custom-checkbox start-talk-checkbox'
                      icon={<UnChecked />}
                      checkedIcon={<Checked />}
                      checked={values?.looking_for?.includes(
                        option?.id?.toString()
                      )}
                      disableRipple
                      value={option?.id}
                      name='looking_for'
                      onChange={handleChange}
                    />
                  }
                  label={option?.title}
                  labelPlacement='start'
                />
              </Box>
            ))}
            {errors.looking_for && (
              <FormHelperText className='color-error'>
                {errors.looking_for}
              </FormHelperText>
            )}
          </Box>
          <Box>
            <CustomButton
              variant='contained'
              title={'DONE'}
              background='#59D09D'
              fontWeight='600'
              type='submit'
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default StartTalk;
