import React from 'react';
import Page from '../../../components/Page';
import ProfileSetupPage from '../../component/application/ProfileSetup';

const ProfileSetupView = () => {
  return (
    <Page title='Profile Setup'>
      <ProfileSetupPage />
    </Page>
  );
};

export default ProfileSetupView;
