import React from 'react';

import Kids from './Kids';
import '../UserWork/userWork.scss';

const UserKids = ({
  localesData,
  handleNext,
  questions,
  setLoader,
  dispatch,
  reduxProgress,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  return (
    <>
      <Kids
        localesData={localesData}
        handleNextPage={handleNext}
        questions={questions}
        setLoader={setLoader}
        dispatch={dispatch}
        reduxProgress={reduxProgress}
        redirectHomePage={redirectHomePage}
        progressWeightage={progressWeightage}
        answars={answars}
        isEdit={isEdit}
        checkForBlankData={checkForBlankData}
      />
    </>
  );
};

export default UserKids;
