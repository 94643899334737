import React, { useContext } from 'react';
import { IntlContext } from 'src/App';
import { Box, Avatar, Typography } from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';

const ContactCard = ({
  contact,
  sendLinkReq,
  cardInfoLeft,
  cardInfoRight,
  isReqSend,
  linkReject,
  linkAccept,
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className='contact-card'>
      <Box className='d-flex justify-center pb-4'>
        <Avatar className='card-image' alt='Image' src={contact?.avatar} />
        {/* {contact?.avatar}
                </Avatar> */}
      </Box>
      <Box className='t-center '>
        <Box className='card-content pb-4'>
          <Typography className='card-msg' variant='span'>
            {cardInfoLeft}
          </Typography>
          <Typography className='card-msg person-name' variant='span'>
            {' ' + contact?.name + ' '}
          </Typography>
          <Typography className='card-msg' variant='span'>
            {cardInfoRight}
          </Typography>
        </Box>
      </Box>
      {isReqSend ? (
        <Box className='btn-sec d-flex justify-center'>
          <CustomButton
            variant='contained'
            title={localesData?.contact_search?.reject}
            fontWeight='600'
            type='submit'
            className='link-btn'
            onClick={() => sendLinkReq()}
          />
        </Box>
      ) : (
        <Box className='btn-sec d-flex space-between'>
          <CustomButton
            variant='contained'
            title={localesData?.contact_search?.reject}
            fontWeight='600'
            type='submit'
            className='link-btn'
            onClick={() => linkReject()}
          />
          <CustomButton
            variant='contained'
            title={localesData?.contact_search?.accept}
            fontWeight='600'
            type='submit'
            className='link-btn'
            onClick={() => linkAccept()}
          />
        </Box>
      )}
    </Box>
  );
};

export default ContactCard;
