export const identifiers = {
  AUTH_DATA: 'authData',
  USER_DATA: 'userData',
  ACCEPT_LANG: 'acceptlanguage',
  RECENT_EMOJI: 'recent_emoji',
  facebookImages: 'facebookImage',
  instagramImages: 'instagramImages',
  VERIFIED: 'verified',
  DEVICE_ID: 'deviceid',
  genderData: [
    {
      label: 'Male',
      name: 'gender',
      value: 'Male',
    },
    {
      label: 'Female',
      name: 'gender',
      value: 'Female',
    },
    {
      label: 'Non-binary',
      name: 'gender',
      value: 'Non-binary',
    },
  ],
  Years: [
    {
      id: 0,
      label: '0 Year',
      value: '0 Year',
    },
    {
      id: 1,
      label: '1 Year',
      value: '1 Year',
    },
    {
      id: 2,
      label: '2 Years',
      value: '2 Years',
    },
    {
      id: 3,
      label: '3 Years',
      value: '3 Years',
    },
    {
      id: 4,
      label: '4 Years',
      value: '4 Years',
    },
    {
      id: 5,
      label: '5 Years',
      value: '5 Years',
    },
    {
      id: 6,
      label: '6 Years',
      value: '6 Years',
    },
    {
      id: 7,
      label: '7 Years',
      value: '7 Years',
    },
    {
      id: 8,
      label: '8 Years',
      value: '8 Years',
    },
    {
      id: 9,
      label: '9 Years',
      value: '9 Years',
    },
    {
      id: 10,
      label: '10 Years',
      value: '10 Years',
    },
    {
      id: 11,
      label: '11 Years',
      value: '11 Years',
    },
    {
      id: 12,
      label: '12 Years',
      value: '12 Years',
    },
    {
      id: 13,
      label: '13 Years',
      value: '13 Years',
    },
    {
      id: 14,
      label: '14 Years',
      value: '14 Years',
    },
    {
      id: 15,
      label: '15 Years',
      value: '15 Years',
    },
    {
      id: 16,
      label: '16 Years',
      value: '16 Years',
    },
    {
      id: 17,
      label: '17 Years',
      value: '17 Years',
    },
    {
      id: 18,
      label: '18 Years',
      value: '18 Years',
    },
    {
      id: 19,
      label: '19 Years',
      value: '19 Years',
    },
    {
      id: 20,
      label: '20 Years',
      value: '20 Years',
    },
    {
      id: 21,
      label: '21 Years',
      value: '21 Years',
    },
    {
      id: 22,
      label: '22 Years',
      value: '22 Years',
    },
    {
      id: 23,
      label: '23 Years',
      value: '23 Years',
    },
    {
      id: 24,
      label: '24 Years',
      value: '24 Years',
    },
    {
      id: 25,
      label: '25 Years',
      value: '25 Years',
    },
    {
      id: 26,
      label: '26 Years',
      value: '26 Years',
    },
    {
      id: 27,
      label: '27 Years',
      value: '27 Years',
    },
    {
      id: 28,
      label: '28 Years',
      value: '28 Years',
    },
    {
      id: 29,
      label: '29 Years',
      value: '29 Years',
    },
    {
      id: 30,
      label: '30 Years',
      value: '30 Years',
    },
  ],

  Months: [
    {
      id: 0,
      label: '0 Month',
      value: '0 Month',
    },
    {
      id: 1,
      label: '1 Month',
      value: '1 Month',
    },
    {
      id: 2,
      label: '2 Months',
      value: '2 Months',
    },
    {
      id: 3,
      label: '3 Months',
      value: '3 Months',
    },
    {
      id: 4,
      label: '4 Months',
      value: '4 Months',
    },
    {
      id: 5,
      label: '5 Months',
      value: '5 Months',
    },
    {
      id: 6,
      label: '6 Months',
      value: '6 Months',
    },
    {
      id: 7,
      label: '7 Months',
      value: '7 Months',
    },
    {
      id: 8,
      label: '8 Months',
      value: '8 Months',
    },
    {
      id: 9,
      label: '9 Months',
      value: '9 Months',
    },
    {
      id: 10,
      label: '10 Months',
      value: '10 Months',
    },
    {
      id: 11,
      label: '11 Months',
      value: '11 Months',
    },
  ],
  talldata: [
    { label: '130cm', value: '130' },
    { label: '131cm', value: '131' },
    { label: '132cm', value: '132' },
    { label: '133cm', value: '133' },
    { label: '134cm', value: '134' },
    { label: '135cm', value: '135' },
    { label: '136cm', value: '136' },
    { label: '137cm', value: '137' },
    { label: '138cm', value: '138' },
    { label: '139cm', value: '139' },
    { label: '140cm', value: '140' },
    { label: '141cm', value: '141' },
    { label: '142cm', value: '142' },
    { label: '143cm', value: '143' },
    { label: '144cm', value: '144' },
    { label: '145cm', value: '145' },
    { label: '146cm', value: '146' },
    { label: '147cm', value: '147' },
    { label: '148cm', value: '148' },
    { label: '149cm', value: '149' },
    { label: '150cm', value: '150' },
    { label: '151cm', value: '151' },
    { label: '152cm', value: '152' },
    { label: '153cm', value: '153' },
    { label: '154cm', value: '154' },
    { label: '155cm', value: '155' },
    { label: '156cm', value: '156' },
    { label: '157cm', value: '157' },
    { label: '158cm', value: '158' },
    { label: '159cm', value: '159' },
    { label: '160cm', value: '160' },
    { label: '161cm', value: '161' },
    { label: '162cm', value: '162' },
    { label: '163cm', value: '163' },
    { label: '164cm', value: '164' },
    { label: '165cm', value: '165' },
    { label: '166cm', value: '166' },
    { label: '167cm', value: '167' },
    { label: '168cm', value: '168' },
    { label: '169cm', value: '169' },
    { label: '170cm', value: '170' },
    { label: '171cm', value: '171' },
    { label: '172cm', value: '172' },
    { label: '173cm', value: '173' },
    { label: '174cm', value: '174' },
    { label: '175cm', value: '175' },
    { label: '176cm', value: '176' },
    { label: '177cm', value: '177' },
    { label: '178cm', value: '178' },
    { label: '179cm', value: '179' },
    { label: '180cm', value: '180' },
    { label: '181cm', value: '181' },
    { label: '182cm', value: '182' },
    { label: '183cm', value: '183' },
    { label: '184cm', value: '184' },
    { label: '185cm', value: '185' },
    { label: '186cm', value: '186' },
    { label: '187cm', value: '187' },
    { label: '188cm', value: '188' },
    { label: '189cm', value: '189' },
    { label: '190cm', value: '190' },
    { label: '191cm', value: '191' },
    { label: '192cm', value: '192' },
    { label: '193cm', value: '193' },
    { label: '194cm', value: '194' },
    { label: '195cm', value: '195' },
    { label: '196cm', value: '196' },
    { label: '197cm', value: '197' },
    { label: '198cm', value: '198' },
    { label: '199cm', value: '199' },
    { label: '200cm', value: '200' },
    { label: '201cm', value: '201' },
    { label: '202cm', value: '202' },
    { label: '203cm', value: '203' },
    { label: '204cm', value: '204' },
    { label: '205cm', value: '205' },
    { label: '206cm', value: '206' },
    { label: '207cm', value: '207' },
    { label: '208cm', value: '208' },
    { label: '209cm', value: '209' },
    { label: '210cm', value: '210' },
    { label: '211cm', value: '211' },
    { label: '212cm', value: '212' },
    { label: '213cm', value: '213' },
    { label: '214cm', value: '214' },
    { label: '215cm', value: '215' },
    { label: '216cm', value: '216' },
    { label: '217cm', value: '217' },
    { label: '218cm', value: '218' },
    { label: '219cm', value: '219' },
    { label: '220cm', value: '220' },
    { label: '221cm', value: '221' },
    { label: '222cm', value: '222' },
    { label: '223cm', value: '223' },
    { label: '224cm', value: '224' },
    { label: '225cm', value: '225' },
    { label: '226cm', value: '226' },
    { label: '227cm', value: '227' },
    { label: '228cm', value: '228' },
    { label: '229cm', value: '229' },
    { label: '230cm', value: '230' },
    { label: '231cm', value: '231' },
    { label: '232cm', value: '232' },
    { label: '233cm', value: '233' },
    { label: '234cm', value: '234' },
    { label: '235cm', value: '235' },
    { label: '236cm', value: '236' },
    { label: '237cm', value: '237' },
    { label: '238cm', value: '238' },
    { label: '239cm', value: '239' },
    { label: '240cm', value: '240' },
    { label: '241cm', value: '241' },
    { label: '242cm', value: '242' },
    { label: '243cm', value: '243' },
    { label: '244cm', value: '244' },
    { label: '245cm', value: '245' },
    { label: '246cm', value: '246' },
    { label: '247cm', value: '247' },
    { label: '248cm', value: '248' },
    { label: '249cm', value: '249' },
    { label: '250cm', value: '250' },
  ],
  weight: [
    { label: '30kg', value: '30' },
    { label: '31kg', value: '31' },
    { label: '32kg', value: '32' },
    { label: '33kg', value: '33' },
    { label: '34kg', value: '34' },
    { label: '35kg', value: '35' },
    { label: '36kg', value: '36' },
    { label: '37kg', value: '37' },
    { label: '38kg', value: '38' },
    { label: '39kg', value: '39' },
    { label: '40kg', value: '40' },
    { label: '41kg', value: '41' },
    { label: '42kg', value: '42' },
    { label: '43kg', value: '43' },
    { label: '44kg', value: '44' },
    { label: '45kg', value: '45' },
    { label: '46kg', value: '46' },
    { label: '47kg', value: '47' },
    { label: '48kg', value: '48' },
    { label: '49kg', value: '49' },
    { label: '50kg', value: '50' },
    { label: '51kg', value: '51' },
    { label: '52kg', value: '52' },
    { label: '53kg', value: '53' },
    { label: '54kg', value: '54' },
    { label: '55kg', value: '55' },
    { label: '56kg', value: '56' },
    { label: '57kg', value: '57' },
    { label: '58kg', value: '58' },
    { label: '59kg', value: '59' },
    { label: '60kg', value: '60' },
    { label: '61kg', value: '61' },
    { label: '62kg', value: '62' },
    { label: '63kg', value: '63' },
    { label: '64kg', value: '64' },
    { label: '65kg', value: '65' },
    { label: '66kg', value: '66' },
    { label: '67kg', value: '67' },
    { label: '68kg', value: '68' },
    { label: '69kg', value: '69' },
    { label: '70kg', value: '70' },
    { label: '71kg', value: '71' },
    { label: '72kg', value: '72' },
    { label: '73kg', value: '73' },
    { label: '74kg', value: '74' },
    { label: '75kg', value: '75' },
    { label: '76kg', value: '76' },
    { label: '77kg', value: '77' },
    { label: '78kg', value: '78' },
    { label: '79kg', value: '79' },
    { label: '80kg', value: '80' },
    { label: '81kg', value: '81' },
    { label: '82kg', value: '82' },
    { label: '83kg', value: '83' },
    { label: '84kg', value: '84' },
    { label: '85kg', value: '85' },
    { label: '86kg', value: '86' },
    { label: '87kg', value: '87' },
    { label: '88kg', value: '88' },
    { label: '89kg', value: '89' },
    { label: '90kg', value: '90' },
    { label: '91kg', value: '91' },
    { label: '92kg', value: '92' },
    { label: '93kg', value: '93' },
    { label: '94kg', value: '94' },
    { label: '95kg', value: '95' },
    { label: '96kg', value: '96' },
    { label: '97kg', value: '97' },
    { label: '98kg', value: '98' },
    { label: '99kg', value: '99' },
    { label: '100kg', value: '100' },
    { label: '101kg', value: '101' },
    { label: '102kg', value: '102' },
    { label: '103kg', value: '103' },
    { label: '104kg', value: '104' },
    { label: '105kg', value: '105' },
    { label: '106kg', value: '106' },
    { label: '107kg', value: '107' },
    { label: '108kg', value: '108' },
    { label: '109kg', value: '109' },
    { label: '110kg', value: '110' },
    { label: '111kg', value: '111' },
    { label: '112kg', value: '112' },
    { label: '113kg', value: '113' },
    { label: '114kg', value: '114' },
    { label: '115kg', value: '115' },
    { label: '116kg', value: '116' },
    { label: '117kg', value: '117' },
    { label: '118kg', value: '118' },
    { label: '119kg', value: '119' },
    { label: '120kg', value: '120' },
    { label: '121kg', value: '121' },
    { label: '122kg', value: '122' },
    { label: '123kg', value: '123' },
    { label: '124kg', value: '124' },
    { label: '125kg', value: '125' },
    { label: '126kg', value: '126' },
    { label: '127kg', value: '127' },
    { label: '128kg', value: '128' },
    { label: '129kg', value: '129' },
    { label: '130kg', value: '130' },
    { label: '131kg', value: '131' },
    { label: '132kg', value: '132' },
    { label: '133kg', value: '133' },
    { label: '134kg', value: '134' },
    { label: '135kg', value: '135' },
    { label: '136kg', value: '136' },
    { label: '137kg', value: '137' },
    { label: '138kg', value: '138' },
    { label: '139kg', value: '139' },
    { label: '140kg', value: '140' },
    { label: '141kg', value: '141' },
    { label: '142kg', value: '142' },
    { label: '143kg', value: '143' },
    { label: '144kg', value: '144' },
    { label: '145kg', value: '145' },
    { label: '146kg', value: '146' },
    { label: '147kg', value: '147' },
    { label: '148kg', value: '148' },
    { label: '149kg', value: '149' },
    { label: '150kg', value: '150' },
    { label: '151kg', value: '151' },
    { label: '152kg', value: '152' },
    { label: '153kg', value: '153' },
    { label: '154kg', value: '154' },
    { label: '155kg', value: '155' },
    { label: '156kg', value: '156' },
    { label: '157kg', value: '157' },
    { label: '158kg', value: '158' },
    { label: '159kg', value: '159' },
    { label: '160kg', value: '160' },
    { label: '161kg', value: '161' },
    { label: '162kg', value: '162' },
    { label: '163kg', value: '163' },
    { label: '164kg', value: '164' },
    { label: '165kg', value: '165' },
    { label: '166kg', value: '166' },
    { label: '167kg', value: '167' },
    { label: '168kg', value: '168' },
    { label: '169kg', value: '169' },
    { label: '170kg', value: '170' },
    { label: '171kg', value: '171' },
    { label: '172kg', value: '172' },
    { label: '173kg', value: '173' },
    { label: '174kg', value: '174' },
    { label: '175kg', value: '175' },
    { label: '176kg', value: '176' },
    { label: '177kg', value: '177' },
    { label: '178kg', value: '178' },
    { label: '179kg', value: '179' },
    { label: '180kg', value: '180' },
    { label: '181kg', value: '181' },
    { label: '182kg', value: '182' },
    { label: '183kg', value: '183' },
    { label: '184kg', value: '184' },
    { label: '185kg', value: '185' },
    { label: '186kg', value: '186' },
    { label: '187kg', value: '187' },
    { label: '188kg', value: '188' },
    { label: '189kg', value: '189' },
    { label: '190kg', value: '190' },
    { label: '191kg', value: '191' },
    { label: '192kg', value: '192' },
    { label: '193kg', value: '193' },
    { label: '194kg', value: '194' },
    { label: '195kg', value: '195' },
    { label: '196kg', value: '196' },
    { label: '197kg', value: '197' },
    { label: '198kg', value: '198' },
    { label: '199kg', value: '199' },
    { label: '200kg', value: '200' },
  ],
};
export const CONSTANT = {
  CALL_STATUS_LOADING: 'loading',
  CALL_STATUS_CONNECTED: 'connected',
  CALL_STATUS_DISCONNECTED: 'disconnected',
  CALL_STATUS_RINGING: 'ringing',
};
