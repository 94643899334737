import React from 'react';
import Page from '../../../components/Page';
import ProfileOpenNotification from '../../component/application/Notification';

const ProfileNotificationView = () => {
  return (
    <Page title='Profile Notification'>
      <ProfileOpenNotification />
    </Page>
  );
};

export default ProfileNotificationView;
