import React from "react";
import Page from "../../../components/Page";
import FavBenefitDetails from "src/views/component/application/Favourites/Benefits/BenefitDetails";

const FavBenefitPage = () => {
  return (
    <Page title="Dog Profile">
      <FavBenefitDetails />
    </Page>
  );
};

export default FavBenefitPage;
