import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  DialogContent,
  Dialog,
  Grid,
} from '@mui/material';
import { ReactComponent as PinkStar } from 'src/assets/images/icons/pink-star.svg';
import { ReactComponent as Cake } from 'src/assets/images/icons/cake.svg';
import { ReactComponent as Height } from 'src/assets/images/icons/height.svg';
import { ReactComponent as GenderIcon } from 'src/assets/images/icons/gender-icon.svg';
import { ReactComponent as Location } from 'src/assets/images/icons/Icon-location.svg';
import { ReactComponent as DogAvtar } from 'src/assets/images/dog_avatar.svg';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import moment from 'moment';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import PreLoader from 'src/components/Loader';
import NoDataFound from './NoData';
import { useNavigate, useLocation } from 'react-router-dom';
import '../favourites.scss';

const Matches = ({ localesData, selectedTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [matchesdata, setmatchesData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);
  // const [toogleDialog, setToogleDialog] = useState(false);
  const [ids, setId] = useState('');
  // GET MATCHES FAV
  const getMatchesfavdata = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        URLS.MATCHING_TAB_LIST + '?favourite=true'
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        getAllUserDetailsNow(data?.users);
        // if (data?.user.length === 0) {
        //   setLoader(false);
        // } else {
        //   console.log('error');
        // }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getAllUserDetailsNow = async (AllUserIDs) => {
    const list = [];
    if (AllUserIDs?.length === 0) {
      getMetchesFromKennetToo(list);
    } else {
      setLoader(true);
      try {
        const promises = AllUserIDs.map(async (ID) => {
          if (ID) {
            const response = await axiosInstance.get(
              URLS.GET_PROFILE + '/' + ID
            );
            return response;
          }
        });
        const AllBullshitResponse = await Promise.all(promises);

        AllBullshitResponse?.map(async (response, responseIndex) => {
          if (response?.status === 200) {
            const data_response = response?.data;

            if (data_response?.userDetails) {
              if (
                data_response?.userDetails?.isLike === 1 ||
                data_response?.userDetails?.isLike === '1' ||
                data_response?.userDetails?.isLike === true
              ) {
                list.push(data_response?.userDetails);
              }
              if (responseIndex === AllUserIDs.length - 1) {
                setmatchesData(list);
                getMetchesFromKennetToo(list);
                // setLoader(false);
              }
            }
          }
        });
      } catch (error) {
        setLoader(false);
        setApiMessage('error', error?.response?.data?.message);
      }
    }
  };

  const getMetchesFromKennetToo = async (matchesdata) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.MATCHES);
      if (status === 200) {
        const old_data = matchesdata;

        setApiMessage('success', data?.message);
        const DuplicateRemovedFilteredFavMatchesAgain = data?.users?.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.id === thing.id)
        );

        DuplicateRemovedFilteredFavMatchesAgain?.map((item, index) => {
          if (item.isLike === 1 || item.isLike === true) {
            old_data.push(item);
          }
          return true;
        });
        setmatchesData(old_data);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // const handlePopupOpen = () => {
  //   if (profileData?.isPaid === false) {
  //     setToogleDialog(true);
  //   }
  // };

  const removeFavouriteAPI = async (id) => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.delete(URLS.FAVOURITE + '/' + id);
      if (status === 200) {
        const filterData = matchesdata?.filter((obj) => {
          return obj?.fevouriteId !== id;
        });
        setmatchesData(filterData);
        setIsDialogOpen(false);
        setId('');
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const MakeFavouriteAPI = async (id) => {
    const param_data = {
      following: id.toString(),
      type: 'benefits',
    };
    try {
      setLoader(true);
      const { status } = await axiosInstance.post(URLS.FAVOURITE, param_data);

      if (status === 200) {
        setIsDialogOpen(false);

        setId('');
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getMatchesfavdata();
  }, [selectedTab]);

  useEffect(() => {
    if (location?.state?.removeId) {
      setTimeout(() => {
        removeFavouriteAPI(location?.state?.removeId);
      }, 400);
    }
  }, [location?.state]);

  return (
    <Box className='matches-wrap'>
      {loader && <PreLoader />}
      {matchesdata?.length === 0 ? (
        <NoDataFound />
      ) : (
        <Box className='favourite-tab-content'>
          {matchesdata &&
            matchesdata?.length > 0 &&
            matchesdata?.map((content, id) => {
              let _age = '';
              if (content?.dob !== '' && content?.dob !== null) {
                const d = moment(content?.dob).format('MMM DD, YYYY');
                const msDiff = new Date().getTime() - new Date(d).getTime();
                const daysTill30June2035 = Math.floor(
                  msDiff / (1000 * 60 * 60 * 24)
                );
                _age = Math.floor(daysTill30June2035 / 365);
              }

              return (
                <>
                  <Box className='matches-all-wrap'>
                    <Box className='matches-card-sec mb-2'>
                      <Box
                        className='matches-box-sec d-flex'
                        onClick={() =>
                          navigate(`/favourites/matches/${content?.id}`)
                        }
                      >
                        <img
                          src={content?.images?.[0]?.url}
                          className='user-img-inner'
                          alt='user-img'
                          height='70px'
                          width='70px'
                          style={{ borderRadius: '15px' }}
                        />

                        <Box className='matche-sec'>
                          <Box className='matches-grid-sec'>
                            <Box>
                              <Box className=' font-r-12 dog-name'>
                                {content?.firstname} {content?.lastname}
                              </Box>
                            </Box>
                            <Box className='d-flex o-auto'>
                              <Box className='d-flex dog-details'>
                                <Box className='detail-content '>
                                  <Cake />
                                </Box>
                                <Box className='font-r-12 '>
                                  {_age !== '' ? _age : '-'}
                                </Box>
                              </Box>
                              <Box className='d-flex location dog-details'>
                                <Box className='detail-content'>
                                  <Height />
                                </Box>
                                <Box className='font-r-12 '>
                                  {content?.height !== ''
                                    ? content?.height
                                    : '-'}
                                </Box>
                              </Box>
                              <Box className='d-flex location dog-details'>
                                <Box className='detail-content'>
                                  <Location />
                                </Box>
                                <Box className='font-r-12 '>
                                  {content?.location !== ''
                                    ? content?.location
                                    : '-'}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className='star-sec'>
                        {' '}
                        <Avatar
                          alt='Remy Sharp'
                          variant='square'
                          className='matches-star-icon'
                          onClick={() => {
                            setIsDialogOpen(true);
                            setId(content);
                          }}
                        >
                          <PinkStar />
                        </Avatar>
                        <Dialog
                          open={isDialogOpen}
                          onClose={() => setIsDialogOpen(false)}
                          className='delete-dialog-box'
                        >
                          <DialogContent>
                            <Box className='delete-head-sec'>
                              <Box className='favourites-delete-sec'>
                                <Typography className='dailog-head-text pb-1'>
                                  {' '}
                                  {localesData?.favourites_delete?.ARE_YOU_SURE}
                                </Typography>
                                <Typography className='font-r-13 t-center pb-1'>
                                  {' '}
                                  {localesData?.favourites_matches?.DELETE}
                                </Typography>
                              </Box>
                              <Box className='permission-btns'>
                                <Grid
                                  container
                                  direction='row'
                                  justifyContent='center'
                                  alignItems='center'
                                >
                                  <Grid item xs={6} className='cancel-btn'>
                                    <CustomButton
                                      variant='text'
                                      title={
                                        localesData?.favourites_delete?.CANCEL
                                      }
                                      fontWeight='600'
                                      type='submit'
                                      classname='favourites-cancel-btn favourites-btn'
                                      onClick={() => {
                                        setId('');
                                        setIsDialogOpen(false);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <CustomButton
                                      variant='text'
                                      title={
                                        localesData?.favourites_delete?.YES
                                      }
                                      fontWeight='600'
                                      type='submit'
                                      classname='favourites-yes-btn favourites-btn'
                                      onClick={() => {
                                        if (
                                          ids?.isLike === 1 ||
                                          ids?.isLike === '1' ||
                                          ids?.isLike === true
                                        ) {
                                          removeFavouriteAPI(ids?.fevouriteId);
                                        } else {
                                          MakeFavouriteAPI(ids?.id);
                                        }
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>

                      {/* 2 */}

                      {content?.dogs?.length === 0 ? (
                        <>
                          <Typography className='font-r-12 t-center m-4'>
                            {localesData?.NO_DOG}
                          </Typography>
                        </>
                      ) : (
                        <Box
                          className='matches-box-sec d-flex'
                          style={{ padding: '19px 0px 10px 17px' }}
                          onClick={() =>
                            navigate(`/favourites/matches/${content?.id}`)
                          }
                        >
                          {/* {content?.dogs?.[0]?.images?.[0]?.substr(
                          content?.dogs?.[0]?.images?.[0]?.length - 3
                        ) === 'mp4' ? (
                          <video className='user-video-inner' controls>
                            <source src={videoUrl} type='video/mp4' />
                          </video>
                        ) : (
                          (console.log('first', content),
                          ( */}
                          {/* <img
                          src={content?.dogs?.[0]?.images?.[0]?.url}
                          height='70px'
                          width='70px'
                          className='user-img-inner'
                        /> */}
                          {/* {console.log('content?.dogs', content?.dogs)} */}
                          <div
                          // style={{ width: '60px' }}
                          >
                            {content?.dogs?.images?.[0] &&
                            content?.dogs?.images?.[0]?.url?.endsWith(
                              '.mp4'
                            ) ? (
                              <img
                                src={content?.dogs?.images?.[1]?.url || ''}
                                className='user-img-inner'
                                alt=''
                              />
                            ) : (
                              <img
                                src={content?.dogs?.images?.[0]?.url}
                                className='user-img-inner'
                                alt=''
                              />
                            )}
                          </div>
                          {/* ))
                        )} */}

                          {/* <img
                            src={content?.dogs?.[0]?.images?.[0]?.url}
                            height='70px'
                            width='70px'
                            className='user-img-inner'
                          /> */}
                          <Box className='matche-sec'>
                            <Box className='matches-grid-sec'>
                              <Box>
                                <Box className=' font-r-12 dog-name d-flex align-center'>
                                  {content?.dogs?.[0]?.name !== '' ||
                                  (content?.dogs?.name !== '' &&
                                    content?.dogs?.[0]?.name !== undefined)
                                    ? content?.dogs?.[0]?.name ||
                                      content?.dogs?.name
                                    : '-'}{' '}
                                  {content.dogs?.[0]?.deceased ? (
                                    <>
                                      <span className='round'></span>
                                      {content.dogs?.[0]?.deceased
                                        ? 'Deceased'
                                        : ''}
                                    </>
                                  ) : null}
                                </Box>
                              </Box>
                              <Box className='d-flex o-auto '>
                                <Box className='d-flex dog-details'>
                                  <Box className='detail-content '>
                                    <Cake />
                                  </Box>
                                  <Box className='font-r-12 '>
                                    {content?.dogs?.[0]?.age !== '' ||
                                    (content?.dogs?.age !== '' &&
                                      content?.dogs?.[0]?.age !== undefined)
                                      ? content?.dogs?.[0]?.age ||
                                        content?.dogs?.age
                                      : '-'}{' '}
                                  </Box>
                                </Box>
                                <Box className='d-flex location dog-details'>
                                  <Box className='detail-content'>
                                    <DogAvtar />
                                  </Box>
                                  <Box className='font-r-12 '>
                                    {' '}
                                    {content?.dogs?.[0]?.breed !== '' ||
                                    (content?.dogs?.breed !== '' &&
                                      content?.dogs?.[0]?.breed !== undefined)
                                      ? content?.dogs?.[0]?.breed ||
                                        content?.dogs?.breed
                                      : '-'}{' '}
                                  </Box>
                                </Box>
                                <Box className='d-flex location dog-details'>
                                  <Box className='detail-content'>
                                    <GenderIcon />
                                  </Box>
                                  <Box className='font-r-12 '>
                                    {content?.dogs?.[0]?.gender !== '' ||
                                    (content?.dogs?.gender !== '' &&
                                      content?.dogs?.[0]?.gender !== undefined)
                                      ? content?.dogs?.[0]?.gender ||
                                        content?.dogs?.gender
                                      : '-'}{' '}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default Matches;
