import React from 'react';
// import '../UserWork/userWork.scss';
import YourPolitics from './YourPolitics';

const YourPoliticsFinal = ({
  localesData,
  handleNext,
  questions,
  setLoader,
  dispatch,
  answars,
}) => {
  return (
    <>
      <YourPolitics
        localesData={localesData}
        handleNext={handleNext}
        questions={questions}
        setLoader={setLoader}
        dispatch={dispatch}
        answars={answars}
      />
    </>
  );
};

export default YourPoliticsFinal;
