import { InputBase } from '@mui/material';
import { withStyles } from '@mui/styles';

export const CustomSelect = withStyles((theme) => ({
  input: {
    '&:hover': {
      borderColor: '#000000',
      // border: "1px solid #4582c3",
    },
    '&:focus': {
      borderBottom: '1.5px solid #4582c3',
    },
    '&:Mui-error': {
      borderBottom: '1.5px solid #D84727',
    },
  },
}))(InputBase);
