import { configureStore } from '@reduxjs/toolkit';

import authSlice from './actions/auth';
import navigateSlice from './actions/navigateSlice';
import profileSetup from './actions/profileSetup';
import progressImage from './actions/progressImage';
import progressBarSlice from './actions/progressBarSlice';
import questionAnswerSlice from './actions/questionAnswerSlice';
import notificationCountSlice from './actions/notificationCount';
import directorySlice from './actions/directory';
import manageaccountSlice from './actions/manageaccount';

export const store = configureStore({
  reducer: {
    auth_data: authSlice,
    page_navigate: navigateSlice,
    profileData: profileSetup,
    progressImage: progressImage,
    progressBarData: progressBarSlice,
    questionAnswer: questionAnswerSlice,
    notificationCount: notificationCountSlice,
    directoryData: directorySlice,
    manageAccountData: manageaccountSlice
  },
});
