export const MSG_TYPE = {
  STRING: 'string',
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  CONTACT: 'contact',
  PROGRESS: 'progress',
  LOCATION: 'location',
  DOCUMENT: 'doc',
  ADD_MEMBER: 'add_user',
  REMOVE_MEMBER: 'remove_user',
  LEAVE_GROUP: 'leave_group',
  CREATE_GROUP: 'create_group',
  CREATE_GROUP_PRIVATE: 'create_private_group',
  EMOJI: 'emoji',
  AUDIO_CALL: 'audio_call',
  VIDEO_CALL: 'video_call',
  END_CALL: 'end_call',
  REJECT_CALL: 'reject_call',
  MISSED_CALL: 'missed_call',
  ACCEPT_CALL: 'accept_call',
  EVENT_END_CALL: 'end_call',
  EVENT_REJECT_CALL: 'reject_call',
  EVENT_ACCEPT_CALL: 'accept_call',
};
