import React from 'react';
import Page from '../../../components/Page';
import SubscriptionPlan from '../../component/application/PaymentDetails/SubscriptionPlan';

const SubscriptionPlanPage = () => {
  return (
    <Page title='Subscription Plan'>
      <SubscriptionPlan />
    </Page>
  );
};

export default SubscriptionPlanPage;
