import React, { useEffect } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CustomTextField } from '../../UI/textfield';
import CustomButton from '../../UI/Button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { setSVGFile } from '../../../../helpers/store/actions/progressImage';
import svgData from '../../../../assets/images/lname-dog.svg';

const SetLastName = ({ localesData, handleNext }) => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.profileData);

  useEffect(() => {
    dispatch(setSVGFile(svgData));
  }, [dispatch]);
  return (
    <Formik
      initialValues={{
        last_name: profileData?.last_name ? profileData?.last_name : '',
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        last_name: Yup.string().required(localesData?.validation?.REQUIRED),
      })}
      onSubmit={(requestData) => {
        // let sendData = {
        //   last_name: requestData.last_name,
        // };
        handleNext();
        dispatch(
          addProfileData({ key: 'last_name', value: requestData.last_name })
        );
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className='form-content'>
          {/* <Box className='t-center'>
            <Typography variant='h3' className='s-bold mb-2'>
              {localesData?.HI} {profileData?.first_name},
            </Typography>
            <Typography
              variant='h4'
              className='mb-10'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.LNAME_DESC,
              }}
            />
            <Typography
              variant='h3'
              className='s-bold'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.WHATS_YOUR_LAST_NAME,
              }}
            />
          </Box>

          <Box className='field-content mb-8'>
            <CustomTextField
              fullWidth
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              variant='standard'
              className='custom-textfield'
              name='last_name'
              value={values.last_name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormHelperText className='font-r-13'>
              {localesData?.profile?.LAST_NAME_HELPER}
            </FormHelperText>
          </Box>
          <Box>
            <CustomButton
              variant='contained'
              title={localesData?.NEXT}
              background='#000000'
              fontWeight='600'
              type='submit'
            />
          </Box> */}
          <Box className='t-center mb-2'>
            <Typography variant='h3' className='s-bold mb-2'>
              {localesData?.HI} {profileData?.first_name},
            </Typography>
            <Typography
              variant='h4'
              className='mb-3'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.LNAME_DESC,
              }}
            />
            <Typography
              variant='h3'
              className='s-bold'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.WHATS_YOUR_LAST_NAME,
              }}
            />
          </Box>

          <Box className='field-content mb-6'>
            <CustomTextField
              fullWidth
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              variant='standard'
              className='custom-textfield'
              name='last_name'
              value={values.last_name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormHelperText className='font-r-13'>
              {localesData?.profile?.LAST_NAME_HELPER}
            </FormHelperText>
          </Box>
          <Box>
            <CustomButton
              variant='contained'
              title={localesData?.NEXT}
              background='#000000'
              fontWeight='600'
              type='submit'
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SetLastName;
