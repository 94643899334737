import React, {  useEffect } from 'react';
import './helpCenter.scss';
import { Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const ContentPage = ({localesData}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  useEffect(() => {
    !state && navigate('/help-center/');
  }, []);

  return (
    <Box className="content-page-sec">
      <Typography
        className="header-txt pb-5"
        dangerouslySetInnerHTML={{
          __html: localesData?.help_center?.HELP_CENTER,
        }}
      />
      <Box>
        <Typography className="sub-header pb-5 t-center">
          {state?.title}
        </Typography>
        <Typography
          className="subject-desc pb-3"
          dangerouslySetInnerHTML={{
            __html: state?.description,
          }}
        />
      </Box>
    </Box>
  );
};

export default ContentPage;
