import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IntlContext } from '../../../../../App';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import Employed from './Employed';
import ProgressBar from '../../../UI/ProgressBar';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import './userWork.scss';

const UserWork = ({
  handleNext,
  reduxProgress,
  questions,
  setLoader,
  dispatch,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [yesNo, setYesNo] = useState(false);
  const [yesNoValue, setYesNoValue] = useState('');
  const [yesBtnColor, setYesBtnColor] = useState('#FFFFFF');
  const [noBtnColor, setNoBtnColor] = useState('#FFFFFF');
  const [empData, setEmpData] = useState('');
  const [empLivingData, setEmpLivingData] = useState('');
  const [doYouWork, setDoYouWork] = useState('');
  const [doYouWorkThere, setDoYouWorkThere] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  // let test = questions
  //   ?.find((o) => o?.id === 2)
  //   ?.Question?.find((item) => {
  //     return item?.q_order === 6;
  //   });
  // setEmpData({
  //   title: test?.title,
  //   id: test?.id,
  //   options: test?.Option,
  // });
  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item?.q_order === 6) {
  //         setEmpData({
  //           title: item?.title,
  //           id: item?.id,
  //           options: item?.Option,
  //         });
  //       }
  //       if (item.q_order === 7) {
  //         setEmpLivingData({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 8) {
  //         setDoYouWork({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 9) {
  //         setDoYouWorkThere({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       return true;
  //     });
  // }, [questions]);

  // useEffect(() => {
  //   const question = questions?.find((o) => o?.id === 2);

  //   const handleQuestionItem = (item, setData) => {
  //     setData({
  //       title: item.title,
  //       id: item.id,
  //       options: item.Option,
  //     });
  //   };

  //   question?.Question?.forEach((item) => {
  //     switch (item.q_order) {
  //       case 6:
  //         handleQuestionItem(item, setEmpData);
  //         break;
  //       case 7:
  //         handleQuestionItem(item, setEmpLivingData);
  //         break;
  //       case 8:
  //         handleQuestionItem(item, setDoYouWork);
  //         break;
  //       case 9:
  //         handleQuestionItem(item, setDoYouWorkThere);
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  // }, [questions]);

  // useEffect(() => {
  //   const yesNoAnswer = answars?.find((j) => j?.id === 9);
  //   const leavingAnswer = answars?.find((j) => j?.id === 10);
  //   if (
  //     yesNoAnswer?.Option?.[0]?.answer === '1' ||
  //     yesNoAnswer?.Option?.[0]?.answer === 1
  //   ) {
  //     setYesNoValue('yes');
  //     setYesNo(true);
  //     setYesBtnColor('#F6D6C9');
  //   } else if (
  //     yesNoAnswer?.Option?.[1]?.answer === '1' ||
  //     yesNoAnswer?.Option?.[1]?.answer === 1
  //   ) {
  //     setYesNoValue('no');
  //     setYesNo(false);
  //     setNoBtnColor('#F6D6C9');
  //   }
  //   if (leavingAnswer?.Option?.[0]?.answer) {
  //     setEmpLivingData({
  //       ...empLivingData,
  //       answer: leavingAnswer?.Option?.[0]?.answer,
  //     });
  //   }
  //   if (leavingAnswer?.Option?.[0]?.answer) {
  //     setEmpLivingData({
  //       ...empLivingData,
  //       answer: leavingAnswer?.Option?.[0]?.answer,
  //     });
  //   }
  //   if (leavingAnswer?.Option?.[0]?.answer) {
  //     setEmpLivingData({
  //       ...empLivingData,
  //       answer: leavingAnswer?.Option?.[0]?.answer,
  //     });
  //   }
  // }, [answars]);

  useEffect(() => {
    const handleQuestionItem = (item, setData) => {
      setData({
        title: item.title,
        id: item.id,
        options: item.Option,
        private: item?.private === '1' || item?.private === 1 ? true : false,
      });
      // setYear(item?.Option?.[0].answer);
      // setMonth(item?.Option?.[0].answer);
      if (item?.id === 12) {
        const answerYearMonth =
          item?.Option[0]?.answer && item?.Option[0]?.answer?.split(' ');
        if (answerYearMonth?.length >= 4) {
          const year = answerYearMonth[0] + ' ' + answerYearMonth[1];
          const month = answerYearMonth[2] + ' ' + answerYearMonth[3];
          setYear(year);
          setMonth(month);
        } else {
          setYear('');
          setMonth('');
        }
      }
      // if (item.id === 12) {
      //   console.log('ddd', item?.Option);
      //   setYear(
      //     item?.Option?.length > 0
      //       ? item?.Option?.[0]?.answer?.split(' ')?.[0] +
      //           ' ' +
      //           item?.Option?.[0]?.answer?.split(' ')?.[1]
      //       : ''
      //   );
      //   setMonth(
      //     item?.Option?.length > 0
      //       ? item?.Option?.[0]?.answer?.split(' ')?.[2] +
      //           ' ' +
      //           item?.Option?.[0]?.answer?.split(' ')?.[3]
      //       : ''
      //   );
      // }
    };
    answars?.forEach((item) => {
      switch (item.id) {
        case 9:
          handleQuestionItem(item, setEmpData);
          break;
        case 10:
          handleQuestionItem(item, setEmpLivingData);
          break;
        case 11:
          handleQuestionItem(item, setDoYouWork);
          break;
        case 12:
          handleQuestionItem(item, setDoYouWorkThere);
          break;
        default:
          break;
      }
    });
  }, [answars]);

  useEffect(() => {
    setYesNoValue(empData?.options?.[0]?.answer === '1' ? 'yes' : 'no');
    setYesNo(empData?.options?.[0]?.answer === '1' ? true : false);
    setYesBtnColor(empData?.options?.[0]?.answer === '1' ? '#F6D6C9' : '#FFF');
    setNoBtnColor(empData?.options?.[1]?.answer === '1' ? '#F6D6C9' : '#FFF');
  }, [empData]);

  // useEffect(() => {

  // }, [questions]);

  const redirectrulespageNo = () => {
    setYesNoValue('no');
    setYesNo(false);
    setNoBtnColor('#F6D6C9');
    setYesBtnColor('#FFFFFF');
  };
  const redirectrulespageYes = () => {
    setYesNoValue('yes');
    setYesNo(true);
    setYesBtnColor('#F6D6C9');
    setNoBtnColor('#FFFFFF');
  };
  // const handleChange = (e) => {
  //   const { name, value, checked } = e.target;
  //   if (name === 'empLivingData') {
  //     setEmpLivingData({ ...empLivingData, answer: value });
  //   }
  //   if (name === 'empLivingDataSwitch') {
  //     setEmpLivingData({ ...empLivingData, private: checked });
  //   }
  //   if (name === 'doYouWork') {
  //     setDoYouWork({ ...doYouWork, answer: value });
  //   }
  //   if (name === 'doYouWorkSwitch') {
  //     setDoYouWork({ ...doYouWork, private: checked });
  //   }

  //   if (name === 'year') {
  //     setYear(value);
  //   }
  //   if (name === 'month') {
  //     setMonth(value);
  //   }
  //   if (name === 'year' || name === 'month') {
  //     setDoYouWorkThere({ ...doYouWorkThere, answer: year + ' ' + month });
  //   }
  //   if (name === 'doYouWorkThereSwitch') {
  //     setDoYouWorkThere({ ...doYouWorkThere, private: checked });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'empLivingData') {
      setEmpLivingData({
        ...empLivingData,
        options: [
          {
            ...empLivingData.options[0],
            answer: value,
          },
          ...empLivingData.options.slice(1),
        ],
      });
    }
    if (name === 'empLivingDataSwitch') {
      setEmpLivingData({ ...empLivingData, private: checked });
    }
    if (name === 'doYouWork') {
      setDoYouWork({
        ...doYouWork,
        options: [
          {
            ...doYouWork.options[0],
            answer: value,
          },
          ...doYouWork.options.slice(1),
        ],
      });
    }
    if (name === 'doYouWorkSwitch') {
      setDoYouWork({ ...doYouWork, private: checked });
    }
    if (name === 'year') {
      setYear(value);
    }
    if (name === 'month') {
      setMonth(value);
    }

    if (name === 'doYouWorkThereSwitch') {
      setDoYouWorkThere({ ...doYouWorkThere, private: checked });
    }
  };

  const handleOnSubmit = async () => {
    // var opt = [];
    // opt.push({
    //   optionId: empData?.options[0]?.id,
    //   answer: yesNoValue === 'yes' ? '1' : '0',
    //   selected: yesNoValue === 'yes' ? true : false,
    //   private: false,
    // });
    // opt.push({
    //   optionId: empData?.options[1]?.id,
    //   answer: yesNoValue === 'no' ? '1' : '0',
    //   selected: yesNoValue === 'no' ? true : false,
    //   private: false,
    // });

    // if (yesNo) {
    //   opt.push({
    //     optionId: empLivingData?.id,
    //     answer: empLivingData?.answer ? empLivingData?.answer : null,
    //     selected: false,
    //     private: empLivingData?.private ? empLivingData?.private : false,
    //   });
    //   opt.push({
    //     optionId: doYouWork?.id,
    //     answer: doYouWork?.answer ? doYouWork?.answer : null,
    //     selected: false,
    //     private: doYouWork?.private ? doYouWork?.private : false,
    //   });
    //   opt.push({
    //     optionId: doYouWorkThere?.id,
    //     answer: year && month ? year + ' ' + month : null,
    //     selected: false,
    //     private: doYouWorkThere?.private ? doYouWorkThere?.private : false,
    //   });
    // }

    var opt = [];
    opt.push({
      optionId: empData?.options[0]?.id,
      answer: yesNoValue === 'yes' ? '1' : '0',
      selected: yesNoValue === 'yes' ? true : false,
      private: false,
    });
    opt.push({
      optionId: empData?.options[1]?.id,
      answer: yesNoValue === 'no' ? '1' : '0',
      selected: yesNoValue === 'no' ? true : false,
      private: false,
    });

    // if (yesNo) {
    opt.push({
      optionId: empLivingData?.options?.[0]?.id,
      // answer: empLivingData?.options?.[0].answer
      //   ? empLivingData?.options?.[0].answer
      //   : null,
      answer:
        yesNoValue === 'yes'
          ? empLivingData?.options?.[0]?.answer?.trim() === ''
            ? null
            : empLivingData?.options?.[0]?.answer
          : yesNoValue === 'no'
          ? ''
          : empLivingData?.options?.[0]?.answer,
      selected: false,
      private: empLivingData?.private ? empLivingData?.private : false,
    });
    opt.push({
      optionId: doYouWork?.options?.[0]?.id,
      // answer: doYouWork?.options?.[0]?.answer
      //   ? doYouWork?.options?.[0]?.answer
      //   : null,
      answer:
        yesNoValue === 'yes'
          ? doYouWork?.options?.[0]?.answer?.trim() === ''
            ? null
            : doYouWork?.options?.[0]?.answer
          : yesNoValue === 'no'
          ? ''
          : doYouWork?.options?.[0]?.answer,
      selected: false,
      private: doYouWork?.private ? doYouWork?.private : false,
    });
    opt.push({
      optionId: doYouWorkThere?.options?.[0]?.id,
      // answer: year && month ? year + ' ' + month : null,
      answer:
        yesNoValue === 'yes'
          ? !year && !month
            ? null
            : year + ' ' + month
          : yesNoValue === 'no'
          ? ''
          : year + ' ' + month,
      selected: false,
      private: doYouWorkThere?.private ? doYouWorkThere?.private : false,
    });
    // }

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        // if (isEdit) {
        //   if (yesNoValue === 'no') {
        //     checkForBlankData();
        //   } else {
        //     navigate('/edit-profile', {
        //       state: 'profile-edit',
        //     });
        //   }
        // } else {
        //   handleNext();
        // }
        if (isEdit) {
          // checkForBlankData();
          checkForBlankAnsData();
        } else {
          handleNext();
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const checkForBlankAnsData = async () => {
    try {
      setLoader(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;
      if (status === 200) {
        data?.questions.map((item) => {
          if (breakIt === false) {
            if (item.id === 13 || item.id === 14 || item.id === 15) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(2));
            } else if (item.id === 16) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(3));
            } else if (item.id === 17) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(4));
            } else if (item.id === 18) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(5));
            } else if (item.id === 19) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(6));
            } else if (item.id === 20) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext('otherKids'));
            } else if (item.id === 23 || item.id === 24) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(7));
            } else if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Box className='t-center pb-6'>
      <Box>
        <Typography
          className='mb-2 font-r-12'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
          }}
        />
        <Box className='mb-3'>
          <Typography variant='h4' className='pb-1'>
            {/* {reduxProgress}% */}
            {progressWeightage}%
          </Typography>
          <ProgressBar processData={progressWeightage} />
        </Box>
        <Typography variant='h3' className='s-bold pb-2'>
          {localesData?.userwork?.Now_its_all_about_you}
        </Typography>
        <Typography variant='h4' className='pb-4'>
          {localesData?.userwork?.lets_talk_about_your_job}
        </Typography>
        <Typography variant='h3' className='s-bold pb-4'>
          {empData?.title}
        </Typography>
      </Box>
      <Box className='d-flex yes-no-sec '>
        <CustomButton
          variant='contained'
          title={empData?.options?.[0]?.title}
          background={yesBtnColor}
          fontWeight='400'
          type='submit'
          color='#000000'
          classname='capitalize yes-no-btn'
          onClick={redirectrulespageYes}
        />
        <CustomButton
          variant='contained'
          title={empData?.options?.[1]?.title}
          background={noBtnColor}
          fontWeight='400'
          type='submit'
          color='#000000'
          classname='capitalize yes-no-btn'
          onClick={redirectrulespageNo}
        />
      </Box>
      {yesNo && (
        <Employed
          questions={questions}
          handleChange={handleChange}
          empLivingData={empLivingData}
          doYouWork={doYouWork}
          doYouWorkThere={doYouWorkThere}
          yearData={year}
          monthData={month}
          isEdit={isEdit}
        />
      )}
      <Box className={!yesNo ? 'keep-going-sec pt-130' : ''}>
        <CustomButton
          variant='contained'
          title={localesData?.userwork?.keep_going}
          background='#6A42ED'
          fontWeight='400'
          onClick={() => handleOnSubmit()}
        />

        <Box className='justify-center'>
          <DogIcon className='profile-footer-dog-img' />
          <Typography
            variant='h5'
            className='pointer'
            // onClick={() => navigate('/')}
            onClick={() => redirectHomePage()}
          >
            {localesData?.userwork?.FINISH}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserWork;
