import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { IntlContext } from '../../../../App';

import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import ProfileCreationHaveDog from './ProfileCreationHaveDog';
import ProfileCreation from './ProfileCreation';
import ProfileCompletionRules from './ProfileCompletionRules';
import ProfileCompletionDog from './ProfileCompletionDogName';
import StartTalk from './StartTalk';
// import ProfileVideoVerification from './ProfileVideoVerification';
import ProfileCompletionDogDetails from './ProfileCompletionDogDetails';
import ProfileCompletionDogPicture from './ProfileCompletionDogPicture';
// import ProfileCompletionUser from './ProfileCompletionUser';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import PreLoader from '../../../../components/Loader';
import { saveToStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import ProfileCompletionUser from './ProfileCompletionUser';
// import DogVideo from './DogVideo';

const ProfileCompletion = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  // const reduxProgress = useSelector((state) => state?.progressBarData);
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answars, setAnswars] = useState([]);
  const [progressWeightage, setProgressWeightage] = useState(0);
  const [questionsBlankLoaded, setQuestionsBlankLoaded] = useState(false);

  useEffect(() => {
    if (reduxStep) {
      setActiveStep(reduxStep);
    } else {
      setActiveStep(0);
    }
  }, [reduxStep]);

  const handleNexts = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(handleNext(activeStep + 1));
  };

  const getAllQuestions = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.QUESTIONS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setQuestions(data?.types);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setAnswars(data?.questions);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getProfile = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        saveToStorage(identifiers?.USER_DATA, data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllQuestions();
    getAllAnswars();
    getProfile();
    // eslint-disable-next-line
  }, []);

  const getAllQuestionsBlank = async () => {
    var black_data = 0;
    var job = true;
    var education = true;
    var height_weight = true;
    var vices = true;
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      if (status === 200) {
        data?.questions?.map((item) => {
          // if (item.id === 8) {
          //   black_data++; //looking for
          // }
          if (
            item.id === 9 ||
            item.id === 10 ||
            item.id === 11 ||
            item.id === 12
          ) {
            if (job) {
              black_data++; //job
              job = false;
            }
          }
          if (item.id === 13 || item.id === 14 || item.id === 15) {
            if (education) {
              black_data++; //education
              education = false;
            }
          }
          if (item.id === 16) {
            black_data++; //hobbies
          }
          if (item.id === 17) {
            black_data++; //religion
          }
          if (item.id === 18) {
            black_data++; //politics
          }
          if (item.id === 19) {
            black_data++; //children
          }
          if (item.id === 20) {
            black_data++; //how many kids
          }
          if (item.id === 23 || item.id === 24) {
            if (height_weight) {
              black_data++; //height weight
              height_weight = false;
            }
          }
          if (item.id === 25) {
            black_data++; //ethnicity
          }
          if (item.id === 26) {
            black_data++; //family plan
          }
          if (
            item.id === 28 ||
            item.id === 29 ||
            item.id === 30 ||
            item.id === 31
          ) {
            if (vices) {
              black_data++; //vices
              vices = false;
            }
          }
          if (item.id === 33) {
            black_data++; //location
          }
          if (item.id === 2) {
            black_data++; //about gender
          }
          if (item.id === 54) {
            black_data++; //about age
          }
          if (item.id === 55) {
            black_data++; //about dog breed
          }
          if (item.id === 56) {
            black_data++; //about job
          }
          if (item.id === 57) {
            black_data++; //about education
          }
          if (item.id === 58) {
            black_data++; //about hobbies
          }
          if (item.id === 59) {
            black_data++; //about religion
          }
          if (item.id === 60) {
            black_data++; //about politics
          }
          if (item.id === 61) {
            black_data++; //about height weight
          }
          if (item.id === 62) {
            black_data++; //about ethnicity
          }
          if (item.id === 63) {
            black_data++; //about vices
          }
          if (item.id === 64) {
            black_data++; //about location
          }
          return true;
        });

        setProgressWeightage((25 - black_data) * 4);
        // dispatch(addProgressBar((25 - black_data) * 4));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (!questionsBlankLoaded) {
      getAllQuestionsBlank();
      setQuestionsBlankLoaded(true);
    }
  }, [activeStep, questionsBlankLoaded]);

  const renderComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ProfileCreationHaveDog
            localesData={localesData}
            handleNext={handleNexts}
            dispatch={dispatch}
          />
        );
      case 1:
        return (
          <ProfileCreation localesData={localesData} handleNext={handleNexts} />
        );
      case 2:
        return (
          <ProfileCompletionRules
            localesData={localesData}
            handleNext={handleNexts}
          />
        );
      case 3:
        return (
          <ProfileCompletionDog
            localesData={localesData}
            handleNext={handleNexts}
            dispatch={dispatch}
            questions={questions}
          />
        );
      // case 3:
      //   return (
      //     <ProfileVideoVerification
      //       localesData={localesData}
      //       handleNext={handleNexts}
      //       dispatch={dispatch}
      //       questions={questions}
      //       setLoader={setLoader}
      //     />
      //   );
      case 4:
        return (
          <ProfileCompletionDogDetails
            localesData={localesData}
            handleNext={handleNexts}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
          />
        );
      case 5:
        return (
          <ProfileCompletionDogPicture
            localesData={localesData}
            handleNext={handleNexts}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            getProfile={getProfile}
            setLoader={setLoader}
          />
        );
      case 6:
        return (
          <StartTalk
            localesData={localesData}
            setLoader={setLoader}
            handleNext={handleNexts}
            dispatch={dispatch}
            progressWeightage={progressWeightage}
            answars={answars}
          />
        );
      case 7:
        return (
          <ProfileCompletionUser
            localesData={localesData}
            dispatch={dispatch}
            progressWeightage={progressWeightage}
          />
        );
      default:
        return (
          <ProfileCreation localesData={localesData} handleNext={handleNexts} />
        );
    }
  };

  return (
    <Box className='page-content-wrap pb-3'>
      {loader && <PreLoader />}
      {renderComponent()}
    </Box>
  );
};

export default ProfileCompletion;
