import React, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';
import { CustomTextField } from '../../../UI/textfield';
import IOSSwitch from '../../../UI/IOSSwitch';
import CustomButton from '../../../UI/Button/CustomButton';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../../helpers/constants/urls';
import { Config, fetchFromStorage } from '../../../../../helpers/context';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import UpgradePlan from '../UpgradePlan';
import { identifiers } from 'src/helpers/constants/identifier';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
// import '../UserWork/userWork.scss';

Geocode.setApiKey(Config?.placeAPIKey);

const YourLocation = ({
  localesData,
  questions,
  setLoader,
  dispatch,
  handleNext,
  answars,
}) => {
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [addressData, setAddressData] = useState('');
  const [locationData, setLocationData] = useState('');
  const [locationSwitchData, setLocationSwitchData] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationToDisplay, setLocationToDisplay] = useState('');
  const [toggleDialog, setToggleDialog] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    answars?.map((item) => {
      if (item.id === 33) {
        setLocationData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private:
            item.Option[0]?.private === 1 || item.Option[0]?.private === '1'
              ? true
              : false,
        });
        setAddressData(item?.Option[0]?.answer);
        setLocationToDisplay(item?.Option[0]?.answer);
      }
      if (item.id === 51) {
        setLocationSwitchData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private:
            item.Option[0]?.private === 1 || item.Option[0]?.private === '1'
              ? true
              : false,
        });
      }
      return true;
    });
  }, [answars]);

  const handleChange = (address) => {
    setAddressData(address);
  };

  // const handleSelect = (address) => {
  //   setAddressData(address);
  //   setLocationToDisplay(address);
  //   geocodeByAddress(address)
  //     .then((results) => getLatLng(results[0]))
  //     .then((latLng) => {
  //       setLatitude(latLng?.lat);
  //       setLongitude(latLng?.lng);
  //     })
  //     .catch((error) => console.error('Error', error));
  // };

  const handleSelect = async (address) => {
    try {
      setAddressData(address);
      setLocationToDisplay(address);
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatitude(latLng?.lat);
      setLongitude(latLng?.lng);
      setError('');
    } catch (error) {
      setError(error);
    }
  };

  // const fetchLocation = () => {
  //   if (Config?.placeAPIKey !== '') {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           setLatitude(position.coords.latitude);
  //           setLongitude(position.coords.longitude);
  //           getLocationString(
  //             position.coords.latitude,
  //             position.coords.longitude
  //           );
  //         },
  //         (error) => {
  //           setError(error.message);
  //         }
  //       );
  //     } else {
  //       setError('Geolocation is not supported by your browser.');
  //     }
  //   }
  // };

  const fetchLocation = () => {
    if (Config?.placeAPIKey !== '') {
      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
              getLocationString(
                position.coords.latitude,
                position.coords.longitude
              );
              setError('');
            },
            (error) => {
              setError(error.message);
            }
          );
        } catch (error) {
          setError(error);
        }
      } else {
        setError('Geolocation is not supported by your browser.');
      }
    }
  };

  const handleLocationChange = (e) => {
    if (e.target.checked) {
      fetchLocation();
    }
  };
  // useEffect(() => {
  //   if (locationSwitchData?.private) {
  //     fetchLocation();
  //   }
  //   // eslint-disable-next-line
  // }, [locationSwitchData]);

  const getLocationString = async (lat, lng) => {
    if (Config?.placeAPIKey !== '') {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          if (response != null) {
            var country_state_name = '';
            var country_name = '';
            var country_city_name = '';
            var full_adrs = '';

            response?.results[0]?.address_components?.map((item) => {
              if (item.types[0] === 'locality') {
                country_city_name = item?.long_name;
              }
              if (item.types[0] === 'administrative_area_level_1') {
                country_state_name = item?.long_name;
              }
              if (item.types[0] === 'country') {
                country_name = item?.long_name;
              }
              full_adrs =
                country_city_name +
                ', ' +
                country_state_name +
                ', ' +
                country_name;
              return true;
            });

            if (response.status === 'OK') {
              if (full_adrs === '') {
                setLocationToDisplay(response.results[0].formatted_address);
                setAddressData(response.results[0].formatted_address);
              } else {
                setLocationToDisplay(full_adrs);
                setAddressData(full_adrs);
              }
            } else {
              setError(response.status);
            }
          } else {
            setError(response);
          }
        },
        (error) => {
          setError(error);
        }
      );
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    const locationVal = addressData?.length === 0;
    const latlong = locationData?.options?.[0];

    opt.push({
      optionId: locationData?.options?.[0]?.id,
      answer: locationVal ? null : locationToDisplay ? locationToDisplay : null,
      selected: true,
      private: locationData?.private,
      lat: locationVal
        ? null
        : locationToDisplay && latitude
        ? latitude?.toString()
        : latlong?.lat
        ? latlong?.lat
        : null,
      long: locationVal
        ? null
        : locationToDisplay && longitude
        ? longitude?.toString()
        : latlong?.long
        ? latlong?.long
        : null,
      startRange: '0',
      endRange: '100',
    });

    opt.push({
      optionId: locationSwitchData?.options?.[0]?.id,
      answer: '',
      selected: false,
      private: locationSwitchData?.private,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'location',
            value: opt,
          })
        );
        handleNext();
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      <UpgradePlan title={localesData?.profile?.YOUR_LOCATION} />
      <Box className='pb-6'>
        <Divider className='divider' />
      </Box>
      <Box className='t-center pb-6'>
        <Box sx={{ justifyContent: 'space-between' }} className='d-flex  pb-4'>
          <Typography
            variant='h4'
            className='pb-4 find-text'
            dangerouslySetInnerHTML={{
              __html: localesData?.user_family_plan?.FIND_LOCATION,
            }}
          />
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch className='ios-switch' />}
              name='locationSwitchData'
              checked={locationSwitchData?.private}
              onChange={(e) => {
                setLocationSwitchData({
                  ...locationSwitchData,
                  private: e.target.checked,
                });
                handleLocationChange(e);
              }}
              style={{ marginRight: '0' }}
            />
          </FormGroup>
        </Box>

        <Box className='pb-4'>
          <PlacesAutocomplete
            value={addressData}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <CustomTextField
                  variant='standard'
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'custom-textfield location-search-input',
                  })}
                  disabled={locationSwitchData?.private}
                />
                <FormHelperText className='custom-field-helper'>
                  {localesData?.profile?.WHERE_YOU_LEAVING}
                </FormHelperText>
                <div className='autocomplete-dropdown-container'>
                  {loading && <div className='t-center'>Loading...</div>}
                  {suggestions?.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>{suggestion?.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {error && <FormHelperText error>{error && error}</FormHelperText>}
        </Box>

        <Box sx={{ justifyContent: 'space-around' }} className='d-flex pb-20'>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch className='ios-switch' />}
              name='locationDataPrivate'
              checked={locationData?.private}
              onChange={(e) => {
                profileData?.isPaid === false
                  ? setToggleDialog(true)
                  : setLocationData({
                      ...locationData,
                      private: !locationData?.private,
                    });
              }}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {locationData?.private ? <LockIcon /> : <UnLockIcon />}
        </Box>

        <Box className='done-btn-sec'>
          <CustomButton
            variant='contained'
            title={localesData?.DONE}
            background={'#59D09D'}
            fontWeight='600'
            classname='done-btn s-bold'
            onClick={handleOnSubmit}
          />
        </Box>
      </Box>
      <DialogBox
        open={toggleDialog}
        handleClose={() => setToggleDialog(!toggleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToggleDialog(!toggleDialog)}
            navigate={navigate}
          />
        }
      />
    </>
  );
};

export default YourLocation;
