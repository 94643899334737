import React from 'react';
import './contactSearch.scss';
import { Box, Avatar, Typography } from '@mui/material';
import CustomButton from '../../UI/Button/CustomButton';

const ContactCard = ({
  contact,
  sendLinkReq,
  cardInfoLeft,
  cardInfoRight,
  btnText,
}) => {
  return (
    <Box className='contact-card'>
      <Box className='d-flex justify-center pb-4'>
        <Avatar className='card-image' alt='Image' src={contact?.avatar} />
        {/* {contact?.avatar}
        </Avatar> */}
      </Box>
      <Box className='t-center '>
        <Box className='card-content pb-4'>
          <Typography className='card-msg' variant='span'>
            {cardInfoLeft}
          </Typography>
          <Typography className='card-msg person-name' variant='span'>
            {' ' + contact?.name + ' '}
          </Typography>
          <Typography className='card-msg' variant='span'>
            {cardInfoRight}
          </Typography>
        </Box>
      </Box>
      <Box className='btn-sec d-flex justify-center'>
        <CustomButton
          variant='contained'
          title={btnText}
          fontWeight='600'
          type='submit'
          className='link-btn'
          onClick={() => sendLinkReq()}
        />
      </Box>
    </Box>
  );
};

export default ContactCard;
