import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, Typography, Select, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { CustomTextField } from 'src/views/component/UI/textfield';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { CustomSelect } from 'src/views/component/UI/select';
import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/down-icon.svg';
import { manageAccountData } from 'src/helpers/store/actions/manageaccount';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useSelector } from 'react-redux';

const MobileEdit = ({ localesData, setActiveStep, setNewUser, dispatch }) => {

    const profileData = fetchFromStorage(identifiers?.USER_DATA);
    const [isSubmit, setIsSubmit] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [loader, setLoader] = useState(false);
    const EmailMobileData = useSelector((state) => state?.manageAccountData);
    const [isFocused, setIsFocused] = useState(false);

    const getCountryList = async () => {
        try {
            setLoader(true);
            const { status, data } = await axiosInstance.get(URLS.COUNTRY_LIST);
            if (status === 200) {
                setApiMessage('success', data?.message);
                setCountryOptions(data);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
        }
    };
    useEffect(() => {
        getCountryList();
        // eslint-disable-next-line
    }, []);
    const handleFocus = () => {
        setIsFocused(true);
    };
    return (
        <Box className='manage-account-edit-page'>
            <Typography
                variant='h6'
                className='s-font-20 color-pink heading'
                dangerouslySetInnerHTML={{
                    __html: localesData?.otp?.manage_mobile,
                }}
            />
            <Formik
                initialValues={{
                    phone_country: EmailMobileData?.newUser && EmailMobileData?.newUser?.countryCode ? EmailMobileData?.newUser?.countryCode : profileData && profileData?.countryCode ? profileData?.countryCode : "61",
                    phone_number: EmailMobileData?.newUser && EmailMobileData?.newUser?.username ? EmailMobileData?.newUser?.username : profileData?.username ? profileData?.username : "",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    phone_country: Yup.string().required(localesData?.validation?.REQUIRED),
                    phone_number: Yup.string()
                        .required(localesData?.validation?.REQUIRED)
                        .matches(/^[0-9]{8,15}$/, localesData?.validation?.NUMBER),
                })}
                onSubmit={async (requestData) => {
                    let sendData = {
                        countryCode: requestData?.phone_country,
                        username: requestData?.phone_number,
                    };
                    try {
                        setLoader(true);
                        const { status, data } = await axiosInstance.put(
                            URLS.USER,
                            sendData
                        );
                        if (status === 200) {
                            dispatch(manageAccountData({ editedEmail: true }));
                            setNewUser(sendData)
                            setActiveStep(1)
                        }
                    } catch (error) {
                        setLoader(false);
                        setApiMessage('error', error?.response?.data?.message);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className='form-content'>
                        <Box className='manage-account-conntet'>
                            <Box className='login-field-content gap-0 mb-8'>
                                <Box className='field-content'>
                                    <Select
                                        fullWidth
                                        variant='standard'
                                        error={Boolean(touched.phone_country && errors.phone_country)}
                                        className={isFocused ? 'custom-select focused-select' : 'custom-select'}
                                        name='phone_country'
                                        input={<CustomSelect />}
                                        IconComponent={DropdownIcon}
                                        value={values.phone_country?.replace('+', '') || '61'}
                                        onChange={(e) => { handleChange(e); dispatch(manageAccountData({ ModifyEmail: true })); }}
                                        onBlur={(e) => { handleBlur(e); setIsFocused(false); }}
                                        onFocus={handleFocus}
                                        displayEmpty
                                    >
                                        {countryOptions?.map((item, i) => (
                                            <MenuItem
                                                key={i}
                                                value={item?.countryCode?.replace('+', '')}
                                                className='select-options'
                                            >
                                                <img
                                                    src={item?.flagUrl}
                                                    alt=''
                                                    className='dropdown-flag-icon'
                                                />
                                                <span className='dropdown-flag-text'>
                                                    {/* {item?.countryName} ({item?.countryCode}) */}
                                                    {item?.countryCode}
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.phone_country && (
                                        <FormHelperText error={Boolean(errors.phone_country)}>
                                            {touched.phone_country && errors.phone_country}
                                        </FormHelperText>
                                    )}
                                    <FormHelperText className='custom-field-helper'>
                                        {localesData?.otp?.mobile}
                                    </FormHelperText>
                                </Box>
                                <Box className={isFocused ? 'field-content focused-filed' : 'field-content'}>
                                    <CustomTextField
                                        fullWidth
                                        error={isSubmit && Boolean(touched.phone_number && errors.phone_number)}
                                        helperText={isSubmit && touched.phone_number && errors.phone_number}
                                        variant='standard'
                                        className={isSubmit ? "custom-textfield" : "custom-textfield not-submitted-textfield"}
                                        name='phone_number'
                                        value={values.phone_number || ''}
                                        onChange={(e) => { handleChange(e); dispatch(manageAccountData({ ModifyEmail: true })); setIsSubmit(false) }}
                                        placeholder={localesData?.otp?.enter_mobile}
                                        onBlur={(e) => { handleBlur(e); setIsFocused(false); }}
                                        onFocus={handleFocus}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                            } else if (/[^0-9]/g.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box className='manage-account-button'>
                                <CustomButton
                                    variant='contained'
                                    title={localesData?.otp?.save}
                                    background='#59D09D'
                                    fontWeight='600'
                                    type='submit'
                                    disabled={values.phone_country && values.phone_number
                                        && (!profileData?.countryCode || !profileData?.username || profileData?.countryCode !== values.phone_country || values.phone_number !== profileData?.username) ? false : true}
                                    classname={values.phone_country && values.phone_number
                                        && (!profileData?.countryCode || !profileData?.username || profileData?.countryCode !== values.phone_country || values.phone_number !== profileData?.username) ? 'save-button ' : 'save-button disable-button'}
                                    onClick={() => { setIsSubmit(true) }}
                                />
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box >
    );
};

export default MobileEdit;
