export const Config = {
  baseURL: process.env.REACT_APP_API_URL,
  GoogleAppID: process.env.REACT_APP_GOOGLE_ID,
  FacebookAppID: process.env.REACT_APP_FACEBOOK_ID,
  AppleAppID: process.env.REACT_APP_APPLE_ID,
  redirectURL: process.env.REACT_APP_REDIRECT_URL,
  placeAPIKey: process.env.REACT_APP_GOOGLE_PALCES_API_KEY,
  InstagramClientID: process.env.REACT_APP_CLIENT_INSTAGRAM_ID,
  InstagramSecretKey: process.env.REACT_APP_INSTAGRAM_SECRETKEY,
  OnesignalAppID: process.env.REACT_APP_ONE_SIGNAL_ID,
  socketURL: process.env.REACT_APP_API_SOCKET_BASE_URL,
  mediaURL: process.env.REACT_APP_MEDIA_URL,
  version: process.env.REACT_APP_VERSION,
};
