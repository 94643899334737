import React, { useState, useEffect } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../UI/Button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../../../../assets/images/icons/plus.svg';
import { ReactComponent as LockIcon } from '../../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../../assets/images/icons/unlock.svg';
import IOSSwitch from '../../../../UI/IOSSwitch';
import { setApiMessage } from '../../../../../../helpers/commonFunctions';
import axiosInstance from '../../../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../../../helpers/constants/urls';
import { addProfileData } from '../../../../../../helpers/store/actions/profileSetup';
import { useNavigate } from 'react-router-dom';
import { CustomTextField } from 'src/views/component/UI/textfield';
import UpgradePlan from '../../UpgradePlan';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
// import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const YourPolitics = ({
  localesData,
  handleNext,
  // answars,
  // questions,
  setLoader,
  dispatch,
}) => {
  const navigate = useNavigate();
  // const reduxQuesAns = useSelector((state) => state?.questionAnswer);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [politicsData, setPoliticsData] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [toogleDialog, setToogleDialog] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [answars, setAnswars] = useState([]);

  const getAllAnswers = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setAnswars(data?.questions);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllAnswers();
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { checked } = e.target;
    setIsPrivate(checked);
  };

  // useEffect(() => {
  //   answars?.map((item) => {
  //     if (item.id === 17) {
  //       setReligionData({
  //         title: item?.title,
  //         id: item?.id,
  //         options: item?.Option,
  //         private: false,
  //       });
  //     }
  //     if (item.id === 65) {
  //       setManuallyId(item?.Option[0]?.id);
  //     }
  //     return true;
  //   });
  // }, [answars]);

  useEffect(() => {
    if (answars?.length !== 0) {
      const politics = answars?.find((o) => o?.id === 18)?.Option || [];
      // const reduxPoliticsData = reduxQuesAns?.manullPolitics
      //   ?.filter((o) => o !== '')
      //   ?.map((obj) => ({
      //     title: obj?.title,
      //     selected: obj?.selected,
      //   }));
      // const manuallyPolitics = reduxPoliticsData
      //   ? reduxPoliticsData
      //   : JSON.parse(answars?.find((o) => o?.id === 66)?.Option[0]?.answer) ||
      //     [];
      // if (!Array.isArray(manuallyPolitics)) {
      //   manuallyPolitics = []; // Convert to an array if it's not already one
      // }
      // const totalItems = [...manuallyPolitics, ...politics];
      // setPoliticsData(totalItems);
      setPoliticsData(politics);

      setSelectedChips(
        politics?.filter(
          (item) => item?.selected === 1 || item?.selected === true
        )
      );

      setIsPrivate(
        answars?.find((o) => o?.id === 18)?.Option?.[0]?.private === 1 ||
          answars?.find((o) => o?.id === 18)?.Option?.[0]?.private === '1'
          ? true
          : false
      );
    }

    // eslint-disable-next-line
  }, [answars]);

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([chip, ...selectedChips]);
    } else {
      setSelectedChips((chips) =>
        chips?.filter((obj) => obj?.title !== chip?.title)
      );
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    politicsData
      ?.filter((o) => o?.id !== undefined)
      ?.map((item) => {
        opt.push({
          optionId: item.id,
          selected: selectedChips
            ?.map((obj) => obj?.title)
            ?.includes(item?.title)
            ? true
            : false,
          private: isPrivate,
        });
        return true;
      });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'religion',
            value: opt,
          })
        );
        handleNext();
        setLoader(false);
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <Box className=''>
      <Box className=' chip-container-box'>
        <UpgradePlan title={localesData?.profile?.YOUR_POLITICS} />
        <Box className='t-center'>
          <Box className='pb-6'>
            <Divider className='divider' />
          </Box>
          <Typography variant='h4' className='pb-4'>
            {localesData?.user_hobbies?.select_as_many_as_you_like_or_add}
            <span className='plus-icon'>
              <PlusIcon
                onClick={() => {
                  navigate('/edit-your-profile', {
                    state: 'yourManuallyPolitics',
                  });
                }}
              />
            </span>
          </Typography>
          <CustomTextField
            variant='standard'
            className='custom-textfield pb-2'
            placeholder='search'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
      </Box>
      {/* <Box sx={{ textAlign: 'left' }} className='chip-container pb-14'> */}
      <Box>
        <Box className='chip-container'>
          {politicsData &&
            politicsData
              ?.filter((item) =>
                item?.title
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchInput?.toLowerCase())
              )
              ?.map((item, i) => (
                <Chip
                  className={`chip-inner font-r-11 ${
                    selectedChips
                      ?.map((obj) => obj?.title)
                      ?.includes(item?.title)
                      ? 'chip-active'
                      : ''
                  } `}
                  key={i}
                  label={item?.title}
                  onClick={() => handleChipClick(item)}
                />
              ))}
          {politicsData &&
            politicsData?.filter((item) =>
              item?.title
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchInput?.toLowerCase())
            )?.length === 0 &&
            searchInput !== '' && (
              <Typography variant='h5' className='t-center disable-color'>
                {localesData?.validation?.NO_SEARCH_RESULT_FOUND}
              </Typography>
            )}
        </Box>
        <Box className='edit-your-profile-box'>
          <Box className='edit-your-profile profile-hobby '>
            {/* btn-fixed-bottom */}
            {/* <Box sx={{ justifyContent: 'space-around' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  className='ios-switch'
                  onClick={() =>
                    setToogleDialog(isPrivate ? toogleDialog : !toogleDialog)
                  }
                />
              }
              name='isPrivate'
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {isPrivate ? <LockIcon /> : <UnLockIcon />}
        </Box> */}

            <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='isPrivate'
                  checked={isPrivate}
                  onChange={(e) => {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {isPrivate ? <LockIcon /> : <UnLockIcon />}
            </Box>
            <Box className='done-btn-sec'>
              <CustomButton
                variant='contained'
                title={localesData?.DONE}
                background={'#59D09D'}
                fontWeight='600'
                classname='done-btn s-bold'
                // onClick={handleNext}
                onClick={() => handleOnSubmit()}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default YourPolitics;
