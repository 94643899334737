import React from 'react';
import Page from '../../../components/Page';
import ContactSearch from 'src/views/component/application/ContactSearch';

const ChatView = () => {
    return (
        <Page title='Contact'>
            <ContactSearch />
        </Page>
    );
};

export default ChatView;
