import React from "react";
import Page from "../../../components/Page";
import MobileOtp from "../../component/auth/MobileOtp";

const MobileOTPView = () => {
    return (
        <Page title="Sign Up">
            <MobileOtp />
        </Page>
    );
};

export default MobileOTPView;