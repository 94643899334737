import React from 'react';
import Page from '../../../components/Page';
import SelectedBones from 'src/views/component/application/KennelThrownBones/ThrownBones/SelectedBones';

const SelectedBonesView = () => {
  return (
    <Page title=''>
      <SelectedBones />
    </Page>
  );
};

export default SelectedBonesView;
