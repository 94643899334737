import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntlContext } from 'src/App';
import '../../Benefits/benefits.scss';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import '../../Benefits/benefits.scss';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { ReactComponent as PinkStar } from 'src/assets/images/icons/pink-star.svg';

const FavBenefitDetails = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [benefitData, setBenefitData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [ids, setId] = useState('');

  useEffect(() => {
    EventEmitter.dispatch('updateHeader', benefitData?.[0]?.petStore);
    const updateHeader = (text) => {
      setSelectedBenefit((prevBenefit) => ({ ...prevBenefit, petStore: text }));
    };
    EventEmitter.subscribe('updateHeader', updateHeader);

    return () => {
      EventEmitter.unsubscribe('updateHeader', updateHeader);
    };
  }, [benefitData, setSelectedBenefit]);

  const getBenefitdata = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.BENEFIT + '?fevourite=true'
      );
      if (status === 200) {
        var selectedId =
          data?.benefits &&
          data?.benefits?.length > 0 &&
          data?.benefits.filter((s) => s?.id === Number(id));

        setBenefitData(selectedId);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getBenefitdata();
  }, []);

  const removeFavouriteAPI = async (id) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + id
      );
      if (status === 200) {
        // setIsDialogOpen(false);
        getBenefitdata();
        setId('');
        setLoader(false);

        navigate('/favourites', {
          state: { isBenefit: true },
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const MakeFavouriteAPI = async (id) => {
    const param_data = {
      following: id.toString(),
      type: 'benefits',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.FAVOURITE,
        param_data
      );
      if (status === 200) {
        getBenefitdata();
        setId('');
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      {benefitData?.map((benefit) => (
        <>
          <Box className='benefit-detail-page-sec'>
            <Box className='benefits-detail-sec benefit-detail-page pb-4'>
              <Box className='benefits-box-sec'>
                <Box className='white-Petbarn-box'>
                  <Box className='Petbarn-box-head d-flex'>
                    <Typography className='Petbarn-icon d-flex'>
                      {benefit.image ? (
                        <img
                          src={benefit.image}
                          alt={benefit.image}
                          width={130}
                          height={30}
                        />
                      ) : (
                        <span className='blank-image-placeholder'> </span>
                      )}
                      {/* Display the image */}
                    </Typography>
                    <PinkStar
                      className='start-icon'
                      onClick={() => {
                        if (
                          benefit?.isLike === 1 ||
                          benefit?.isLike === '1' ||
                          benefit?.isLike === true
                        ) {
                          removeFavouriteAPI(benefit?.fevouriteId);
                        } else {
                          MakeFavouriteAPI(benefit?.id);
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box className='Petbarn-box'>
                  <Box className='store-off-content'>
                    <Box className='d-flex'>
                      <Typography className='pet-store-head-txt'>
                        {benefit?.petStore}
                      </Typography>
                      <Typography className='pet-store-txt'>
                        {'-'}
                        {benefit?.petStoreType}
                      </Typography>
                    </Box>
                    <Typography className='font-r-12'>
                      {benefit?.benefistName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                variant='h4'
                className='pb-2'
                dangerouslySetInnerHTML={{
                  __html: localesData?.benefits_details?.DESCRIPTION,
                }}
              />
              <Typography
                className='description-text pb-1'
                dangerouslySetInnerHTML={{
                  __html: `${benefit?.description?.replace(
                    /<a([^>]*)>/g,
                    '<a$1 class="web-link">'
                  )}`,
                }}
              />
            </Box>
            <Box>
              <Box className='mt-10 mb-10 barcode-sec'>
                <img
                  src={benefit?.barCode}
                  alt={benefit?.barCode}
                  width={345}
                  height={84}
                />
              </Box>
            </Box>
            <Box className='mt-4'>
              {benefit?.thingsYouShouldKnow && (
                <Typography
                  className='description-text pb-2'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.benefits_details?.THINGS_YOU_KNOW,
                  }}
                />
              )}
              <Typography
                className='description-text pb-1'
                dangerouslySetInnerHTML={{
                  __html: benefit?.thingsYouShouldKnow,
                }}
              />
            </Box>
          </Box>
        </>
      ))}
    </>
  );
};

export default FavBenefitDetails;
