import React from 'react';
import Page from '../../../components/Page';
import SelectedMatches from 'src/views/component/application/Favourites/Matches/Selectedmatches';

const SelectedMatchesView = () => {
  return (
    <Page title='Subscription Popup'>
      <SelectedMatches />
    </Page>
  );
};

export default SelectedMatchesView;
