// import socketio from 'socket.io-client';
import React from 'react';
import { Config } from '../config';
import { EventEmitter } from 'src/helpers/EventEmitter';

let socketInitialize = null;

const { io } = require('socket.io-client');

socketInitialize = io(Config?.socketURL, {
  jsonp: false,
  path: '/api/socket.io/',
  upgrade: false,
  reconnection: false,
});

export const socket = socketInitialize.connect();

export const SocketContext = React.createContext();

/*** chat socket functions */
export function initGroupSocketEvents() {
  socket.on('JoinSocket', (data) => {
    // console.log('===join socket===' + JSON.stringify(data));
  });
  socket.on('isActive', (data) => {
    // console.log('isActive', data);
    EventEmitter.dispatch('isActive', data);
  });
  socket.on('typing', (data) => {
    // console.log('typing', data);
    EventEmitter.dispatch('typingUserList', data);
  });
  socket.on('listGroup', (data) => {
    // console.log('on add listGroup', data);
    EventEmitter.dispatch('updateGroupList', data);
  });
  socket.on('add-message', (data) => {
    // console.log('on add messageee', data);
    EventEmitter.dispatch('AddMessage', data);
  });
  socket.on('message-seen', (data) => {
    // console.log('message-seen', data);
    EventEmitter.dispatch('MessageSeen', data);
  });
  socket.on('DeleteMessageSocket', (data) => {
    // console.log('DeleteMessageSocket', data);
    EventEmitter.dispatch('DeleteMessage', data);
  });
  socket.on('endcall', (data) => {
    // console.log('endcall', data);
    EventEmitter.dispatch('endcall', data);
  });
  socket.on('sendLinkNotification', (data) => {
    // console.log('sendLinkNotification' + JSON.stringify(data));
  });
}
