import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { IntlContext } from '../../../../App';
// import ProgressBar from '../../UI/ProgressBar';
// import { useSelector } from 'react-redux';
import ProfileEditing from './ProfileEditing';
// import { useNavigate } from 'react-router-dom';
import './profile.scss';

const ManageProfile = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  // const reduxProgress = useSelector((state) => state?.progressBarData);
  // const navigate = useNavigate();

  return (
    <Box className='main-page-content-wrap'>
      {/* <Box className='d-flex align-center space-between mt-2'>
        <Typography variant='h6' className='color-pink'>
          {localesData?.manage_profile?.YOUR_PROFILE}
        </Typography>
        <Box>
          <Button
            className='pink-outline-btn  color-pink'
            onClick={() => navigate(`/profile-preview/${}`)}
          >
            {localesData?.manage_profile?.PREVIEW_PROFILE}
          </Button>
        </Box>
      </Box> */}
      <Box>
        <ProfileEditing localesData={localesData} />
      </Box>
    </Box>
  );
};

export default ManageProfile;
