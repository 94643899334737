import React, { useState } from 'react';
import { Box, Typography, FormHelperText } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

import CustomButton from '../../UI/Button/CustomButton';
import { CustomTextField } from '../../UI/textfield';

import { addProfileData } from '../../../../helpers/store/actions/profileSetup';

import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';

const ForgotPasswordOTP = ({
  localesData,
  handleNext,
  profileData,
  setLoader,
}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpErrors, serOtpErrors] = useState('');

  const resendCode = async () => {
    if (profileData?.email) {
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.get(
          URLS.FORGOT_PASSWORD +
            `/${profileData?.email}/${profileData?.phone_number}`
        );
        if (status === 200) {
          setApiMessage('success', data?.message);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        // setApiMessage('error', error?.response?.data?.message);
        serOtpErrors(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          otp: '',
        }}
        validationSchema={Yup.object().shape({
          otp: Yup.string().required(localesData?.validation?.REQUIRED),
        })}
        onSubmit={async (requestData) => {
          try {
            setLoader(true);
            const { status, data } = await axiosInstance.get(
              URLS.VERIFY_OTP + `/${profileData?.email}/${requestData.otp}`
            );
            if (status === 200) {
              setApiMessage('success', data?.message);
              setLoader(false);
              dispatch(addProfileData({ key: 'otp', value: requestData.otp }));
              handleNext();
            }
          } catch (error) {
            setLoader(false);
            serOtpErrors(error?.response?.data?.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            <Box className='t-center mb-10'>
              <Typography variant='h6' className='s-bold mb-2'>
                {localesData?.HI} {profileData?.first_name}{' '}
                {/* {profileData?.last_name}, */}
              </Typography>

              {otpErrors && (
                <Typography variant='h6' className='color-error mb-6 mt-2'>
                  {otpErrors}
                </Typography>
              )}

              {otpErrors === '' ? (
                <Typography
                  variant='h4'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.forgot?.FORGOT_OTP_DESC,
                  }}
                />
              ) : (
                <Typography
                  variant='h4'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.forgot?.WRONG_OTP_DESC,
                  }}
                />
              )}
            </Box>
            <Box className='field-content mb-8'>
              <CustomTextField
                fullWidth
                error={Boolean(touched.otp && errors.otp)}
                helperText={touched.otp && errors.otp}
                variant='standard'
                className='custom-textfield'
                name='otp'
                value={values.otp || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormHelperText className='custom-field-helper'>
                {localesData?.forgot?.OTP}
              </FormHelperText>
            </Box>
            <CustomButton
              classname='uppercase'
              variant='contained'
              title={localesData?.LET_ME_IN}
              background='#F54D71'
              fontWeight='600'
              type='submit'
            />
            <Typography
              variant='h6'
              className='t-center pointer pb-5'
              onClick={resendCode}
            >
              {localesData?.forgot?.RESEND_OTP}
            </Typography>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordOTP;
