import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { IntlContext } from '../../../../../App';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import IOSSwitch from '../../../UI/IOSSwitch';
import ProgressBar from '../../../UI/ProgressBar';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import { useNavigate } from 'react-router-dom';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const FamilyPlan = ({
  handleNext,
  reduxProgress,
  questions,
  setLoader,
  dispatch,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [familyData, setFamilyData] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [toogleDialog, setToogleDialog] = useState(false);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'isPrivate') {
      setFamilyData({ ...familyData, private: checked });
      setIsPrivate(checked);
    }
  };

  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item?.q_order === 23) {
  //         setFamilyData({
  //           title: item?.title,
  //           id: item?.id,
  //           options: item?.Option,
  //           private: false,
  //         });
  //       }
  //     });
  // }, [questions]);
  useEffect(() => {
    const question = questions?.find((o) => o?.id === 2);
    const itemWithOrder23 = question?.Question?.find(
      (item) => item?.q_order === 23
    );

    if (itemWithOrder23) {
      setFamilyData({
        title: itemWithOrder23?.title,
        id: itemWithOrder23?.id,
        options: itemWithOrder23?.Option,
        private:
          answars?.find((o) => o?.id === 26)?.Option?.[0]?.private === 1
            ? true
            : false,
      });
      setIsPrivate(
        answars?.find((o) => o?.id === 26)?.Option?.[0]?.private === 1
          ? true
          : false
      );
    }

    setSelectedChips(
      answars
        ?.find((o) => o?.id === 26)
        ?.Option?.filter(
          (item) => item?.selected === 1 || item?.selected === true
        )
    );
  }, [questions, answars]);

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([chip]);
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    familyData?.options?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        private: familyData?.private,
      });
      return true;
    });

    // selectedChips?.map((item) => {
    //   opt.push({
    //     optionId: item.id,
    //     selected: true,
    //     private: familyData?.private,
    //   });
    //   return true;
    // });

    let sendData = {
      private: false,
      optionIds: opt,
    };

    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'family_plans',
            value: opt,
          })
        );
        if (isEdit) {
          // checkForBlankData();
          checkForBlankAnsData();
        } else {
          handleNext();
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const checkForBlankAnsData = async () => {
    try {
      setLoader(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;
      if (status === 200) {
        data?.questions.map((item) => {
          if (breakIt === false) {
            if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Box className='t-center'>
      <>
        <Box className='chip-container-box'>
          <Typography
            className='mb-2 font-r-12'
            dangerouslySetInnerHTML={{
              __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
            }}
          />
          <Box className='mb-3'>
            <Typography variant='h4' className='pb-1'>
              {/* {reduxProgress}% */}
              {progressWeightage}%
            </Typography>
            <ProgressBar processData={progressWeightage} />
          </Box>
          <Typography variant='h3' className='s-bold pb-2'>
            {localesData?.user_hobbies?.now_its_all_about_you}
          </Typography>
          <Typography variant='h4' className='pb-4'>
            What's your <span className='s-bold'>family plan?</span>
          </Typography>
        </Box>
        <Box
          sx={{ textAlign: 'left', alignItems: 'center' }}
          className='chip-container pb-20'
        >
          {familyData?.options?.map((item, i) => (
            <Chip
              className={`chip-inner font-r-11 ${
                selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
                  ? 'chip-active'
                  : ''
              } `}
              key={i}
              label={item?.title}
              onClick={() => handleChipClick(item)}
            />
          ))}
        </Box>
      </>

      <Box className=''>
        <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch className='ios-switch' />}
              name='isPrivate'
              checked={isPrivate}
              // onChange={(e) => {
              //   profileData?.isPaid === false
              //     ? setToogleDialog(true)
              //     : handleChange(e);
              // }}
              onChange={(e) => {
                if (isEdit) {
                  profileData?.isPaid === false
                    ? setToogleDialog(true)
                    : handleChange(e);
                } else {
                  handleChange(e);
                }
              }}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {isPrivate ? <LockIcon /> : <UnLockIcon />}
        </Box>

        <CustomButton
          variant='contained'
          title={localesData?.userwork?.keep_going}
          background='#6A42ED'
          fontWeight='600'
          onClick={() => handleOnSubmit()}
        />

        <Box className='pb-8 mb-4'>
          <DogIcon width='81.32px' height='61.36px' className='mb-2' />

          <Typography
            variant='h5'
            className='pointer'
            // onClick={() => navigate('/')}
            onClick={() => redirectHomePage()}
          >
            {localesData?.userwork?.FINISH}
          </Typography>
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default FamilyPlan;
