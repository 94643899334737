import React, { useContext, useState } from 'react';
import { IntlContext } from 'src/App';
import { Box, Typography, Dialog, DialogContent, Avatar } from '@mui/material';
// import { ReactComponent as CrossIcon } from 'src/assets/images/icons/cross-icon.svg';
// import { ReactComponent as PinkDogHeand } from 'src/assets/images/icons/dog-pink-heand-icon.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import CloseIcon from '@mui/icons-material/CancelSharp';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { saveToStorage } from 'src/helpers/context';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { identifiers } from 'src/helpers/constants/identifier';
import PreLoader from 'src/components/Loader';
import './linkrequest.scss';
// import ContactCard from '../ContactCard';

const LinkRequest = ({ eventData }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [isDialogPopupOpen, setIsDialogPopupOpen] = useState(true);
  const [loader, setLoader] = useState(false);

  const getProfile = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        saveToStorage(identifiers?.USER_DATA, data);
        setIsDialogPopupOpen(false);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const linkReject = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(URLS.SIMPLE_UNLNK);
      if (status === 200) {
        setLoader(false);
        getProfile();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const linkAccept = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(
        URLS.LINK +
          eventData?.data?.fromUserImages[0]?.userId +
          URLS.ACCEPT_LINK_REQUEST
      );
      if (status === 200) {
        setLoader(false);
        getProfile();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      {loader && <PreLoader />}
      <Dialog
        open={isDialogPopupOpen}
        className='link-request-sec dialog_box'
        onClose={() => setIsDialogPopupOpen(false)}
      >
        <CloseIcon
          className={`close pointer`}
          onClick={() => setIsDialogPopupOpen(false)}
        />
        <DialogContent>
          {/* <ContactCard
                        handleClose={() => setIsDialogPopupOpen(!isDialogPopupOpen)}
                        cardInfoLeft={localesData?.contact_search?.CARD_SELECTED}
                        cardInfoRight={localesData?.contact_search?.LINK_PROFILE}
                        contact={eventData?.data}
                        linkReject={linkReject}
                        linkAccept={linkAccept}
                        isReqSend={false}
                    // navigate={navigate}
                    /> */}
          <Box className='contact-card'>
            <Box className='d-flex justify-center pb-4'>
              {eventData?.data &&
              eventData?.data?.fromUserImages &&
              eventData?.data?.fromUserImages[0] &&
              eventData?.data?.fromUserImages[0]?.url ? (
                <Avatar
                  className='card-image'
                  alt='Image'
                  src={eventData?.data?.fromUserImages[0]?.url}
                />
              ) : (
                <Avatar
                  className='card-image'
                  alt='Image'
                  src={eventData?.data?.fromUserImages[0]?.url}
                />
              )}
              {/* {eventData?.data?.fromUserImages?.url}
                            </Avatar> */}
            </Box>
            <Box className='t-center '>
              <Box className='card-content pb-4'>
                <Typography className='card-msg person-name' variant='span'>
                  {' ' + eventData?.data?.fromUserName + ' '}
                </Typography>
                <Typography className='card-msg' variant='span'>
                  {localesData?.account_detail?.has_request_to_link_profile}
                </Typography>
              </Box>
            </Box>
            <Box className='btn-sec d-flex space-between'>
              <CustomButton
                variant='contained'
                title={localesData?.contact_search?.reject}
                fontWeight='600'
                type='submit'
                className='link-btn'
                onClick={() => linkReject()}
              />
              <CustomButton
                variant='contained'
                title={localesData?.contact_search?.accept}
                fontWeight='600'
                type='submit'
                className='link-btn'
                onClick={() => linkAccept()}
              />
            </Box>
          </Box>
          {/* <Box className='respond-card-sec link-req-sec'>
                        <Box className='respond-card-head pb-2'>
                            <Typography
                                className='t-center head-text'
                            >
                                {localesData?.account_detail?.you_received_link_request_from}
                                <span className='head-text-bold'>{' '}Anne</span>
                            </Typography>
                            <Box className='t-center'>
                                <Typography variant='span' className='dog-name'>
                                    {eventData?.data?.firstname}{' '}
                                    //  <span className='head-text'>And</span>{' '} 
                                    {eventData?.data?.dogs?.name}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className='dog-img-card'>
                            <Avatar
                                // src={eventData?.data?.images?.[0]?.url}
                                className='dog-img'
                                src={"https://puppiloversdev.s3.ap-southeast-2.amazonaws.com/1685349876325.jpg"}
                            />
                        </Box>
                        <Box className='content-sec'>
                            <Typography
                                className='content-text'
                            >
                                {"If you accept, neither of you will appear in matching for love and you will no longer have to pay for your subscription. But both of you will still be able to take advantage of your premium account benefits."}
                            </Typography>
                        </Box>
                        <Box className='d-flex space-between button-sec pb-3'>
                            <Button
                                alt='Remy Sharp'
                                variant='square'
                                className='bones-star-icon close-btn'
                                startIcon={<CrossIcon />}
                                onClick={() => linkReject()}
                            // onClick={setDialogTogglePopup}
                            />
                            <Button
                                alt='Remy Sharp'
                                variant='square'
                                className='bones-star-icon agree-btn'
                                startIcon={<PinkDogHeand />}
                                onClick={() => {
                                    linkAccept();
                                }}
                            />
                        </Box>
                    </Box> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LinkRequest;
