import React, { useState, useEffect, useRef, useContext } from 'react';
// import { connect } from 'react-redux';
// import ReactPlayer from "react-player";
// import { Picker } from 'emoji-mart';
import {
  // Avatar,
  AppBar,
  Typography,
  TextField,
  IconButton,
  // List,
  // ListItem,
  // ListItemAvatar,
  // ListItemText,
  Button,
  Box,
} from '@mui/material';
import moment from 'moment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as Plus } from 'src/assets/images/icons/keyborad-plus.svg';
import { ReactComponent as Voice } from 'src/assets/images/icons/keyboard-voice.svg';
import { ReactComponent as Gallery } from 'src/assets/images/icons/gallery-icon.svg';
import { ReactComponent as Heart } from 'src/assets/images/icons/matches-active.svg';
import { ReactComponent as MessageSend } from 'src/assets/images/message-send.svg';
import { ReactComponent as AudioIcon } from 'src/assets/images/icons/audioIcon.svg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { FaTimes } from 'react-icons/fa';
// import { faSmile, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
// import 'emoji-mart/css/emoji-mart.css';
// import ChatOption from '../../../sharedUI/form/ChatOption';
// import ViewLocationMessage from './ViewLocationMessage';
import { EventEmitter } from 'src/helpers/EventEmitter';
import ImageUploadOptions from 'src/views/component/UI/ImageUploadOptions';
import { MSG_TYPE } from './MsgType';
import { Config, fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { socket } from 'src/helpers/context/socket';
import { useParams } from 'react-router-dom';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import undoPicture from 'src/assets/images/icons/undo.png';
import ClickPicture from 'src/assets/images/icons/camera.png';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import AddEmojiTab from '../../../Keyboard/Emoji';
import { IntlContext } from 'src/App';
import { ReactComponent as RecentTab } from 'src/assets/images/recent-tab.svg';
import { ReactComponent as RecentTabActive } from 'src/assets/images/recent-tab-active.svg';
import { ReactComponent as BoneTab } from 'src/assets/images/bone-tab-icon.svg';
import { ReactComponent as BoneTabActive } from 'src/assets/images/active-bone.svg';
import { ReactComponent as DogTab } from 'src/assets/images/dog-imoji-tab.svg';
import { ReactComponent as DogActiveTab } from 'src/assets/images/emoji-active-dog-tab.svg';
import { ReactComponent as HeartTab } from 'src/assets/images/heart-emoji-tab.svg';
import { ReactComponent as HeartActiveTab } from 'src/assets/images/heart-emoji-active-tab.svg';
import { ReactComponent as HomeTab } from 'src/assets/images/home-emoji-tab.svg';
import { ReactComponent as HomeActiveTab } from 'src/assets/images/home-emoji-active-tab.svg';
import Webcam from 'react-webcam';
import PreLoader from 'src/components/Loader';
import { setApiMessage } from 'src/helpers/commonFunctions';
import _ from 'lodash';
const message_limit = 5;

const ChatForm = (props) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const { id } = useParams();
  const [message, setMessage] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [replayTo, setReplayTo] = useState(null);
  const [loader, setLoader] = useState(false);
  const [toggleSticker, setToggleSticker] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [openCamera, setOpenCamera] = useState(false);
  const [isOpenOption, setIsOpenOption] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const webcamRef = useRef(null);
  const [mediaRecorders, setMediaRecorders] = useState(null);
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  const [timer, setTimer] = useState(null);
  const [facingMode, setFacingMode] = useState(null);
  const [isRecording, setIsRecording] = useState('');
  const [isEableSend, setIsEableSend] = useState(true);
  // const [checkMediaAvilable, setCheckMediaAvilable] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  // const [msgData, setMsgData] = useState([]);

  useEffect(() => {
    const checkForBackCamera = async () => {
      try {
        // Check for mediaDevices availability in the browser
        if (
          !navigator.mediaDevices ||
          !navigator.mediaDevices.enumerateDevices
        ) {
          console.error('MediaDevices API not available in this browser.');
          return;
        }

        // Request permission to access media devices
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        // If permission is granted, enumerate devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasBackCam = devices.some(
          (device) =>
            device.kind === 'videoinput' &&
            device.label.toLowerCase().includes('back')
        );
        setIsMobile(hasBackCam);
        setFacingMode('user');
        // Release the camera stream
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.error('Error checking camera availability:', error);
      }
    };

    checkForBackCamera();
  }, []);

  useEffect(() => {
    EventEmitter.subscribe('isReply', handleSetIsReplay);
    EventEmitter.subscribe('replayTo', handleSetReplayTo);
  }, []);

  //GET MATCHES DATA
  // const getMessegedata = async () => {
  //   try {
  //     setLoader(true);
  //     const { status, data } = await axiosInstance.get(URLS.CHAT_LIST);
  //     if (status === 200) {
  //       setApiMessage('success', data?.message);
  //       const sortedMsgData = data?.users.sort(
  //         (a, b) =>
  //           new Date(b.lastMessase?.MessageCreated).getTime() -
  //           new Date(a.lastMessase?.MessageCreated).getTime()
  //       );
  //       setMsgData(sortedMsgData);
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     setApiMessage('error', error?.response?.data?.message);
  //   }
  // };

  useEffect(() => {
    // getMessegedata();

    // const time = moment(moment.unix(props?.list_data))
    //   .utc()
    //   .format('YYYY-MM-DD HH:mm:ss');

    var seenData = props?.list_data?.filter(
      (a) => a?.SeenByData && a?.SeenByData?.length > 0 && a?.user
    );

    const sortedData = seenData?.sort((a, b) => b.timestamp - a.timestamp);
    const latestData = sortedData[sortedData.length - 1];

    let seen_data =
      latestData?.SeenByData && latestData?.SeenByData != null
        ? latestData?.SeenByData
        : [];

    if (latestData?.user?._id !== latestData?.userId && seen_data.length) {
      let filtered_list = seen_data.filter(
        (item_1) => item_1.userId === latestData?.MessageID
      );
      if (
        filtered_list.length === 0 &&
        latestData?.user?._id !== latestData?.userId
      ) {
        let socket_data = {
          ReceiverID: latestData?.user?._id,
          MessageID: [latestData._id],
          UserID: latestData?.user?._id,
        };

        // socket.emit('message-seen', socket_data, (response) => {
        // });
        socket.emit('message-seen', socket_data);
      }
    }

    if (latestData?.user?._id === latestData?.userId && seen_data.length) {
      // socket.on('message-seen', (data) => {
      // if (
      //   data &&
      //   data.ReceiverID == this.props.chatId &&
      //   data.MessageID == this.state.message_obj._id
      // ) {
      //   let local_obj = {
      //     UserID: data.UserID,
      //     UserFullName: data.UserFullName,
      //     UserProfilePicture: data.UserProfilePicture,
      //   };
      //   let all_data = [...this.state.seen_by_user_data, local_obj];
      //   this.setState(
      //     {
      //       seen_by_user_data: all_data,
      //       is_all_seen: all_data.length == 1 ? true : false,
      //     },
      //     () => this.forceUpdate()
      //   );
      // }
      // });
    }
    socket.on('message-seen', (data) => {});
  }, [props?.list_data]);

  const handleSetIsReplay = (data) => {
    setIsReply(data);
  };
  const handleSetReplayTo = (data) => {
    setReplayTo(data);
  };

  const iconTab = [
    {
      id: '1',
      icon: activeTab === '1' ? <RecentTabActive /> : <RecentTab />,
    },
    {
      id: '2',
      icon: activeTab === '2' ? <BoneTabActive /> : <BoneTab />,
    },
    {
      id: '3',
      icon: activeTab === '3' ? <DogActiveTab /> : <DogTab />,
    },
    {
      id: '4',
      icon: activeTab === '4' ? <HeartActiveTab /> : <HeartTab />,
    },
    {
      id: '5',
      icon: activeTab === '5' ? <HomeActiveTab /> : <HomeTab />,
    },
  ];

  const onSubmit = (event) => {
    event.preventDefault();
    if (isReply) {
      handleSend(message.trim(), MSG_TYPE.STRING, replayTo, '');
      EventEmitter.dispatch('isReply', false);
      setMessage('');
    } else if (message.trim() !== '') {
      handleSend(message.trim(), MSG_TYPE.STRING, null, '');
      setMessage('');
    }
  };
  const capture = () => {
    const base64Data = webcamRef.current.getScreenshot();
    setCapturedImage(base64Data);
    // const file = dataURLtoFile(base64Data, 'image.jpg');
    // handleFileUpload([file]);
  };
  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  // const submitMessage = () => {
  //   // const taggedusers = checkIfTaggedUsers();
  //   // handleSend(message.trim(), taggedusers, replayTo);
  //   handleSend(message.trim(), MSG_TYPE.STRING, null, '');
  //   setMessage('');
  // };

  const handleChange = (event) => {
    const { value } = event?.target;
    setMessage(value);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 && event.shiftKey) {
    } else if (event.keyCode === 13) {
      event.preventDefault();
      onSubmit(event);
    }
  };

  const handleClose = () => {
    setToggleSticker(false);
  };
  const handleFileUpload = async (file) => {
    for (let i = 0; i < file.length; i++) {
      var parts = file[i] && file[i]?.type && file[i]?.type.split('/');
      var result = parts && parts[0] && parts[0];
      // || result === 'audio'
      if (result === 'image' || result === 'video') {
        uploadImage(file[i], result);
      } else {
        setIsOpenOption(false);
        setOpenCamera(false);
        handleClose();
      }
      // uploadImage(file[i]);
    }
  };

  const uploadImage = async (file, type) => {
    setLoader(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.IMAGE_UPLOAD;
    // body.append('name', file);
    body.append('image', file);
    // type ? body.append(type, file) : body.append('image', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 200) {
        setLoader(false);
        // let mediaType = data?.url?.mimetype?.includes('video')
        //   ? MSG_TYPE.VIDEO
        //   : data?.url?.mimetype?.includes('audio')
        //   ? MSG_TYPE.AUDIO
        //   : MSG_TYPE.IMAGE;
        // handleSend(data?.url?.location, mediaType, null, '');
        setAudioBlob('');
        setOpenCamera(false);
        setCapturedImage(null);
        handleSend(data?.url?.location, type, null, '');
      }
    } catch (err) {
      setLoader(false);
    }
  };
  const getLastFiveMessageFromUserId = (user_id) => {
    let filtered_array = _.filter(props?.list_data, function (chat_message) {
      return (
        chat_message?.user?._id === user_id &&
        moment(moment.unix(chat_message.createdAt))
          .utc()
          .format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')
      );
    });
    return filtered_array.length;
  };
  const isUserCanSend = () => {
    if (profileData && profileData?.isPaid) {
      setIsEableSend(true);
      return true;
    } else {
      var total_match_count = getLastFiveMessageFromUserId(profileData?.id);
      if (total_match_count < message_limit) {
        setIsEableSend(true);
        return true;
      } else {
        setIsEableSend(false);
        return false;
      }
    }
  };
  const handleSend = (message, message_type, reply_obj, doc_origional_name) => {
    if (isUserCanSend()) {
      if (message.trim() !== '') {
        let user_obj = {};
        user_obj._id = profileData?.id;
        user_obj.name = profileData?.firstname;
        user_obj.avatar =
          profileData?.images && profileData?.images?.length !== 0
            ? profileData?.images[0].url
            : '';
        let link = [];
        try {
          // let pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
          // let pattern = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
          let pattern = /(?:(?:https?|ftp):\/\/|www\.)[^\s/$.?#].[^\s]*/gi;
          link = message?.match(pattern);
        } catch (e) {
          console.log('error', e);
        }
        let param_data = {
          UserID: profileData?.id,
          to: id,
          type: message_type,
          content: message,
          reply: reply_obj,
          chatType: 'private',
          origional_name: doc_origional_name,
          user: user_obj,
          link:
            message_type === MSG_TYPE.STRING && link && link.length > 0
              ? link
              : [],
        };
        socket.emit('add-message', param_data);
        setIsOpenOption(false);
        setOpenCamera(false);
        handleClose();
      }
    }
  };

  const handleOpenSticker = () => {
    setToggleSticker(true);
  };
  const getSelectedMedia = (images) => {
    const url = Config.baseURL + URLS.IMAGE_UPLOAD;
    images.forEach((image) => {
      const imageUrl = image?.media_url;
      setLoader(true);
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append('image', blob, 'image.jpg');

          return fetch(url, {
            method: 'POST',
            body: formData,
          });
        })
        .then((response) => response.json())
        .then((data) => {
          setLoader(false);
          // const updatedImagesArray = imagesArray.map((item) => {
          //   if (item.displayOrder === currentIndex) {
          //     return {
          //       ...item,
          //       url: data?.url?.location,
          //       media_type: data?.url?.mimetype.includes('video')
          //         ? 'video'
          //         : 'image',
          //     };
          //   }
          //   return item;
          // });
          // setImagesArray(updatedImagesArray);
          setIsOpenOption(false);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };
  const handleSwitchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const videoConstraints = {
    facingMode: facingMode,
  };
  useEffect(() => {
    if (profileData && profileData?.isPaid) {
      setIsEableSend(true);
    } else {
      setIsEableSend(false);
    }
    if (profileData) {
      isUserCanSend();
    }
  }, [profileData]);
  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true })
  //     .then((stream) => {
  //       const mediaRecorder = new MediaRecorder(stream);
  //       var audioChunks = [];

  //       mediaRecorder.addEventListener('dataavailable', (event) => {
  //         if (event.data.size > 0) {
  //           audioChunks.push(event.data);
  //         }
  //       });
  //       var hours = new Date().getHours();
  //       var min = new Date().getMinutes();
  //       var sec = new Date().getSeconds();
  //       let fileName =
  //         'puppilovers_recording_' + hours + '_' + min + '_' + sec + '.mp3';

  //       mediaRecorder.addEventListener('stop', () => {
  //         const audioBlobs = new Blob(audioChunks, { type: 'audio/mp4' });
  //         const audioFile = new File([audioBlobs], fileName, {
  //           type: 'audio/mp4',
  //         });
  //         // const audioURLs = URL.createObjectURL(audioFile);
  //         setAudioBlob(audioFile);
  //         audioChunks = [];
  //       });

  //       setMediaRecorders(mediaRecorder);
  //       setCheckMediaAvilable('');
  //     })
  //     .catch((error) => {
  //       setCheckMediaAvilable(error?.message);
  //       // if (checkMediaAvilable) {
  //       // setApiMessage('error', error?.message);
  //       //   setCheckMediaAvilable(!checkMediaAvilable);
  //       // }
  //     });
  // }, []);

  useEffect(() => {
    if (audioBlob && isRecording === 'send') {
      uploadImage(audioBlob, 'audio');
      setAudioBlob('');
    } else {
      setAudioBlob('');
    }
  }, [audioBlob, isRecording]);
  // const initializeMediaRecorder = (is_record) => {
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true })
  //     .then((stream) => {
  //       const mediaRecorder = new MediaRecorder(stream);
  //       const audioChunks = [];
  //       if (is_record === 'start') {
  //         mediaRecorder.addEventListener('dataavailable', (event) => {
  //           if (event.data.size > 0) {
  //             audioChunks.push(event.data);
  //           }
  //         });
  //       } else {
  //         mediaRecorder.addEventListener('stop', () => {
  //           const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
  //           const audioURL = URL.createObjectURL(audioBlob);
  //           setAudioBlob(audioBlob);
  //         });
  //       }
  //       setMediaRecorders(mediaRecorder);
  //     })
  //     .catch((error) => {
  //       setApiMessage('error', error?.message);
  //     });
  // };
  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        var audioChunks = [];

        mediaRecorder.addEventListener('dataavailable', (event) => {
          if (event.data.size > 0) {
            audioChunks.push(event.data);
          }
        });
        var hours = new Date().getHours();
        var min = new Date().getMinutes();
        var sec = new Date().getSeconds();
        let fileName =
          'puppilovers_recording_' + hours + '_' + min + '_' + sec + '.mp3';

        mediaRecorder.addEventListener('stop', () => {
          const audioBlobs = new Blob(audioChunks, { type: 'audio/mp4' });
          const audioFile = new File([audioBlobs], fileName, {
            type: 'audio/mp4',
          });
          setAudioBlob(audioFile);
          audioChunks = [];
        });
        if (mediaRecorder) {
          setElapsedSeconds(0);
          setElapsedMinutes(0);

          const timer = setInterval(() => {
            setElapsedSeconds((prevSeconds) => prevSeconds + 1);
          }, 1000);

          setTimer(timer);
          mediaRecorder?.start();
          setRecording(true);
        }
        setMediaRecorders(mediaRecorder);
      })
      .catch((error) => {
        setApiMessage('error', error?.message);
      });
  };

  // const startRecording = () => {
  //   // setCheckMediaAvilable(!checkMediaAvilable);
  //   // initializeMediaRecorder('start');
  //   if (mediaRecorder) {
  //     setElapsedSeconds(0);
  //     setElapsedMinutes(0);

  //     const timer = setInterval(() => {
  //       setElapsedSeconds((prevSeconds) => prevSeconds + 1);
  //     }, 1000);

  //     setTimer(timer);
  //     mediaRecorder?.start();
  //     setRecording(true);
  //   } else {
  //     setApiMessage('error', checkMediaAvilable);
  //   }
  // };

  const stopRecording = (is_recording) => {
    setIsRecording(is_recording);

    if (mediaRecorders) {
      mediaRecorders.stop();
      mediaRecorders.stream.getTracks().forEach((track) => track.stop());
      setRecording(false);
      clearInterval(timer);

      const totalSeconds = elapsedMinutes * 60 + elapsedSeconds;
      const totalMinutes = Math.floor(totalSeconds / 60);
      setElapsedMinutes(totalMinutes);
    }
  };

  const formatTime = (minutes, seconds) => {
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <>
      {loader && <PreLoader />}
      <AppBar className='chat-form' position='static' color='default'>
        {isReply ? (
          <div className='replay-form'>
            <div className='alert-box reply-msg left-border'>
              <div className='message-content'>
                <Typography variant='h5' className='sender-name'>
                  {replayTo?.messageFromID === profileData?.id
                    ? 'You'
                    : replayTo?.messageFromName}
                  :
                </Typography>
                {replayTo?.messageType === 'image' ? (
                  <span
                    className='sender-message'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <PhotoCameraIcon style={{ marginRight: '5px' }} /> Image
                  </span>
                ) : replayTo?.messageType === 'video' ? (
                  <span
                    className='sender-message'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <PlayCircleFilledIcon style={{ marginRight: '5px' }} />{' '}
                    Video
                  </span>
                ) : replayTo?.messageType === 'audio' ? (
                  <span
                    className='sender-message'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <AudioIcon style={{ marginRight: '5px' }} />
                    Audio
                  </span>
                ) : replayTo?.messageType === 'emoji' ? (
                  <span
                    className='sender-message'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <PhotoCameraIcon style={{ marginRight: '5px' }} />
                    Sticker
                  </span>
                ) : (
                  <Typography variant='body1' className='sender-message'>
                    {replayTo?.messageContent}
                  </Typography>
                )}
              </div>
              {replayTo?.messageType === 'image' ? (
                <div className='reply-imageWrap'>
                  <img src={replayTo?.messageContent} alt='' />
                </div>
              ) : replayTo?.messageType === 'video' ? (
                <span
                  className='contact-letter'
                  style={{
                    background: '#448ac7',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#ffffff',
                  }}
                >
                  V
                </span>
              ) : replayTo?.messageType === 'emoji' ? (
                <div className=''>
                  <img src={replayTo?.messageContent} alt='' />
                </div>
              ) : (
                ''
              )}
            </div>
            <IconButton
              style={{ marginLeft: '10px' }}
              aria-label='cancel'
              className='cancel-action'
              onClick={() => {
                EventEmitter.dispatch('isReply', false);
              }}
            >
              <CloseIcon size={'22px'} />
            </IconButton>
          </div>
        ) : (
          ''
        )}
        <form onSubmit={(e) => onSubmit(e)}>
          {!recording ? (
            <>
              <Button
                style={{
                  opacity: !isEableSend && '0.5',
                  cursor: !isEableSend && 'default',
                }}
                onClick={() => isEableSend && handleOpenSticker()}
              >
                <Plus />
              </Button>
              <IconButton
                style={{
                  color: '#CCCCCC',
                  opacity: !isEableSend && '0.5',
                  cursor: !isEableSend && 'default',
                }}
                aria-label='face'
                onClick={() => isEableSend && startRecording()}
              >
                <Voice />
              </IconButton>
              <Button
                component='label'
                style={{
                  opacity: !isEableSend && '0.5',
                  cursor: !isEableSend && 'default',
                }}
              >
                <Gallery
                  onClick={() => {
                    isEableSend && setIsOpenOption(true);
                  }}
                />
                {/* <input
                  accept='image/*, video/*'
                  type='file'
                  hidden
                  onChange={(e) => handleFileUpload(e.target.files)}
                  multiple
                /> */}
              </Button>
              <TextField
                onChange={(e) => isEableSend && handleChange(e)}
                className='message-input'
                id='message'
                placeholder='Aa'
                margin='normal'
                // value={message}
                onKeyDown={handleKeyUp}
                disabled={!isEableSend}
                style={{
                  opacity: !isEableSend && '0.7',
                  cursor: !isEableSend && 'default',
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  autoComplete: 'off',
                  value: message,
                  multiline: true,
                  row: 2,
                }}
              />
              {message.trim() === '' ? (
                <IconButton
                  aria-label='send'
                  style={{
                    opacity: !isEableSend && '0.5',
                    cursor: !isEableSend && 'default',
                  }}
                  onClick={() => {
                    isEableSend &&
                      handleSend(
                        Config.mediaURL + '1644383498433.png',
                        MSG_TYPE.EMOJI,
                        null,
                        ''
                      );
                  }}
                >
                  <Heart />
                </IconButton>
              ) : (
                <>
                  {' '}
                  {/* {isReply ? ( */}
                  <IconButton
                    aria-label='send'
                    style={{
                      opacity: !isEableSend && '0.5',
                      cursor: !isEableSend && 'default',
                      padding: 0,
                    }}
                    onClick={() => {
                      if (isEableSend) {
                        if (isReply) {
                          handleSend(
                            message.trim(),
                            MSG_TYPE.STRING,
                            replayTo,
                            ''
                          );
                          setMessage('');
                          EventEmitter.dispatch('isReply', false);
                        } else {
                          handleSend(message.trim(), MSG_TYPE.STRING, null, '');
                          setMessage('');
                        }
                      }
                    }}
                  >
                    <MessageSend size={'22px'} />
                  </IconButton>
                  {/* ) : (
                <IconButton
                  aria-label='send'
                  onClick={() => {
                    handleSend(message.trim(), MSG_TYPE.STRING, null, '');
                    setMessage('');
                  }}
                >
                  <MessageSend />
                </IconButton>
              )} */}
                </>
              )}
            </>
          ) : (
            <div className='audio-recording-continer'>
              <IconButton
                style={{
                  opacity: !isEableSend && '0.5',
                  cursor: !isEableSend && 'default',
                }}
                onClick={() => {
                  if (isEableSend) {
                    stopRecording('cancel');
                    setAudioBlob('');
                    setAudioBlob('');
                  }
                }}
              >
                <DeleteIcon className='pointer color-pink' />
              </IconButton>
              <div className='audio-recording-timer'>
                {formatTime(elapsedMinutes, elapsedSeconds)}
              </div>
              <IconButton
                style={{
                  opacity: !isEableSend && '0.5',
                  cursor: !isEableSend && 'default',
                }}
                aria-label='send'
                onClick={() => {
                  if (isEableSend) {
                    handleSend(message.trim(), MSG_TYPE.STRING, null, '');
                    setMessage('');
                    stopRecording('send');
                  }
                }}
              >
                <MessageSend />
              </IconButton>
            </div>
          )}
        </form>
      </AppBar>
      {toggleSticker === true ? (
        <>
          {/* <ClickAwayListener onClickAway={() => handleClose()}> */}
          {/* <Picker onSelect={addEmoji} /> */}
          <ClickAwayListener
            onClickAway={() => {
              handleClose();
            }}
            mouseEvent='onMouseDown'
          >
            <Box>
              <AddEmojiTab
                localesData={localesData}
                setIsOpen={setToggleSticker}
                isOpen={toggleSticker}
                iconTab={iconTab}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                handleSend={handleSend}
              />
            </Box>
          </ClickAwayListener>
        </>
      ) : (
        ''
      )}
      {openCamera && (
        <Box className={capturedImage ? 'camera-wrap clicked' : 'camera-wrap'}>
          {!capturedImage && (
            <Webcam
              audio={false}
              screenshotFormat='image/jpeg'
              ref={webcamRef}
              mirrored={facingMode === 'environment' ? false : true}
              videoConstraints={{ ...videoConstraints, facingMode }}
              // onUserMediaError={setOpenCamera(false)}
            />
          )}
          <Box
            className='capture-wrap'
            style={{ width: '100%', display: 'flex' }}
          >
            {!capturedImage && (
              <>
                <Box style={{ marginLeft: '15px', alignSelf: 'center' }}>
                  <img
                    src={undoPicture}
                    onClick={() => {
                      setOpenCamera(false);
                      setCapturedImage(null);
                    }}
                    alt='not found'
                    width={30}
                    className='pointer' //mt-3
                  />
                </Box>

                <Box style={{ width: 'fit-content', margin: '0 auto' }}>
                  <img
                    src={ClickPicture}
                    onClick={capture}
                    alt='not found'
                    width={50}
                    className='pointer'
                  />
                </Box>
                <Box style={{ marginRight: '15px', alignSelf: 'center' }}>
                  {isMobile && (
                    <FlipCameraAndroidIcon
                      className='pointer'
                      onClick={() => handleSwitchCamera()}
                      style={{ fill: '#ffffff', width: '30px', height: '30px' }}
                    />
                  )}
                </Box>
              </>
            )}
            {capturedImage && (
              <>
                <img
                  src={capturedImage}
                  alt='Captured'
                  width={200}
                  height={200}
                />
                <Box className='retake-wrap'>
                  <span
                    onClick={() => {
                      setOpenCamera(true);
                      setCapturedImage(null);
                    }}
                  >
                    Retry
                  </span>
                  <span
                    onClick={() => {
                      const file = dataURLtoFile(capturedImage, 'image.jpg');
                      handleFileUpload([file]);
                    }}
                  >
                    Ok
                  </span>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
      {isOpenOption && (
        <Box className='select-option-wrap pointer'>
          <ImageUploadOptions
            onClose={() => setIsOpenOption(false)}
            handleFileUpload={handleFileUpload}
            localesData={localesData}
            openCamera={() => {
              setOpenCamera(true);
              setIsOpenOption(false);
            }}
            getSelectedMedia={getSelectedMedia}
            accept='both'
            fromChat={true}
          />
        </Box>
      )}
    </>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     activeUserProfile: state.profile.activeUserProfile,
//     activeGroupMembers: state.group.activeGroupMembers,
//   };
// };

// export default connect(mapStateToProps)(ChatForm);
export default ChatForm;
