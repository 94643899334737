import React from 'react';
import { Navigate } from 'react-router-dom';

// LAYOUT
import AuthLayout from './layouts/AuthLayout';
import MainLayout from './layouts/MainLayout';
import ProfileLayout from './layouts/ProfileLayout';

import YourProfiles from './views/component/application/ManageProfile/DogProfile/DogPictures';
import DownloadDataView from './views/pages/application/DownloadData';
// import ContactSearch from './views/component/application/ContactSearch';
import ContactSearch from './views/pages/application/Contact';
import BenefitDetails from './views/component/application/Benefits/BenefitDetails';

// ROUTES
import WelcomeView from '../src/views/pages/auth/Welcome';
import LoginView from '../src/views/pages/auth/Login';
import RegisterView from '../src/views/pages/auth/Register';

import UserProfileView from './views/pages/application/ManageProfile/UserProfile';
import ForgotView from '../src/views/pages/auth/Forgot';
import ProfileNotification from './views/pages/application/ProfileOpenNotification';
import FavouritesView from './views/pages/application/Favourites';
import FavBenefitPage from './views/pages/application/FavBenefitDetails';
import BenefitsView from './views/pages/application/Benefits';
// import BenefitsDetails from './views/pages/application/BenefitsDetails';
import ThrownBonesView from './views/pages/application/KennelThrownBones';
import DirectoryDashboardPage from './views/pages/application/Directory';
import FavDirectoryDashboardPage from './views/pages/application/FavDirectory';
import AccountDetailsView from './views/pages/application/AccountDetails';
import ProfileNotificationView from './views/pages/application/ProfileOpenNotification';
import HelpCenterView from './views/pages/application/HelpCenter';
import SelectedKennelMatchesView from './views/pages/application/SelectedKennelMatches';
import ProfileCompletionAdvanceView from './views/pages/application/ProfileCompletionAdvance';
import ProfileSetupView from '../src/views/pages/auth/ProfileSetup';
import ProfilePreviewpage from './views/pages/application/ProfilePreview';

import SubscriptionPlanPage from './views/pages/application/SubscriptionPlan';
// AUTH ROUTES
// import HomeView from '../src/views/pages/application/Home/home';
import UserProfileSetupView from '../src/views/pages/application/profileSetup';
import ProfileCompletionView from './views/pages/application/ProfileCompletion';
import YourProfileView from './views/pages/application/ManageProfile/YourProfile';
import EditYourProfilePage from './views/pages/application/ManageProfile/EditYourProfile';
import EditProfilePage from './views/pages/application/ManageProfile/EditProfile';
import ProfileLookingView from './views/pages/application/ManageProfile/LookingFor';
import DogProfileView from './views/pages/application/ManageProfile/DogProfile';
import SelectedMatchesView from './views/pages/application/SelectedMatches';
import SelectedBonesView from './views/pages/application/SelectedBones';
import MobileOTPView from '../src/views/pages/auth/MobileOTP';
import ManageAccount from './views/pages/application/manageAccount';
// import SelectedFavView from './views/pages/application/SelectedFavPlaces';
import AddEditDogProfilePage from './views/pages/application/ManageProfile/AddEditDogProfile';
import DogPictureView from './views/pages/application/ManageProfile/DogPicture';
// PROFILE ROUTES
import MatchingView from '../src/views/pages/application/Home/Matching';
import ChatView from '../src/views/pages/application/Chats';
import SelectedCollectBonesView from './views/pages/application/SelectCollectBones';
import StartTalkPage from './views/pages/application/StartTalkPage';

export const profileRoutes = {
  path: '/',
  element: <ProfileLayout />,
  children: [
    { path: '/profile', element: <YourProfileView /> },
    { path: '/edit-profile', element: <EditYourProfilePage /> },
    { path: '/edit-your-profile', element: <EditProfilePage /> },
    { path: '/profile-looking-for', element: <ProfileLookingView /> },
    { path: '/dog-profile', element: <DogProfileView /> },
    { path: '/dog-profile/:type', element: <AddEditDogProfilePage /> },
    { path: '/dog-picture', element: <DogPictureView /> },
    { path: '/download-data', element: <DownloadDataView /> },
    { path: '/manage-account/:type', element: <ManageAccount /> },
    // { path: '/dog-profile', element: <DogProfileView /> },
    // { path: '/profile', element: <EditProfilePage /> },
    // { path: '/fav-places/:id', element: <SelectedFavView /> },
    { path: '/user-profile', element: <UserProfileView /> },
    { path: '/account-details', element: <AccountDetailsView /> },
    { path: '/subscription-plan', element: <SubscriptionPlanPage /> },
    { path: '/notification', element: <ProfileNotificationView /> },
    { path: '/help-center', element: <HelpCenterView /> },
    { path: '/help-center/category', element: <HelpCenterView /> },
    { path: '/help-center/category/details', element: <HelpCenterView /> },
    { path: '/favourites/benefit/:id', element: <FavBenefitPage /> },
    { path: '/edit-description', element: <StartTalkPage /> },
    {
      path: '/favourites/matches/:id',
      element: <SelectedMatchesView />,
    },
    { path: '/kennel/matches/:id', element: <SelectedKennelMatchesView /> },
    { path: '/edit-profile-view', element: <YourProfiles /> },
    { path: '/benefits', element: <BenefitsView /> },
    { path: '/benefits/:id', element: <BenefitDetails /> },
    {
      path: '/favourites',
      element: <FavouritesView />,
    },
    { path: '/favourites/:id', element: <FavouritesView /> },
    { path: '/profile-view', element: <YourProfileView /> },
    { path: '/kennel', element: <ThrownBonesView /> },
    { path: '/kennel/thrownbones/:id', element: <SelectedBonesView /> },
    { path: '/kennel/collectbones/:id', element: <SelectedCollectBonesView /> },
    { path: '/contact', element: <ContactSearch /> },
    { path: '/chat/:id', element: <ChatView /> },
    // { path: '/matches-view', element: <MatchesView /> },
    { path: '/matching', element: <MatchingView /> },
    { path: '/directory', element: <DirectoryDashboardPage /> },
    { path: '/place-details', element: <FavDirectoryDashboardPage /> },
    { path: '/profile-preview/:id', element: <ProfilePreviewpage /> },
    { path: '/profile-view/:id', element: <ProfilePreviewpage /> },
    { path: '*', element: <Navigate to='/profile' /> },
  ],
};

// USER ROUTES
export const userRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Navigate to='/matching' /> },
      { path: '/profile-setup', element: <UserProfileSetupView /> },
      { path: '/profile-completion', element: <ProfileCompletionView /> },
      {
        path: '/profile-completion-advance',
        element: <ProfileCompletionAdvanceView />,
      },

      { path: '*', element: <Navigate to='/' /> },
    ],
  },
  profileRoutes,
];

// PUBLIC ROUTES
export const publicRoute = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { path: '/', element: <WelcomeView /> },
      { path: '/sign-in', element: <LoginView /> },
      { path: '/sign-up', element: <RegisterView /> },
      { path: '/forgot-password', element: <ForgotView /> },
      { path: '/profile-setup', element: <ProfileSetupView /> },
      { path: '/otp/:type', element: <MobileOTPView /> },
      // { path: '/profile-completion', element: <ProfileCompletionView /> },
      { path: '/profile-notification', element: <ProfileNotification /> },
      // { path: '/subscription-popup', element: <SubscriptionPopupView /> },
      // { path: '/user-data', element: <UserData /> },
      // { path: '/user-work', element: <UserWorkPage /> },
      // { path: '/user-hobbies', element: <UserHobbiesPage /> },
      // { path: '/user-religion', element: <UserReligionPage /> },
      // { path: '/user-DNA', element: <UserDNAPage /> },
      // { path: '/user-politics', element: <UserPoliticsPage /> },
      // { path: '/user-kids', element: <UserKidsPage /> },
      // { path: '/user-height-weight', element: <UserHeightWeightPage /> },
      // { path: '/user-info', element: <UserInformationPage /> },
      // { path: '/user-family-plan', element: <UserFamilyPlanPage /> },
      { path: '*', element: <Navigate to='/' /> },
    ],
  },
];
