import { createSlice } from '@reduxjs/toolkit';

const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState: 0,
  reducers: {
    addProgressBar: (state, action) => {
      return action.payload;
    },
    clearProgressBar: (state) => {
      return '';
    },
  },
});

export const { addProgressBar, clearProgressBar } = progressBarSlice.actions;
export default progressBarSlice.reducer;
