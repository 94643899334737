import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IntlContext } from '../../../../App';

import PhoneNumber from './PhoneNumber';
import AlreadyAccount from './AlreadyAccount';

import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import PreLoader from '../../../../components/Loader';

import { clearSvgData } from 'src/helpers/store/actions/progressImage';
import './login.scss';

const Login = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const profileData = useSelector((state) => state?.profileData);

  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (reduxStep) {
      setActiveStep(reduxStep);
    } else {
      setActiveStep(0);
    }
  }, [reduxStep, location]);

  useEffect(() => {
    dispatch(clearSvgData());
  }, [dispatch]);

  const handleNexts = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(handleNext(activeStep + 1));
  };

  const renderComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <PhoneNumber
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            profileData={profileData}
          />
        );
      case 1:
        return (
          <AlreadyAccount
            localesData={localesData}
            setLoader={setLoader}
            profileData={profileData}
          />
        );
      default:
        return (
          <PhoneNumber
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            profileData={profileData}
          />
        );
    }
  };

  return (
    <Box className='page-content-wrap'>
      {loader && <PreLoader />}
      {renderComponent()}
    </Box>
  );
};

export default Login;
