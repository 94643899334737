import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { differenceInYears } from 'date-fns';
import CustomButton from '../../UI/Button/CustomButton';
import { fetchFromStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { URLS } from '../../../../helpers/constants/urls';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { ReactComponent as Checked } from '../../../../assets/images/checked.svg';
import { ReactComponent as UnChecked } from '../../../../assets/images/unchecked.svg';
import SetExpressIdentitfy from './SetExpressIdentitfy';
import { setSVGFile } from '../../../../helpers/store/actions/progressImage';
import svgData from '../../../../assets/images/gender-dog.svg';
import { useSelector } from 'react-redux';

const SetGender = ({
  localesData,
  handleNext,
  setLoader,
  dispatch,
  questions,
  getProfile,
  getUpdatedAllAnswars,
  answers,
}) => {
  // const reduxProfileData = useSelector((state) => state?.profileData);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const [showOtherGender, setShowOtherGender] = useState(false);
  const [selectGender, setSelectGender] = useState('');
  const genderData = questions?.[0]?.Question?.find((o) => o?.q_order === 2);

  const genderList = [
    { label: localesData?.gender?.MALE, value: 2 },
    { label: localesData?.gender?.FEMALE, value: 3 },
    { label: localesData?.gender?.OTHER, value: 0 },
  ];

  const updateUserDetails = async (req) => {
    let _gender =
      req?.gender === '2'
        ? 0
        : req?.gender === '3'
        ? 1
        : req?.gender === '0'
        ? 2
        : '';
    let sendData = { gender: _gender };
    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, sendData);
      if (status === 200) {
        getProfile();
        // handleNext();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    dispatch(setSVGFile(svgData));
  }, [dispatch]);

  useEffect(() => {
    setSelectGender(profileData?.gender);
  }, [profileData?.gender, reduxStep]);
  return (
    <>
      {!showOtherGender ? (
        <Formik
          initialValues={{
            gender:
              selectGender === 0
                ? '2'
                : selectGender === 1
                ? '3'
                : selectGender !== null
                ? '0'
                : '',
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            gender: Yup.string().required(
              localesData?.validation?.SELECT_AT_LIST_ONE
            ),
          })}
          onSubmit={async (requestData) => {
            var opt = [];

            opt.push({
              optionId:
                requestData?.gender === '2'
                  ? 2
                  : requestData?.gender === '3'
                  ? 3
                  : 0,
              selected: true,
            });

            const sendData = {
              private: false,
              optionIds: opt,
            };
            if (requestData?.gender === '2' || requestData?.gender === '3') {
              try {
                setLoader(true);
                const { status, data } = await axiosInstance.post(
                  URLS.SET_ANSWERS,
                  sendData
                );
                if (status === 200) {
                  setApiMessage('success', data?.message);
                  updateUserDetails(requestData);
                  dispatch(
                    addProfileData({
                      key: 'gender',
                      value: requestData?.gender,
                    })
                  );
                  setLoader(false);
                }
              } catch (error) {
                setLoader(false);
                setApiMessage('error', error?.response?.data?.message);
              }
            } else {
              setShowOtherGender(true);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className='form-content pb-6'>
              <Box className='t-center'>
                <Typography variant='h3' className='mb-6'>
                  {localesData?.OKAY}{' '}
                  <span className='s-bold'>
                    {profileData?.firstname}
                    {/* {profileData?.lastname} */}
                  </span>
                  ,{' '}
                  {/* {localesData?.gender?.WHO_IS}{' '}
                  <span className='s-bold'>
                    {differenceInYears(new Date(), new Date(profileData?.dob))}{' '}
                  </span>
                  {localesData?.gender?.YEAR} */}
                </Typography>

                <Typography variant='h3' className='s-bold mb-5'>
                  {genderData?.title}
                </Typography>
              </Box>

              <FormControl className='custom-radio pb-20'>
                <RadioGroup
                  name='gender'
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {genderList?.map((item) => (
                    <FormControlLabel
                      key={item?.value}
                      value={item?.value}
                      control={
                        <Radio
                          size='small'
                          icon={<UnChecked />}
                          checkedIcon={<Checked />}
                          disableRipple
                        />
                      }
                      labelPlacement='start'
                      label={
                        <Typography variant='h5'>{item?.label}</Typography>
                      }
                    />
                  ))}
                </RadioGroup>
                {errors.gender && (
                  <FormHelperText sx={{ margin: '0' }} className='color-error'>
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormControl>

              <CustomButton
                classname='uppercase'
                variant='contained'
                title={localesData?.NEXT}
                background='#000000'
                fontWeight='600'
                type='submit'
              />
            </form>
          )}
        </Formik>
      ) : (
        <SetExpressIdentitfy
          localesData={localesData}
          handleNext={handleNext}
          setLoader={setLoader}
          dispatch={dispatch}
          questions={questions}
          getProfile={getProfile}
          getUpdatedAllAnswars={getUpdatedAllAnswars}
          answers={answers}
        />
      )}
    </>
  );
};

export default SetGender;
