import React, { useContext, useState, useRef, useEffect } from 'react';
import { Box, Paper, InputBase, Typography, Avatar } from '@mui/material';
import { IntlContext } from 'src/App';
import './contactSearch.scss';
import { ReactComponent as Search } from 'src/assets/images/icon-map-search.svg';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import ContactCard from './ContactCard';
import DialogBox from '../../UI/PremiumPopup/PremiumDialogBox';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import PreLoader from 'src/components/Loader';
import { fetchFromStorage } from 'src/helpers/context';
import { socket } from 'src/helpers/context/socket';
import { identifiers } from 'src/helpers/constants/identifier';
import _ from 'lodash';

const ContactSearch = () => {
  var NO_SPECIAL_CHARACTER_PATTERN = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [contactDialog, setContactDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const [conGrpDetails, setConGrpDetails] = useState([]);
  const [contact, setContact] = useState();

  // const listOrder = [
  //   {
  //     id: 1,
  //     name: localesData?.contact_search?.ALL,
  //   },
  //   {
  //     id: 2,
  //     name: localesData?.contact_search?.A,
  //   },
  //   {
  //     id: 3,
  //     name: localesData?.contact_search?.B,
  //   },
  //   {
  //     id: 4,
  //     name: localesData?.contact_search?.C,
  //   },
  //   {
  //     id: 5,
  //     name: localesData?.contact_search?.D,
  //   },
  //   {
  //     id: 6,
  //     name: localesData?.contact_search?.E,
  //   },
  //   {
  //     id: 7,
  //     name: localesData?.contact_search?.F,
  //   },
  //   {
  //     id: 8,
  //     name: localesData?.contact_search?.G,
  //   },
  //   {
  //     id: 9,
  //     name: localesData?.contact_search?.H,
  //   },
  //   {
  //     id: 10,
  //     name: localesData?.contact_search?.I,
  //   },
  //   {
  //     id: 11,
  //     name: localesData?.contact_search?.J,
  //   },
  //   {
  //     id: 12,
  //     name: localesData?.contact_search?.K,
  //   },
  //   {
  //     id: 13,
  //     name: localesData?.contact_search?.L,
  //   },
  //   {
  //     id: 14,
  //     name: localesData?.contact_search?.M,
  //   },
  //   {
  //     id: 15,
  //     name: localesData?.contact_search?.N,
  //   },
  //   {
  //     id: 16,
  //     name: localesData?.contact_search?.O,
  //   },
  //   {
  //     id: 17,
  //     name: localesData?.contact_search?.Q,
  //   },
  //   {
  //     id: 18,
  //     name: localesData?.contact_search?.R,
  //   },
  //   {
  //     id: 19,
  //     name: localesData?.contact_search?.S,
  //   },
  //   {
  //     id: 20,
  //     name: localesData?.contact_search?.T,
  //   },
  //   {
  //     id: 21,
  //     name: localesData?.contact_search?.U,
  //   },
  //   {
  //     id: 22,
  //     name: localesData?.contact_search?.V,
  //   },
  //   {
  //     id: 23,
  //     name: localesData?.contact_search?.W,
  //   },
  //   {
  //     id: 24,
  //     name: localesData?.contact_search?.X,
  //   },
  //   {
  //     id: 25,
  //     name: localesData?.contact_search?.Y,
  //   },
  //   {
  //     id: 26,
  //     name: localesData?.contact_search?.Z,
  //   },
  // ];

  // const dataArray = [
  //   { id: 1, personName: "Annette Brown", image: <UserImg /> },
  //   { id: 2, personName: "Brown", image: <UserImg /> },
  //   { id: 3, personName: "Rnnette Brown", image: <UserImg /> },
  //   { id: 4, personName: "Innette Brown", image: <UserImg /> },
  //   { id: 5, personName: "Lnnette Brown", image: <UserImg /> },
  //   { id: 6, personName: "Cnnette Brown", image: <UserImg /> },
  //   { id: 7, personName: "Innette Brown", image: <UserImg /> },
  //   { id: 6, personName: "1.nnette Brown", image: <UserImg /> },
  //   { id: 9, personName: "2.nnette Brown", image: <UserImg /> },
  // ];
  // const sortedDataArray = dataArray.sort();

  // const groupedData = sortedDataArray.reduce((acc, item) => {
  //   const firstLetter = item?.personName?.charAt(0).toUpperCase();
  //   if (!acc[firstLetter]) {
  //     acc[firstLetter] = [];
  //   }
  //   acc[firstLetter].push(item);
  //   return acc;
  // }, {});

  // const alphabetLetters = Object.keys(groupedData);

  // const sortedAlphabetLetters = alphabetLetters.sort();

  const scrollRef = useRef(null);
  const [selectedLetter, setSelectedLetter] = useState('');

  const handleScrollToLetter = (letter) => {
    setSelectedLetter(letter);
    const letterElement = document.getElementById(letter);
    if (letterElement) {
      letterElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleSendLink = () => {
    let param_data = {
      UserID: profileData?.id,
      to: contact?.id,
      content: 'send-link',
      // type: message_type,
      // content: message,
      // reply: reply_obj,
      // chatType: 'private',
      // origional_name: doc_origional_name,
      // user: user_obj,
      // link:
      //   message_type === MSG_TYPE.STRING && link && link.length > 0
      //     ? link
      //     : [],
    };
    socket.emit('sendLinkNotification', param_data);
  };
  const getContactDetails = async (name) => {
    // `?page=${
    //   this.state.search_text.trim().length !== 0
    //     ? this.state.searchPage
    //     : this.state.page
    // }&size=${this.state.size}&searchName=${this.state.search_text}`
    setLoader(true);
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.USER + `?searchName=${name}`
      );
      if (status === 200) {
        setLoader(false);

        var DataArray = [];
        let rowArray = data;

        rowArray?.map((item) => {
          // if (item.id !== tokenID) {
          if (
            item.firstname != null &&
            item.lastname != null &&
            item.firstname.trim() !== '' &&
            item.lastname.trim() !== '' &&
            !NO_SPECIAL_CHARACTER_PATTERN.test(item.lastname.trim())
          ) {
            DataArray.push({
              name: item.firstname.trim() + ' ' + item.lastname.trim(),
              avatar: item?.userFirstImage?.url,
              id: item.id,
            });
          }
          // }
        });

        var tmpArray = _.sortBy(DataArray, 'name');

        const sortedDataArray = tmpArray.sort();

        const groupedData = sortedDataArray.reduce((acc, item) => {
          const firstLetter = item?.name?.charAt(0).toUpperCase();
          if (!acc[firstLetter]) {
            acc[firstLetter] = [];
          }
          acc[firstLetter].push(item);
          return acc;
        }, {});
        setConGrpDetails(groupedData);
        const alphabetLetters = Object.keys(groupedData);

        const sortedAlphabetLetters = alphabetLetters.sort();
        setContactDetails(sortedAlphabetLetters);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const sendLinkReq = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.LINK + contact?.id + URLS.SEND_LINK_REQUEST
      );
      if (status === 200) {
        setLoader(false);
        setContactDialog(false);
        handleSendLink();
        navigate('/account-details');
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getContactDetails('');
  }, []);

  return (
    <Box className='contact-list-head-sec'>
      {loader && <PreLoader />}
      <Typography
        className='header-text pb-3'
        dangerouslySetInnerHTML={{
          __html: localesData?.contact_search?.CONTACTS,
        }}
      />
      <Paper component='form' className='search-bar mb-3'>
        <Search className='search-icon' />
        <InputBase
          className='search-contact-textfield'
          onChange={(e) => getContactDetails(e.target.value)}
          placeholder={localesData?.contact_search?.SEARCH_Contact}
        />
      </Paper>
      <Box className='contact-list-sec'>
        <Box className='contact-list'>
          {/* {/ <div style={{ display: "flex" }}> /} */}
          <div style={{ flex: 1, overflowY: 'scroll' }} ref={scrollRef}>
            {contactDetails &&
              contactDetails?.length > 0 &&
              contactDetails.map((contact) => {
                if (
                  conGrpDetails[contact] &&
                  conGrpDetails[contact]?.length === 1 &&
                  conGrpDetails[contact][0]?.id === profileData?.id
                ) {
                  return <></>;
                } else {
                  return (
                    <Box key={contact} id={contact}>
                      <Typography className='contact-list-name'>
                        {contact}
                      </Typography>
                      {conGrpDetails &&
                        conGrpDetails[contact]?.length > 0 &&
                        conGrpDetails[contact].map((item) => {
                          if (item?.id === profileData?.id) {
                            return <></>;
                          } else {
                            return (
                              <Box className='d-flex person-deatil'>
                                {' '}
                                <Avatar
                                  className='person-image'
                                  alt='Image'
                                  src={item?.avatar}
                                />
                                <Box
                                  className='person-name-sec d-flex'
                                  onClick={() => {
                                    setContactDialog(true);
                                    setContact(item);
                                  }}
                                  key={contact}
                                >
                                  <Typography className='person-name'>
                                    {item?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          }
                        })}
                    </Box>
                  );
                }
              })}
          </div>

          {/* {/ </div> /} */}
        </Box>
        {/*  <Box className="contact-list-order">
          {listOrder.map((tab, index) => {
            return (
              <Box>
                <CustomButton
                  variant="text"
                  title={tab?.name}
                  fontWeight="600"
                  type="submit"
                  className="list-name"
                />
              </Box>
            );
          })}
        </Box> */}
      </Box>
      <div className='contact-char'>
        {contactDetails.map((letter) => (
          <Box
            className='contact-list-order'
            key={letter}
            onClick={() => handleScrollToLetter(letter)}
          >
            <Box
              style={{
                fontWeight: letter === selectedLetter ? 'bold' : 'normal',
              }}
            >
              <CustomButton
                variant='text'
                title={letter}
                fontWeight='600'
                type='submit'
                className='list-name'
              />
            </Box>
          </Box>
        ))}
      </div>
      <DialogBox
        open={contactDialog}
        handleClose={() => setContactDialog(!contactDialog)}
        title=''
        className='card-dialog-box contact-card-box '
        setContactDialog={setContactDialog}
        content={
          <ContactCard
            handleClose={() => setContactDialog(!contactDialog)}
            contact={contact}
            sendLinkReq={sendLinkReq}
            cardInfoLeft={localesData?.contact_search?.CARD_SELECTED}
            cardInfoRight={localesData?.contact_search?.LINK_PROFILE}
            btnText={localesData?.contact_search?.LINK}
          />
        }
      />
    </Box>
  );
};

export default ContactSearch;
