import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../UI/Button/CustomButton';
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as DogBadgeIcon } from 'src/assets/images/icons/dog-badge-icon.svg';
import { ReactComponent as UnselectIcon } from 'src/assets/images/icons/radio-unselect-icon.svg';
import { ReactComponent as SelectIcon } from 'src/assets/images/icons/radio-select-icon.svg';
import {
  clearSvgData,
  setSVGFile,
} from 'src/helpers/store/actions/progressImage';

import svgData from 'src/assets/images/finish-dog.svg';
import { addProfileData } from 'src/helpers/store/actions/profileSetup';
import { useSelector } from 'react-redux';

const ProfileCreationHaveDog = ({ localesData, handleNext, dispatch }) => {
  const navigate = useNavigate();
  const reduxProfileData = useSelector((state) => state?.profileData);

  const [haveDog, setHaveDog] = useState('');
  const dogHaveList = [
    { label: localesData?.dog?.I_HAVE_DOG, value: '0' },
    { label: localesData?.dog?.i_DONT_HAVE_DOG, value: '1' },
  ];
  useEffect(() => {
    setHaveDog(reduxProfileData?.haveDog);
  }, []);

  useEffect(() => {
    dispatch(setSVGFile(svgData));
  }, [dispatch]);

  return (
    <Box className='t-center'>
      <Box className='pb-10'>
        <Typography variant='h3' className='s-bold'>
          {localesData?.profile?.COMPLETE_MY_PROFILE}!
        </Typography>
        <Typography
          variant='h5'
          className='mt-2 mb-8'
          dangerouslySetInnerHTML={{
            __html: localesData?.dog?.DO_YOU_HAVE_DOG,
          }}
        />
        {/* <Typography>
          {localesData?.dog?.TO_EARN}{' '}
          <DogBadgeIcon className='dog-badge-icon' />{' '}
          {localesData?.dog?.COMPLETE_DOG_PROFIEL}
        </Typography> */}
        <Box>
          <FormControl className='w-100 pb-4'>
            <RadioGroup
              name='haveDog'
              value={haveDog}
              onChange={(e) => {
                setHaveDog(e.target.value);
                dispatch(
                  addProfileData({
                    key: 'haveDog',
                    value: e.target.value,
                  })
                );
              }}
              // onBlur={handleBlur}
            >
              {dogHaveList?.map((item, i) => (
                <FormControlLabel
                  key={i}
                  value={item?.value}
                  className='dog-label-card space-between'
                  control={
                    <Radio
                      size='small'
                      icon={<UnselectIcon className='radio-icon' />}
                      checkedIcon={<SelectIcon className='radio-icon' />}
                      disableRipple
                    />
                  }
                  labelPlacement='start'
                  label={
                    <Typography
                      variant='h5'
                      className='font-r-13'
                      style={{ fontWeight: 600 }}
                    >
                      {item?.label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <CustomButton
          variant='contained'
          title={localesData?.NEXT}
          background='#F54D71'
          fontWeight='600'
          onClick={() => {
            dispatch(clearSvgData());
            haveDog === '0' ? handleNext() : navigate('/');
          }}
        />
      </Box>
    </Box>
  );
};

export default ProfileCreationHaveDog;
