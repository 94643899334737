import React, { useContext, useRef, useEffect, useState } from 'react';
import { IntlContext } from 'src/App';
import { Box, Typography, Checkbox, Link } from '@mui/material';

import CustomButton from 'src/views/component//UI/Button/CustomButton';
import Dog from 'src/assets/images/subscription-dog.png';
import { ReactComponent as Cup } from 'src/assets/images/cup.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
// import { saveToStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
import SubDialogbox from 'src/views/component/UI/Subscriptionpopup/SubDialogbox';
import './subscriptionPoppup.scss';
import PreLoader from 'src/components/Loader';
import PaymentDetails from '../../application/PaymentDetails';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const SubscriptionPopup = ({ handleClose }) => {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const sliderRef = useRef(null);
  // const [duration, setDuration] = useState('');
  // const [subscriptionDuration, setsubscriptionDuration] = useState('');
  // const [disable1Month, setDisable1Month] = useState(false);
  // const [disable3Month, setDisable3Month] = useState(false);
  const [toggleDialog, setToggleDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeSlide, setActiveSlide] = useState(1);
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [isMostPaw, setIsMostPaw] = useState(2);
  const [purchasedPlan, setPurchasedPlan] = useState('');
  const settings = {
    dots: true,
    infinite: false,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    variableWidth: true,
    afterChange: (index) => {
      const activeIndex = index + 1;
      setActiveSlide(activeIndex);
      const selectePlan = subscriptionPlan?.find(
        (item) => item?.id === activeIndex
      );
      setSelectedPlan(selectePlan);
    },
  };

  const subscriptionPhrase = [
    {
      id: 1,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE1,
    },
    {
      id: 2,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE2,
    },
    {
      id: 3,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE3,
    },
    {
      id: 4,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE4,
    },
    {
      id: 5,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE5,
    },
    {
      id: 6,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE6,
    },
    {
      id: 7,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE7,
    },
    {
      id: 8,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE8,
    },
    {
      id: 9,
      phrases: localesData?.subscription_popup?.SUBSCRIPTION_PHRASE9,
    },
  ];

  useEffect(() => {
    if (sliderRef.current) {
      if (activeSlide !== null) {
        sliderRef.current.slickGoTo(activeSlide - 1);
      }
    }
  }, [activeSlide]);

  const handleCheckboxChange = (index, obj) => {
    setActiveSlide(index);
    setSelectedPlan(obj);
  };

  // GET SUBSCRIPTION LIST
  const getSubscriptionList = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.SUBSCRIPTION_PLAN_LIST
      );
      if (status === 200) {
        setActiveSlide(
          data?.data?.filter((item) => item?.purchasedPlan === true)?.[0]?.id
            ? data?.data?.filter((item) => item?.purchasedPlan === true)?.[0]
                ?.id
            : 2
        );
        setIsMostPaw(
          data?.data?.filter((item) => item?.isMostPaw === true)?.[0]?.id
            ? data?.data?.filter((item) => item?.isMostPaw === true)?.[0]?.id
            : 2
        );
        setSelectedPlan(
          data?.data?.filter((item) => item?.purchasedPlan === true)?.[0]
            ? data?.data?.filter((item) => item?.purchasedPlan === true)?.[0]
            : data?.data?.[1]
        );
        setSubscriptionPlan(data?.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // GET SUBSCRIBE DETAILS
  const getSubscrbeDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_SUBSCRIBE_PLAN);
      if (status === 200) {
        setPurchasedPlan(data?.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getSubscriptionList();
    getSubscrbeDetails();
  }, []);

  const getSubscriptionDuration = (duration) => {
    if (duration && duration != null) {
      if (
        duration.includes('Months') ||
        duration.includes('Month') ||
        duration.includes('months') ||
        duration.includes('month')
      ) {
        return duration;
      } else {
        if (duration === 1) {
          return duration + ' Month';
        } else {
          return duration + ' Months';
        }
      }
    } else {
      return '';
    }
  };

  const onTrialPress = () => {
    var subDuration = getSubscriptionDuration(
      profileData?.subscription?.duration
    );

    const disable1Month =
      profileData?.subscription?.duration != null
        ? subDuration === localesData?.subscription_popup?.THREE_MONTHS ||
          subDuration === localesData?.subscription_popup?.THREE_MONTHS_S ||
          subDuration === localesData?.subscription_popup?.SIX_MONTHS ||
          subDuration === localesData?.subscription_popup?.SIX_MONTHS_S
          ? true
          : false
        : false;
    const disable3Month =
      profileData?.subscription?.duration != null
        ? subDuration === localesData?.subscription_popup?.SIX_MONTHS ||
          subDuration === localesData?.subscription_popup?.SIX_MONTHS_S
          ? true
          : false
        : false;
    if (profileData?.platform === 'web' || profileData?.platform === null) {
      if (purchasedPlan?.status !== 'cancel') {
        if (activeSlide === 1) {
          if (disable1Month) {
            if (
              subDuration === localesData?.subscription_popup?.THREE_MONTHS ||
              subDuration === localesData?.subscription_popup?.THREE_MONTHS_S
            ) {
              setApiMessage(
                'error',
                localesData?.subscription_popup?.ALREADY_HAVE_3_MONTHS_PLAN
              );
            } else {
              setApiMessage(
                'error',
                localesData?.subscription_popup?.ALREADY_HAVE_6_MONTHS_PLAN
              );
            }
          } else {
            if (
              subDuration === localesData?.subscription_popup?.ONE_MONTHS ||
              subDuration === localesData?.subscription_popup?.ONE_MONTHS_S
            ) {
              setApiMessage(
                'error',
                localesData?.subscription_popup?.CANT_BUY_SAME_SUBSCRIPTION
              );
            } else {
              // setDuration(localesData?.subscription_popup?.ONE_MONTHS);
              requestPurchaseMethod(activeSlide);
            }
          }
        } else if (activeSlide === 2) {
          if (disable3Month) {
            setApiMessage(
              'error',
              localesData?.subscription_popup?.ALREADY_HAVE_6_MONTHS_PLAN
            );
          } else {
            if (
              subDuration === localesData?.subscription_popup?.THREE_MONTHS ||
              subDuration === localesData?.subscription_popup?.THREE_MONTHS_S
            ) {
              setApiMessage(
                'error',
                localesData?.subscription_popup?.CANT_BUY_SAME_SUBSCRIPTION
              );
            } else {
              // setDuration(localesData?.subscription_popup?.THREE_MONTHS);
              requestPurchaseMethod(activeSlide);
            }
          }
        } else if (activeSlide === 3) {
          if (
            subDuration === localesData?.subscription_popup?.SIX_MONTHS ||
            subDuration === localesData?.subscription_popup?.SIX_MONTHS_S
          ) {
            setApiMessage(
              'error',
              localesData?.subscription_popup?.CANT_BUY_SAME_SUBSCRIPTION
            );
          } else {
            // setDuration(localesData?.subscription_popup?.SIX_MONTHS);
            requestPurchaseMethod(activeSlide);
          }
        }
      } else {
        setApiMessage(
          'error',
          "You can't buy a new plan until the existing plans billing cycle is complete"
        );
      }
    } else {
      setApiMessage(
        'error',
        "You can't change plan in web, you can check in app"
      );
    }
    setLoader(false);
  };

  const requestPurchaseMethod = () => {
    if (selectedPlan) {
      // setDuration(selectedPlan?.duration);
      setToggleDialog(true);
    } else {
      setApiMessage('error', localesData?.validation?.SELECT_AT_LIST_ONE);
    }
  };
  return (
    <>
      {loader && <PreLoader />}
      <Box className='subscription-head-sec'>
        <Box className='subscription-popup-sec'>
          <img alt='not found' src={Dog} className='dog-img' />
        </Box>
        <Box className='premium-btn-sec pt-18 pb-8'>
          <Typography variant='h5' className='premium-btn'>
            {localesData?.subscription_popup?.PREMIUM_BTN}
          </Typography>
        </Box>
        <Box className='subscription-header-sec'>
          {' '}
          <Typography
            className='header-txt pb-2'
            dangerouslySetInnerHTML={{
              __html: localesData?.subscription_popup?.PREMIUM_FEATURE,
            }}
          />
          <Typography
            className='sub-header-txt pb-3'
            dangerouslySetInnerHTML={{
              __html: localesData?.subscription_popup?.SUB_HEADER,
            }}
          />
        </Box>
        {subscriptionPhrase &&
          subscriptionPhrase?.length > 0 &&
          subscriptionPhrase?.map((content, i) => {
            return (
              <Box key={i} className='subscription-instruction'>
                <Cup className='cup-img' />
                <Typography className='instructions pb-2'>
                  {content.phrases}
                </Typography>
              </Box>
            );
          })}
        <Box className='t-center mb-3'>
          <Link
            href='https://puppilovers.com/membership-plans/'
            target=' _'
            className='font-r-10 subscription-link  t-center'
          >
            {localesData?.MEMBERSHIP_PLANS}
          </Link>
        </Box>
        <Box className='slider-sec'>
          <Typography
            className='select-plan-txt pb-2'
            dangerouslySetInnerHTML={{
              __html: localesData?.subscription_popup?.SELECT_PLAN,
            }}
          />
        </Box>
        {/* Slider */}
        <Box>
          <Slider ref={sliderRef} {...settings} className='card-slider'>
            {subscriptionPlan &&
              subscriptionPlan?.length > 0 &&
              subscriptionPlan?.map((obj, index) => {
                return (
                  <Card
                    className={`slide-card pointer mb-6 ${
                      obj?.id === activeSlide ? 'active' : ''
                    }`}
                    key={index}
                    onClick={() => handleCheckboxChange(obj?.id, obj)}
                  >
                    {obj?.purchasedPlan && (
                      <Typography className='active-plan'>
                        <span className='active-plan-dot'></span>{' '}
                        {localesData?.ACTIVE}
                      </Typography>
                    )}
                    <Box className='card-content-sec'>
                      <CardContent className='card-content'>
                        <Typography
                          className={
                            obj?.id === activeSlide
                              ? 'header-txt-checked pb-2'
                              : 'header-txt pb-2'
                          }
                          // dangerouslySetInnerHTML={{
                          //   __html:
                          //     localesData?.FREE +
                          //     `/<span class='plan-duration'>${obj?.duration}</span>`,
                          // }}
                        >
                          {'$' + obj?.cost + '/' + obj?.duration}
                        </Typography>
                        <Typography
                          className={
                            obj?.id === activeSlide
                              ? 'sub-header-txt-checked'
                              : 'sub-header-txt'
                          }
                        >
                          {obj?.description}
                        </Typography>
                      </CardContent>
                      {!obj?.purchasedPlan && (
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          // className='checkbox-sec'
                          checked={obj?.id === activeSlide}
                          onChange={() => handleCheckboxChange(obj?.id, obj)}
                        />
                      )}
                    </Box>
                    {obj?.id === isMostPaw ? (
                      <Box className='slider-card-sec'>
                        <Box
                          className={
                            obj?.purchasedPlan
                              ? 'slider-card-btn active-plan-card-btn'
                              : 'slider-card-btn'
                          }
                        >
                          <Typography variant='h6' className='card-btn'>
                            {localesData?.subscription_popup?.CARD_BTN}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Card>
                );
              })}
          </Slider>
        </Box>

        <Box className='btn-instruction-sec pb-2'>
          <CustomButton
            variant='contained'
            title={
              profileData?.isPaid
                ? localesData?.subscription_popup?.UPDATE_YOUR_PLAN
                : localesData?.subscription_popup?.TRIAL_BTN
            }
            background='#F54D71'
            fontWeight='600'
            classname='trial-btn'
            onClick={() => onTrialPress()}
            // onClick={() => setToggleDialog(true)}
          />
        </Box>
        <Typography
          className='instruction-txt'
          dangerouslySetInnerHTML={{
            __html: localesData?.subscription_popup?.INSTRUCTION,
          }}
        />
        <Box className='t-center agree-link'>
          <Link
            href='https://puppilovers.com/terms-conditions/'
            target=' _'
            className='font-r-10 t-center subscription-link'
          >
            {localesData?.AGREE_TERMS_CONDITIONS}
          </Link>
        </Box>
      </Box>
      <SubDialogbox
        open={toggleDialog}
        handleClose={() => setToggleDialog(!toggleDialog)}
        title={''}
        closeIconShow={true}
        contentClass='payment-content'
        content={
          <PaymentDetails
            handleClose={() => setToggleDialog(!toggleDialog)}
            localesData={localesData}
            // duration={duration}
            selectedPlan={selectedPlan}
            handleCloseBothModal={() => {
              handleClose();
              setToggleDialog(!toggleDialog);
            }}
            isMostPaw={isMostPaw}
          />
        }
      />
    </>
  );
};

export default SubscriptionPopup;
