import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Typography,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import RangeSlider from 'src/views/component/UI/RangeSlider';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import UpgradePlan from '../UpgradePlan';
// import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
// import { ReactComponent as UnLockIcon } from 'src/assets/images/icons/unlock.svg';

const valuetext = (value) => {
  return `${value}`;
};

const AboutAge = ({
  hideUpgradePlan,
  localesData,
  setLoader,
  answers,
  navigate,
}) => {
  const minDistance = 1;
  const [ageData, setAgeData] = useState('');
  const [dontCareAge, setDontCareAge] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [ageSlider, setAgeSlider] = useState([]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setAgeSlider([
        Math.min(newValue[0], ageSlider[1] - minDistance),
        ageSlider[1],
      ]);
    } else {
      setAgeSlider([
        ageSlider[0],
        Math.max(newValue[1], ageSlider[0] + minDistance),
      ]);
    }
  };

  useEffect(() => {
    setDontCareAge([{ id: 1, title: 'Don’t Care' }]);
    answers?.map((item) => {
      if (item.id === 54) {
        if (item.Option[0]?.answer === null || item.Option[0]?.answer === '') {
          setAgeData({
            opt_id: item.Option[0]?.id,
            dont_care: false,
          });
          setAgeSlider([18, 100]);
        } else {
          var ans = item.Option[0]?.answer;
          setAgeData({
            dont_care: ans?.split('-')[2] === 'true' ? true : false,
            opt_id: item.Option[0]?.id,
            is_must: item.Option[0].isMust === 1 ? true : false,
          });
          setIsPrivate(item.Option[0].isMust === 1 ? true : false);
          setAgeSlider([
            Number(ans?.split('-')[0]),
            Number(ans?.split('-')[1]),
          ]);
        }
      }
      return true;
    });
  }, [answers]);

  const handleOnSubmit = async () => {
    var opt = [];

    opt.push({
      optionId: ageData?.opt_id,
      answer:
        ageSlider?.[0]?.toString() +
        '-' +
        ageSlider?.[1]?.toString() +
        '-' +
        ageData?.dont_care?.toString(),
      lookingFor: true,
      startRange: ageData?.dont_care ? 18 : ageSlider?.[0],
      endRange: ageData?.dont_care ? 100 : ageSlider?.[1],
      isMust: isPrivate ? true : false,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        navigate('/edit-profile', {
          state: 'looking-for',
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleChipClick = () => {
    setAgeData({
      ...ageData,
      dont_care: !ageData?.dont_care,
    });
  };

  return (
    <>
      <Box className="">
        <Box className="chip-container-box">
          {hideUpgradePlan && (
            <Box>
              <UpgradePlan
                title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
              />
              <Box className="b-bottom mt-4 mb-4" />
            </Box>
          )}
          <Typography variant="h3" className="mb-6 t-center s-bold">
            {localesData?.profile?.AGE}
          </Typography>

          <Box className="mb-9" m="0 45px">
            <Box className="d-flex space-between">
              <Typography
                variant="h6"
                className="font-r-13"
                style={{ fontWeight: 600 }}
              >
                {ageSlider?.[0]?.toString()}
              </Typography>
              <Typography
                variant="h6"
                className="font-r-13"
                style={{ fontWeight: 600 }}
              >
                {ageSlider?.[1]?.toString()}
              </Typography>
            </Box>
            <RangeSlider
              min={18}
              max={100}
              getAriaLabel={() => 'Minimum distance'}
              value={ageSlider}
              onChange={handleChange1}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              disableSwap
            />
          </Box>
        </Box>
        {/* <Box className="chip-container-box">
          <Box className="chip-container t-center pb-20"> */}
        <Box className="looking-for-chip">
          <Box className="chip-container t-center pb-14">
            {dontCareAge?.map((item, i) => (
              <Chip
                className={`border-chip-inner border font-r-11 ${
                  ageData?.dont_care ? 'chip-active' : ''
                } `}
                style={{ width: '144px' }}
                key={i}
                label={item?.title}
                onClick={() => handleChipClick()}
              />
            ))}
          </Box>

          <Box>
            <Box className="d-flex pb-4 justify-center">
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className="ios-switch" />}
                  name="isPrivate"
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
              </FormGroup>
              <Typography variant="h5">
                {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
              </Typography>
              {/* {isPrivate ? <UnLockIcon /> : <LockIcon />} */}
            </Box>
            <Box className="t-center m-auto" sx={{ maxWidth: '134px' }}>
              <CustomButton
                classname="uppercase"
                variant="contained"
                title={localesData?.DONE}
                background="#59D09D"
                fontWeight="600"
                onClick={handleOnSubmit}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutAge;
