import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { ReactComponent as CallEnd } from "src/assets/images/call-end-icon.svg";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import Video from "twilio-video";
import { fetchFromStorage } from "src/helpers/context";
import { CONSTANT, identifiers } from "src/helpers/constants/identifier";
import { socket } from "src/helpers/context/socket";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import { MSG_TYPE } from "../chat/Messages/components/MsgType";
import { setApiMessage } from "src/helpers/commonFunctions";
import { useNavigate } from "react-router-dom";

const CallScreen = ({
  handleEnd,
  callDetails,
  notificationType,
  ringingStatus,
  startCallRoomObj,
  videoContent,
  startToggleFacingMode,
  startAcceptVideoCall,
  endSetCallDetails,
  startRemoteParticipants,
}) => {
  const navigate = useNavigate();
  const userDetail = fetchFromStorage(identifiers?.USER_DATA);
  const [acceptCall, setAcceptCall] = useState(false);
  const [acceptVideoCall, setAcceptVideoCall] = useState(false);
  const [roomObj, setRoomObj] = useState("");
  const [isMicMuted, setIsMicMuted] = useState(true);
  const [isVolume, setIsVolume] = useState(1);

  const localVideoRef = useRef();
  const remoteVideosRef = useRef();
  const [remoteVideoElements, setRemoteVideoElements] = useState([]);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [localVideoAudioTrack, setLocalVideoAudioTrack] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [remoteParticipants, setRemoteParticipants] = useState([]);
  const [errors, setErrors] = useState("");
  useEffect(() => {
    const checkIsMobile = () => {
      const mobileKeywords = ["iPhone", "iPad", "iPod", "Android"];
      return mobileKeywords.some((keyword) =>
        navigator.userAgent.includes(keyword)
      );
    };

    setIsMobile(checkIsMobile());
  }, []);

  useEffect(() => {
    if (remoteVideoElements?.length !== 0) {
      remoteVideoElements?.forEach((element) => {
        remoteVideosRef?.current?.appendChild(element);
      });
    } else {
      localVideoRef.current = null;
      remoteVideosRef.current = null;
    }
  }, [remoteVideoElements]);

  // useEffect(() => {
  const connectToRoom = async () => {
    setErrors("");
    try {
      const room = await Video.connect(callDetails?.data?.token, {
        name: callDetails?.data?.content?.room_name,
        video:
          callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL
            ? true
            : false,
        audio: true,
      });
      setRoomObj(room);
      // console.log('Connected to Room "%s"', room.name);

      room.participants.forEach(participantConnected);
      room.on("participantConnected", participantConnected);

      room.on("participantDisconnected", participantDisconnected);
      room.once("disconnected", (error) =>
        room.participants.forEach(participantDisconnected)
      );
    } catch (error) {
      console.error("Error connecting to the room:", error);
    }
  };

  const participantConnected = (participant) => {
    // console.log('Participant "%s" connected', participant.identity);
    // console.log('participant', participant);
    setRemoteParticipants((prevParticipants) => [
      ...prevParticipants,
      participant,
    ]);
    const div = document.createElement("div");
    div.id = participant?.sid;
    // div.innerText = participant.identity;

    participant.on("trackSubscribed", (track) => trackSubscribed(div, track));
    participant.on("trackUnsubscribed", trackUnsubscribed);

    participant.tracks.forEach((publication) => {
      if (publication?.isSubscribed) {
        trackSubscribed(div, publication?.track);
      }
    });
    // remoteVideosRef.current.appendChild(div);
    if (callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL) {
      setRemoteVideoElements((prevElements) => [...prevElements, div]);
    }
    setAcceptVideoCall(true);
    setAcceptCall(true);
  };

  const participantDisconnected = (participant) => {
    // console.log('Participant "%s" disconnected', participant.identity);
    // console.log('participantDisconnected', participant);
    setRemoteParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p !== participant)
    );
    document.getElementById(participant?.sid)?.remove();
    setAcceptCall(false);
    setAcceptVideoCall(false);
  };

  const trackSubscribed = (div, track) => {
    if (track?.kind === "video" || track?.kind === "audio") {
      const mediaElement = track?.attach();
      div.appendChild(mediaElement);
    }
    // if (callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL) {
    setRemoteVideoElements((prevElements) => [...prevElements]);
    // }
  };

  const trackUnsubscribed = (track) => {
    if (track?.kind === "video" || track?.kind === "audio") {
      track?.detach().forEach((element) => element?.remove());
    }
  };

  const acceptIncomingCall = async () => {
    setErrors("");
    if (callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL) {
      setAcceptVideoCall(true);
      setAcceptCall(true);
    }
    try {
      const localTracks = await Video.createLocalTracks({
        video:
          callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL
            ? true
            : false,
        audio: true,
      });
      if (callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL) {
        const videoTrack = localTracks?.find(
          (track) => track?.kind === "video"
        );
        const videoAudioTrack = localTracks?.find(
          (track) => track?.kind === "audio"
        );

        setLocalVideoTrack(videoTrack);
        setLocalVideoAudioTrack(videoAudioTrack);
        const audioTrack = localTracks?.find(
          (track) => track?.kind === "audio"
        );
        setLocalAudioTrack(audioTrack);
        localVideoRef.current.appendChild(videoTrack?.attach());
      }
      await connectToRoom();
    } catch (error) {
      console.error("Error starting the call:", error);
    }
  };

  // Function to end the call for a participant
  const endCallForParticipant = async (from_where) => {
    if (localVideoTrack) {
      const track = localVideoTrack?.mediaStreamTrack;
      localVideoTrack.stop();
      track.stop();
    }
    if (localAudioTrack) {
      localAudioTrack.stop();
    }
    if (localVideoAudioTrack) {
      localVideoAudioTrack.stop();
    }

    setErrors("");
    if (roomObj) {
      roomObj.disconnect();
      // send_end_call_data(from_where);
    }
    if (startCallRoomObj) {
      startCallRoomObj.disconnect();
      // send_end_call_data(from_where);
    }
    localVideoRef.current = null;
    remoteVideosRef.current = null;
    setRemoteVideoElements([]);
    setRemoteParticipants([]);
    setLocalVideoTrack(null);
    setLocalAudioTrack(null);
    setLocalVideoAudioTrack(null);
    send_end_call_data(from_where);
    // console.log('Disconnected from the call.', from_where, callDetails);
    // endSetCallDetails('');
    setAcceptCall(false);
    setAcceptVideoCall(false);
    // window.location.reload();
  };
  const send_end_call_data = (from_where) => {
    let call_made_by_id = callDetails?.data?.content?.call_made_by_id;
    let group_member_id = callDetails?.data?.content?.selected_member;
    let current_call_uuid = callDetails?.data?.uuid;
    let is_video_enabled =
      callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL
        ? true
        : false;
    let is_call_made_by_me =
      callDetails?.data?.content?.call_made_by_id === userDetail?.id
        ? true
        : false;
    let call_accept_details = {};
    // if (is_call_made_by_me) {
    //   group_member_id = group_member_id;
    // } else {
    //   group_member_id = [call_made_by_id];
    // }

    if (!is_call_made_by_me) {
      group_member_id = [call_made_by_id];
    }

    endSetCallDetails("");
    // console.log('send_end_call_event');
    send_end_call_event(group_member_id, current_call_uuid);
    send_end_call_notification(
      group_member_id,
      call_made_by_id,
      is_video_enabled,
      current_call_uuid,
      call_accept_details,
      from_where
    );
  };
  const send_end_call_event = (group_member_id, current_call_uuid) => {
    socket.emit("end-call", {
      UserIDs: group_member_id,
      uuid: current_call_uuid,
    });
  };
  const send_end_call_notification = async (
    group_member_id,
    call_made_by_id,
    is_video_enabled,
    current_call_uuid,
    accept_call_details,
    from_where
  ) => {
    var param_data = {
      memberId: group_member_id,
      type: MSG_TYPE?.END_CALL,
      uuid: current_call_uuid,
    };

    try {
      // setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.REJECT_CALL,
        param_data
      );
      if (status === 200) {
        proceed_after_end_call(
          group_member_id,
          call_made_by_id,
          is_video_enabled,
          current_call_uuid,
          accept_call_details,
          from_where,
          data
        );
      }
    } catch (error) {
      proceed_after_end_call(
        group_member_id,
        call_made_by_id,
        is_video_enabled,
        current_call_uuid,
        accept_call_details,
        from_where,
        null
      );
    }
  };
  const proceed_after_end_call = (
    group_member_id,
    call_made_by_id,
    is_video_enabled,
    current_call_uuid,
    accept_call_details,
    from_where,
    response
  ) => {
    if (from_where === 1 && response !== null && response.status) {
      send_missed_call_notification(
        group_member_id,
        call_made_by_id,
        is_video_enabled
      );
      // console.log('proceed_after_end_call');
    } else {
      setTimeout(() => {
        // window.location.reload();

        window.location.href = window?.location?.pathname;

        // const pathname = window.location.pathname;
        // const state = { message: true };
        // const queryString = new URLSearchParams(state).toString();
        // const urlWithState = `${pathname}?${queryString}`;
        // window.location.href = urlWithState;

        // window.location.href = '/kennel';

        // navigate('/kennel', {
        //   state: { message: true },
        // });
      }, 1000);
    }
  };
  const send_missed_call_notification = async (
    group_member_id,
    call_made_by_id,
    is_video_enabled
  ) => {
    try {
      let msg = "";

      if (is_video_enabled) {
        msg =
          "You missed Video call from " +
          userDetail?.firstname +
          " " +
          userDetail?.lastname;
      } else {
        msg =
          "You missed Audio call from " +
          userDetail?.firstname +
          " " +
          userDetail?.lastname;
      }

      var param_data = {
        memberId: group_member_id,
        type: MSG_TYPE.MISSED_CALL,
        message: msg,
        UserId: call_made_by_id,
      };
      const { status } = await axiosInstance.post(URLS.MISSED_CALL, param_data);
      if (status === 200) {
        send_missed_call_event(msg, group_member_id);
      }
    } catch (error) {
      setApiMessage("error", error.message);
    }
  };
  const send_missed_call_event = (message, chat_id) => {
    let user_obj = {
      _id: userDetail?.id,
      name: userDetail?.firstname + " " + userDetail?.lastname,
      avatar:
        userDetail?.images && userDetail?.images?.length !== 0
          ? userDetail?.images[0].url
          : "",
    };
    let param_data = {
      UserID: userDetail?.id,
      to: chat_id[0],
      type: MSG_TYPE.MISSED_CALL,
      content: message,
      reply: {},
      chatType: "private",
      origional_name: "",
      user: user_obj,
      link: [],
    };
    // console.log('send_missed_call_event', chat_id, param_data);
    socket.emit("add-message", param_data);
    setTimeout(() => {
      // window.location.reload();

      window.location.href = window?.location?.pathname;

      // const pathname = window.location.pathname;
      // const state = { message: true };
      // const queryString = new URLSearchParams(state).toString();
      // const urlWithState = `${pathname}?${queryString}`;
      // window.location.href = urlWithState;

      // window.location.href = '/kennel';

      // navigate('/kennel', {
      //   state: { message: true },
      // });
    }, 1000);
  };
  const handleToggleMic = () => {
    if (roomObj) {
      roomObj?.localParticipant?.audioTracks?.forEach((publication) => {
        const track = publication?.track;
        if (track && track?.kind === "audio") {
          track?.enable(!isMicMuted);
        }
      });
    } else if (startCallRoomObj) {
      startCallRoomObj?.localParticipant?.audioTracks?.forEach(
        (publication) => {
          const track = publication?.track;
          if (track && track?.kind === "audio") {
            track?.enable(!isMicMuted);
          }
        }
      );
    }
    setIsMicMuted(!isMicMuted);
  };
  const handleVolumeChange = (vol) => {
    if (roomObj) {
      remoteParticipants?.forEach((participant) => {
        participant?.audioTracks?.forEach((publication) => {
          const track = publication?.track;
          if (
            track?.kind === "audio" &&
            typeof track?.mediaStreamTrack?.enabled === "boolean"
          ) {
            track.mediaStreamTrack.enabled = vol === 1;
          }
        });
      });
    }
    if (startCallRoomObj) {
      startRemoteParticipants?.forEach((participant) => {
        participant?.audioTracks?.forEach((publication) => {
          const track = publication?.track;
          if (
            track?.kind === "audio" &&
            typeof track?.mediaStreamTrack?.enabled === "boolean"
          ) {
            track.mediaStreamTrack.enabled = vol === 1;
          }
        });
      });
    }

    setIsVolume(vol);
  };
  const toggleFacingMode = async () => {
    if (startCallRoomObj) {
      startToggleFacingMode();
    }
    try {
      if (!localVideoTrack || !localVideoTrack?.mediaStreamTrack) {
        return;
      }
      const track = localVideoTrack?.mediaStreamTrack;
      const currentFacingMode = track?.getSettings()?.facingMode;
      const newFacingMode =
        currentFacingMode === "user" ? "environment" : "user";

      const videoConstraints = {
        video: { facingMode: { exact: newFacingMode } },
        audio: true,
      };

      // Stop the current local video track
      track?.stop();
      localVideoTrack?.stop();
      setErrors("");
      // Create the new local video track with the updated facing mode
      const newLocalTracks = await Video.createLocalTracks(videoConstraints);

      // Find the new video track
      const newVideoTrack = newLocalTracks?.find(
        (track) => track?.kind === "video"
      );

      // Check if the new video track was created successfully
      if (!newVideoTrack) {
        console.error(
          "Failed to get the video track with the new facing mode."
        );
        return;
      }

      // Update the local video track state
      setLocalVideoTrack(newVideoTrack);

      // Update remote video track
      if (roomObj && roomObj?.localParticipant) {
        const localParticipant = roomObj?.localParticipant;
        const newFacingMode = videoConstraints?.video?.facingMode?.exact;
        localParticipant?.tracks?.forEach((publication) => {
          const track = publication?.track;
          if (track && track?.kind === "video") {
            track.restart({ facingMode: newFacingMode });
          }
        });
      }

      // Ensure the element is available before attaching the video track
      if (localVideoRef && localVideoRef.current) {
        localVideoRef.current.innerHTML = ""; // Clear any previous track
        localVideoRef.current.appendChild(newVideoTrack.attach());
      } else {
        console.error("video is not set or not a valid DOM element.");
      }
    } catch (error) {
      console.error("Error while toggling the camera facing mode:", error);
      setErrors("We're sorry, but we couldn't access your camera.");
    }
  };

  // TIMEOUT DISCONNECT
  useEffect(() => {
    if (ringingStatus === CONSTANT?.CALL_STATUS_RINGING) {
      const timeoutId = setTimeout(() => {
        endCallForParticipant(1);
        handleEnd();
      }, 100000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    //eslint-disable-next-line
  }, [ringingStatus, callDetails]);
  useEffect(() => {
    const preventNavigation = (event) => {
      window.history.forward();
      event.preventDefault();
      return false;
    };

    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = preventNavigation;

    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <Box className="call-screen-head-sec">
      {videoContent}
      <Box className="call-screen">
        {(startAcceptVideoCall || (acceptCall && acceptVideoCall)) &&
        callDetails?.data?.content?.call_type === MSG_TYPE?.VIDEO_CALL ? (
          <>
            <div className="local-call-video">
              {remoteVideoElements?.length !== 0 && (
                <div ref={remoteVideosRef} className="remote-video" />
              )}
              {/* {errors === '' ? ( */}
              <div
                ref={localVideoRef}
                className={
                  remoteVideoElements?.length === 0
                    ? "local-full-video"
                    : "local-video"
                }
              />
              {/* ) : ( */}
              {errors && <Box className="video-not-allow-msg">{errors}</Box>}
              {/* )} */}
            </div>

            <Box className="video-call-btn">
              {/* {(acceptVideoCall ||
                ringingStatus === CONSTANT?.CALL_STATUS_CONNECTED) && ( */}
              <>
                <Box className="pointer" onClick={() => handleToggleMic()}>
                  {isMicMuted ? (
                    <MicIcon
                      style={{
                        height: "50px",
                        width: "50px",
                        background: "#ffffff70",
                        color: "#ffffff",
                        borderRadius: "50%",
                        padding: "10px",
                      }}
                    />
                  ) : (
                    <MicOffIcon
                      style={{
                        height: "50px",
                        width: "50px",
                        background: "#ffffff70",
                        color: "#ffffff",
                        borderRadius: "50%",
                        padding: "10px",
                      }}
                    />
                  )}
                </Box>
                <Box
                  className="pointer"
                  onClick={() => {
                    endCallForParticipant(
                      acceptVideoCall ||
                        ringingStatus === CONSTANT?.CALL_STATUS_CONNECTED
                        ? 0
                        : 1
                    );
                    handleEnd();
                  }}
                >
                  <CallEnd />
                </Box>
                {isMobile && (
                  <Box className="pointer" onClick={() => toggleFacingMode()}>
                    <FlipCameraIosIcon
                      style={{
                        height: "50px",
                        width: "50px",
                        background: "#ffffff70",
                        color: "#ffffff",
                        borderRadius: "50%",
                        padding: "10px",
                      }}
                    />
                  </Box>
                )}
              </>
              {/* )} */}
            </Box>
          </>
        ) : (
          <Box className="call-screen-sec">
            <Box className="person-name">
              <Typography variant="h5">
                {callDetails && callDetails?.content}
              </Typography>
              <Typography variant="h3">
                {notificationType === "start_audio_call" ||
                notificationType === "start_video_call"
                  ? callDetails?.data?.content?.my_group_details?.group_name
                  : callDetails?.data?.content?.other_group_details?.group_name}
              </Typography>
            </Box>

            <Box className="">
              <img
                src={
                  notificationType === "start_audio_call" ||
                  notificationType === "start_video_call"
                    ? callDetails?.data?.content?.my_group_details
                        ?.group_profile_pic
                    : callDetails?.data?.content?.other_group_details
                        ?.group_profile_pic
                }
                className="person-img"
                alt=""
              />
              <Typography
                variant="h6"
                className="mt-2 mb-2"
                style={{ color: "#fff" }}
              >
                {ringingStatus === CONSTANT?.CALL_STATUS_RINGING
                  ? "Ringing..."
                  : ringingStatus === CONSTANT?.CALL_STATUS_DISCONNECTED
                  ? "Disconnected"
                  : ringingStatus === CONSTANT?.CALL_STATUS_CONNECTED
                  ? "Connected"
                  : ""}
              </Typography>
            </Box>
            <Box className="d-flex call-action-sec pb-9">
              {notificationType !== "audio_call" ||
              notificationType !== "video_call" ? (
                <>
                  {acceptCall ||
                  ringingStatus === CONSTANT?.CALL_STATUS_CONNECTED ? (
                    <>
                      <Box
                        className="pointer"
                        onClick={() => handleToggleMic()}
                      >
                        {isMicMuted ? (
                          <MicIcon
                            style={{
                              height: "50px",
                              width: "50px",
                              background: "#ffffff70",
                              color: "#ffffff",
                              borderRadius: "50%",
                              padding: "10px",
                            }}
                          />
                        ) : (
                          <MicOffIcon
                            style={{
                              height: "50px",
                              width: "50px",
                              background: "#ffffff70",
                              color: "#ffffff",
                              borderRadius: "50%",
                              padding: "10px",
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        className="pointer"
                        onClick={() => {
                          endCallForParticipant(0);
                          handleEnd();
                        }}
                      >
                        <CallEnd />
                      </Box>
                      <Box
                        className="pointer"
                        onClick={() =>
                          handleVolumeChange(isVolume === 1 ? 0 : 1)
                        }
                      >
                        {isVolume === 1 ? (
                          <VolumeUpIcon
                            style={{
                              height: "50px",
                              width: "50px",
                              background: "#ffffff70",
                              color: "#ffffff",
                              borderRadius: "50%",
                              padding: "10px",
                            }}
                          />
                        ) : (
                          <VolumeOffIcon
                            style={{
                              height: "50px",
                              width: "50px",
                              background: "#ffffff70",
                              color: "#ffffff",
                              borderRadius: "50%",
                              padding: "10px",
                            }}
                          />
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      {ringingStatus !== CONSTANT?.CALL_STATUS_RINGING ? (
                        <>
                          <Box
                            className="pointer"
                            onClick={() => acceptIncomingCall()}
                          >
                            <CallIcon
                              style={{
                                height: "50px",
                                width: "50px",
                                // background: '#1c6306',
                                background: "#58cf33",
                                color: "#ffffff",
                                borderRadius: "50%",
                                padding: "10px",
                              }}
                            />
                          </Box>
                          <Box
                            className="pointer"
                            onClick={() => {
                              endCallForParticipant(2);
                              handleEnd();
                            }}
                          >
                            <CloseIcon
                              style={{
                                height: "50px",
                                width: "50px",
                                background: "#ff0005",
                                color: "#ffffff",
                                borderRadius: "50%",
                                padding: "10px",
                              }}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box
                            className="pointer"
                            onClick={() => {
                              endCallForParticipant(1);
                              handleEnd();
                            }}
                          >
                            <CallEnd />
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Avatar
                    onClick={() => handleToggleMic()}
                    className="call-action-icons"
                    alt="Image"
                  >
                    {isMicMuted ? <MicIcon /> : <MicOffIcon />}
                  </Avatar>
                  <Avatar className="call-end-icon" alt="Image">
                    <CallEnd onClick={() => handleEnd()} />
                  </Avatar>
                  <Avatar
                    onClick={() => {
                      handleVolumeChange(isVolume === 1 ? 0 : 1);
                    }}
                    className="call-action-icons"
                    alt="Image"
                  >
                    {isVolume === 1 ? <VolumeUpIcon /> : <VolumeOffIcon />}
                  </Avatar>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CallScreen;
