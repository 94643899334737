import { Box, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../UI/Button/CustomButton';
import ProgressBar from '../../UI/ProgressBar';
import './profileCompletion.scss';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import { useNavigate } from 'react-router-dom';

const ProfileCompletionUser = ({
  localesData,
  dispatch,
  progressWeightage,
}) => {
  const navigate = useNavigate();
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);

  const handleDone = () => {
    navigate('/profile-completion-advance');
    dispatch(handleNext(0));
  };
  return (
    <Box className='t-center pb-6'>
      <Typography
        className='mb-2 font-r-12'
        dangerouslySetInnerHTML={{
          __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
        }}
      />
      <Box className='mb-3'>
        <Typography variant='h4' className='pb-1'>
          {/* {reduxProgress}% */}
          {progressWeightage}%
        </Typography>
        <ProgressBar processData={progressWeightage} />
      </Box>
      <Typography
        variant='h3'
        className='mb-4 s-bold'
        dangerouslySetInnerHTML={{
          __html: localesData?.profile_completion_user?.ALL_ABOUT_YOU,
        }}
      />
      <Typography
        variant='h4'
        className='mb-4'
        dangerouslySetInnerHTML={{
          __html: localesData?.profile_completion_user?.DESC1,
        }}
      />
      <Typography
        variant='h4'
        className='mb-4'
        dangerouslySetInnerHTML={{
          __html: localesData?.profile_completion_user?.DESC2,
        }}
      />
      <Typography
        variant='h4'
        className='mb-6'
        dangerouslySetInnerHTML={{
          __html: localesData?.profile_completion_user?.DESC3,
        }}
      />
      <Box>
        <CustomButton
          variant='contained'
          title={localesData?.profile_completion_user?.ADVANTAGE_BTN}
          background='#59D09D'
          fontWeight='600'
          onClick={() => handleDone()}
        />
        <CustomButton
          variant='contained'
          title={localesData?.profile_completion_user?.WHATEVER_COMES}
          background='#FF0000'
          fontWeight='600'
          onClick={() => {
            navigate('/');
          }}
        />
      </Box>
    </Box>
  );
};

export default ProfileCompletionUser;
