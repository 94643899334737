import React from 'react';
import Page from '../../../components/Page';
import StartTalk from 'src/views/component/application/ManageProfile/EditYourProfile/StartTalk';

const StartTalkPage = () => {
  return (
    <Page title='Edit Profile'>
      <StartTalk />
    </Page>
  );
};

export default StartTalkPage;
