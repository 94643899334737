import React from 'react';
import Page from 'src/components/Page';
import DogPictures from 'src/views/component/application/ManageProfile/DogProfile/DogPictures';

const DogPictureView = () => {
  return (
    <Page title='Dog Picture'>
      <DogPictures />
    </Page>
  );
};

export default DogPictureView;
