import React, { useEffect, useState } from 'react';
import { Box, Typography, Chip } from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import { CustomTextField } from 'src/views/component/UI/textfield';
import UpgradePlan from '../UpgradePlan';

const AboutGender = ({
  hideUpgradePlan,
  localesData,
  setLoader,
  answers,
  navigate,
}) => {
  const [selectedChips, setSelectedChips] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setGenderData(
      answers
        ?.find((o) => o?.id === 2)
        ?.Option?.map((item) => ({
          ...item,
          id: item?.id,
          private: false,
        }))
    );
    setSelectedChips(
      answers
        ?.find((o) => o?.id === 2)
        ?.Option?.filter((item) => item?.selected === 1)
    );
  }, [answers]);

  const handleChipClick = (chip) => {
    // if (!selectedChips?.includes(chip)) {
    //   setSelectedChips([...selectedChips, chip]);
    // } else {
    //   setSelectedChips((chips) => chips?.filter((obj) => obj?.id !== chip.id));
    // }

    if (!selectedChips?.some((obj) => obj?.id === chip?.id)) {
      setSelectedChips([...selectedChips, chip]);
    } else {
      setSelectedChips((chips) => chips?.filter((obj) => obj?.id !== chip?.id));
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    genderData?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        lookingFor: true,
      });
      return true;
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setLoader(false);
        navigate('/edit-profile', {
          state: 'looking-for',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      <Box className=''>
        <Box className='chip-container-box'>
          {hideUpgradePlan && (
            <Box>
              <UpgradePlan
                title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
              />
              <Box className='b-bottom mt-4 mb-4' />
            </Box>
          )}
          <Typography variant='h3' className='mb-6 t-center s-bold'>
            {localesData?.profile?.GENDER}
          </Typography>

          <CustomTextField
            variant='standard'
            className='custom-textfield pb-4'
            placeholder='search'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
        <Box className='looking-for-chip'>
          <Box className='chip-container mb-2'>
            {genderData &&
              genderData
                ?.filter((item) =>
                  item?.title
                    ?.toLowerCase()
                    .includes(searchInput?.toLowerCase())
                )
                ?.map((item, i) => (
                  <Chip
                    className={`chip-inner font-r-11 ${
                      selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
                        ? 'chip-active'
                        : ''
                    } `}
                    key={i}
                    label={item?.title}
                    onClick={() => handleChipClick(item)}
                  />
                ))}
            {genderData &&
              genderData?.filter((item) =>
                item?.title?.toLowerCase().includes(searchInput?.toLowerCase())
              )?.length === 0 && (
                <Typography variant='h5' className='t-center disable-color'>
                  {localesData?.validation?.NO_SEARCH_RESULT_FOUND}
                </Typography>
              )}
          </Box>
          <Box className='edit-your-profile-box'>
            <Box className='btn-fixed-bottom'>
              <Box className='t-center m-auto' sx={{ maxWidth: '134px' }}>
                <CustomButton
                  classname='uppercase'
                  variant='contained'
                  title={localesData?.DONE}
                  background='#59D09D'
                  fontWeight='600'
                  onClick={handleOnSubmit}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutGender;
