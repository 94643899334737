import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import { CustomTextField } from '../../../../UI/textfield';
import { ReactComponent as PlusIcon } from '../../../../../../assets/images/icons/plus.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/images/icons/check.svg';
import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from 'src/assets/images/icons/unlock.svg';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import { useSelector } from 'react-redux';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
// import { addQuestionAnswer } from 'src/helpers/store/actions/questionAnswerSlice';
import { useNavigate } from 'react-router-dom';
import { IntlContext } from '../../../../../../App';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const YourManuallyEthnicity = ({ answars, setLoader }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  // const disatch = useDispatch();
  const reduxQuesAns = useSelector((state) => state?.questionAnswer);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [manuallyData, setManuallyData] = useState(['']);
  const [numFields, setNumFields] = useState(1);
  const [manuallyId, setManuallyId] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    if (answars?.length !== 0) {
      setManuallyId(
        answars?.find((o) => o?.id === 67)?.Option?.map((item) => item)?.[0]?.id
      );
      // const reduxEthnicityData = reduxQuesAns?.manullEthnicity
      //   ?.filter((o) => o !== '')
      //   ?.map((obj) => ({
      //     title: obj?.title,
      //     selected: obj?.selected,
      //   }));

      // const manuallyBreed = reduxEthnicityData
      //   ? reduxEthnicityData?.map((obj) => obj?.title) || []
      //   : JSON.parse(
      //       answars?.find((o) => o?.id === 67)?.Option?.[0]?.answer
      //     )?.map((obj) => obj?.title) || [];

      // // const manuallyWithBlank = Array.isArray(manuallyBreed)
      // //   ? [...manuallyBreed, '']
      // //   : [''];

      // const manuallyWithBlank = [...manuallyBreed, ''];

      // setManuallyData(manuallyWithBlank);
      // setNumFields(manuallyWithBlank?.length);

      setIsPrivate(
        answars?.find((o) => o?.id === 67)?.Option?.[0]?.private === 0
          ? false
          : true
      );
    }
  }, [answars, reduxQuesAns?.manullEthnicity]);

  const handleBlur = (index, value) => {
    if (numFields === index + 1 && value !== '') {
      setManuallyData([...manuallyData, '']);
      setNumFields(numFields + 1);
    } else {
      setErrors(localesData?.validation?.ITS_CANT_BE_BLANK);
    }
  };

  const handleEthnicityChange = (index, value) => {
    const newEthnicity = [...manuallyData];
    newEthnicity[index] = value;
    setManuallyData(newEthnicity);
    setErrors('');
  };

  const handleOnSubmit = async () => {
    var opt = [];
    var test = [];
    var chipAdd = [];
    manuallyData?.map((item) => {
      if (item !== '') {
        test.push({ title: item, selected: true });
      }
      return true;
    });
    manuallyData?.map((item) => {
      if (item !== '') {
        chipAdd.push({ title: item, selected: false });
      }
      return true;
    });
    opt.push({
      optionId: manuallyId,
      selected: false,
      answer: JSON.stringify(test),
      private: isPrivate,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };

    const matchData = answars
      ?.find((o) => o?.id === 25)
      ?.Option?.some((obj1) =>
        manuallyData?.some(
          (obj2) => obj1?.title?.toUpperCase() === obj2?.toUpperCase()
        )
      );
    if (matchData) {
      setApiMessage('error', localesData?.validation?.DUPLICATE_ERROR);
    } else {
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.post(
          URLS.SET_ANSWERS,
          sendData
        );
        if (status === 200) {
          setApiMessage('success', data?.message);
          navigate('/edit-your-profile', {
            state: '10',
          });
          // navigate('/profile-looking-for', { state: '5' });
          // disatch(addQuestionAnswer({ key: 'manullEthnicity', value: chipAdd }));
          // disatch(
          //   addQuestionAnswer({ key: 'manullEthnicityPrivate', value: isPrivate })
          // );
          // disatch(
          //   addQuestionAnswer({ key: 'manuallyEthnicityId', value: manuallyId })
          // );
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        setApiMessage('error', error?.response?.data?.message);
      }
    }
  };

  const handleChange = (e) => {
    const { checked } = e.target;
    setIsPrivate(checked);
  };

  return (
    <Box>
      <Typography variant='h4' className='color-pink s-bold pb-2 mt-2'>
        {localesData?.profile?.YOUR_ETHNICITY}
      </Typography>
      <Box className='field-sec'>
        {manuallyData?.map((hobby, index) => (
          <Box key={index}>
            <CustomTextField
              fullWidth
              variant='standard'
              className='custom-textfield pb-2'
              name={`hobby_${index}`}
              InputProps={{
                endAdornment:
                  index === numFields - 1 ? (
                    <PlusIcon
                      className='pointer'
                      onClick={() => handleBlur(index, manuallyData[index])}
                    />
                  ) : (
                    <CheckIcon />
                  ),
              }}
              value={hobby}
              onChange={(event) =>
                handleEthnicityChange(index, event.target.value)
              }
              // onBlur={(event) => handleBlur(index, event.target.value)}
            />
          </Box>
        ))}
        {errors && (
          <FormHelperText className='color-error'>{errors}</FormHelperText>
        )}
      </Box>
      <Box className='keep-going-sec'>
        {/* <Box sx={{ justifyContent: 'space-around' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  className='ios-switch'
                  onClick={() =>
                    setToogleDialog(isPrivate ? toogleDialog : !toogleDialog)
                  }
                />
              }
              name='isPrivate'
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {isPrivate ? <LockIcon /> : <UnLockIcon />}
        </Box> */}
        <Box sx={{ justifyContent: 'space-around' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch className='ios-switch' />}
              name='isPrivate'
              checked={isPrivate}
              onChange={(e) => {
                profileData?.isPaid === false
                  ? setToogleDialog(true)
                  : handleChange(e);
              }}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {isPrivate ? <LockIcon /> : <UnLockIcon />}
        </Box>
        <Box className='done-btn-sec '>
          <CustomButton
            variant='contained'
            title={'Done'}
            background={'#59D09D'}
            fontWeight='600'
            classname='done-btn s-bold'
            // onClick={handleNext}
            onClick={() => handleOnSubmit()}
          />
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default YourManuallyEthnicity;
