import React from 'react';
import Page from '../../../components/Page';
import AccountDetails from '../../component/application/ManageProfile/AccountDetails';

const AccountDetailsView = () => {
  return (
    <Page title='Account Details'>
      <AccountDetails />
    </Page>
  );
};

export default AccountDetailsView;
