import React, {
  useContext,
  useEffect,
  useState,
  // useEffect,
  // useRef
} from 'react';
import { Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialApple,
} from 'reactjs-social-login';
import banner1 from '../../../../assets/images/banner1.png';
import LogoUI from '../../UI/LogoComponent/LogoUI';
import CustomButton from '../../UI/Button/CustomButton';
import { ReactComponent as FacebookIcon } from '../../../../assets/images/icons/facebook.svg';
import { ReactComponent as AppleIcon } from '../../../../assets/images/icons/apple.svg';
import { ReactComponent as GoogleIcon } from '../../../../assets/images/icons/google.svg';

import { IntlContext } from '../../../../App';
import { Config, saveToStorage } from '../../../../helpers/context';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { identifiers } from '../../../../helpers/constants/identifier';
import { addAuth } from '../../../../helpers/store/actions/auth';
import PreLoader from '../../../../components/Loader';
import { clearProfileData } from 'src/helpers/store/actions/profileSetup';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import { clearSvgData } from 'src/helpers/store/actions/progressImage';
import './welcome.scss';

const Welcome = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  //GET ALL ANSWER
  const getAllAnswars = async (token) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`,
          'Content-Type': 'application/json',
        },
      });
      if (status === 200) {
        setApiMessage('success', data?.message);
        var all_ans = data?.questions;

        var selectedCount = 0;
        all_ans?.map((itm) => {
          if (itm?.id === 74) {
            itm?.Option?.map((item) => {
              if (
                item?.answer === true ||
                item?.answer === '1' ||
                item?.answer === 1
              ) {
                selectedCount++;
              }
              return true;
            });
          }
          return true;
        });
        if (selectedCount === 0) {
          redirectNow(token, true);
        } else {
          redirectNow(token, false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const redirectNow = async (token, userType) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`,
          'Content-Type': 'application/json',
        },
      });
      if (status === 200) {
        setTimeout(() => {
          // if (userType) {
          //   setTimeout(() => {
          //     navigate('/profile-setup');
          //     dispatch(handleNext(0));
          //   }, 100);
          // } else
          if (!data?.images || data?.images.length === 0) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(1));
            }, 100);
          } else if (
            data?.dob === '' ||
            data?.dob === null ||
            data?.dob === 'null'
          ) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(2));
            }, 100);
          } else if (
            data?.gender === '' ||
            data?.gender === null ||
            data?.gender === 'null'
          ) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(3));
            }, 100);
          } else if (
            data?.looking_for === '' ||
            data?.looking_for === null ||
            data?.looking_for === 'null'
          ) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(4));
            }, 100);
          } else {
            setTimeout(() => {
              dispatch(handleNext(0));
              navigate('/');
            }, 100);
          }
          setLoader(false);
        }, 500);
        saveToStorage(identifiers?.USER_DATA, data);
        saveToStorage(identifiers?.AUTH_DATA, token);
        saveToStorage(identifiers?.VERIFIED, true);
        dispatch(addAuth(token));
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const socialLoginHandler = async (response, type) => {
    const requestData = {
      access_token:
        type === 'facebook'
          ? response?.data?.accessToken
          : type === 'apple-web'
          ? response?.access_token
          : response?.data?.access_token,
    };
    if (requestData?.access_token) {
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.post(
          URLS.SIGN_IN + `?type=${type}`,
          requestData
        );

        if (status === 200) {
          if (data?.access_token) {
            // saveToStorage(identifiers?.AUTH_DATA, data);
            // dispatch(addAuth(data));
            // navigate('/');
            // getAllAnswars(data);
            // setTimeout(() => {
            checkForFirstAndLastName(type, data, response?.data);
            // }, 200);
          }
          setApiMessage('success', data?.message);
          setLoader(false);
        }
      } catch (error) {
        setApiMessage('error', error?.response?.data?.message);
        setLoader(false);
      }
    }
  };

  const handleAppleLoginSuccess = async (response) => {
    if (!response?.error) {
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.post(
          URLS.APPLE_LOGIN,
          response
        );
        if (status === 200) {
          setLoader(false);
          checkForFirstAndLastName('apple-web', data, data);
        }
      } catch (error) {
        setApiMessage('error', error?.response?.data?.message);
        setLoader(false);
      }
    }
  };

  const checkForFirstAndLastName = async (type, token, response) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (status === 200) {
        let api_user_data = data;
        let first_name = api_user_data?.firstname;
        let last_name = api_user_data?.lastname;
        if (
          first_name !== null &&
          first_name?.length !== 0 &&
          last_name !== null &&
          last_name?.length !== 0
        ) {
          getAllAnswars(token);

          // navigate('/');
        } else {
          if (type === 'facebook') {
            saveData(type, token, response);
          } else if (type === 'web') {
            saveData(type, token, response);
          } else if (type === 'apple-web') {
            saveData(type, token, response);
          }
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
      setLoader(false);
    }
  };

  const saveData = async (type, token, response) => {
    var sendData = {};
    if (type === 'web') {
      sendData = {
        firstname: response?.given_name ? response?.given_name : '',
        lastname: response?.family_name ? response?.family_name : '',
        email: response?.email ? response?.email : '',
      };
    } else if (type === 'facebook') {
      sendData = {
        firstname: response.first_name ? response.first_name : '',
        lastname: response.last_name ? response.last_name : '',
        email: response.email ? response.email : '',
      };
    } else if (type === 'apple-web') {
      sendData = {
        // firstname: response?.fullName?.givenName
        //   ? response?.fullName?.givenName
        //   : '',
        // lastname: response?.fullName?.familyName
        //   ? response?.fullName?.familyName
        //   : '',
        // email: response?.email ? response?.email : '',
      };
    }
    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, sendData, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`,
          'Content-Type': 'application/json',
        },
      });
      if (status === 200) {
        // navigate('/');
        saveToStorage(identifiers?.VERIFIED, true);
        getAllAnswars(token);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    dispatch(clearProfileData());
    dispatch(clearSvgData());
  }, [dispatch]);
  return (
    <Box className='auth-wc-main-container'>
      {loader && <PreLoader />}
      <Box className='login-content'>
        <Box className='logo-content'>
          <LogoUI color='white' />
        </Box>
        <Box className='wc-btn-content'>
          <LoginSocialFacebook
            appId={Config.FacebookAppID || ''}
            fieldsProfile={
              'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
            }
            redirect_uri={Config.redirectURL}
            onResolve={(user) => {
              if (user) {
                socialLoginHandler(user, 'facebook');
              }
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <CustomButton
              variant='contained'
              leftIcon={<FacebookIcon className='social-icon' />}
              title={localesData?.wc?.LOGIN_WITH_FACEBOOK}
              background='#3b66c4'
              fontWeight='400'
            />
          </LoginSocialFacebook>

          <LoginSocialApple
            client_id={Config?.AppleAppID || ''}
            scope={'name email'}
            redirect_uri={Config?.redirectURL}
            onResolve={(data) => {
              handleAppleLoginSuccess(data?.data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <CustomButton
              variant='contained'
              leftIcon={<AppleIcon className='social-icon' />}
              title='LOGIN WITH APPLE'
              background='#000000'
              fontWeight='400'
            />
          </LoginSocialApple>

          <LoginSocialGoogle
            client_id={Config.GoogleAppID}
            discoveryDocs='claims_supported'
            access_type='offline'
            redirect_uri={Config.redirectURL}
            scope='openid profile email'
            onResolve={(user) => {
              if (user) {
                socialLoginHandler(user, 'web');
              }
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <CustomButton
              variant='contained'
              leftIcon={<GoogleIcon className='social-icon' />}
              title={localesData?.wc?.LOGIN_WITH_GOOGLE}
              background='#DB4437'
              fontWeight='400'
            />
          </LoginSocialGoogle>

          <Box className='or-content'>
            <span className='or-line'></span>
            <Typography variant='h6' className='or-text'>
              {localesData?.wc?.OR}
            </Typography>
            <span className='or-line'></span>
          </Box>

          <CustomButton
            variant='text'
            title={localesData?.wc?.LOGIN_WITH_YOUR_PHONE_NUMBER}
            color='#ffffff'
            fontWeight='600'
            onClick={() => navigate('/sign-in')}
          />

          <Box className='login-bottom-link'>
            <Typography variant='h5' className='pointer'>
              <Link
                to='https://puppilovers.com/terms-conditions/'
                target='_blank'
              >
                {localesData?.wc?.TERMS_AND_CONDITIONS}
              </Link>
            </Typography>

            <Link to='https://puppilovers.com/privacy-policy/' target='_blank'>
              <Typography variant='h5' className='pointer'>
                {localesData?.wc?.PRIVACY_POLICY}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box className='login-banner-content'>
        <img src={banner1} alt='' />
      </Box>
    </Box>
  );
};

export default Welcome;
