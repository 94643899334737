import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as PlusIcon } from 'src/assets/images/icons/plus.svg';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import { CustomTextField } from 'src/views/component/UI/textfield';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestionAnswer } from 'src/helpers/store/actions/questionAnswerSlice';
import UpgradePlan from '../UpgradePlan';
// import { clearQuestionAnswer } from 'src/helpers/store/actions/questionAnswerSlice';
// import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
// import { ReactComponent as UnLockIcon } from 'src/assets/images/icons/unlock.svg';

const AboutDogBreed = ({
  hideUpgradePlan,
  localesData,
  setLoader,
  answers,
  navigate,
}) => {
  const disatch = useDispatch();
  const reduxQuesAns = useSelector((state) => state?.questionAnswer);
  const [selectedChips, setSelectedChips] = useState([]);
  const [dogData, setDogData] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [manuallyId, setManuallyId] = useState(0);

  useEffect(() => {
    if (answers?.length !== 0) {
      const dogBreed = answers?.find((o) => o?.id === 55)?.Option || [];
      const reduxDogData = reduxQuesAns?.manullDog
        ?.filter((o) => o !== '')
        ?.map((obj) => ({
          title: obj?.title,
          selected: obj?.selected,
        }));
      setIsPrivate(
        answers?.find((o) => o?.id === 55)?.Option?.[0]?.isMust === 1
          ? true
          : false
      );
      const manuallyDogBreed = reduxDogData
        ? reduxDogData
        : JSON.parse(answers?.find((o) => o?.id === 75)?.Option[0]?.answer) ||
          [];

      const totalItems = [...manuallyDogBreed, ...dogBreed];

      setDogData(totalItems);
      // setDogData(dogBreed);
      setManuallyId(
        answers?.find((o) => o?.id === 75)?.Option?.map((item) => item)?.[0]?.id
      );
      setSelectedChips(
        totalItems?.filter(
          (item) => item?.selected === 1 || item?.selected === true
        )
      );
    }
    // eslint-disable-next-line
  }, [answers]);

  const handleChipClick = (chip) => {
    if (!selectedChips?.some((obj) => obj?.title === chip?.title)) {
      setSelectedChips([...selectedChips, chip]);
    } else {
      setSelectedChips((chips) =>
        chips?.filter((obj) => obj?.title !== chip?.title)
      );
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    dogData
      ?.filter((o) => o?.id !== undefined)
      ?.map((item) => {
        opt.push({
          optionId: item.id,
          selected: selectedChips
            ?.map((obj) => obj?.title)
            ?.includes(item?.title)
            ? true
            : false,
        });
        return true;
      });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveInfo();
        saveManuallyData();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const saveInfo = async () => {
    var opt = [];

    dogData
      ?.filter((o) => o?.id !== undefined)
      ?.map((item) => {
        opt.push({
          optionId: item.id,
          selected: selectedChips
            ?.map((obj) => obj?.title)
            ?.includes(item?.title)
            ? true
            : false,
          lookingFor: true,
          isMust: isPrivate ? true : false,
        });
        return true;
      });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        navigate('/edit-profile', {
          state: 'looking-for',
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const saveManuallyData = async () => {
    var opt = [];
    var test = [];
    var manuallyData = dogData?.filter((o) => o?.id === undefined);

    manuallyData?.map((item) => {
      test.push({
        title: item?.title,
        selected: selectedChips?.map((obj) => obj?.title)?.includes(item?.title)
          ? true
          : false,
      });
      return true;
    });

    opt.push({
      optionId: manuallyId,
      selected: false,
      answer: JSON.stringify(test),
      private: isPrivate,
      lookingFor: true,
    });
    let sendData = {
      private: false,
      optionIds: opt,
    };

    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        disatch(addQuestionAnswer({ key: 'manullDog', value: undefined }));
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      <Box className=''>
        <Box className='chip-container-box'>
          {hideUpgradePlan && (
            <Box>
              <UpgradePlan
                title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
              />
              <Box className='b-bottom mt-4 mb-4' />
            </Box>
          )}
          <Typography variant='h3' className='mb-2 t-center s-bold'>
            {localesData?.profile?.DOG_BREED}
          </Typography>
          <Typography
            variant='h4'
            className='mb-3 t-center d-flex align-center justify-center'
          >
            <span style={{ marginRight: '16px' }}>
              {localesData?.profile?.ADD_BREED}
            </span>
            <PlusIcon
              className='pointer'
              onClick={() =>
                navigate('/profile-looking-for', { state: 'manuallyDogBreed' })
              }
            />
          </Typography>
          <CustomTextField
            variant='standard'
            className='custom-textfield pb-3'
            placeholder='search'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
        {/* <Box className='pb-14 chip-container-box'>
          <Box className='chip-container mb-2'> */}
        <Box className='looking-for-chip'>
          <Box className='chip-container'>
            {dogData
              ?.filter((item) =>
                item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
              )
              ?.map((item, i) => (
                <Chip
                  className={`chip-inner font-r-11 ${
                    selectedChips
                      ?.map((obj) => obj?.title)
                      ?.includes(item?.title)
                      ? 'chip-active'
                      : ''
                  } `}
                  key={i}
                  label={item?.title}
                  onClick={() => handleChipClick(item)}
                />
              ))}
            {dogData &&
              dogData?.filter((item) =>
                item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
              )?.length === 0 && (
                <Typography variant='h5' className='t-center disable-color'>
                  {localesData?.validation?.NO_SEARCH_RESULT_FOUND}
                </Typography>
              )}
          </Box>
          <Box className='edit-your-profile-box'>
            <Box className='btn-fixed-bottom'>
              <Box className='d-flex pb-4 justify-center'>
                <FormGroup>
                  <FormControlLabel
                    control={<IOSSwitch className='ios-switch' />}
                    name='isPrivate'
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                  />
                </FormGroup>
                <Typography variant='h5'>
                  {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
                </Typography>
                {/* {isPrivate ? <UnLockIcon /> : <LockIcon />}  */}
              </Box>
              <Box className='t-center m-auto' sx={{ maxWidth: '134px' }}>
                <CustomButton
                  classname='uppercase'
                  variant='contained'
                  title={localesData?.DONE}
                  background='#59D09D'
                  fontWeight='600'
                  onClick={handleOnSubmit}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutDogBreed;
