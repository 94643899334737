import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
} from '@mui/material';
// import CustomButton from "../Button/CustomButton";
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/dialog-close-icon.svg';
import './dogGallery.scss';

const Gallery = ({ handleClose, open, title, noTitle, content, className }) => {
  return (
    <Dialog
      className={`custom-dialog ${className}`}
      maxWidth='md'
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
    >
      <Box className='dialog-content-box'>
        <Box className='mt-3 mb-4'>
          <IconButton className='dialog-close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <DialogTitle>
            <Box className='custom-dialog-title'>
              <Typography className='dialog-title'>
                {noTitle ? '' : title}
              </Typography>
            </Box>
          </DialogTitle>
        </Box>
      </Box>
      {content}
    </Dialog>
  );
};

export default Gallery;
