import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { IntlContext } from 'src/App';
import { AppBar, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as BackArrow } from '../../assets/images/icons/back-arrow.svg';

import { handleNext } from '../../helpers/store/actions/navigateSlice';
import { useLocation } from 'react-router-dom';
import { setApiMessage } from 'src/helpers/commonFunctions';
// import { clearProfileData } from 'src/helpers/store/actions/profileSetup';

const TopBar = ({ auth, className, navigate, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const reduxBackStep = useSelector((state) => state?.page_navigate?.value);

  const backStep = reduxBackStep;
  const isSignIn = location.pathname === '/sign-in';

  const handleBack = () => {
    if (backStep !== 0) {
      dispatch(handleNext(backStep - 1));
      // dispatch(clearProfileData());
    } else if (isSignIn) {
      navigate('/');
    } else if (location.pathname.includes('/otp/')) {
      setApiMessage('error', localesData?.otp?.error_verify);
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar
      className={clsx(className, 'app-bar')}
      elevation={0}
      position='static'
      {...rest}
    >
      <Toolbar>
        <BackArrow className='pointer' onClick={() => handleBack()} />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
