import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import UserProfileEditor from 'src/views/component/UI/UserProfileEditor';
import ProgressBar from 'src/views/component/UI/ProgressBar';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { ReactComponent as Setting } from 'src/assets/images/icons/settings.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/images/icons/profileicon.svg';
import { ReactComponent as Plus } from 'src/assets/images/icons/plus.svg';
import { ReactComponent as Notifications } from 'src/assets/images/icons/notifications.svg';
import { ReactComponent as Help } from 'src/assets/images/icons/help.svg';
import { ReactComponent as Search } from 'src/assets/images/icons/search.svg';
import { ReactComponent as Male } from 'src/assets/images/icons/male.svg';
import { ReactComponent as Female } from 'src/assets/images/icons/female.svg';
import { ReactComponent as Other } from 'src/assets/images/icons/other.svg';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { Config, fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useDispatch } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import BottomFooter from 'src/views/component/UI/BottomFooter';
import { useNavigate } from 'react-router-dom';
import PreLoader from 'src/components/Loader';
import SubDialogbox from 'src/views/component/UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from 'src/views/component/UI/Subscriptionpopup/SubscriptionDialog';
import { addProfileData } from 'src/helpers/store/actions/profileSetup';
import { IntlContext } from 'src/App';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import { clearSvgData } from 'src/helpers/store/actions/progressImage';

const ProfileEditing = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  // const reduxProgress = useSelector((state) => state?.progressBarData);
  const [loader, setLoader] = useState(false);
  const [wEditProfile, setWEditProfile] = useState(0);
  const [wWhatAreYouLookingFor, setWWhatAreYouLookingFor] = useState(0);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [progressWeightage, setProgressWeightage] = useState(0);
  // const reduxDogData = useSelector((state) => state?.profileData);
  // const reduxStep = useSelector((state) => state?.page_navigate?.value);

  const profileEditList = [
    {
      id: 1,
      label: 'Add / Edit Your Dog Profiles',
      icons: Plus,
      link: '/dog-profile',
    },
    {
      id: 2,
      label: 'What You Are Looking For',
      icons: Search,
      link: '/edit-profile',
      locationState: 'looking-for',
      incre: wWhatAreYouLookingFor,
    },
    {
      id: 3,
      label: 'Edit Your Profile ',
      icons: ProfileIcon,
      link: '/edit-profile',
      locationState: 'profile-edit',
      incre: wEditProfile,
    },
    {
      id: 4,
      label: 'Account Details',
      icons: Setting,
      link: '/account-details',
    },

    {
      id: 5,
      label: 'Notifications',
      icons: Notifications,
      link: '/notification',
    },
    {
      id: 6,
      label: 'Help Centre',
      icons: Help,
      link: '/help-center',
    },
  ];

  const profile_image =
    profileData?.images?.length === 0 ? '' : profileData?.images?.[0].url;

  const getAllQuestions = async () => {
    var black_data = 0;
    var edit_profile_black_data = 0;
    var what_are_you_looking_for_black_data = 0;
    var job = true;
    var education = true;
    var height_weight = true;
    var vices = true;
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      if (status === 200) {
        data?.questions?.map((item) => {
          // if (item.id === 8) {
          //   black_data++; //looking for
          //   edit_profile_black_data++;
          // }
          if (
            item.id === 9 ||
            item.id === 10 ||
            item.id === 11 ||
            item.id === 12
          ) {
            if (job) {
              black_data++; //job
              edit_profile_black_data++;
              job = false;
            }
          }
          if (item.id === 13 || item.id === 14 || item.id === 15) {
            if (education) {
              black_data++; //education
              education = false;
              edit_profile_black_data++;
            }
          }
          if (item.id === 16) {
            black_data++; //hobbies
            edit_profile_black_data++;
          }
          if (item.id === 17) {
            black_data++; //religion
            edit_profile_black_data++;
          }
          if (item.id === 18) {
            black_data++; //politics
            edit_profile_black_data++;
          }
          if (item.id === 19) {
            black_data++; //children
            edit_profile_black_data++;
          }
          if (item.id === 20) {
            black_data++; //how many kids
            edit_profile_black_data++;
          }
          if (item.id === 23 || item.id === 24) {
            if (height_weight) {
              black_data++; //height weight
              edit_profile_black_data++;
              height_weight = false;
            }
          }
          if (item.id === 25) {
            black_data++; //ethnicity
            edit_profile_black_data++;
          }
          if (item.id === 26) {
            black_data++; //family plan
            edit_profile_black_data++;
          }
          if (
            item.id === 28 ||
            item.id === 29 ||
            item.id === 30 ||
            item.id === 31
          ) {
            if (vices) {
              black_data++; //vices
              edit_profile_black_data++;
              vices = false;
            }
          }
          if (item.id === 33) {
            black_data++; //location
            edit_profile_black_data++;
          }
          if (item.id === 2) {
            what_are_you_looking_for_black_data++;
            black_data++; //about gender
          }
          if (item.id === 54) {
            what_are_you_looking_for_black_data++;
            black_data++; //about age
          }
          if (item.id === 55) {
            what_are_you_looking_for_black_data++;
            black_data++; //about dog breed
          }
          if (item.id === 56) {
            what_are_you_looking_for_black_data++;
            black_data++; //about job
          }
          if (item.id === 57) {
            what_are_you_looking_for_black_data++;
            black_data++; //about education
          }
          if (item.id === 58) {
            what_are_you_looking_for_black_data++;
            black_data++; //about hobbies
          }
          if (item.id === 59) {
            what_are_you_looking_for_black_data++;
            black_data++; //about religion
          }
          if (item.id === 60) {
            what_are_you_looking_for_black_data++;
            black_data++; //about politics
          }
          if (item.id === 61) {
            what_are_you_looking_for_black_data++;
            black_data++; //about height weight
          }
          if (item.id === 62) {
            what_are_you_looking_for_black_data++;
            black_data++; //about ethnicity
          }
          if (item.id === 63) {
            what_are_you_looking_for_black_data++;
            black_data++; //about vices
          }
          if (item.id === 64) {
            what_are_you_looking_for_black_data++;
            black_data++; //about location
          }
          return true;
        });
        setTimeout(() => {
          setWNow(
            black_data,
            edit_profile_black_data,
            what_are_you_looking_for_black_data
          );
          setLoader(false);
        }, 100);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const checkForBlankData = async () => {
    try {
      // setLoading(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;

      // if (response.data != null) {
      if (status === 200) {
        // setLoading(false);
        dispatch(clearSvgData());
        data?.questions.map((item) => {
          if (breakIt === false) {
            // if (item.id === 8) {
            //   breakIt = true;
            //   // navigate('/profile-completion-advance', { state: 'isEdit' });
            //   // dispatch(handleNext(0));
            //   navigate('/edit-description');
            //   // navigate('/edit-description');
            //   // navigate('/profile-completion');
            //   // dispatch(handleNext(6));
            // } else
            if (
              item.id === 9 ||
              item.id === 10 ||
              item.id === 11 ||
              item.id === 12
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(1));
            } else if (item.id === 13 || item.id === 14 || item.id === 15) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(2));
            } else if (item.id === 16) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(3));
            } else if (item.id === 17) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(4));
            } else if (item.id === 18) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(5));
            } else if (item.id === 19) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(6));
            } else if (item.id === 20) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext('otherKids'));
            } else if (item.id === 23 || item.id === 24) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(7));
            } else if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            }
            if (item.id === 2) {
              breakIt = true; //about gender
              navigate('/profile-looking-for', {
                state: '1',
              });
            } else if (item.id === 54) {
              breakIt = true; //about age
              navigate('/profile-looking-for', {
                state: '2',
              });
            } else if (profileData?.isPaid) {
              if (item.id === 55) {
                breakIt = true; //about dog breed
                navigate('/profile-looking-for', {
                  state: '3',
                });
              } else if (item.id === 56) {
                breakIt = true; //about job
                navigate('/profile-looking-for', {
                  state: '4',
                });
              } else if (item.id === 57) {
                breakIt = true; //about education
                navigate('/profile-looking-for', {
                  state: '5',
                });
              } else if (item.id === 58) {
                breakIt = true; //about hobbies
                navigate('/profile-looking-for', {
                  state: '6',
                });
              } else if (item.id === 59) {
                breakIt = true; //about religion
                navigate('/profile-looking-for', {
                  state: '7',
                });
              } else if (item.id === 60) {
                breakIt = true; //about politics
                navigate('/profile-looking-for', {
                  state: '8',
                });
              } else if (item.id === 61) {
                breakIt = true; //about height weight
                navigate('/profile-looking-for', {
                  state: '9',
                });
              } else if (item.id === 62) {
                breakIt = true; //about ethnicity
                navigate('/profile-looking-for', {
                  state: '10',
                });
              } else if (item.id === 63) {
                breakIt = true; //about vices
                navigate('/profile-looking-for', {
                  state: '11',
                });
              } else if (item.id === 64) {
                breakIt = true; //about location
                navigate('/profile-looking-for', {
                  state: '12',
                });
              }
            } else {
              setToogleDialog(!toogleDialog);
            }
          }
          return true;
        });
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllQuestions();
    getProfile();
    // eslint-disable-next-line
  }, []);

  const getProfile = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        saveToStorage(identifiers?.USER_DATA, data);
        setLoader(false);
        dispatch(
          addProfileData({
            key: 'User_images',
            value: [identifiers?.USER_DATA, data],
          })
        );
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const setWNow = async (
    black_data,
    edit_profile_blank_data,
    what_are_you_looking_for_black_data
  ) => {
    var _black_data = (25 - black_data) * 4;
    var _edit_profile_blank_data = (13 - edit_profile_blank_data) * 7.7;
    var _what_are_you_looking_for_black_data =
      (12 - what_are_you_looking_for_black_data) * 8.35;

    // var w = await AsyncStorage.getItem('w');
    // var _w = Number(_black_data);
    // var _w_edit = Number(_edit_profile_blank_data);
    // var _w_what_are_you = Number(_what_are_you_looking_for_black_data);

    setProgressWeightage(_black_data);
    setWEditProfile(Math.floor(_edit_profile_blank_data));
    setWWhatAreYouLookingFor(Math.floor(_what_are_you_looking_for_black_data));
  };

  const ProfileView = () => {
    navigate('/user-profile');
  };

  return (
    <>
      {loader && <PreLoader />}
      <Box>
        <Box className='main-page-content-wrap'>
          <Box className='d-flex align-center space-between mt-2'>
            <Typography variant='h6' className='color-pink'>
              {localesData?.manage_profile?.YOUR_PROFILE}
            </Typography>
            <Box>
              <Button
                className='pink-outline-btn  color-pink'
                onClick={() => navigate(`/profile-preview/${profileData?.id}`)}
              >
                {localesData?.manage_profile?.PREVIEW_PROFILE}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className='profile-bg dog-profile-wrap mb-5 pb-5'>
        <UserProfileEditor
          src={
            profile_image === '' ? (
              profileData?.gender === 2 ? (
                <Male />
              ) : profileData?.gender === 3 ? (
                <Female />
              ) : (
                <Other />
              )
            ) : (
              profile_image
            )
            // all_images
          }
          handlePencilIconClick={() => ProfileView()}
        />
        <Box className='t-center dog-name-edit pb-1'>
          <Typography className='font-r-12'>
            {profileData?.firstname}
          </Typography>
        </Box>
        <Divider className='divider pb-2' />
        <Box className='t-center pb-2'>
          <Typography variant='h6' className='font-r-12 pb-2'>
            {localesData?.profile_completion_user?.PROFILE_COMPLETION}{' '}
            <span className='color-pink bold'>
              {/* {reduxProgress}% */}
              {progressWeightage}%
            </span>
          </Typography>
          <Box className='pb-2'>
            <ProgressBar processData={progressWeightage} />
          </Box>
          {progressWeightage === 100 ? null : (
            <Box className=' continue-btn-sec'>
              <CustomButton
                variant='contained'
                title={localesData?.CONTINUE}
                background='#F54D71'
                // onClick={() => navigate('/profile-completion-advance',{ state: 'isEdit' })}
                onClick={() => checkForBlankData()}
                fontWeight='600'
                classname='continue-btn s-bold'
              />
            </Box>
          )}
          <Box className='pb-2'>
            <Divider className='divider ' />
          </Box>

          <Typography className='font-r-12 pb-2'>
            {localesData?.profile_notification?.ACCOUNT_TYPE}{' '}
            <span className='s-bold'>
              -{' '}
              {profileData?.isPaid === false
                ? localesData?.FREE
                : profileData?.subscription?.duration
                ? `${profileData?.subscription?.duration} paid`
                : localesData?.PAID}
            </span>
          </Typography>

          <Box className='continue-btn-sec'>
            <CustomButton
              variant='contained'
              title={
                profileData?.isPaid
                  ? localesData?.VIEW_YOUR_PLAN
                  : localesData?.UPGRADE_NOW
              }
              background='#6A42ED'
              fontWeight='600'
              onClick={() => {
                profileData?.isPaid
                  ? navigate('/subscription-plan')
                  : setToogleDialog(!toogleDialog);
              }}
              classname='continue-btn s-bold'
            />
          </Box>
          <Divider className='divider' />
        </Box>
        <Box>
          {profileEditList?.map((item, i) => {
            return (
              <Box
                key={i}
                className='d-flex align-center space-between pb-3 font-r-14 pointer'
                onClick={() =>
                  navigate(item?.link, { state: item?.locationState })
                }
              >
                {/* <Link
                  to={item?.l}
                  className='d-flex align-center  space-between pb-3 font-r-14 text-decor'
                > */}
                <Typography>
                  {item?.label}{' '}
                  {item.incre !== undefined && (
                    <span className='color-pink bold'>{item.incre}%</span>
                  )}
                </Typography>
                <Box>
                  <item.icons />
                </Box>
                {/* </Link> */}
              </Box>
            );
          })}
          <Typography variant='h5' className='color-pink t-center '>
            {Config?.version}
          </Typography>
        </Box>
      </Box>
      <Box className='pb-4'>
        <BottomFooter />
      </Box>
      <SubDialogbox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </>
  );
};

export default ProfileEditing;
