import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { IntlContext } from '../../../../../App';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import ProgressBar from '../../../UI/ProgressBar';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import IOSSwitch from '../../../UI/IOSSwitch';
import { CustomTextField } from '../../../UI/textfield';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';

const UserEducation = ({
  handleNext,
  reduxProgress,
  questions,
  setLoader,
  dispatch,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [empData, setEmpData] = useState('');
  const [achiveData, setAchiveData] = useState('');
  const [doYouFinish, setDoYouFinish] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [empIsPrivate, setEmpIsPrivate] = useState(false);
  const [achivePrivate, setAchivePrivate] = useState(false);
  const [doYouFinishPrivate, setDoYouFinishPrivate] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(dayjs(date));
  };

  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item?.q_order === 10) {
  //         setEmpData({
  //           title: item?.title,
  //           id: item?.id,
  //           options: item?.Option,
  //         });
  //       }
  //       if (item.q_order === 11) {
  //         setAchiveData({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 12) {
  //         setDoYouFinish({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //     });
  // }, [questions]);

  useEffect(() => {
    const question = questions?.find((o) => o?.id === 2);
    const empAns = answars?.find((o) => o?.id === 13);
    const achiveAns = answars?.find((o) => o?.id === 14);
    const doYouFinishAns = answars?.find((o) => o?.id === 15);

    const selectedOption = empAns?.Option.find((option) => option.selected);
    setSelectedChips(selectedOption ? [selectedOption] : []);
    setEmpIsPrivate(
      empAns?.private === '1' || empAns?.private === 1 ? true : false
    );
    setAchivePrivate(
      achiveAns?.private === '1' || achiveAns?.private === 1 ? true : false
    );
    setDoYouFinishPrivate(
      doYouFinishAns?.private === '1' || doYouFinishAns?.private === 1
        ? true
        : false
    );

    doYouFinishAns?.Option?.[0].answer
      ? setSelectedDate(dayjs(doYouFinishAns?.Option?.[0].answer))
      : setSelectedDate('');

    question?.Question?.forEach((item) => {
      switch (item?.q_order) {
        case 10:
          setEmpData({
            title: item?.title,
            id: item?.id,
            options: item?.Option,
            private:
              item?.private === '1' || item?.private === 1 ? true : false,
          });
          break;
        case 11:
          setAchiveData({
            title: item?.title,
            id: item?.id,
            options: item?.Option,
            private:
              item?.private === '1' || item?.private === 1 ? true : false,
          });
          break;
        case 12:
          setDoYouFinish({
            title: item?.title,
            id: item?.id,
            options: item?.Option,
            private:
              item?.private === '1' || item?.private === 1 ? true : false,
          });
          break;
        default:
          break;
      }
    });
  }, [questions, answars]);

  useEffect(() => {
    answars?.forEach((item) => {
      if (item.id === 13) {
        setEmpData({
          title: item.title,
          id: item.id,
          options: item.Option,
          private: item?.private === '1' || item?.private === 1 ? true : false,
        });
      }
      if (item.id === 14) {
        setAchiveData({
          title: item.title,
          id: item.id,
          options: item.Option,
          answer: item.answer,
          private: item?.private === '1' || item?.private === 1 ? true : false,
        });
      }
      if (item.id === 15) {
        setDoYouFinish({
          title: item.title,
          id: item.id,
          options: item.Option,
          answer: item?.Option?.[0].answer,
          private: item?.private === '1' || item?.private === 1 ? true : false,
        });
        // Set the selected date for the date picker
        // setSelectedDate(new Date(item?.Option?.[0].answer));
      }
    });
  }, [answars]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'empDataSwitch') {
      setEmpData({ ...empData, private: checked });
      setEmpIsPrivate(checked);
    }
    if (name === 'achiveData') {
      setAchiveData({
        ...achiveData,
        options: [
          {
            ...achiveData.options[0],
            answer: value,
          },
          ...achiveData.options.slice(1),
        ],
      });

      // setAchiveData({ ...achiveData, answer: value });
    }
    if (name === 'achiveDataSwitch') {
      setAchiveData({ ...achiveData, private: checked });
      setAchivePrivate(checked);
    }
    if (name === 'doYouFinish') {
      setDoYouFinish({ ...doYouFinish, answer: value });
    }
    if (name === 'doYouFinishSwitch') {
      setDoYouFinish({ ...doYouFinish, private: checked });
      setDoYouFinishPrivate(checked);
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    opt.push({
      optionId: empData?.options[0]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[0]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[0]?.id)
        ? true
        : false,
      private: empData?.private,
    });
    opt.push({
      optionId: empData?.options[1]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[1]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[1]?.id)
        ? true
        : false,
      private: empData?.private,
    });
    opt.push({
      optionId: empData?.options[2]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[2]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[2]?.id)
        ? true
        : false,
      private: empData?.private,
    });
    opt.push({
      optionId: empData?.options[3]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[3]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[3]?.id)
        ? true
        : false,
      private: empData?.private,
    });

    opt.push({
      optionId: achiveData?.options?.[0]?.id,
      answer: achiveData?.options?.[0]?.answer
        ? achiveData?.options?.[0]?.answer
        : null,
      selected: false,
      private: achiveData?.private ? achiveData?.private : false,
    });

    opt.push({
      optionId: doYouFinish?.options?.[0]?.id,
      answer: selectedDate ? dayjs(selectedDate).format('YYYY') : '',
      selected: false,
      private: doYouFinish?.private ? doYouFinish?.private : false,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        if (isEdit) {
          // checkForBlankData();
          checkForBlankAnsData();
        } else {
          handleNext();
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([chip]);
    }
  };

  const checkForBlankAnsData = async () => {
    try {
      setLoader(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;
      if (status === 200) {
        data?.questions.map((item) => {
          if (breakIt === false) {
            if (item.id === 16) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(3));
            } else if (item.id === 17) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(4));
            } else if (item.id === 18) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(5));
            } else if (item.id === 19) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(6));
            } else if (item.id === 20) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext('otherKids'));
            } else if (item.id === 23 || item.id === 24) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(7));
            } else if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Box className='t-center user-education'>
      <Box>
        <Typography
          className='mb-2 font-r-12'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
          }}
        />
        <Box className='mb-3'>
          <Typography variant='h4' className='pb-1'>
            {/* {reduxProgress}% */}
            {progressWeightage}%
          </Typography>
          <ProgressBar processData={progressWeightage} />
        </Box>
        <Typography variant='h3' className='s-bold pb-2'>
          {localesData?.userwork?.Now_its_all_about_you}
        </Typography>
        <Typography variant='h4' className='pb-4'>
          {
            localesData?.userwork
              ?.now_that_we_know_where_you_work_how_about_your_education
          }
        </Typography>
        <Typography variant='h3' className='s-bold pb-4'>
          {empData?.title}
        </Typography>
      </Box>
      <Box className='d-flex chip-block mb-5 chip-box'>
        {empData?.options?.map((item, i) => {
          return (
            <Chip
              className={`chip-inner font-r-10 ${
                selectedChips?.[0] === item ? 'chip-active' : ''
              } `}
              key={i}
              label={item?.title}
              onClick={() => handleChipClick(item)}
            />
          );
        })}
      </Box>
      <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch />}
            name='empDataSwitch'
            checked={empIsPrivate}
            // onChange={(e) => {
            //   profileData?.isPaid === false
            //     ? setToogleDialog(true)
            //     : handleChange(e);
            // }}
            onChange={(e) => {
              if (isEdit) {
                profileData?.isPaid === false
                  ? setToogleDialog(true)
                  : handleChange(e);
              } else {
                handleChange(e);
              }
            }}
          />
        </FormGroup>
        <Typography variant='h5'>
          {localesData?.userwork?.thats_private_dont_show_this}
        </Typography>
        {empIsPrivate ? <LockIcon /> : <UnLockIcon />}
      </Box>
      <Box sx={{ textAlign: 'left' }} className='pb-6'>
        <Box className='field-content'>
          <CustomTextField
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            value={achiveData?.options?.[0]?.answer}
            name='achiveData'
            onChange={(e) => handleChange(e)}
          />
        </Box>
        <Box>
          <Typography variant='h5' className='s-bold pb-2'>
            {achiveData?.title}
          </Typography>
          <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                name='achiveDataSwitch'
                checked={achivePrivate}
                // onChange={(e) => {
                //   profileData?.isPaid === false
                //     ? setToogleDialog(true)
                //     : handleChange(e);
                // }}
                onChange={(e) => {
                  if (isEdit) {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  } else {
                    handleChange(e);
                  }
                }}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {achivePrivate ? <LockIcon /> : <UnLockIcon />}
          </Box>
        </Box>
        <Box className='custom-date-picker date-picker'>
          {/* <CustomTextField
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            name='doYouFinish'
            onChange={(e) => handleChange(e)}
          /> */}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              views={['year']}
              value={selectedDate}
              className='custom-textfield pb-2'
              onChange={handleDateChange}
              renderInput={(params) => (
                <CustomTextField variant='standard' fullWidth {...params} />
              )}
              disableFuture
            />
          </LocalizationProvider> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              // onBlur={handleBlur}
              name='selectedDate'
              value={selectedDate || null}
              className='custom-textfield'
              format='YYYY'
              // maxDate={eighteenYearsAgo}
              // disableFuture
              views={['year']}
              onChange={(date) => handleDateChange(date)}
              renderInput={(params) => (
                <CustomTextField variant='standard' fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
          {/* <DatePicker
            selected={selectedDate}
            dateFormat='yyyy'
            onChange={handleDateChange}
            showYearPicker
            maxDate={currentDate}
            className='custom-date-picker education-font date-picker-input b-rad-left'
            value={selectedDate}
          /> */}
        </Box>

        <Box>
          <Typography variant='h5' className='s-bold pb-2'>
            {doYouFinish?.title}
          </Typography>
          <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                name='doYouFinishSwitch'
                checked={doYouFinishPrivate}
                // onChange={(e) => {
                //   profileData?.isPaid === false
                //     ? setToogleDialog(true)
                //     : handleChange(e);
                // }}
                onChange={(e) => {
                  if (isEdit) {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  } else {
                    handleChange(e);
                  }
                }}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {doYouFinishPrivate ? <LockIcon /> : <UnLockIcon />}
          </Box>
        </Box>
      </Box>
      <Box>
        <CustomButton
          variant='contained'
          title={localesData?.userwork?.keep_going}
          background='#6A42ED'
          fontWeight='400'
          // type='submit'
          onClick={() => handleOnSubmit()}
        />

        <Box className='pb-8 mb-4 d-flex justify-center'>
          <DogIcon />
          <Typography
            variant='h5'
            className='pointer'
            // onClick={() => navigate('/')}
            onClick={() => redirectHomePage()}
          >
            {localesData?.userwork?.FINISH}
          </Typography>
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default UserEducation;
