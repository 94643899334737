import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  Avatar,
  IconButton,
  FormHelperText,
} from '@mui/material';
import Webcam from 'react-webcam';
import CustomButton from '../../UI/Button/CustomButton';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import ImageUploadOptions from '../../UI/ImageUploadOptions';
import {
  LoginSocialInstagram,
  LoginSocialFacebook,
} from 'reactjs-social-login';
import {
  Config,
  fetchFromStorage,
  removeFromStorage,
  saveToStorage,
} from '../../../../helpers/context';
import { ReactComponent as ImgUploadPlus } from '../../../../assets/images/img-upload-plus.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/images/icons/cross.svg';
import { ReactComponent as VideoCamIcon } from '../../../../assets/images/icons/videocam-icon.svg';
import ClickPicture from '../../../../assets/images/icons/camera.png';
import undoPicture from '../../../../assets/images/icons/undo.png';
import { identifiers } from '../../../../helpers/constants/identifier';
import { clearSvgData } from '../../../../helpers/store/actions/progressImage';
import PreLoader from 'src/components/Loader';
import Axios from 'axios';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import CropImage from 'src/views/component/UI/CropImage/cropimage';

function SetPictures({ localesData, dispatch, getProfile, setLoader }) {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [facebookStatus, setFacebookStatus] = useState(false);
  const [instagramStatus, setInstagramStatus] = useState(false);
  const [error, setError] = useState('');
  const [openCamera, setOpenCamera] = useState(false);
  const [facingMode, setFacingMode] = useState(null);
  const [imagesArray, setImagesArray] = useState([
    { displayOrder: 1, location: '', type: '' },
    { displayOrder: 2, location: '', type: '' },
    { displayOrder: 3, location: '', type: '' },
    { displayOrder: 4, location: '', type: '' },
    { displayOrder: 5, location: '', type: '' },
    { displayOrder: 6, location: '', type: '' },
    { displayOrder: 7, location: '', type: '' },
    { displayOrder: 8, location: '', type: '' },
    { displayOrder: 9, location: '', type: '' },
  ]);
  const [currenOrder, setCurrenOrder] = useState(0);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [dorder, setDorder] = useState();
  const webcamRef = useRef(null);

  const [capturedImage, setCapturedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [cropFile, setCropFile] = useState('');

  useEffect(() => {
    const checkForBackCamera = async () => {
      try {
        // Check for mediaDevices availability in the browser
        if (
          !navigator.mediaDevices ||
          !navigator.mediaDevices.enumerateDevices
        ) {
          console.error('MediaDevices API not available in this browser.');
          return;
        }

        // Request permission to access media devices
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        // If permission is granted, enumerate devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasBackCam = devices.some(
          (device) =>
            device.kind === 'videoinput' &&
            device.label.toLowerCase().includes('back')
        );
        setIsMobile(hasBackCam);
        setFacingMode('user');
        // Release the camera stream
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.error('Error checking camera availability:', error);
      }
    };

    checkForBackCamera();
  }, []);

  const capture = () => {
    const base64Data = webcamRef.current.getScreenshot();
    setCapturedImage(base64Data);
    // const file = dataURLtoFile(base64Data, "image.jpg");
    // handleFileUpload([file]);
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  useEffect(() => {
    dispatch(clearSvgData(''));
  }, [dispatch]);

  const redirectrulespage = () => {
    updateUserDetails();
  };
  const handleFileUpload = async (file) => {
    const maxFileUploads = 9;

    for (let i = 0; i < file.length; i++) {
      const blankImg = imagesArray?.filter((item) => item?.location === '')?.[0]
        ?.displayOrder;
      if (file?.length === 1) {
        await uploadImage(file[i], blankImg, false);
      } else {
        await uploadImage(file[i], blankImg, true);
      }
      if (file.length >= maxFileUploads) {
        // If the maximum number of file uploads (9) is reached, break out of the loop
        break;
      }
    }
  };
  const uploadImage = async (file, blankImg, multipleImage) => {
    setLoading(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.IMAGE_UPLOAD;
    body.append('image', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);

      if (status === 200) {
        setLoading(false);
        setIsOpen(false);
        setOpenCamera(false);
        setError('');
        setOpenCamera(false);
        setCapturedImage(null);
        const newData = [];

        const newObj = {
          displayOrder: multipleImage ? blankImg : currenOrder,
          location: data?.url?.location,
          type: data?.url?.mimetype,
        };
        newData.push(newObj);

        newData?.forEach((obj) => {
          const index = imagesArray?.findIndex(
            (item) => item?.displayOrder === obj?.displayOrder
          );
          if (index !== -1) {
            imagesArray[index] = obj;
          }
        });

        setImagesArray([...imagesArray]);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const updateUserDetails = async (req) => {
    var _imgs = [];

    imagesArray?.map((item) => {
      var _url = item?.location?.split('/')[3];
      if (_url && _url !== '') {
        _imgs.push({
          url: _url,
        });
      }
      return true;
    });

    let sendData = { images: _imgs };
    if (_imgs?.length !== 0) {
      try {
        setLoader(true);
        const { status } = await axiosInstance.put(URLS.USER, sendData);
        if (status === 200) {
          getProfile();
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        setApiMessage('error', error?.response?.data?.message);
      }
    } else {
      setError(localesData?.validation?.UPLOAD_AT_LIST_ONE);
    }
  };
  const removeImage = (order) => {
    const updatedImagesArray = imagesArray.map((item) => {
      if (item.displayOrder === order) {
        return {
          ...item,
          location: '',
          type: '',
        };
      }
      return item;
    });
    setImagesArray(updatedImagesArray);
  };
  const responseInstagram = async (response) => {
    try {
      const { status, data } = await axiosInstance.get(
        `https://graph.instagram.com/${response?.data?.id}/media?fields=id,media_type,media_url,username,timestamp&access_token=${response?.data?.access_token}`
      );
      if (status === 200) {
        const InstaImage = data?.data;
        saveToStorage(identifiers?.instagramImages, InstaImage);
        setInstagramStatus(true);
      }
    } catch (error) {
      setApiMessage('error', error.message);
    }
  };
  const responseFacebook = async (res) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${res?.accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const { status, data } = await Axios.get(
        `https://graph.facebook.com/${res?.id}/photos?type=uploaded&fields=images`,
        headers
      );
      if (status === 200) {
        var filteredData = [];
        var facebookImageArray = data?.data;

        facebookImageArray?.map((item, index) => {
          var obj = {
            id: item?.id,
            media_url: item.images[0].source,
          };
          filteredData[index] = obj;
          return true;
        });
        const FBImage = filteredData;
        saveToStorage(identifiers?.facebookImages, FBImage);
        setFacebookStatus(true);
      }
    } catch (error) {}
  };
  const getSelectedMedia = (images) => {
    const url = Config.baseURL + URLS.IMAGE_UPLOAD;
    images.forEach((image) => {
      const imageUrl = image?.media_url;
      setLoading(true);
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append('image', blob, 'image.jpg');

          return fetch(url, {
            method: 'POST',
            body: formData,
          });
        })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);

          const blankImg = imagesArray?.filter(
            (item) => item?.location === ''
          )?.[0]?.displayOrder;

          const newData = [];

          const newObj = {
            displayOrder: images?.length === 1 ? currenOrder : blankImg,
            location: data?.url?.location,
            type: data?.url?.mimetype,
          };
          newData.push(newObj);

          newData?.forEach((obj) => {
            const index = imagesArray?.findIndex(
              (item) => item?.displayOrder === obj?.displayOrder
            );
            if (index !== -1) {
              imagesArray[index] = obj;
            }
          });
          setImagesArray([...imagesArray]);
          setIsOpen(false);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };
  const handleSwitchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };
  useEffect(() => {
    fetchFromStorage(identifiers?.facebookImages) !== null
      ? setFacebookStatus(true)
      : setFacebookStatus(false);

    fetchFromStorage(identifiers?.instagramImages) !== null
      ? setInstagramStatus(true)
      : setInstagramStatus(false);
  }, [
    fetchFromStorage(identifiers?.facebookImages),
    fetchFromStorage(identifiers?.instagramImages),
  ]);

  const videoConstraints = {
    facingMode: facingMode,
  };

  const handleDragStart = (event, imageIndex) => {
    setDraggedIndex(imageIndex);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', imageIndex);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    // event.dataTransfer.dropEffect = 'move';
  };
  const handleDrop = (event, targetIndex) => {
    event.preventDefault();
    const sourceIndex = draggedIndex;
    if (sourceIndex === targetIndex) return;
    const reorderedImages = [...imagesArray];
    if (reorderedImages[sourceIndex]?.location) {
      const temp = reorderedImages[targetIndex];
      reorderedImages[targetIndex] = reorderedImages[sourceIndex];
      reorderedImages[sourceIndex] = temp;
    }

    setImagesArray(reorderedImages);
    setDraggedIndex(null);
  };
  const handleTouchStart = (event, imageIndex) => {
    event.preventDefault();
    setDraggedIndex(imageIndex);
  };
  const handleTouchMove = (event, targetIndex) => {
    event.preventDefault();
    if (draggedIndex === null) return;

    const sourceIndex = draggedIndex;
    if (sourceIndex === targetIndex) return;

    const reorderedImages = [...imagesArray];
    const movedImage = reorderedImages[sourceIndex];

    reorderedImages.splice(sourceIndex, 1);
    reorderedImages.splice(targetIndex, 0, movedImage);

    setImagesArray(reorderedImages);
    setDraggedIndex(targetIndex);
  };
  const handleTouchEnd = () => {
    setDraggedIndex(null);
  };
  useEffect(() => {
    const updatedArray = imagesArray?.map((item) => {
      const matchingItem = profileData?.images?.find(
        (obj, i) => i + 1 === item.displayOrder
      );
      if (matchingItem) {
        return {
          ...item,
          location: matchingItem?.url,
          type: matchingItem?.media_type,
        };
      }
      return item;
    });

    setImagesArray(updatedArray);
    // eslint-disable-next-line
  }, []);
  const handleCrop = (file, indez) => {
    setCropFile(file);
    setDorder(indez + 1);
    setTimeout(() => {
      setCropOpen(true);
    }, 700);
  };
  const handleCropclose = () => {
    setCropOpen(false);
    // setCropFile('');
    if (openCamera) {
      const file = dataURLtoFile(capturedImage, 'image.jpg');
      handleFileUpload([file]);
    }
  };
  const EditUploadImage = async (file) => {
    var baseText = file.getCroppedCanvas().toDataURL();
    const blob = await (await fetch(baseText)).blob();
    const files = new File([blob], cropFile, {
      type: 'image',
      lastModified: new Date(),
    });
    setLoading(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.IMAGE_UPLOAD;
    body.append('image', files);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 200) {
        setOpenCamera(false);
        setCapturedImage(null);
        setLoading(false);
        setIsOpen(false);
        setError('');

        const newObj = {
          displayOrder: Number(dorder),
          location: data?.url?.location,
          type: data?.url?.mimetype,
        };
        imagesArray[Number(dorder) - 1] = newObj;

        setImagesArray([...imagesArray]);
        setCropOpen(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <Box style={{ overflowX: 'hidden' }}>
      {loading && <PreLoader />}
      <Box className='t-center'>
        <Typography variant='h3' className='pb-3 s-bold'>
          {localesData?.profile?.WE_NEED_TO_SEE_YOU} {profileData?.firstname}
        </Typography>
        <Typography
          variant='h4'
          className='mb-6'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile?.YOUR_BEST_PICTURE,
          }}
        />
      </Box>
      <div className='lot-Images-block pb-4 img-main-flow-block'>
        {imagesArray?.map((item, index) => (
          <div
            key={index}
            className='file-uploader'
            draggable={item?.location ? true : false}
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, index)}
            onTouchStart={(event) => handleTouchStart(event, index)}
            onTouchMove={(event) => handleTouchMove(event, index)}
            onTouchEnd={handleTouchEnd}
            onContextMenu={(event) => event.preventDefault()}
            id={`image-${index}`}
          >
            <div className='dropzone d-flex justify-content-center align-items-center mx-auto'>
              <Box className='t-center profile-picture'>
                <div>
                  <Box className='picture-upload'>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignContent='center'
                      direction='row'
                      className='file-upload-sec'
                    >
                      <Box className='card pointer'>
                        {item?.location !== '' ? (
                          item?.type?.includes('video') ? (
                            <Box style={{ height: '100%' }}>
                              <video className='video-box'>
                                <source src={item?.location} />
                              </video>
                              <VideoCamIcon className='video-cam-icon' />
                              <IconButton
                                disableRipple
                                onClick={() => removeImage(item?.displayOrder)}
                                className='remove-image'
                              >
                                <CrossIcon />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box style={{ height: '100%' }}>
                              <Avatar src={item?.location} alt='' />
                              <IconButton
                                disableRipple
                                onClick={() => removeImage(item?.displayOrder)}
                                className='remove-image'
                              >
                                <CrossIcon />
                              </IconButton>
                            </Box>
                          )
                        ) : (
                          <Box
                            className='file-upload-box pointer'
                            onClick={() => {
                              setCurrenOrder(item?.displayOrder);
                              setIsOpen(!isOpen);
                            }}
                          >
                            {/* <IconButton disableRipple> */}
                            <ImgUploadPlus className='file-upload-img' />
                            {/* </IconButton> */}
                          </Box>
                        )}
                      </Box>
                      {item?.location && (
                        <div
                          className='picture-overlay'
                          onClick={() =>
                            item?.location !== '' &&
                            !item?.type?.includes('video') &&
                            handleCrop(item?.location, index)
                          }
                        />
                      )}
                    </Grid>
                  </Box>
                </div>
              </Box>
            </div>
          </div>
          // <div
          // 	sm="4"
          // 	xs="12"
          // 	className="mb-4"
          // 	draggable={true}
          // 	id={item?.id}
          // 	// onDragOver={(ev) => ev.preventDefault()}
          // 	// onDragStart={handleDrag}
          // 	// onDrop={handleDrop}
          // >
          // 	{item?.name !== "" ? (
          // 		<>
          // 			<img src={item?.preview} width="100px" />
          // 		</>
          // 	) : (
          // 		<DropZone
          // 			accept="image/jpeg, image/png"
          // 			text={`upload image${index}`}
          // 			name={`uploader${index}`}
          // 			onDrop={(file) => handleFileUpload(file, item)}
          // 			multiple
          // 		/>
          // 	)}
          // </div>
        ))}
      </div>
      {error && (
        <FormHelperText
          sx={{ fontSize: '10px', marginLeft: '12px' }}
          className='color-error'
        >
          {error}
        </FormHelperText>
      )}

      <Box className='t-center pb-6'>
        {/* FB CONNECT START */}
        {facebookStatus ? (
          <Typography
            variant='h3'
            className='s-bold mb-2 pointer'
            onClick={() => {
              removeFromStorage(identifiers?.facebookImages);
              setFacebookStatus(false);
            }}
          >
            {localesData?.profile?.DISCONNECT_FB}
          </Typography>
        ) : (
          <LoginSocialFacebook
            appId={Config.FacebookAppID || ''}
            fieldsProfile='email,name,first_name,last_name,picture'
            redirect_uri={Config?.redirectURL + '/profile-setup'}
            onResolve={(user) => {
              responseFacebook(user?.data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <Typography variant='h3' className='s-bold mb-2 pointer'>
              {localesData?.profile?.CONNECT_FB}
            </Typography>
          </LoginSocialFacebook>
        )}
        {/* FB CONNECT END */}
        {/* INSTAGRAM CONNECT START */}
        {instagramStatus ? (
          <Typography
            variant='h3'
            className='s-bold mb-2 pointer'
            onClick={() => {
              removeFromStorage(identifiers?.instagramImages);
              setInstagramStatus(false);
            }}
          >
            {localesData?.profile?.DISCONNECT_INSTA}
          </Typography>
        ) : (
          <LoginSocialInstagram
            client_id={Config.InstagramClientID || ''}
            client_secret={Config.InstagramSecretKey || ''}
            redirect_uri={Config?.redirectURL + '/profile-setup'}
            onResolve={(data) => {
              responseInstagram(data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
            scope={['user_profile', 'user_media']}
          >
            <Typography variant='h3' className='s-bold mb-2 pointer'>
              {localesData?.profile?.CONNECT_INSTA}
            </Typography>
          </LoginSocialInstagram>
        )}
        {/* INSTAGRAM CONNECT END */}
        {/* <InstagramLogin
					clientId="382889262779980"
					buttonText="Login"
					onSuccess={responseInstagram}
					onFailure={responseInstagram}
					redirectUri="https://5a74-2405-201-2033-c009-3dea-1549-adcc-d9a2.ngrok-free.app/profile-completion"
				/> */}

        <CustomButton
          variant='contained'
          title={localesData?.DONE}
          background='#59D09D'
          fontWeight='600'
          onClick={() => {
            // setIsOpen(!isOpen);
            redirectrulespage();
          }}
        />
      </Box>
      {/* <div className='grid-container'>{cells}</div> */}
      {/* <Box>
				<CustomButton
					variant="contained"
					title={localesData?.DONE}
					background="#59D09D"
					fontWeight="600"
					onClick={() => {
						// setIsOpen(!isOpen);
						redirectrulespage();
					}}
				/>
			</Box> */}
      {openCamera && (
        <Box className={capturedImage ? 'camera-wrap clicked' : 'camera-wrap'}>
          {!capturedImage && (
            <Webcam
              audio={false}
              screenshotFormat='image/jpeg'
              ref={webcamRef}
              mirrored={facingMode === 'environment' ? false : true}
              videoConstraints={{ ...videoConstraints, facingMode }}
              // onUserMediaError={setOpenCamera(false)}
            />
          )}
          <Box
            className='capture-wrap'
            style={{ width: '100%', display: 'flex' }}
          >
            {!capturedImage && (
              <>
                <Box style={{ marginLeft: '15px', alignSelf: 'center' }}>
                  <img
                    src={undoPicture}
                    onClick={() => {
                      setOpenCamera(false);
                      setCapturedImage(null);
                    }}
                    alt='not found'
                    width={30}
                    className='pointer' //mt-3
                  />
                </Box>

                <Box style={{ width: 'fit-content', margin: '0 auto' }}>
                  <img
                    src={ClickPicture}
                    onClick={capture}
                    alt='not found'
                    width={50}
                    className='pointer'
                  />
                </Box>
                <Box style={{ marginRight: '15px', alignSelf: 'center' }}>
                  {isMobile && (
                    <FlipCameraAndroidIcon
                      className='pointer'
                      onClick={() => handleSwitchCamera()}
                      style={{ fill: '#ffffff', width: '30px', height: '30px' }}
                    />
                  )}
                </Box>
              </>
            )}
            {capturedImage && (
              <>
                <img
                  src={capturedImage}
                  alt='Captured'
                  width={200}
                  height={200}
                />
                <Box className='retake-wrap'>
                  <span
                    onClick={() => {
                      setOpenCamera(true);
                      setCapturedImage(null);
                    }}
                  >
                    Retry
                  </span>
                  <span
                    onClick={() => {
                      // const file = dataURLtoFile(capturedImage, 'image.jpg');
                      // handleFileUpload([file]);
                      handleCrop(capturedImage, currenOrder - 1);
                    }}
                  >
                    Ok
                  </span>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
      {isOpen && (
        <Box className='select-option-wrap pointer'>
          <ImageUploadOptions
            onClose={() => setIsOpen(false)}
            // files={files}
            openCamera={() => {
              setOpenCamera(true);
              setIsOpen(false);
            }}
            handleFileUpload={handleFileUpload}
            localesData={localesData}
            getSelectedMedia={getSelectedMedia}
            accept='image'
          />
        </Box>
      )}
      <DialogBox
        handleClose={() => handleCropclose()}
        open={cropOpen}
        title=''
        className='crop-dialog-box'
        content={
          <>
            <CropImage
              localesData={localesData}
              file={cropFile}
              // MultipleFile={MultipleFile}
              getCropData={EditUploadImage}
              handleCropclose={handleCropclose}
            />
          </>
        }
      />
    </Box>
  );
}

export default SetPictures;
