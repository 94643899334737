import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { IntlContext } from 'src/App';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as PinkDogIcon } from 'src/assets/images/icons/pink-dog-icon.svg';
import { ReactComponent as FavIcon } from 'src/assets/images/icons/favourites.svg';
import { ReactComponent as FavSelectedIcon } from 'src/assets/images/icons/favourites-active.svg';
// import { ReactComponent as CloseIcon } from 'src/assets/images/icons/round-close-icon.svg';
import DogImageSlider from './DogImageSlider';
import NoDataFound from './NoDataFound';
import InfoAboutUs from './InfoAboutUs/InfoAboutUs';
import MatchingVideo from './MatchingVideo';
import MatchingContent from './MatchingContent';
import MatchingImage from './MatchingImage';
import BackToTop from '../BackToTop';
import MatchToSend from './MatchToSend';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { Config, fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import PreLoader from 'src/components/Loader';
import Geocode from 'react-geocode';
import './matching.scss';

Geocode.setApiKey(Config?.placeAPIKey);

const Matching = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const location = useLocation();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState('');
  const [firstDogsData, setFirstDogsData] = useState('');
  const [toogleDialog, setToogleDialog] = useState(false);
  const [currentUserID, setCurrentUserID] = useState('');
  const [allUserID, setAllUserID] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [firstApperUserID, setFirstApperUserID] = useState('');
  const [rejectLoading, setRejectLoading] = useState(false);
  const [isActivePawIcon, setIsActivePawIcon] = useState(false);
  const [isRemoveImg, setIsRemoveImg] = useState(false);
  const [dogMustFilter] = useState([]);

  const pageTopRef = useRef(null);

  const getAllAnswers = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        const locationData = data?.questions?.filter(
          (item) => item.id === 33 || item.id === 51
        );
        setLoader(false);
        fetchLocation(locationData);
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };

  const fetchLocation = (locationData) => {
    if (Config?.placeAPIKey !== '') {
      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // setLatitude(position.coords.latitude);
              // setLongitude(position.coords.longitude);
              let checkLocationData = locationData?.find(
                (item) => item?.id === 33
              )?.Option?.[0]?.answer;
              if (checkLocationData === '' || checkLocationData === null) {
                getLocationString(
                  locationData,
                  position.coords.latitude,
                  position.coords.longitude
                );
              }
            },
            (error) => {
              console.log(error.message);
            }
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log('Geolocation is not supported by your browser.');
      }
    }
  };

  const getLocationString = async (locationData, lat, lng) => {
    if (Config?.placeAPIKey !== '') {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          if (response != null) {
            var country_state_name = '';
            var country_name = '';
            var country_city_name = '';
            var full_adrs = '';

            response?.results[0]?.address_components?.map((item) => {
              if (item.types[0] === 'locality') {
                country_city_name = item?.long_name;
              }
              if (item.types[0] === 'administrative_area_level_1') {
                country_state_name = item?.long_name;
              }
              if (item.types[0] === 'country') {
                country_name = item?.long_name;
              }
              full_adrs =
                country_city_name +
                ', ' +
                country_state_name +
                ', ' +
                country_name;
              return true;
            });

            if (response.status === 'OK') {
              if (full_adrs === '') {
                handleUpdateLocation(
                  locationData,
                  response.results[0].formatted_address,
                  lat,
                  lng
                );
              } else {
                handleUpdateLocation(locationData, full_adrs, lat, lng);
              }
            } else {
              console.log('something_went_wrong', response.status);
            }
          } else {
            console.log('something_went_wrong', response);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const handleUpdateLocation = async (locationData, addressData, lat, lng) => {
    var opt = [];
    const yourLocation = locationData?.find((item) => item?.id === 33);
    const findYourLocation = locationData?.find((item) => item?.id === 51);

    opt.push({
      optionId: yourLocation?.Option?.[0]?.id,
      answer: addressData ? addressData : null,
      selected: true,
      private: false,
      lat: addressData && lat ? lat?.toString() : null,
      long: addressData && lng ? lng?.toString() : null,
      startRange: '0',
      endRange: '100',
    });

    opt.push({
      optionId: findYourLocation?.Option?.[0]?.id,
      answer: '',
      selected: false,
      private: true,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    console.log('sendData', sendData);
    try {
      setLoader(true);
      const { status } = await axiosInstance.post(URLS.SET_ANSWERS, sendData);
      if (status === 200) {
        // setApiMessage('success', data?.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllAnswers();
    // eslint-disable-next-line
  }, []);

  const scrollToTop = () => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getMatchingDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.MATCHING_TAB_LIST);
      if (status === 200) {
        const userIds = data?.users;
        if (userIds?.length !== 0) {
          getUserDetailsNow(userIds?.[0], false);
          setAllUserID(userIds);
          setFirstApperUserID(userIds?.[0]);
        } else {
          setLoader(false);
        }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const checkDogFilter = (userData) => {
    if (
      dogMustFilter &&
      dogMustFilter !== null &&
      dogMustFilter.length !== 0 &&
      dogMustFilter.includes(userData?.mainDog?.breed)
    ) {
      return true;
    } else if (
      !dogMustFilter ||
      dogMustFilter === undefined ||
      dogMustFilter === null ||
      dogMustFilter.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getUserDetailsNow = async (current_user_id, isScroll) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.GET_PROFILE + '/' + current_user_id
      );
      if (status === 200) {
        setIsRemoveImg(true);
        let filter_status = checkDogFilter(data?.userDetails);
        if (filter_status) {
          setUserData(data?.userDetails);
          setFirstDogsData(data?.userDetails?.dogs?.[0]);
          setCurrentUserID(current_user_id);
          setTimeout(() => {
            setLoader(false);
            setRejectLoading(false);
            setIsRemoveImg(false);
          }, 1000);
          if (isScroll) {
            scrollToTop();
            if (firstApperUserID === current_user_id) {
              setShowLocation(!showLocation);
            }
          }
        } else {
          removeUserAndCallApi();
        }
      }
    } catch (error) {
      setLoader(false);
      setRejectLoading(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getMatchingDetails();
    // eslint-disable-next-line
  }, [location]);

  const makeFavouriteAPI = async (itemID) => {
    const sendData = {
      following: itemID.toString(),
      type: 'match_profile',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.FAVOURITE,
        sendData
      );
      if (status === 200) {
        userData.isLike = 1;
        userData.fevouriteId = data?.data?.favouriteId;
        setUserData(userData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const removeFavouriteAPI = async (itemID) => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + itemID
      );
      if (status === 200) {
        userData.isLike = 0;
        userData.fevouriteId = null;
        setUserData(userData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const onPawIconClick = async () => {
    if (profileData?.isPaid === false) {
      sendBoneAPI();
    } else {
      sendBoneAPIForFreeUsers();
    }
    setIsActivePawIcon(true);
  };
  const sendBoneAPIForFreeUsers = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(
        URLS.SEND_BONE + '/' + currentUserID
      );
      if (status === 200) {
        setLoader(false);
        if (!showAnimation) {
          setShowAnimation(true);
          setIsActivePawIcon(false);
          setTimeout(() => {
            let all_user_id = allUserID;
            if (all_user_id?.length === 1) {
              setCurrentUserID('');
              setLoader(false);
              setAllUserID([]);
              setShowAnimation(false);
            } else {
              setShowAnimation(false);
              removeUserAndCallApi();
            }
          }, 2000);
        }
      }
    } catch (error) {
      setLoader(false);
      setIsActivePawIcon(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const sendBoneAPI = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(
        URLS.SEND_BONE + '/' + currentUserID
      );
      if (status === 200) {
        setLoader(false);
        if (!showAnimation) {
          setShowAnimation(true);
          setIsActivePawIcon(false);
          setTimeout(() => {
            let all_user_id = allUserID;
            if (all_user_id?.length === 1) {
              setCurrentUserID('');
              setLoader(false);
              setAllUserID([]);
              setShowAnimation(false);
            } else {
              setShowAnimation(false);
              removeUserAndCallApi();
            }
          }, 2000);
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        setTimeout(() => {
          setToogleDialog(true);
        }, 1000);
      }
      setIsActivePawIcon(false);
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const removeUserAndCallApi = async () => {
    let all_user_id = allUserID;
    const updatedUserAfterRemoveID = all_user_id?.filter(
      (item) => item !== currentUserID
    );
    if (updatedUserAfterRemoveID.length !== 0) {
      setAllUserID(updatedUserAfterRemoveID);
      fetchNextUserData(updatedUserAfterRemoveID);
      if (firstApperUserID === currentUserID) {
        setFirstApperUserID(updatedUserAfterRemoveID[0]);
      }
    } else {
      currentUserID('');
      setAllUserID([]);
      setLoader(false);
      setRejectLoading(false);
    }
  };

  const fetchNextUserData = async (userIds) => {
    let all_user_id = userIds;
    if (all_user_id.length !== 0) {
      getUserDetailsNow(all_user_id[0], true);
    } else {
      setLoader(false);
      setRejectLoading(false);
    }
  };

  const rejectUserAPI = async () => {
    setRejectLoading(true);
    try {
      const { status } = await axiosInstance.get(
        URLS.REJECT_BONE + '/' + currentUserID + '?notificationFlag=false'
      );

      if (status === 200) {
        let old_user_id = allUserID;
        const filter_data = old_user_id?.filter(
          (item) => item !== currentUserID
        );
        let new_data = [...filter_data, currentUserID];
        setAllUserID(new_data);
        if (new_data?.length !== 0) {
          setTimeout(() => {
            fetchNextUserData(new_data);
            // setRejectLoading(false);
          }, 2000);
        }
      }
    } catch (error) {
      setLoader(false);
      setRejectLoading(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <Box ref={pageTopRef}>
      {loader && !rejectLoading && <PreLoader />}

      {!loader && userData === '' ? (
        <NoDataFound />
      ) : (
        <>
          {/* ONWER/DOG/FAV ICON */}
          {userData && (
            <Box className='mt-2 mb-2 d-flex space-between align-center'>
              {firstDogsData ? (
                <Typography variant='h6' className='color-pink'>
                  {userData?.firstname + ' '} <PinkDogIcon />{' '}
                  {' ' + firstDogsData?.name}
                </Typography>
              ) : (
                <Typography variant='h6' className='color-pink'>
                  {userData?.firstname}
                </Typography>
              )}
              <Box>
                {userData?.isLike === 1 ||
                userData?.isLike === '1' ||
                userData?.isLike === true ? (
                  <FavSelectedIcon
                    onClick={() => removeFavouriteAPI(userData?.fevouriteId)}
                    className='pointer'
                    height={22}
                    width={23}
                  />
                ) : (
                  <FavIcon
                    onClick={() => {
                      profileData?.isPaid === false
                        ? setToogleDialog(true)
                        : makeFavouriteAPI(userData?.id);
                    }}
                    className='pointer'
                    height={22}
                    width={23}
                  />
                )}
              </Box>
            </Box>
          )}
          {/* DOG VIDEO */}
          {firstDogsData?.video ? (
            <MatchingVideo
              videoLink={firstDogsData?.video}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          ) : null}
          {/* ONWER/DOG INFO */}
          {userData && <InfoAboutUs userData={userData} />}
          {/* 1st ONWER PIC */}
          {userData &&
            userData?.images?.[0] !== '' &&
            userData?.images?.[0] !== undefined && (
              <MatchingImage
                userData={userData}
                isRemoveImg={isRemoveImg}
                imgIndex={0}
                onPawIconClick={onPawIconClick}
                isActivePawIcon={isActivePawIcon}
              />
            )}
          {/* 1st DOG */}
          {userData &&
            userData?.dogs?.[0] !== '' &&
            userData?.dogs?.[0] !== undefined &&
            userData?.dogs?.[0]?.images?.length !== 0 && (
              <DogImageSlider
                dogsData={userData?.dogs?.[0]}
                isRemoveImg={isRemoveImg}
              />
            )}
          {/* 2nd ONWER PIC */}
          {userData && userData?.images?.[1] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={1}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* JOB DESCRIPTION */}
          {userData && userData?.job_designation !== '' && (
            <MatchingContent
              title={localesData?.matching?.MY_JOB}
              description={
                'My Job: ' +
                userData?.job_designation +
                '<br/>Working at: ' +
                userData?.job_place +
                '<br/>Worked there for: ' +
                userData?.job_since
              }
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* 3rd ONWER PIC */}
          {userData && userData?.images?.[2] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={2}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* EDUCATION DESCRIPTION */}
          {userData && userData?.education_institute !== '' && (
            <MatchingContent
              title={localesData?.matching?.MY_EDUCATION}
              description={
                userData?.education_institute +
                ' in ' +
                userData?.education_complete_year
              }
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* 4th ONWER PIC */}
          {userData && userData?.images?.[3] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={3}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* HOBBIES DESCRIPTION */}
          {userData &&
            (userData?.hobbies !== '' ||
              (userData?.manual_hobbies !== '[]' &&
                userData?.manual_hobbies !== '')) && (
              <MatchingContent
                title={localesData?.matching?.MY_HOBBIES}
                description={[
                  ...userData?.hobbies,
                  ...JSON.parse(
                    userData?.manual_hobbies ? userData?.manual_hobbies : '[]'
                  )?.map((obj) => obj?.title),
                ]?.join(' | ')}
                onPawIconClick={onPawIconClick}
                isActivePawIcon={isActivePawIcon}
              />
            )}
          {/* 5th ONWER PIC */}
          {userData && userData?.images?.[4] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={4}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* 2nd DOG */}
          {userData &&
            userData?.dogs?.[1] !== '' &&
            userData?.dogs?.[1] !== undefined &&
            userData?.dogs?.[1]?.images?.length !== 0 && (
              <DogImageSlider
                dogsData={userData?.dogs?.[1]}
                isRemoveImg={isRemoveImg}
              />
            )}
          {/* RELIGION DESCRIPTION */}
          {userData &&
            (userData?.religion !== '' ||
              (userData?.manual_religion !== '[]' &&
                userData?.manual_religion !== '')) && (
              <MatchingContent
                title={localesData?.matching?.MY_RELIGION}
                description={[
                  ...userData?.religion,
                  ...JSON.parse(
                    userData?.manual_religion ? userData?.manual_religion : '[]'
                  )?.map((obj) => obj?.title),
                ]?.join(' | ')}
                onPawIconClick={onPawIconClick}
                isActivePawIcon={isActivePawIcon}
              />
            )}
          {/* 6th ONWER PIC */}
          {userData && userData?.images?.[5] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={5}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* POLITICS DESCRIPTION */}
          {userData &&
            (userData?.politics !== '' ||
              (userData?.manual_politics !== '[]' &&
                userData?.manual_politics !== '')) && (
              <MatchingContent
                title={localesData?.matching?.MY_POLITIC}
                description={[
                  ...userData?.politics,
                  ...JSON.parse(
                    userData?.manual_politics ? userData?.manual_politics : '[]'
                  )?.map((obj) => obj?.title),
                ]?.join(' | ')}
                onPawIconClick={onPawIconClick}
                isActivePawIcon={isActivePawIcon}
              />
            )}
          {/* 7th ONWER PIC */}
          {userData && userData?.images?.[6] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={6}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* KIDS DESCRIPTION */}
          {userData && userData?.kids !== '' && (
            <MatchingContent
              title={localesData?.matching?.MY_KIDS}
              description={userData?.kids}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* 8th ONWER PIC */}
          {userData && userData?.images?.[7] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={7}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* 3rd DOG */}
          {userData &&
            userData?.dogs?.[2] !== '' &&
            userData?.dogs?.[2] !== undefined &&
            userData?.dogs?.[2]?.images?.length !== 0 && (
              <DogImageSlider
                dogsData={userData?.dogs?.[2]}
                isRemoveImg={isRemoveImg}
              />
            )}
          {/* FAMILY BACKGROUND DESCRIPTION */}
          {userData &&
            (userData?.ethnicity !== '' ||
              (userData?.manual_ethnicity !== '[]' &&
                userData?.manual_ethnicity !== '')) && (
              <MatchingContent
                title={localesData?.matching?.FAMILY_BACKGROUND}
                description={[
                  ...userData?.ethnicity,
                  ...JSON.parse(
                    userData?.manual_ethnicity
                      ? userData?.manual_ethnicity
                      : '[]'
                  )?.map((obj) => obj?.title),
                ]?.join(' | ')}
                onPawIconClick={onPawIconClick}
                isActivePawIcon={isActivePawIcon}
              />
            )}
          {/* 9th ONWER PIC */}
          {userData && userData?.images?.[8] && (
            <MatchingImage
              userData={userData}
              isRemoveImg={isRemoveImg}
              imgIndex={8}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {/* FAMILY PLAN DESCRIPTION */}
          {userData && userData?.family_plan !== '' && (
            <MatchingContent
              title={localesData?.matching?.FAMILY_PLAN}
              description={userData?.family_plan}
              onPawIconClick={onPawIconClick}
              isActivePawIcon={isActivePawIcon}
            />
          )}
          {userData && (
            <Box className='pb-23 m-auto' sx={{ maxWidth: '320px' }}>
              <BackToTop scrollToTop={scrollToTop} />
            </Box>
          )}
        </>
      )}
      {userData &&
        (!rejectLoading ? (
          <Box className='reject-close-icon'>
            <Close className='pointer' onClick={rejectUserAPI} />
          </Box>
        ) : (
          <Box className='reject-close-icon'>
            <CircularProgress size='24px' />
          </Box>
        ))}
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
      <Dialog
        open={showAnimation}
        onClose={() => setShowAnimation(!showAnimation)}
      >
        <MatchToSend
          handleClose={() => setShowAnimation(!showAnimation)}
          gender={userData && userData?.gender}
        />
      </Dialog>
      <Dialog
        open={showLocation}
        onClose={() => setShowLocation(!showLocation)}
      >
        <DialogContent className='custom-dialog-yes-no-box t-center'>
          <Typography variant='h5' className='font-r-13 mb-2'>
            {localesData?.matching?.LOCATION_POPUP_DESC_1}
          </Typography>
          <Typography
            variant='h5'
            className='font-r-13 mb-2'
            style={{ color: '#f54d71' }}
          >
            {localesData?.matching?.LOCATION_POPUP_DESC_2}
          </Typography>
          <CustomButton
            onClick={() => {
              profileData?.isPaid === false
                ? setToogleDialog(!toogleDialog)
                : navigate('/profile-looking-for', {
                    state: 'matching_location',
                  });
            }}
            variant='contained'
            fontWeight='400'
            type='submit'
            background='#F54D71'
            classname='capitalize b-radius-10'
            title={localesData?.userwork?.Yes}
          />
          <CustomButton
            onClick={() => setShowLocation(!showLocation)}
            variant='contained'
            fontWeight='400'
            type='submit'
            background='transparent'
            color='#000000'
            classname='capitalize b-radius-10'
            title={localesData?.userwork?.No}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Matching;
