import React from 'react';
import { Box, Typography, FormHelperText } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CustomButton from '../../UI/Button/CustomButton';
import { CustomTextField } from '../../UI/textfield';

import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { useDispatch } from 'react-redux';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';

const ForgotWithEmail = ({
  localesData,
  handleNext,
  profileData,
  changeComponent,
  setLoader,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          email: profileData?.email ? profileData?.email : '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(localesData?.validation?.REQUIRED)
            .email(localesData?.validation?.EMIAL_INVALID),
        })}
        onSubmit={async (requestData) => {
          dispatch(addProfileData({ key: 'email', value: requestData?.email }));
          try {
            setLoader(true);
            const { status, data } = await axiosInstance.get(
              URLS.FORGOT_PASSWORD +
                `/${requestData?.email}/${profileData.phone_number}`
            );
            if (status === 200) {
              setApiMessage('success', data?.message);
              setLoader(false);
              handleNext();
            }
          } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            <Box className='t-center mb-10'>
              <Typography variant='h3' className='s-bold mb-2'>
                {localesData?.HI} {profileData?.first_name}{' '}
                {/* {profileData?.last_name}, */}
              </Typography>
              <Typography
                variant='h4'
                dangerouslySetInnerHTML={{
                  __html: localesData?.forgot?.FORGOT_EMAIL_DESC,
                }}
              />
            </Box>
            <Box className='field-content mb-8'>
              <CustomTextField
                fullWidth
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                variant='standard'
                className='custom-textfield'
                name='email'
                value={values.email || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormHelperText className='custom-field-helper'>
                {localesData?.EMAIL}
              </FormHelperText>
            </Box>
            <CustomButton
              classname='uppercase'
              variant='contained'
              title={localesData?.SEND_NOW}
              background='#F54D71'
              fontWeight='600'
              type='submit'
            />
          </form>
        )}
      </Formik>
      {/* <Typography
        variant='h6'
        className='t-center pointer'
        onClick={() => changeComponent('phone')}
      >
        {localesData?.forgot?.SEND_SMS}
      </Typography> */}
    </>
  );
};

export default ForgotWithEmail;
