import React, { useState } from 'react';
import { Avatar, Box, Typography, Stack, Rating, Link } from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { ReactComponent as FavIcon } from 'src/assets/images/icons/favourites.svg';
import { ReactComponent as FavSelectedIcon } from 'src/assets/images/icons/favourites-white.svg';
import { ReactComponent as Benefits } from 'src/assets/images/icons/benefits.svg';
import { useNavigate } from 'react-router-dom';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useSelector } from 'react-redux';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import PreLoader from 'src/components/Loader';

const PlaceDetails = ({
  showPlaceDetails,
  showPlaceInfo,
  state,
  selectedCategory,
  handleBenefitClick,
  callBenefitIntoFav,
  callStatusApi,
  mainloader,
}) => {
  const reduxAuth = useSelector((state) => state?.auth_data?.auth);
  const localAuth = fetchFromStorage(identifiers.AUTH_DATA);
  const userData = fetchFromStorage(identifiers.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [favButton, setFavButton] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const checkIsBenefit = (marker) => {
    if (
      marker?.dataType &&
      marker?.dataType !== null &&
      marker?.dataType?.length !== 0 &&
      marker?.dataType === 'benefit'
    ) {
      return true;
    } else {
      return false;
    }
  };
  const checkVisibility = (place_detail) => {
    if (checkIsBenefit(place_detail)) {
      if (
        place_detail?.benefits !== null &&
        place_detail?.benefits?.length !== 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const AddToFavNow = async (ID, place_detail) => {
    setLoader(true);
    const param_data = {
      following: ID.toString(),
      type: 'place',
    };
    const { status, data } = await axiosInstance.post(
      URLS.FAVOURITE,
      param_data
    );
    if (status === 200) {
      callStatusApi(place_detail);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const GetDirectoryList = async (placeID, place_detail) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.DIRECTORY);
      if (status === 200) {
        var favID = '';
        data?.results?.map((item) => {
          if (placeID === item?.placeId) {
            favID = item?.id;
          }
        });
        favID && AddToFavNow(favID, place_detail);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };
  const callFavouriteAPI = async () => {
    setLoader(true);
    let place_detail = state.place_detail;
    const param_data = {
      name:
        place_detail?.place_type !== ''
          ? place_detail?.place_name.toString() +
            ' - ' +
            place_detail?.place_type
          : place_detail?.place_name.toString(),
      address:
        place_detail?.place_address != '' ||
        place_detail?.place_address != undefined
          ? place_detail?.place_address.toString()
          : '',
      lat: place_detail?.latitude.toString(),
      long: place_detail?.longitude.toString(),
      imageUrl: place_detail?.photo_reference
        ? place_detail?.photo_reference?.toString()
        : null,
      placeId: place_detail?.place_id.toString(),
      place_rating: place_detail?.place_rating,
      place_total_review: place_detail?.place_total_review,
      place_status: place_detail?.place_status,
      place_type: place_detail?.place_type,
      phoneNumber: place_detail?.isSponsered ? place_detail?.phoneNumber : null,
      sponsorName: place_detail?.isSponsered ? place_detail?.sponsorName : null,
      sponsorImage: place_detail?.isSponsered
        ? place_detail?.sponsorImage
        : null,
      sponsorWebsite: place_detail?.isSponsered ? place_detail?.webSite : null,
      benefitId: place_detail?.isSponsered ? place_detail?.benefitId : null,
      directoryType: place_detail?.isSponsered ? 'sponsor' : 'normal',
    };
    const { status, data } = await axiosInstance.post(
      URLS.DIRECTORY,
      param_data
    );
    if (status === 200) {
      GetDirectoryList(place_detail?.place_id.toString(), place_detail);
      setLoader(false);
    }
  };
  const callRemoveFavouriteAPI = async (FavID, placeDetails) => {
    setLoader(true);
    try {
      const { status } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + FavID
      );
      if (status === 200) {
        callStatusApi(placeDetails);
        setLoader(false);
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
      setLoader(false);
    }
  };
  const onFavPress = async () => {
    setLoader(true);
    if (!reduxAuth && !localAuth) {
      // log out
      navigate('/');
    } else {
      // if (state.isConnected) {
      if (state?.is_fav) {
        callRemoveFavouriteAPI(state?.fav_id, state?.place_detail);
      } else {
        if (userData?.isPaid) {
          let place_detail = state?.place_detail;
          if (state?.isDirectoryExist) {
            GetDirectoryList(place_detail?.place_id.toString(), place_detail);
          } else {
            callFavouriteAPI();
          }
        } else {
          setToogleDialog(true);
        }
      }
      setLoader(false);
      // }
      // else {
      //     //   Toast.show(ScreenText.NET_CONNECTION_ERROR_MESSAGE, Toast.LONG);
      //     setApiMessage('error', 'Please check your internet connection and try again.')
      // }
    }
  };
  const callRemoveBenefitFromFav = async (marker, benfits) => {
    setLoader(true);
    let marker_data = marker?.benefits;
    var RemovedId = marker_data[0].fav_id ? marker_data[0].fav_id : favButton;
    try {
      const { status } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + RemovedId
      );
      if (status === 200) {
        setLoader(false);
        // callBenefitIntoFav(true, benfits, marker?.id)
        // callBenefitIntoFav(marker)
        callBenefitIntoFav(false, marker?.id);
        handleBenefitClick(marker?.id);
        setFavButton(false);
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
      setLoader(false);
    }
  };
  const callAddBenefitIntoFav = async (marker, benfits) => {
    setLoader(true);
    let marker_data = marker?.benefits;
    const param_data = {
      following: marker_data[0].id,
      type: 'benefits',
    };
    const { status, data } = await axiosInstance.post(
      URLS.FAVOURITE,
      param_data
    );
    if (status === 200) {
      setLoader(false);
      setFavButton(data?.data?.favouriteId);
      callBenefitIntoFav(data?.data?.favouriteId, marker?.id);
      handleBenefitClick();
    } else {
      setLoader(false);
    }
  };

  return (
    <Box className='map-place-details'>
      {(loader || mainloader) && <PreLoader />}
      <Box className='map-place-details-sec'>
        <Box className='map-places'>
          <Box className='place-details-left'>
            <Avatar
              variant='square'
              className='place-image'
              src={showPlaceInfo?.icon}
            />
          </Box>
          <Box className='place-details-right'>
            <Typography className='place-name'>
              {showPlaceInfo?.place_name}
            </Typography>
            {showPlaceDetails && (
              <Box className='rating-details'>
                <Stack spacing={1}>
                  <Rating
                    name='half-rating'
                    className='rating-sec'
                    readOnly
                    defaultValue={showPlaceDetails?.rating}
                    precision={0.5}
                  />
                </Stack>
                <Typography className='rate-number'>
                  {'(' + showPlaceDetails?.user_ratings_total + ')'}
                </Typography>
              </Box>
            )}

            <Typography className='place-address'>
              {showPlaceInfo?.place_address}
            </Typography>
            {/* <Typography variant='span' className='place-status'>{showPlaceInfo?.categoryName + " •"} </Typography> */}
            {showPlaceInfo?.place_status === 'OPERATIONAL' && (
              <Typography variant='span' className='place-status color-green'>
                {' '}
                Open
              </Typography>
            )}
            <Box>
              {state?.place_detail?.webSite && (
                <Link
                  className='sponsored-name visit-link'
                  href={state?.place_detail?.webSite}
                  target='_blank'
                >
                  {'Visit Website'}
                </Link>
              )}
            </Box>
          </Box>
        </Box>
        <Box className='map-places mt-2'>
          <Box className='place-details-left'>
            <Avatar
              variant='square'
              className='place-image'
              src={showPlaceInfo?.icon}
            />
          </Box>
          <Box className='place-details-right'>
            <Typography className='sponsored-name'>Sponsored By</Typography>
            <Typography className='sponsored-name f-bold'>
              {showPlaceInfo?.place_name}
            </Typography>
          </Box>
        </Box>
        <Box
          className={
            state?.place_detail?.isSponsered &&
            state?.place_detail?.benefits &&
            state?.place_detail?.benefits !== null &&
            state?.place_detail?.benefits?.length !== 0
              ? 'fav-benefit-button-sec'
              : 'fav-benefit-button-sec fav-button-sec'
          }
        >
          {checkVisibility(state?.place_detail) && (
            <CustomButton
              variant='text'
              title={'Favourite'}
              classname={
                state?.is_fav || favButton
                  ? 'fav-bene-button pink-button ' //mr-9
                  : 'fav-bene-button '
              }
              leftIcon={
                state?.is_fav || favButton ? <FavSelectedIcon /> : <FavIcon />
              }
              onClick={() => {
                let place_detail = state?.place_detail;
                if (checkIsBenefit(place_detail)) {
                  if (userData?.isPaid) {
                    if (place_detail?.benefits[0]?.is_fav || favButton) {
                      callRemoveBenefitFromFav(
                        place_detail,
                        place_detail?.benefits
                      );
                    } else {
                      callAddBenefitIntoFav(
                        place_detail,
                        place_detail?.benefits
                      );
                    }
                  } else {
                    setToogleDialog(true);
                  }
                } else {
                  onFavPress();
                }
              }}
            />
          )}
          {state?.place_detail?.isSponsered &&
            state?.place_detail?.benefits &&
            state?.place_detail?.benefits !== null &&
            state?.place_detail?.benefits?.length !== 0 && (
              <CustomButton
                variant='text'
                title={'Benefits'}
                classname='fav-bene-button'
                leftIcon={<Benefits />}
                onClick={() => {
                  if (userData?.isPaid) {
                    if (
                      state?.place_detail?.benefits &&
                      state?.place_detail?.benefits !== null &&
                      state?.place_detail?.benefits.length !== 0
                    ) {
                      // navigate(`/benefits/${state?.place_detail?.id}`, { state: { placeDetails: state?.placeDetails } })
                      // state?.place_detail && state?.place_detail?.benefits && state?.place_detail?.benefits[0] && navigate(`/benefits/fav-benefit/${state?.place_detail?.benefits[0]?.id}`, { state: { placeDetails: state?.place_detail } })
                      state?.place_detail &&
                        state?.place_detail?.benefits &&
                        state?.place_detail?.benefits[0] &&
                        navigate(
                          `/benefits/${state?.place_detail?.benefits[0]?.id}`,
                          {
                            state: {
                              placeDetails: state?.place_detail,
                              selectedCategory: selectedCategory,
                            },
                          }
                        );
                    } else {
                      setApiMessage('error', 'Benefits are not available');
                    }
                  } else {
                    setToogleDialog(true);
                  }
                }}
              />
            )}
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default PlaceDetails;
