import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import '../profile.scss';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import SubDialogbox from 'src/views/component/UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from 'src/views/component/UI/Subscriptionpopup/SubscriptionDialog';
import { IntlContext } from 'src/App';

const UpgradePlan = ({ title, hidebtn }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);

  return (
    <>
      <Box className='mt-2'>
        {title && (
          <Typography
            variant='h6'
            className='s-font-20 color-pink'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        )}
        <Typography variant='h4' className='mt-2 mb-8 t-center'>
          {localesData?.YOUR_AC_TYPE} -{' '}
          <span className='s-bold'>
            {/* {profileData?.isPaid === false ? 'Free' : 'Paid'} */}
            {profileData?.isPaid === false
              ? localesData?.FREE
              : profileData?.subscription?.duration
              ? `${profileData?.subscription?.duration} paid`
              : localesData?.PAID}
          </span>
        </Typography>

        {profileData?.isPaid === false && !hidebtn ? (
          <Box className='t-center'>
            <CustomButton
              variant='contained'
              title={localesData?.UPGRADE_NOW}
              background='#6A42ED'
              onClick={() => {
                profileData?.isPaid
                  ? navigate('/subscription-plan')
                  : setToogleDialog(!toogleDialog);
              }}
              fontWeight='600'
              classname='custom-btn capitalize'
            />
          </Box>
        ) : (
          ''
        )}

        <SubDialogbox
          open={toogleDialog}
          handleClose={() => setToogleDialog(!toogleDialog)}
          title=''
          content={
            <SubscriptionPopup
              handleClose={() => setToogleDialog(!toogleDialog)}
              navigate={navigate}
            />
          }
        />
      </Box>
    </>
  );
};

export default UpgradePlan;
