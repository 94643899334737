import React from 'react';
import { Box } from '@mui/material';

import ConfirmPassword from './ConfirmPassword';

import './register.scss';

const Register = () => {
  return (
    <Box className="page-content-wrap">
      <ConfirmPassword />
    </Box>
  );
};

export default Register;
