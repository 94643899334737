import React, { useContext, useState } from 'react';
import { IntlContext } from 'src/App';
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';
import '../thrownBones.scss';
import { ReactComponent as CrossIcon } from 'src/assets/images/icons/cross-icon.svg';
import { ReactComponent as ChatIcon } from 'src/assets/images/icons/chat-icon.svg';
import { ReactComponent as PinkDogHeand } from 'src/assets/images/icons/dog-pink-heand-icon.svg';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NotiWomen } from 'src/assets/images/icons/noti-women.svg';
import { ReactComponent as NotiMen } from 'src/assets/images/icons/noti-men.svg';
import { ReactComponent as NotiDog } from 'src/assets/images/icons/noti-dog.svg';

const AcceptCard = ({ eventData }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(true);
  const [showCardDetails, setShowCardDetails] = useState(true);
  const [isDialogPopupOpen, setIsDialogPopupOpen] = useState(true);

  return (
    <>
      {/* {showDialog && (
        <Box>
          {showCardDetails ? ( */}
      <Dialog
        className='dailog-respond'
        open={isDialogPopupOpen}
        onClose={() => setIsDialogPopupOpen(false)}
      >
        <DialogContent>
          <Box className='respond-card-sec'>
            <Box className='respond-card-head pb-2'>
              <Box className='t-center'>
                <Typography variant='span' className='dog-name'>
                  {eventData?.data?.firstname}{' '}
                  <span className='head-text'>has</span>{' '}
                  <Typography variant='span' className='accepted-name'>
                    accepted
                  </Typography>
                </Typography>

                <Typography
                  className='head-text'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.repsond_card?.YOUR_BONE,
                  }}
                />
              </Box>
            </Box>
            <Box className='dog-img-card pb-7'>
              <Grid
                container
                xl={10}
                lg={10}
                md={10}
                sm={10}
                xs={10}
                justifyContent='space-between'
                alignContent='center'
                direction='row'
              >
                <img
                  src={eventData?.data?.images?.[0]?.url}
                  className='dog-img'
                />
                {/* <img
                  src={eventData?.data?.dogs?.images?.[0]?.url}
                  className='dog-img'
                /> */}
                {eventData?.data?.dogs?.images?.[0] &&
                eventData?.data?.dogs?.images?.[0]?.url?.endsWith('.mp4') ? (
                  <img
                    src={eventData?.data?.dogs?.images?.[1]?.url || ''}
                    className='dog-img'
                  />
                ) : (
                  <img
                    src={eventData?.data?.dogs?.images?.[1]?.url}
                    className='dog-img'
                  />
                )}
              </Grid>
            </Box>
            <Box className='content-sec'>
              {/* <Typography
                className='t-center content-text'
                dangerouslySetInnerHTML={{
                  __html: localesData?.repsond_card?.ANIMATION_RESPONSE,
                }}
              /> */}

              <Box className='d-flex align-center justify-center'>
                {eventData?.data?.gender === 'female' ? (
                  <NotiWomen className='women-icon' />
                ) : (
                  <NotiMen className='women-icon' />
                )}

                <NotiDog className='dog-icon' />
              </Box>
            </Box>
            <Box className='d-flex justify-center chat-sec pb-3'>
              <Button
                alt='Remy Sharp'
                variant='square'
                className='chat-icon'
                startIcon={<ChatIcon />}
                onClick={() => {
                  navigate(`/chat/${eventData?.data?.id}`);
                  setIsDialogPopupOpen(false);
                }}
              >
                <Typography
                  variant='h4'
                  className='chat-text'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.repsond_card?.CHAT,
                  }}
                />{' '}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AcceptCard;
