import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as ProfileIcon } from 'src/assets/images/icons/black-profile-icon.svg';
import { ReactComponent as CakeIcon } from 'src/assets/images/icons/black-cake-icon.svg';
import { ReactComponent as ScaleIcon } from 'src/assets/images/icons/black-scale-icon.svg';
import { ReactComponent as BoneIcon } from 'src/assets/images/icons/blak-dog-bone-icon.svg';
import { ReactComponent as LocationIcon } from 'src/assets/images/icons/black-location-icon.svg';
import { ReactComponent as DogIcon } from 'src/assets/images/icons/black-dog-icon.svg';
import { ReactComponent as GenderGirlIcon } from 'src/assets/images/icons/black-gender-girl-icon.svg';
import { ReactComponent as MitchIcon } from 'src/assets/images/icons/mitch-icon.svg';
// import { ReactComponent as DrinkIcon } from 'src/assets/images/icons//drinking-icon.svg';
// import { ReactComponent as CigaretteIcon } from 'src/assets/images/icons/smoking-icon.svg';
// import { ReactComponent as WeedIcon } from 'src/assets/images/icons/marijuana-icon.svg';
// import { ReactComponent as DrugIcon } from 'src/assets/images/icons/drugs-icon.svg';

import { IntlContext } from 'src/App';
import moment from 'moment';

const InfoKennel = ({ userData }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  var _age = '';

  if (userData?.dob !== '') {
    const d = moment(userData?.dob).format('MM/DD/YYYY');
    var msDiff = new Date().getTime() - new Date(d).getTime();
    var daysTill30June2035 = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    _age = Math.floor(daysTill30June2035 / 365);
  }

  return (
    <>
      <Box className='mt-4 info-about-card-wrapper'>
        {/* Info ABOUT US */}
        {userData?.dogs?.length !== 0
          ? localesData?.matching?.INFO_PROFILE
          : localesData?.matching?.INFO_PROFILE_ME}
        <hr />
        {/* PROFILE */}
        <Box className='info-containt-wrap'>
          <Box className='d-flex align-center mt-2'>
            {/* <img src={profileIcon} alt='name' style={{ marginRight: '12px' }} /> */}
            <ProfileIcon className='info-options-icon' />
            <Typography className='font-r-12'>
              {userData && userData?.firstname + ' ' + userData?.lastname}
            </Typography>
          </Box>
          <Box className='b-bottom mt-2' />
          <Box className='d-flex o-auto'>
            {/* AGE */}

            {userData && _age !== '' && (
              <Box className='d-flex info-options'>
                {/* <img src={cakeIcon} alt='age' className='info-options-icon' /> */}
                <CakeIcon className='info-options-icon' />
                <Typography variant='h5' className='info-option-text'>
                  {_age}
                </Typography>
              </Box>
            )}
            {/* HEIGHT */}

            {userData && userData?.height !== '' && (
              <Box className='d-flex info-options'>
                {/* <img
                  src={scaleIcon}
                  alt='height'
                  className='info-options-icon'
                /> */}
                <ScaleIcon className='info-options-icon' />
                <Typography variant='h5' className='info-option-text'>
                  {userData?.height}
                </Typography>
              </Box>
            )}
            {/* GENDER */}
            {userData && userData?.gender !== '' && (
              <Box className='d-flex info-options'>
                {/* <img
                  src={boneIcon}
                  alt='gender'
                  className='info-options-icon'
                  width={25}
                /> */}
                <BoneIcon className='info-options-icon' />
                <Typography variant='h5' className='info-option-text'>
                  {userData?.gender}
                </Typography>
              </Box>
            )}

            {/* ADDRESS */}
            {userData && userData?.location !== '' && (
              <Box className='d-flex info-options'>
                {/* <img
                  src={locationIcon}
                  alt='location'
                  className='info-options-icon'
                /> */}
                <LocationIcon className='info-options-icon' />
                <Typography variant='h5' className='info-option-text'>
                  {userData?.location}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {userData &&
          userData?.dogs?.map((item, i) => {
            return (
              <Box key={i} className='info-containt-wrap'>
                <Box className='d-flex align-center mt-4'>
                  {/* <img
                    src={dogIcon}
                    alt='dog icon'
                    style={{ marginRight: '12px' }}
                  /> */}
                  <DogIcon className='info-options-icon' />
                  <Typography className='font-r-12'>{item?.name}</Typography>
                  {item.deceased === true ? (
                    <>
                      <Box className='dog-deceased-icon' />
                      {item.deceased === true ? (
                        <Typography className='font-r-12'>Deceased</Typography>
                      ) : (
                        ''
                      )}
                    </>
                  ) : null}
                </Box>
                <Box className='b-bottom mt-2' />
                <Box className='d-flex o-auto'>
                  {/* AGE */}
                  {item?.age !== '' && (
                    <Box className='d-flex info-options'>
                      {/* <img
                        src={cakeIcon}
                        alt='age'
                        className='info-options-icon'
                      /> */}
                      <CakeIcon className='info-options-icon' />
                      <Typography variant='h5' className='info-option-text'>
                        {item?.age}
                      </Typography>
                    </Box>
                  )}
                  {/* BREED */}
                  {item?.breed !== '' && (
                    <Box className='d-flex info-options'>
                      {/* <img
                        src={dogIcon}
                        alt='height'
                        className='info-options-icon'
                      /> */}
                      <DogIcon className='info-options-icon' />
                      <Typography variant='h5' className='info-option-text'>
                        {item?.breed}
                      </Typography>
                    </Box>
                  )}
                  {/* GENDER */}
                  {item?.gender !== '' && (
                    <Box className='d-flex info-options'>
                      {/* <img
                        src={genderGirlIcon}
                        alt='gender'
                        className='info-options-icon'
                        width={25}
                      /> */}
                      <GenderGirlIcon className='info-options-icon' />
                      <Typography variant='h5' className='info-option-text'>
                        {item?.gender}
                      </Typography>
                    </Box>
                  )}

                  {/* MITCH */}
                  {/* {item?.mitch !== '' && ( */}
                  <Box className='d-flex info-options'>
                    {/* <img
                        src={mitchIcon}
                        alt='location'
                        className='info-options-icon'
                      /> */}
                    <MitchIcon className='info-options-icon' />
                    <Typography variant='h5' className='info-option-text'>
                      {item?.mitch}
                    </Typography>
                  </Box>
                  {/* )} */}
                </Box>
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default InfoKennel;
