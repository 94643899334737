import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { IntlContext } from '../../../../../App';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import IOSSwitch from '../../../UI/IOSSwitch';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import { useNavigate } from 'react-router-dom';
import UpgradePlan from '../UpgradePlan';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const YourVices = ({ handleNext, questions, setLoader, dispatch, answars }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [drinkingData, setDrinkingData] = useState('');
  const [smokingData, setSmokingData] = useState('');
  const [marijuanaData, setMarijuanaData] = useState('');
  const [LFData, setLFData] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [smokingChips, setSmokingChips] = useState([]);
  const [marijuanaChips, setMarijuanaChips] = useState([]);
  const [LFDataChips, setLFDataChips] = useState([]);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [vicesData, setVicesData] = useState('');
  const navigate = useNavigate();

  // const handleChange = (e) => {
  //   const { name, checked } = e.target;
  //   if (name === 'hobbiesPrivate') {
  //     setDrinkingData({ ...drinkingData, private: checked });
  //   }
  // };

  const handleChange = (e) => {
    const { checked } = e.target;
    setIsPrivate(checked);
  };

  useEffect(() => {
    answars?.map((item) => {
      if (item.id === 27) {
        setVicesData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: false,
        });
        setIsPrivate(item?.Option?.[0]?.private === 0 ? false : true);
      }
      if (item.id === 28) {
        setDrinkingData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: false,
        });
        setSelectedChips(item?.Option?.filter((item) => item?.selected === 1));
      }
      if (item.id === 29) {
        setSmokingData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: false,
        });
        setSmokingChips(item?.Option?.filter((item) => item?.selected === 1));
        // setIsPrivate(item?.Option?.[0]?.private === 0 ? true : false);
      }
      if (item.id === 30) {
        setMarijuanaData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: false,
        });
        setMarijuanaChips(item?.Option?.filter((item) => item?.selected === 1));
        // setIsPrivate(item?.Option?.[0]?.private === 0 ? true : false);
      }
      if (item.id === 31) {
        setLFData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: false,
        });
        setLFDataChips(item?.Option?.filter((item) => item?.selected === 1));
        // setIsPrivate(item?.Option?.[0]?.private === 0 ? true : false);
      }
      return true;
    });
  }, [answars]);

  const handleChipClick = (chip) => {
    if (chip?.questionId === 28) {
      if (!selectedChips?.includes(chip)) {
        setSelectedChips([chip]);
      } else {
        setSelectedChips((chips) =>
          chips?.filter((obj) => obj?.id !== chip.id)
        );
      }
    }
    if (chip?.questionId === 29) {
      if (!smokingChips?.includes(chip)) {
        setSmokingChips([chip]);
      } else {
        setSmokingChips((chips) => chips?.filter((obj) => obj?.id !== chip.id));
      }
    }
    if (chip?.questionId === 30) {
      if (!marijuanaChips?.includes(chip)) {
        setMarijuanaChips([chip]);
      } else {
        setMarijuanaChips((chips) =>
          chips?.filter((obj) => obj?.id !== chip.id)
        );
      }
    }
    if (chip?.questionId === 31) {
      if (!LFDataChips?.includes(chip)) {
        setLFDataChips([chip]);
      } else {
        setLFDataChips((chips) => chips?.filter((obj) => obj?.id !== chip.id));
      }
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    vicesData?.options?.map((item) => {
      opt.push({
        optionId: item.id,
        private: isPrivate,
        answer: '',
        selected: false,
      });
      return true;
    });

    drinkingData?.options?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        private: isPrivate,
        answer: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? '1'
          : '0',
      });
      return true;
    });

    smokingData?.options?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: smokingChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        private: isPrivate,
        answer: smokingChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? '1'
          : '0',
      });
      return true;
    });

    // marijuanaChips?.map((item) => {
    //   opt.push({
    //     optionId: item.id,
    //     selected: true,
    //     private: isPrivate,
    //   });
    // });

    marijuanaData?.options?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: marijuanaChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        private: isPrivate,
        answer: marijuanaChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? '1'
          : '0',
      });
      return true;
    });

    LFData?.options?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: LFDataChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        private: isPrivate,
        answer: LFDataChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? '1'
          : '0',
      });
      return true;
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'family_plans',
            value: opt,
          })
        );
        handleNext();
        setLoader(false);
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      <Box className='chip-container-box'>
        <UpgradePlan title={localesData?.profile?.YOUR_VICES} />
        <Box className='pb-4'>
          <Divider className='divider' />
        </Box>
      </Box>
      <Box className='t-center'>
        {/* <> */}

        {/* style={{ height: '365px' }} */}
        <Typography sx={{ textAlign: 'left' }} variant='h4' className='mb-2'>
          {localesData?.profile?.DRINKING}
        </Typography>

        <Box
          sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
          className='chip-container pb-4'
        >
          {/* <Box
            sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
            className='chip-container pb-4'
          > */}
          {drinkingData?.options?.map((item, i) => (
            <Chip
              className={`chip-inner font-r-11 ${
                selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
                  ? 'chip-active'
                  : ''
              } `}
              style={{ width: '100%' }}
              key={i}
              label={item?.title}
              onClick={() => handleChipClick(item)}
            />
          ))}
        </Box>

        <Typography sx={{ textAlign: 'left' }} variant='h4' className='mb-2'>
          {localesData?.profile?.SMOKING}
        </Typography>

        <Box
          sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
          className='chip-container pb-4'
        >
          {smokingData?.options?.map((item, i) => (
            <Chip
              className={`chip-inner font-r-11 ${
                smokingChips?.map((obj) => obj?.id)?.includes(item?.id)
                  ? 'chip-active'
                  : ''
              } `}
              style={{ width: '100%' }}
              key={i}
              label={item?.title}
              onClick={() => handleChipClick(item)}
            />
          ))}
        </Box>

        <Typography sx={{ textAlign: 'left' }} variant='h4' className='mb-2'>
          {localesData?.profile?.MARIJUANA}
        </Typography>
        <Box
          sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
          className='chip-container pb-4'
        >
          {marijuanaData?.options?.map((item, i) => (
            <Chip
              className={`chip-inner font-r-11 ${
                marijuanaChips?.map((obj) => obj?.id)?.includes(item?.id)
                  ? 'chip-active'
                  : ''
              } `}
              style={{ width: '100%' }}
              key={i}
              label={item?.title}
              onClick={() => handleChipClick(item)}
            />
          ))}
        </Box>

        <Typography sx={{ textAlign: 'left' }} variant='h4' className='mb-2'>
          {localesData?.profile?.LIFESTYLE_DRUGS}
        </Typography>
        <Box
          sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
          className='chip-container pb-4'
        >
          {LFData?.options?.map((item, i) => (
            <Chip
              className={`chip-inner font-r-11 ${
                LFDataChips?.map((obj) => obj?.id)?.includes(item?.id)
                  ? 'chip-active'
                  : ''
              } `}
              style={{ width: '100%' }}
              key={i}
              label={item?.title}
              onClick={() => handleChipClick(item)}
            />
          ))}
        </Box>
        {/* </> */}
        <Box className='edit-your-profile-box'>
          <Box className='profile-hobby'>
            {/* <Box sx={{ justifyContent: 'space-around' }} className='d-flex pb-4'>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    className='ios-switch'
                    onClick={() =>
                      setToogleDialog(isPrivate ? toogleDialog : !toogleDialog)
                    }
                  />
                }
                name='hobbiesPrivate'
                onChange={(e) => handleChange(e)}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {isPrivate ? <LockIcon /> : <UnLockIcon />}
          </Box> */}
            <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='isPrivate'
                  checked={isPrivate}
                  onChange={(e) => {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {isPrivate ? <LockIcon /> : <UnLockIcon />}
            </Box>

            <Box className='done-btn-sec'>
              <CustomButton
                variant='contained'
                title={localesData?.DONE}
                background={'#59D09D'}
                fontWeight='600'
                classname='done-btn s-bold'
                onClick={() => handleOnSubmit()}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </>
  );
};

export default YourVices;
