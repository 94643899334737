import React from "react";
import Page from "../../../components/Page";
import RegisterPage from "../../component/auth/Register";

const RegisterView = () => {
  return (
    <Page title="Sign Up">
      <RegisterPage />
    </Page>
  );
};

export default RegisterView;
