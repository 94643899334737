import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
} from '@mui/material';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/down-icon.svg';
import ProgressBar from '../../../UI/ProgressBar';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import IOSSwitch from '../../../UI/IOSSwitch';
import { CustomTextField } from '../../../UI/textfield';
import { CustomSelect } from 'src/views/component/UI/select';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const SomeOtherKids = ({
  localesData,
  handleNext,
  reduxProgress,
  questions,
  setLoader,
  dispatch,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  const navigate = useNavigate();
  const [youHaveKidsData, setYouHaveKidsData] = useState('');
  const [haveKidsIsPrivate, setHaveKidsIsPrivate] = useState(false);
  // const [error, setError] = useState(false);
  const [oldChildData, setOldChildData] = useState('');
  const [childGender, setChildGender] = useState('');
  const [otherData, setOtherData] = useState({
    totalKids: [],
    how_many_kids: '',
  });
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];

  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item.q_order === 17) {
  //         setYouHaveKidsData({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 18) {
  //         setOldChildData({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 19) {
  //         setChildGender({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       return true;
  //     });
  // }, [questions]);

  useEffect(() => {
    const item = questions
      ?.find((o) => o?.id === 2)
      ?.Question?.find((item) => item.q_order >= 17 && item.q_order <= 19);

    if (item) {
      switch (item.q_order) {
        case 17:
          setYouHaveKidsData({
            title: item.title,
            id: item.id,
            options: item.Option,
            answer: answars?.find((o) => o?.id === 20)?.Option?.[0]?.answer,
          });
          break;
        case 18:
          setOldChildData({
            title: item.title,
            id: item.id,
            options: item.Option,
          });
          break;
        case 19:
          setChildGender({
            title: item.title,
            id: item.id,
            options: item.Option,
          });
          break;
        default:
          break;
      }
    }
    if (answars?.length !== 0) {
      setOtherData({
        ...otherData,
        totalKids: JSON.parse(
          answars?.find((o) => o?.id === 21)?.Option?.[0]?.answer
        ),
      });
      setHaveKidsIsPrivate(
        answars?.find((o) => o?.id === 20)?.Option?.[0]?.private === 1 ||
          answars?.find((o) => o?.id === 20)?.Option?.[0]?.private === '1'
          ? true
          : false
      );
    }
    // eslint-disable-next-line
  }, [questions, answars]);

  const handleChange = (index, e) => {
    if (e.target.value !== '') {
      setErrorMessage('');
    }
    const { name, value, checked } = e?.target;
    if (name === 'youHaveKidsData') {
      setYouHaveKidsData({
        ...youHaveKidsData,
        answer: value,
      });

      setOtherData({
        ...otherData,
        totalKids: [],
        how_many_kids: value,
      });
    }

    if (name === 'kidsHaveSwitch') {
      setYouHaveKidsData({ ...youHaveKidsData, private: checked });
      setHaveKidsIsPrivate(checked);
    }
    if (name === 'oldChildData-' + index) {
      setOldChildData({ ...oldChildData, answer: value });
      let addAge = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, age_state: value };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addAge });
    }
    if (name === 'oldChildDataSwitch-' + index) {
      setOldChildData({ ...oldChildData, private: checked });
      let addAgePrivate = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, age_private: checked };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addAgePrivate });
    }
    if (name === 'childGender-' + index) {
      setChildGender({ ...childGender, answer: value });
      let addGender = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, gender_state: value };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addGender });
    }
    if (name === 'childGenderSwitch-' + index) {
      setChildGender({ ...childGender, private: checked });
      let addGenderPrivate = otherData?.totalKids?.map((obj, i) => {
        if (obj?.index === index) {
          return { ...obj, gender_private: checked };
        } else {
          return obj;
        }
      });
      setOtherData({ ...otherData, totalKids: addGenderPrivate });
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    opt.push({
      optionId: youHaveKidsData?.options[0]?.id,
      answer: youHaveKidsData?.answer === '' ? null : youHaveKidsData?.answer,
      selected: false,
      private: youHaveKidsData?.private ? youHaveKidsData?.private : false,
    });

    opt.push({
      optionId: 48,
      answer:
        otherData?.totalKids?.length === 0
          ? ''
          : JSON.stringify(otherData?.totalKids),
      selected: false,
      private: oldChildData?.private ? oldChildData?.private : false,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      if (!youHaveKidsData?.answer) {
        setErrorMessage('Please enter, how many kids do you have?.');
        return false;
      }
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        if (isEdit) {
          // checkForBlankData();
          checkForBlankAnsData();
        } else {
          handleNext();
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const onChangeText = (text) => {
    if (Number(text) === 0 || text.trim().length === 0) {
      setOtherData({
        ...otherData,
        totalKids: [],
        how_many_kids: text,
      });
    } else {
      for (let i = 1; i <= Number(text); i++) {
        otherData.totalKids.push({
          index: i,
          age_state: '',
          gender_state: '',
          age_private: false,
          gender_private: false,
        });
      }
      setOtherData({
        ...otherData,
        how_many_kids: text,
      });
    }
  };

  const checkForBlankAnsData = async () => {
    try {
      setLoader(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;
      if (status === 200) {
        data?.questions.map((item) => {
          if (breakIt === false) {
            if (item.id === 23 || item.id === 24) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(7));
            } else if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Box className='t-center'>
      <Box>
        <Typography
          className='mb-2 font-r-12'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
          }}
        />
        <Box className='mb-3'>
          <Typography variant='h4' className='pb-1'>
            {/* {reduxProgress}% */}
            {progressWeightage}%
          </Typography>
          <ProgressBar processData={progressWeightage} />
        </Box>
        <Typography variant='h3' className='s-bold pb-2'>
          {localesData?.userwork?.Now_its_all_about_you}
        </Typography>
        <Typography variant='h4' className='pb-4'>
          {localesData?.user_kids?.so_it_looks_like_you_have_some_kids}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'left' }} className='pb-6'>
        <Box className='field-content'>
          <CustomTextField
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            name='youHaveKidsData'
            onChange={(e) => handleChange('', e)}
            onBlur={(e) => onChangeText(e.target.value)}
            value={youHaveKidsData?.answer}
            onKeyPress={(e) => {
              if (/[^0-9]/g.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          {errorMessage && (
            <FormHelperText error>{errorMessage}</FormHelperText>
          )}
        </Box>
        <Box>
          <Typography variant='h5' className='s-bold pb-2'>
            {youHaveKidsData?.title}
          </Typography>
          <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                name='kidsHaveSwitch'
                // onChange={(e) => handleChange('', e)}
                checked={haveKidsIsPrivate}
                // onChange={(e) => {
                //   profileData?.isPaid === false
                //     ? setToogleDialog(true)
                //     : handleChange('', e);
                // }}
                onChange={(e) => {
                  if (isEdit) {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange('', e);
                  } else {
                    handleChange('', e);
                  }
                }}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {haveKidsIsPrivate ? <LockIcon /> : <UnLockIcon />}
          </Box>
        </Box>
        {otherData?.totalKids &&
          otherData?.totalKids?.map((kids) => {
            return (
              <>
                <Box className='field-content'>
                  <CustomTextField
                    onKeyPress={(e) => {
                      if (/[^0-9]/g.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    variant='standard'
                    className='custom-textfield pb-2'
                    name={`oldChildData-` + kids?.index}
                    value={kids?.age_state}
                    onChange={(e) => handleChange(kids?.index, e)}
                  />
                </Box>
                <Box>
                  <Typography variant='h5' className='s-bold pb-2'>
                    {/* {oldChildData?.title} */}
                    {localesData?.user_kids?.HOW_OLD_IS_CHILD} {kids?.index}?
                  </Typography>
                  <Box
                    sx={{ justifyContent: 'space-between' }}
                    className='d-flex pb-4'
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<IOSSwitch />}
                        name={`oldChildDataSwitch-` + kids?.index}
                        checked={kids?.age_private}
                        // onChange={(e) => handleChange(kids?.index, e)}
                        // onChange={(e) => {
                        //   profileData?.isPaid === false
                        //     ? setToogleDialog(true)
                        //     : handleChange(kids?.index, e);
                        // }}
                        onChange={(e) => {
                          if (isEdit) {
                            profileData?.isPaid === false
                              ? setToogleDialog(true)
                              : handleChange(kids?.index, e);
                          } else {
                            handleChange(kids?.index, e);
                          }
                        }}
                      />
                    </FormGroup>
                    <Typography variant='h5'>
                      {localesData?.userwork?.thats_private_dont_show_this}
                    </Typography>
                    {kids?.age_private ? <LockIcon /> : <UnLockIcon />}
                  </Box>
                </Box>
                <Box className='field-content'>
                  {/* <CustomTextField
                    fullWidth
                    variant='standard'
                    className='custom-textfield pb-2'
                    name={`childGender-` + kids?.index}
                    onChange={(e) => handleChange(kids?.index, e)}
                  /> */}

                  <Select
                    fullWidth
                    variant='standard'
                    className='custom-select'
                    name={`childGender-` + kids?.index}
                    input={<CustomSelect />}
                    IconComponent={DropdownIcon}
                    // value={values.dog_gender}
                    value={kids?.gender_state}
                    onChange={(e) => handleChange(kids?.index, e)}
                    displayEmpty
                  >
                    {genderList?.map((item, i) => (
                      <MenuItem
                        key={i}
                        value={item?.value}
                        className='select-options'
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography variant='h5' className='s-bold pb-2'>
                    {/* {childGender?.title} */}
                    {localesData?.user_kids?.WHAT_IS_CHILD} {kids?.index}'s{' '}
                    {localesData?.user_kids?.GENDER}?
                  </Typography>
                  <Box
                    sx={{ justifyContent: 'space-between' }}
                    className='d-flex pb-4'
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<IOSSwitch />}
                        name={`childGenderSwitch-` + kids?.index}
                        checked={kids?.gender_private}
                        // onChange={(e) => handleChange(kids?.index, e)}
                        // onChange={(e) => {
                        //   profileData?.isPaid === false
                        //     ? setToogleDialog(true)
                        //     : handleChange(kids?.index, e);
                        // }}
                        onChange={(e) => {
                          if (isEdit) {
                            profileData?.isPaid === false
                              ? setToogleDialog(true)
                              : handleChange(kids?.index, e);
                          } else {
                            handleChange(kids?.index, e);
                          }
                        }}
                      />
                    </FormGroup>
                    <Typography variant='h5'>
                      {localesData?.userwork?.thats_private_dont_show_this}
                    </Typography>
                    {kids?.gender_private ? <LockIcon /> : <UnLockIcon />}
                  </Box>
                </Box>
              </>
            );
          })}
      </Box>
      <Box>
        <CustomButton
          variant='contained'
          title={localesData?.userwork?.keep_going}
          background='#6A42ED'
          fontWeight='400'
          // type='submit'
          onClick={() => handleOnSubmit()}
        />

        <Box className='pb-8 mb-4 d-flex justify-center'>
          <DogIcon />
          <Typography
            variant='h5'
            className='pointer'
            // onClick={() => navigate('/')}
            onClick={() => redirectHomePage()}
          >
            {localesData?.userwork?.FINISH}
          </Typography>
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default SomeOtherKids;
