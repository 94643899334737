import { createSlice } from '@reduxjs/toolkit';

export const profileSetupSlice = createSlice({
  name: 'profileSetup',
  initialState: {},
  reducers: {
    addProfileData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    clearProfileData: (state) => {
      return {};
    },
  },
});

export const { addProfileData, clearProfileData } = profileSetupSlice.actions;

export default profileSetupSlice.reducer;
