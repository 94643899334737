import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IntlContext } from 'src/App';
import PreLoader from 'src/components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
// import EditProfile from '../ProfileEditing/EditProfile';
import WhatYouWantSeeYou from './WhatYouWantSeeYou';
import WhatYouLookingFor from './WhatYouLookingFor';
import YourEducation from './YourEducation';
import YourPhysicalAttributes from './YourPhysicalAttributes';
import YourFamilyPlan from './YourFamilyPlan';
import YourVices from './YourVices';
import YourLocation from './YourLocation';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import JobSelected from './JobSelected';
import YourChildrenFinal from './EditChildren';
import YourChildren from './EditChildren/YourChildren';
import { useLocation, useNavigate } from 'react-router-dom';
import YourHobbiesFinal from './EditHobbies';
import YourManuallyHobbies from './EditHobbies/YourManuallyHobbies';
import YourManuallyReligion from './EditReligion/YourManuallyReligion';
import YourEthnicityFinal from './EditEthnicity';
import YourReligionFinal from './EditReligion';
import YourManuallyEthnicity from './EditEthnicity/YourManuallyEthnicity';
import YourManuallyPolitics from './EditPolitics/YourManuallyPolitics';
import YourPoliticsFinal from './EditPolitics';

const EditYourProfile = () => {
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  //   const [answers, setAnswers] = useState([]);
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const reduxProgress = useSelector((state) => state?.progressBarData);
  const [questions, setQuestions] = useState([]);
  const [answars, setAnswars] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (reduxStep) {
      setActiveStep(reduxStep);
    } else {
      setActiveStep(0);
    }
  }, [reduxStep]);

  useEffect(() => {
    if (location?.state) {
      setActiveStep(location?.state);
    } else {
      navigate('/profile');
    }
  }, [location, navigate]);

  const handleNexts = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(handleNext(activeStep + 1));
  };

  const getAllQuestions = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.QUESTIONS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setQuestions(data?.types);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getAllAnswers = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setAnswars(data?.questions);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllQuestions();
    getAllAnswers();
  }, []);
  const renderComponent = () => {
    switch (activeStep) {
      // case 0:
      //   return (
      //     <EditProfile
      //       localesData={localesData}
      //       handleNext={handleNexts}
      //       setLoader={setLoader}
      //       dispatch={dispatch}
      //       reduxProgress={reduxProgress}
      //       answars={answars}
      //     />
      //   );
      case '1':
        return (
          <WhatYouWantSeeYou
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '2':
        return (
          <WhatYouLookingFor
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '3':
        return (
          <JobSelected
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '4':
        return (
          <YourEducation
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '5':
        return (
          <YourHobbiesFinal
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case 'yourManuallyHobbies':
        return (
          <YourManuallyHobbies
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '6':
        return (
          <YourReligionFinal
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case 'yourManuallyReligion':
        return (
          <YourManuallyReligion
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '7':
        return (
          <YourPoliticsFinal
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case 'yourManuallyPolitics':
        return (
          <YourManuallyPolitics
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '8':
        return (
          <YourChildrenFinal
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case 'yourChildren':
        return (
          <YourChildren
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      // case '9':
      //   return (
      //     <YourChildren
      //       localesData={localesData}
      //       handleNext={handleNexts}
      //       setLoader={setLoader}
      //       dispatch={dispatch}
      //       questions={questions}
      //       answars={answars}
      //     />
      //   );
      case '9':
        return (
          <YourPhysicalAttributes
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '10':
        return (
          <YourEthnicityFinal
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case 'YourManuallyEthnicity':
        return (
          <YourManuallyEthnicity
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '11':
        return (
          <YourFamilyPlan
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '12':
        return (
          <YourVices
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      case '13':
        return (
          <YourLocation
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
      default:
        return (
          <WhatYouWantSeeYou
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            answars={answars}
            reduxProgress={reduxProgress}
          />
        );
    }
  };

  return (
    <Box className=''>
      {/* main-page-content-wrap */}
      {loader && <PreLoader />}
      {renderComponent()}
    </Box>
  );
};

export default EditYourProfile;
