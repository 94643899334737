import React, { createContext, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { gapi } from 'gapi-script';

import GlobalStyles from './components/GlobalStyles';
import theme from './theme';

import messagesEn from './helpers/locales/en.json';
import messagesDe from './helpers/locales/de.json';

import { saveToStorage, fetchFromStorage, Config } from './helpers/context';
import { identifiers } from './helpers/constants/identifier';

import { publicRoute, userRoutes } from './routes';

import { LoginSocialInstagram } from 'reactjs-social-login';

import {
  SocketContext,
  initGroupSocketEvents,
  socket,
} from 'src/helpers/context/socket';

import {
  initOneSignalNotification,
  addListenerForNotificationOpened,
  isPushNotificationsEnabled,
  getUserId,
  // setDefaultNotificationUrl,
} from 'src/helpers/OneSignal/index';

import '../src/assets/scss/global.scss';
import { EventEmitter } from './helpers/EventEmitter';
import axiosInstance from './helpers/axios/axiosInstance';
import { URLS } from './helpers/constants/urls';
import DialogBox from './views/component/UI/PremiumPopup/PremiumDialogBox';
import CallScreen from './views/component/application/CallScreen';
import RespondCard from './views/component/application/KennelThrownBones/RespondCard';
import AcceptCard from './views/component/application/KennelThrownBones/RespondCard/Chat';
import RejectCard from './views/component/application/KennelThrownBones/RespondCard/RejectPopup';
import LinkRequest from './views/component/application/ManageProfile/AccountDetails/LinkRequest';
// import UnLinkRequest from './views/component/application/ManageProfile/AccountDetails/UnlinkRequest';
import RequestAccess from './views/component/application/ManageProfile/AccountDetails/RequestAccess';
import { setApiMessage } from './helpers/commonFunctions';
import { setCount } from 'src/helpers/store/actions/notificationCount';

const Context = createContext();
// initOneSignalNotification();
// addListenerForNotificationOpened();
// isPushNotificationsEnabled();

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxAuth = useSelector((state) => state?.auth_data?.auth);
  const localAuth = fetchFromStorage(identifiers?.AUTH_DATA);
  const IsVerified = fetchFromStorage(identifiers?.VERIFIED);

  const userDetail = fetchFromStorage(identifiers?.USER_DATA);
  const deviceID = fetchFromStorage(identifiers.DEVICE_ID);
  const [notificationType, setNotificationType] = useState('');
  const [toggleDialog, setToggleDialog] = useState(false);
  const [callDetails, setCallDetails] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [unLinkPopup, setUnlinkPopup] = useState(false);
  const [acceptPopup, setacceptPopup] = useState(false);
  const [rejectPopup, setrejectPopup] = useState(false);
  const [eventData, setEventData] = useState(null);
  const NOT_LINK_REQUEST_SEND = 'RequestSend';
  const NOT_LINK_REQUEST_REJECT = 'RequestReject';
  const NOT_LINK_REQUEST_ACCEPT = 'RequestAccept';
  const NOT_LINK_REQUEST_UNLINK = 'RequestUnlink';
  const NOT_LINK_REQUEST_CANCEL = 'RequestCancel';
  const auth = reduxAuth ? reduxAuth : localAuth;

  const menuMessages = {
    en: { ...messagesEn },
    de: { ...messagesDe },
  };

  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState(menuMessages['en']);

  const switchLanguage = (lang) => {
    saveToStorage(identifiers.ACCEPT_LANG, lang);
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };
  const viewRoutes =
    fetchFromStorage(identifiers?.VERIFIED) && auth ? userRoutes : publicRoute;

  const routing = useRoutes(viewRoutes);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: Config.GoogleAppID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        await initOneSignalNotification();
        addListenerForNotificationOpened();
        const isEnabled = await isPushNotificationsEnabled();
        if (isEnabled) {
          console.log('Push notifications are enabled!');
        } else {
          console.log('Push notifications are not enabled yet.');
        }
      } catch (error) {
        console.error('Error initializing OneSignal:', error);
      }
    };

    initializeOneSignal();
    return () => {};
  }, []);
  // ONSIGNAL
  useEffect(() => {
    EventEmitter.subscribe('handlenotificationredirect', (event) => {
      initOneSignalNotification();
      // handleNotificationRedirect(event);
    });

    // Request permission to show browser notifications
    // Notification.requestPermission();

    // Set up the notification click handler
    document.addEventListener('notificationclick', (event) => {
      event.notification.close();
      // Handle notification click event
      // ...
    });

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('notificationclick');
    };
  }, []);

  useEffect(() => {
    EventEmitter.subscribe('notificationDisplay', (event) => {
      console.log('notificationDisplay → event:', event, event?.data?.type);
      setNotificationType(event?.data?.type);
      setEventData(event?.data);

      if (event?.data?.type === 'accept') {
        setacceptPopup(true);
      } else if (event?.data?.type === 'reject') {
        setrejectPopup(true);
      } else if (event?.data?.type === 'send') {
        setShowPopup(true);
      } else if (
        event?.data?.type === NOT_LINK_REQUEST_SEND ||
        event?.data?.type === NOT_LINK_REQUEST_REJECT ||
        event?.data?.type === NOT_LINK_REQUEST_ACCEPT ||
        event?.data?.type === NOT_LINK_REQUEST_UNLINK ||
        event?.data?.type === NOT_LINK_REQUEST_CANCEL
      ) {
        setUnlinkPopup(event?.data?.type);
      }
      if (
        event?.data?.type === 'missed_call' ||
        event?.data?.type === 'end_call' ||
        event?.data?.type === 'reject_call'
      ) {
        setToggleDialog(false);
        setCallDetails('');
        setNotificationType('');
      }
      EventEmitter.subscribe('endcall', (data) => {
        // handleEndCall(event?.data?.type, data);

        if (callDetails?.data?.uuid === data?.uuid) {
          setToggleDialog(false);
          setNotificationType('end_call');
          setCallDetails('');
        }
      });
    });
  }, []);
  useEffect(() => {
    const handleNotificationDisplay = async (event) => {
      if (callDetails === '') {
        if (
          event?.data?.type === 'audio_call' ||
          event?.data?.type === 'video_call'
        ) {
          const devices = await navigator?.mediaDevices?.enumerateDevices();
          const hasVideoInput = devices?.some(
            (device) => device?.kind === 'videoinput'
          );

          if (!hasVideoInput && event?.data?.type === 'video_call') {
            setApiMessage(
              'error',
              'No camera available. you can not accept video calls.'
            );
            return;
          }

          setToggleDialog(true);
          setCallDetails(event);
        }
      }

      if (callDetails?.data?.uuid === event?.data?.uuid) {
        if (
          event?.data?.type === 'missed_call' ||
          event?.data?.type === 'end_call' ||
          event?.data?.type === 'reject_call'
        ) {
          setToggleDialog(false);
          setCallDetails('');
          setNotificationType('');
        }
      }
    };

    EventEmitter.subscribe('notificationDisplay', handleNotificationDisplay);

    // Clean up the subscription when the component unmounts
    return () => {
      EventEmitter.unsubscribe(
        'notificationDisplay',
        handleNotificationDisplay
      );
    };
  }, [callDetails]);

  // GET DEVICE ID
  useEffect(() => {
    var playerID = getUserId();
    Promise.all([playerID]).then((values) => {
      let data = {
        webAppToken: values?.[0],
      };
      if (auth && !userDetail?.webAppToken && values?.[0]) {
        registerDeviceID(data);
      }
    });
  }, [auth, deviceID, userDetail]);

  // ADD DEVICE ID
  const registerDeviceID = async (params) => {
    // alert('onesingle');
    if (params) {
      try {
        // setLoader(true);
        const { status } = await axiosInstance.post(
          URLS.REGISTER_ONE_SIGNAL_TOKEN,
          params
        );
        if (status === 200) {
          // if (type === 'onesignal') {
          //   saveToStorage('is_onesignal_token_registaered', '1');
          // } else {
          //   saveToStorage('is_voip_token_registered', '1');
          // }
          saveToStorage(identifiers.DEVICE_ID, params?.webAppToken);
        }
      } catch (error) {
        // alert('error');
        setApiMessage('error', error?.message);
      }
    }
  };

  // SOCKET
  useEffect(() => {
    if (userDetail && socket) {
      socket.emit('JoinSocket', {
        UserID: userDetail?.id + '',
      });
      socket.on('JoinSocket', (data) => {
        // console.log('JoinSocket', data);
      });
      socket.on('disconnect', (reason) => {
        // console.log('disconnect', reason);
        socket.connect();
      });
      socket.on('connect', (data) => {
        // console.log('connect', data);
        // socket.emit('JoinSocket', {
        //   UserID: userDetail?.id + '',
        // });
      });
      socket.on('error', (error) => {
        // console.log('error', error);
        socket.connect();
      });
      initGroupSocketEvents();
    }
  }, [userDetail]);

  const getNotificationCount = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        URLS.NOTIFICATION_COUNT + '/all'
      );
      if (status === 200) {
        const count =
          data?.count?.unreadMessageCount +
          data?.count?.collectedcount +
          data?.count?.matchescount +
          data?.count?.throwncount;
        dispatch(setCount(count));
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const updateNoti =
    location?.pathname === '/matching' ||
    location?.pathname === '/kennel' ||
    location?.pathname === '/directory' ||
    location?.pathname === '/benefits' ||
    location?.pathname === '/favourites';

  useEffect(() => {
    if (auth) {
      if (updateNoti) {
        getNotificationCount();
      }
    }
  }, [auth]);

  return (
    <React.Suspense fallback=''>
      <ThemeProvider theme={theme}>
        <Context.Provider value={{ locale, switchLanguage, messages }}>
          <SocketContext.Provider value={{ socket }}>
            {showPopup && <RespondCard eventData={eventData} />}
            {acceptPopup && <AcceptCard eventData={eventData} />}
            {rejectPopup && <RejectCard eventData={eventData} />}
            {unLinkPopup === NOT_LINK_REQUEST_SEND && (
              <LinkRequest eventData={eventData} />
            )}
            {(unLinkPopup === NOT_LINK_REQUEST_CANCEL ||
              unLinkPopup === NOT_LINK_REQUEST_REJECT ||
              unLinkPopup === NOT_LINK_REQUEST_UNLINK ||
              unLinkPopup === NOT_LINK_REQUEST_ACCEPT) && (
              <RequestAccess eventData={eventData} />
            )}
            <GlobalStyles />
            {routing}
            {/*  do not remove this code it will stop working connect instagram */}
            <div style={{ display: 'none' }}>
              <LoginSocialInstagram
                client_id={Config.InstagramClientID || ''}
                client_secret={Config.InstagramSecretKey || ''}
                redirect_uri={Config?.redirectURL + '/profile-setup'}
                onResolve={(data) => {
                  console.log(data);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
                scope={['user_profile', 'user_media']}
              />
              <LoginSocialInstagram
                client_id={Config.InstagramClientID || ''}
                client_secret={Config.InstagramSecretKey || ''}
                redirect_uri={Config?.redirectURL + '/user-profile'}
                onResolve={(data) => {
                  console.log(data);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
                scope={['user_profile', 'user_media']}
              />
            </div>
            {/* end */}

            <DialogBox
              open={toggleDialog}
              handleClose={() => setToggleDialog(!toggleDialog)}
              title=''
              className='call-screen-title call-box call-screen-content call-screen-close-icon card-dialog-box'
              closeIconShow={false}
              onCloseStatus={false}
              content={
                (notificationType === 'audio_call' ||
                  notificationType === 'video_call') && (
                  <CallScreen
                    handleEnd={() => setToggleDialog(!toggleDialog)}
                    callDetails={callDetails}
                    notificationType={notificationType}
                    endSetCallDetails={setCallDetails}
                  />
                )
              }
            />
          </SocketContext.Provider>
        </Context.Provider>
      </ThemeProvider>
    </React.Suspense>
  );
};

export default App;
export { Context as IntlContext };
