import React from 'react';
import Page from '../../../components/Page';
import FavPlaces from 'src/views/component/application/Directory/FavPlaces';

const FavPlacesPage = () => {
    return (
        <Page title='Direcory'>
            <FavPlaces />
        </Page>
    );
};

export default FavPlacesPage;
