import React, { useEffect, useRef, useState } from 'react';
import CallIcon from '@mui/icons-material/Call';
import VideocamIcon from '@mui/icons-material/Videocam';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { CONSTANT } from 'src/helpers/constants/identifier';
import { URLS } from 'src/helpers/constants/urls';
import { Config, fetchFromStorage } from 'src/helpers/context';
// import { socket } from 'src/helpers/context/socket';
import Video from 'twilio-video';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import CallScreen from 'src/views/component/application/CallScreen';
import { MSG_TYPE } from '../MsgType';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AudioVideoCalling = ({ callingType, reciverData }) => {
  const storageUserData = fetchFromStorage('userData');
  const navigate = useNavigate();
  const [isCallOngoing, setIsCallOngoing] = useState(false);
  const [ringingStatus, setRingingStatus] = useState('');
  const [roomName, setRoomName] = useState('');
  const [toggleDialog, setToggleDialog] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [callDetails, setCallDetails] = useState('');
  const [roomObj, setRoomObj] = useState('');
  const [acceptVideoCall, setAcceptVideoCall] = useState(false);
  const localVideoStartRef = useRef();
  const remoteVideosStartRef = useRef();
  const [remoteVideoElements, setRemoteVideoElements] = useState([]);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [localVideoAudioTrack, setLocalVideoAudioTrack] = useState(null);
  const [remoteParticipants, setRemoteParticipants] = useState([]);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    if (remoteVideoElements?.length !== 0) {
      remoteVideoElements?.forEach((element) => {
        remoteVideosStartRef?.current?.appendChild(element);
      });
    } else {
      localVideoStartRef.current = null;
      remoteVideosStartRef.current = null;
    }
  }, [remoteVideoElements]);

  // get image
  const getImage = (images) => {
    if (images !== null && images?.length !== 0) {
      for (let i = 0; i < images?.length; i++) {
        if (images[i]?.url !== null && images[i]?.url.length !== 0) {
          return images[i]?.url;
        }
      }
      return null;
    } else {
      return null;
    }
  };
  // Set Details For Start Call
  const handleCall = async () => {
    setErrors('');
    setRemoteVideoElements([]);
    setRemoteParticipants([]);
    const user_type = storageUserData?.isPaid;
    if (user_type === true) {
      let member_id = [];
      member_id.push(reciverData?.id);
      const devices = await navigator?.mediaDevices?.enumerateDevices();
      const hasVideoInput = devices?.some(
        (device) => device?.kind === 'videoinput'
      );

      if (!hasVideoInput && callingType === 'video_calling') {
        setApiMessage('info', 'No camera available. Starting audio call.');
        callingType = 'audio_calling';
      }

      let details = {
        room_name: storageUserData?.id + '_' + reciverData?.id,
        my_group_details: {
          group_name: reciverData?.firstname + ' ' + reciverData?.lastname,
          group_profile_pic: getImage(reciverData?.images),
        },
        other_group_details: {
          group_name:
            storageUserData?.firstname + ' ' + storageUserData?.lastname,
          group_profile_pic:
            storageUserData?.images && storageUserData?.images.length !== 0
              ? storageUserData?.images[0].url
              : '',
        },
        selected_member: member_id,
        call_type:
          callingType === 'video_calling'
            ? MSG_TYPE?.VIDEO_CALL
            : MSG_TYPE?.AUDIO_CALL,
        call_made_by_id: storageUserData?.id,
        reject_call_api_url: Config?.baseURL + URLS.REJECT_CALL,
        missed_call_api_url: Config?.baseURL + URLS.MISSED_CALL,
      };
      if (reciverData) {
        startCall(details);
      }
    } else {
      // show priumn modal
      setApiMessage(
        'error',
        'please upgarde your account, your account is free'
      );
    }
  };
  // start Call
  const startCall = (details) => {
    if (navigator.onLine) {
      setIsCallOngoing(true);
      setToggleDialog(true);
      setRingingStatus(CONSTANT?.CALL_STATUS_RINGING);
      // setCallDetails({ data: { content: details } });
      setNotificationType(
        details?.call_type === MSG_TYPE?.VIDEO_CALL
          ? 'start_video_call'
          : 'start_audio_call'
      );
      setRoomName(details?.room_name);
      getAccessToken(details, details?.call_type);
    } else {
      setApiMessage('error', 'Network connection error');
      setIsCallOngoing(false);
      setToggleDialog(false);
      setNotificationType('');
    }
  };
  const getAccessToken = async (details, type) => {
    const paramData = {
      userName: storageUserData?.id,
      roomName: details?.room_name,
      memberId: details?.selected_member,
      data: details,
      type: type,
    };

    try {
      const { status, data } = await axiosInstance.post(
        URLS.GET_TWILLIO_TOKEN,
        paramData
      );
      if (status === 200) {
        const twToken = data?.toekn; // Fixed typo in accessing the token property
        setCallDetails({ data: { content: details, uuid: data?.uuid } });

        try {
          const localTracks = await Video.createLocalTracks({
            video: type === MSG_TYPE?.VIDEO_CALL ? true : false,
            audio: true,
          });
          if (type === MSG_TYPE?.VIDEO_CALL) {
            const videoTrack = localTracks?.find(
              (track) => track?.kind === 'video'
            );
            const videoAudioTrack = localTracks?.find(
              (track) => track?.kind === 'audio'
            );
            setAcceptVideoCall(true);
            setLocalVideoTrack(videoTrack);
            setLocalVideoAudioTrack(videoAudioTrack);
            localVideoStartRef?.current.appendChild(videoTrack?.attach());
          } else if (type === MSG_TYPE?.AUDIO_CALL) {
            const audioTrack = localTracks?.find(
              (track) => track?.kind === 'audio'
            );
            setLocalAudioTrack(audioTrack);
          }
          connectTwillio(twToken, type);
        } catch (error) {
          console.error('Error starting the call:', error);
        }
      } else {
        setIsCallOngoing(false);
        setToggleDialog(false);
        setNotificationType('');
        setApiMessage(
          'error',
          'Unable to connect. Please try again after some time'
        );
      }
    } catch (error) {
      setIsCallOngoing(false);
      setToggleDialog(false);
      setNotificationType('');
      setApiMessage(
        'error',
        'Unable to connect. Please try again after some time'
      );
    }
  };
  const connectTwillio = async (token, callType) => {
    try {
      const room = await Video.connect(token, {
        name: roomName,
        video: callType === MSG_TYPE?.VIDEO_CALL ? true : false,
        audio: true,
      });
      setRoomObj(room);
      // console.log('Connected to Room "%s"', room.name);

      room.participants.forEach(participantConnected);
      room.on('participantConnected', participantConnected);

      room.on('participantDisconnected', participantDisconnected);
      room.once('disconnected', (error) =>
        room.participants.forEach(participantDisconnected)
      );
    } catch (error) {
      console.error('Error connecting to the room:', error);
    }
  };
  const disconnectCall = async () => {
    if (localVideoTrack) {
      const track = localVideoTrack?.mediaStreamTrack;
      localVideoTrack.stop();
      track.stop();
    }
    if (localAudioTrack) {
      localAudioTrack.stop();
    }
    if (localVideoAudioTrack) {
      localVideoAudioTrack.stop();
    }
    if (roomObj) {
      roomObj.disconnect();
      setRoomObj(null);
    }
    localVideoStartRef.current = null;
    remoteVideosStartRef.current = null;
    setRemoteVideoElements([]);
    setRemoteParticipants([]);
    setLocalVideoTrack(null);
    setLocalAudioTrack(null);
    setLocalVideoAudioTrack(null);
    setToggleDialog(false);
    setNotificationType('');
    setIsCallOngoing(false);
    setErrors('');
    setTimeout(() => {
      // window.location.reload();

      window.location.href = window?.location?.pathname;

      // const pathname = window.location.pathname;
      // const state = { message: true };
      // const queryString = new URLSearchParams(state).toString();
      // const urlWithState = `${pathname}?${queryString}`;
      // window.location.href = urlWithState;

      // window.location.href = '/kennel';

      // navigate('/kennel', {
      //   state: { message: true },
      // });
    }, 1000);
  };
  const participantConnected = (participant) => {
    // console.log('Participant "%s" connected', participant.identity);
    // console.log('participantstart', participant);
    setRemoteParticipants((prevParticipants) => [
      ...prevParticipants,
      participant,
    ]);
    const div = document.createElement('div');
    div.id = participant?.sid;
    // div.innerText = participant.identity;

    participant.on('trackSubscribed', (track) => trackSubscribed(div, track));
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.tracks.forEach((publication) => {
      if (publication?.isSubscribed) {
        trackSubscribed(div, publication?.track);
        // remoteVideosStartRef.current.appendChild(div);
      }
    });
    if (callingType === 'video_calling') {
      setRemoteVideoElements((prevElements) => [...prevElements, div]);
    }
    setRingingStatus(CONSTANT?.CALL_STATUS_CONNECTED);
    setErrors('');
  };
  const participantDisconnected = (participant) => {
    // console.log('Participant "%s" disconnected', participant.identity);
    // console.log('participantDisconnectedstart', participant);
    setRemoteParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p !== participant)
    );
    document.getElementById(participant?.sid)?.remove();
    setAcceptVideoCall(false);
    setToggleDialog(false);
    setNotificationType('');
    setRingingStatus(CONSTANT?.CALL_STATUS_DISCONNECTED);
    setErrors('');
    if (roomObj) {
      roomObj.disconnect();
    }
  };
  const trackSubscribed = (div, track) => {
    // div.appendChild(track.attach());
    if (track?.kind === 'video' || track?.kind === 'audio') {
      const mediaElement = track?.attach();
      div.appendChild(mediaElement);
    }
    // if (callingType === 'video_calling') {
    setRemoteVideoElements((prevElements) => [...prevElements]);
    // }
  };
  const trackUnsubscribed = (track) => {
    if (track?.kind === 'video' || track?.kind === 'audio') {
      track?.detach()?.forEach((element) => element?.remove());
    }
  };

  const toggleFacingMode = async () => {
    try {
      if (!localVideoTrack || !localVideoTrack?.mediaStreamTrack) {
        console.error('Local video track is not available.');
        return;
      }
      const track = localVideoTrack?.mediaStreamTrack;
      const currentFacingMode = track?.getSettings()?.facingMode;
      const newFacingMode =
        currentFacingMode === 'user' ? 'environment' : 'user';

      const videoConstraints = {
        video: { facingMode: { exact: newFacingMode } },
        audio: true,
      };
      setErrors('');
      // Stop the current local video track
      track.stop();
      localVideoTrack.stop();
      // Create the new local video track with the updated facing mode
      const newLocalTracks = await Video.createLocalTracks(videoConstraints);

      // Find the new video track
      const newVideoTrack = newLocalTracks?.find(
        (track) => track?.kind === 'video'
      );

      // Check if the new video track was created successfully
      if (!newVideoTrack) {
        console.error(
          'Failed to get the video track with the new facing mode.'
        );
        return;
      }

      // Update the local video track state
      setLocalVideoTrack(newVideoTrack);

      // Update remote video track
      if (roomObj && roomObj?.localParticipant) {
        const localParticipant = roomObj?.localParticipant;
        const newFacingMode = videoConstraints?.video?.facingMode?.exact;
        localParticipant?.tracks?.forEach((publication) => {
          const track = publication?.track;
          if (track && track?.kind === 'video') {
            track.restart({ facingMode: newFacingMode });
          }
        });
      }

      // Ensure the element is available before attaching the video track
      if (localVideoStartRef && localVideoStartRef.current) {
        localVideoStartRef.current.innerHTML = ''; // Clear any previous track
        localVideoStartRef.current.appendChild(newVideoTrack.attach());
      } else {
        console.error('video is not set or not a valid DOM element.');
      }
    } catch (error) {
      console.error('Error while toggling the camera facing mode:', error);
      setErrors("We're sorry, but we couldn't access your camera.");
    }
  };
  useEffect(() => {
    EventEmitter.subscribe('endcall', (data) => {
      // handleEndCall(event?.data?.type, data);

      if (callDetails?.data?.uuid === data?.uuid) {
        setToggleDialog(false);
        setNotificationType('end_call');
        setCallDetails('');
        disconnectCall();
      }
    });
    EventEmitter.subscribe('notificationDisplay', (event) => {
      // if (callDetails?.data?.uuid === event?.data?.uuid) {
      if (
        event?.data?.type === 'missed_call' ||
        event?.data?.type === 'end_call' ||
        event?.data?.type === 'reject_call'
      ) {
        setToggleDialog(false);
        setCallDetails('');
        disconnectCall();
      }
      // }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        onClick={() => {
          handleCall();
        }}
      >
        {callingType === 'audio_calling' ? <CallIcon /> : <VideocamIcon />}
      </div>
      <DialogBox
        open={toggleDialog}
        handleClose={() => {
          setToggleDialog(!toggleDialog);
          disconnectCall();
        }}
        title=''
        className='call-screen-title call-box call-screen-content call-screen-close-icon card-dialog-box'
        closeIconShow={false}
        onCloseStatus={false}
        content={
          isCallOngoing && (
            <CallScreen
              handleEnd={() => {
                setToggleDialog(!toggleDialog);
                setNotificationType('');
                setIsCallOngoing(false);
                disconnectCall();
              }}
              callDetails={callDetails}
              startAcceptVideoCall={acceptVideoCall}
              notificationType={notificationType}
              ringingStatus={ringingStatus}
              startCallRoomObj={roomObj}
              endSetCallDetails={setCallDetails}
              videoContent={
                acceptVideoCall && (
                  <div className='local-call-video start'>
                    {remoteVideoElements?.length !== 0 && (
                      <div
                        ref={remoteVideosStartRef}
                        className='remote-video'
                      />
                    )}
                    {/* {errors === '' ? ( */}
                    <div
                      ref={localVideoStartRef}
                      className={
                        remoteVideoElements?.length === 0
                          ? 'local-full-video'
                          : 'local-video'
                      }
                    />
                    {/* ) : ( */}
                    {errors && (
                      <Box className='video-not-allow-msg'>{errors}</Box>
                    )}
                    {/* )} */}
                  </div>
                )
              }
              startToggleFacingMode={toggleFacingMode}
              startRemoteParticipants={remoteParticipants}
            />
          )
        }
      />
    </>
  );
};

export default AudioVideoCalling;
