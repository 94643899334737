import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import '../thrownBones.scss';
import DogCard from '../DogCard';

import DummyImg from 'src/assets/images/logo-black.png';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import moment from 'moment';
import PreLoader from 'src/components/Loader';

const CollectBoneTab = ({ localesData, activeTab, selecteTab }) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [loader, setLoader] = useState(false);
  const [matchesData, setMatchesData] = useState([]);

  const handleBoxClick = (boxId) => {
    setSelectedBox((prevSelectedBox) =>
      prevSelectedBox === boxId ? null : boxId
    );
  };

  //GET MATCHES DATA
  const getMatchesdata = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.COLLECTED_BONES);
      if (status === 200) {
        setApiMessage('success', data?.message);
        const allThrownData = data?.users?.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.id === thing.id)
        );

        setMatchesData(allThrownData);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getMatchesdata();
  }, [selecteTab]);

  return (
    <>
      {loader && <PreLoader />}
      {matchesData?.length === 0 ? (
        <Box className='kennel-page-head-sec'>
          <Box className='dummy-img t-center'>
            <img
              alt='not found'
              className='no-data-dummy-img mb-4 opacity'
              src={DummyImg}
            />
          </Box>
          <Typography
            variant='h3'
            className='t-center'
            dangerouslySetInnerHTML={{
              __html:
                localesData?.kennel_tab_no_data_found?.COLLECTED_BONES_TAB,
            }}
          />
        </Box>
      ) : (
        <Box className='collected-bones-tab pb-18'>
          <Typography
            className='font-r-12 header-text'
            dangerouslySetInnerHTML={{
              __html: localesData?.collect_bones?.UP_NEXT,
            }}
          />
          <Typography className='font-r-12 content-text pb-3'>
            Free members can view all Thor likes once
          </Typography>
          {matchesData &&
            matchesData?.length > 0 &&
            matchesData?.map((collected, id) => {
              //AGE
              let age = '';
              if (collected?.dob !== '' && collected?.dob !== null) {
                const d = moment(collected?.dob).format('MMM DD, YYYY');
                const msDiff = new Date().getTime() - new Date(d).getTime();
                const daysTill30June2035 = Math.floor(
                  msDiff / (1000 * 60 * 60 * 24)
                );
                age = Math.floor(daysTill30June2035 / 365);
              }

              return (
                <>
                  <Box key={id}>
                    <DogCard
                      Cindex={id}
                      activeTab={activeTab}
                      collected={collected}
                      dogs={collected?.dogs}
                      getCollecteddata={getMatchesdata}
                      selectedBox={selectedBox}
                      firstDogBirthDate={age}
                      secondDogBirthDate={collected?.dogs?.age}
                      firstDogHeight={collected?.height}
                      firstDogLocationName={collected?.location}
                    />
                  </Box>
                </>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default CollectBoneTab;
