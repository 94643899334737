import React from 'react';
import Page from 'src/components/Page';
import LookingFor from 'src/views/component/application/ManageProfile/LookingFor';

const LookingForView = () => {
  return (
    <Page title='Looking For'>
      <LookingFor />
    </Page>
  );
};

export default LookingForView;
