import React from 'react';
import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import { fetchFromStorage } from '../../helpers/context';
import { identifiers } from '../../helpers/constants/identifier';

import TopBar from './TopBar';
import LogoUI from '../../views/component/UI/LogoComponent/LogoUI';
import ProgresImage from '../../views/component/UI/ProgressImage';
import ScrollToTop from '../../components/ScrollTop';

import clsx from 'clsx';
import '../layout.scss';

const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reduxAuth = useSelector((state) => state?.auth_data?.auth);
  const localAuth = fetchFromStorage(identifiers?.AUTH_DATA);
  const progressImg = useSelector((state) => state.progressImage);
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const auth = reduxAuth ? reduxAuth : localAuth;
  const isWelcome = location.pathname === '/';
  const isLoginImage = location.pathname === '/sign-in';
  const isPasswordImage = location.pathname === '/forgot-password';
  const isSignUpImage = location.pathname === '/sign-up';

  return (
    <Box className='body-image'>
      <Box
        className={clsx(
          'main-layout-wrapper',
          isLoginImage && reduxStep === 1
            ? 'auth-signup-img'
            : isLoginImage
            ? 'auth-login-img'
            : isPasswordImage && reduxStep !== 2
            ? 'auth-password-img'
            : isPasswordImage && reduxStep === 2
            ? 'auth-signup-img'
            : isSignUpImage
            ? 'auth-signup-img'
            : ''
        )}
      >
        <ToastContainer style={{ padding: '5px' }} limit={1} />
        {!isWelcome && (
          <>
            <TopBar auth={auth} navigate={navigate} />
          </>
        )}
        <ScrollToTop />
        <Box
          className={clsx(
            'layout-wrapper',
            isWelcome ? 'layout-without-header' : '',
            location?.pathname === '/sign-up' ? 'confirm-password' : ''
          )}
        >
          {!isWelcome && (
            <Box className='pb-8'>
              <LogoUI />
            </Box>
          )}
          <Outlet />
        </Box>
        {progressImg && <ProgresImage />}
      </Box>
    </Box>
  );
};

export default AuthLayout;
