import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { IntlContext } from '../../../../App';

import { CustomTextField } from '../../UI/textfield';
import CustomButton from '../../UI/Button/CustomButton';

import { ReactComponent as Visibility } from '../../../../assets/images/icons/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../../../assets/images/icons/visibility-off.svg';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { ReactComponent as Info } from 'src/assets/images/icons/info.svg';

import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';

const ConfirmPassword = ({ isForgot, setLoader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const profileData = useSelector((state) => state?.profileData);

  const [showEnterPassword, setShowCurrentPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  useEffect(() => {
    if (!profileData?.phone_number) {
      navigate('/sign-in');
    }
  }, [navigate, profileData]);

  return (
    <>
      <Formik
        initialValues={{
          enter_password: profileData?.password ? profileData?.password : '',
          repeat_password: profileData?.new_password
            ? profileData?.new_password
            : '',
        }}
        validationSchema={Yup.object().shape({
          enter_password: Yup.string()
            .required(localesData?.validation?.REQUIRED)
            .min(6, localesData?.validation?.PASSWORD_LENGTH),
          repeat_password: Yup.string()
            .required(localesData?.validation?.REQUIRED)
            .oneOf(
              [Yup.ref('enter_password'), null],
              localesData?.validation?.PASSWORD_EXACT_MATCH
            ),
        })}
        onSubmit={async (requestData) => {
          if (profileData?.otp) {
            let sendData = {
              otp: profileData?.otp,
              password: requestData?.enter_password,
              phoneNumber: profileData?.phone_number,
            };
            try {
              setLoader(true);
              const { status, data } = await axiosInstance.put(
                URLS.RESET_PASSWORD + `/${profileData?.email}`,
                sendData
              );
              if (status === 200) {
                setApiMessage('success', data?.message);
                setTimeout(() => {
                  navigate('/sign-in');
                  dispatch(handleNext(1));
                  setLoader(false);
                }, 200);
              }
            } catch (error) {
              setLoader(false);
              setApiMessage('error', error?.response?.data?.message);
            }
          } else {
            let sendData = {
              countryCode: profileData?.phone_country,
              username: profileData?.phone_number,
              // countryCode: profileData.phone_country,
              password: requestData?.enter_password,
              platform: 'web',
            };
            try {
              const { status, data } = await axiosInstance.post(
                URLS.SIGN_UP,
                sendData
              );
              if (status === 200) {
                // saveToStorage(identifiers?.AUTH_DATA, data?.token);
                // dispatch(addAuth(data?.token));
                navigate('/otp/mobile');
                dispatch(
                  addProfileData({
                    key: 'password',
                    value: requestData?.enter_password,
                  })
                );
                dispatch(
                  addProfileData({
                    key: 'new_password',
                    value: requestData?.repeat_password,
                  })
                );
              }
            } catch (error) {
              setApiMessage('error', error?.response?.data?.message);
            }
            // navigate('/profile-setup');
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            <Box className='t-center mb-5'>
              <Typography variant='h3' className='s-bold mb-2'>
                {localesData?.WELCOME}
              </Typography>
              <Typography
                variant='h4'
                dangerouslySetInnerHTML={{
                  __html: isForgot
                    ? localesData?.forgot?.PASSWORD_DESC
                    : localesData?.register?.DESC1,
                }}
              />
            </Box>
            <Box>
              <Box className='field-content mb-5'>
                <CustomTextField
                  fullWidth
                  error={Boolean(
                    touched.enter_password && errors.enter_password
                  )}
                  // helperText=
                  variant='standard'
                  className='custom-textfield'
                  name='enter_password'
                  value={values.enter_password?.replace(/\s+/g, '') || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={showEnterPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          disableRipple
                          onClick={() =>
                            setShowCurrentPassword(!showEnterPassword)
                          }
                        >
                          {showEnterPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {touched.enter_password && errors.enter_password && (
                  <FormHelperText className='color-error d-flex'>
                    <Info className='mr-9' />
                    {touched.enter_password && errors.enter_password}
                  </FormHelperText>
                )}

                <FormHelperText className='custom-field-helper'>
                  {localesData?.ENTER_PASSWORD}
                </FormHelperText>
              </Box>
              <Box className='field-content mb-10'>
                <CustomTextField
                  fullWidth
                  error={Boolean(
                    touched.repeat_password && errors.repeat_password
                  )}
                  // helperText={touched.repeat_password && errors.repeat_password}
                  variant='standard'
                  className='custom-textfield'
                  name='repeat_password'
                  value={values.repeat_password?.replace(/\s+/g, '') || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={showRepeatPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          disableRipple
                          onClick={() =>
                            setShowRepeatPassword(!showRepeatPassword)
                          }
                        >
                          {showRepeatPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {touched.repeat_password && errors.repeat_password && (
                  <FormHelperText className='color-error d-flex'>
                    <Info className='mr-9' />{' '}
                    {touched.repeat_password && errors.repeat_password}
                  </FormHelperText>
                )}
                <FormHelperText className='custom-field-helper'>
                  {localesData?.REPEAT_PASSWORD}
                </FormHelperText>
              </Box>
              <CustomButton
                variant='contained'
                title={localesData?.LOG_IN}
                background='#F54D71'
                fontWeight='400'
                type='submit'
              />
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmPassword;
