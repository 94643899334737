import React from 'react';
import Page from '../../../components/Page';
import ProfileCompletionAdvance from '../../component/application/ProfileCompletionAdvance';

const ProfileCompletionAdvanceView = () => {
  return (
    <Page title='Profile Completion Advance'>
      <ProfileCompletionAdvance />
    </Page>
  );
};

export default ProfileCompletionAdvanceView;
